import React from "react";
import { useTranslation } from "react-i18next";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import PageCenter from "../../commons/components/layout/pages/PageCenter";

const NotFoundPage = () => {
  const { t } = useTranslation();
  return (
    <PageCenter width={75}>
      <Box pt={6} textAlign="center" fontSize={200}>
        {/* <LinkOffIcon color="secondary" fontSize="inherit" /> */}
        <img
          src="/ng/images/beaver_error_logo.png"
          alt="SAD BEAVER"
          style={{ maxHeight: "50vh", mixBlendMode: "darken" }}
        />
      </Box>
      <Box pb={2}>
        <Typography variant="h2">{t("page.404.title")}</Typography>
      </Box>
      <Box>
        <Typography variant="h5">{t("page.404.description")}</Typography>
      </Box>
    </PageCenter>
  );
};

export function NotAllowedPage({
  error,
  errorMsg,
}: {
  error: string;
  errorMsg: string;
}) {
  const { t } = useTranslation();
  return (
    <PageCenter width={75}>
      <Box pt={6} textAlign="center" fontSize={200}>
        {/* <LinkOffIcon color="secondary" fontSize="inherit" /> */}
        <img
          src="/ng/images/beaver_error_logo.png"
          alt="SAD BEAVER"
          style={{ maxHeight: "50vh", mixBlendMode: "darken" }}
        />
      </Box>
      <Box pb={2}>
        <Typography variant="h2">{t(error)}</Typography>
      </Box>
      <Box>
        <Typography variant="h5">{t(errorMsg)}</Typography>
      </Box>
    </PageCenter>
  );
}

export default NotFoundPage;
