import React from "react";
import { Link } from "react-router-dom";
import _ from "lodash";

import { TypeDetailModal } from "./TypeDetailModal";

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  useTheme,
} from "@material-ui/core";
import { LinkWrap } from "components/beaver/components/report/common/LinkWrap";

import { TypeReportData } from "../../../../models/report/type/TypeReportData";
import { SubReportType } from "../../../../models/report/type/SubReportType";
import { SimpleTableCardView } from "../SimpleTableView";

import { useStyles } from "../sharedStyles";
import { useTranslation } from "react-i18next";
import { WarningOutlined } from "@material-ui/icons";

import { DecodeButton } from "../../common/DecodeButton";

import { isSimplified } from "components/beaver/helpers/ServerProperties";
import { Flag } from "components/beaver/helpers/Flag";

const md5 = require("md5");

export type TypeDetailProps = {
  data: TypeReportData;
  show: SubReportType;
  showSubView: string;
  setShowSubView: Function;
  testing?: boolean;
};

export const getTypeDetailWidth = (h) => {
  switch (h) {
    case "protocol":
    case "country":
    case "port":
    case "addedDate":
    case "analyser":
      return "1%";
    case "payloadClient":
    case "payloadServer":
    case "channel":
      return "40%";
    default:
      return "3%";
  }
};

export const renderField = (
  h,
  rowData,
  classes,
  t,
  showLine,
  setShowLine,
  // this is an override for addedOn date for when using a timeline popup
  addedOnDateOverride?: string
) => {
  if (h === "clusterName" && rowData[h] === "") return "UNKNOWN";
  if (!rowData[h] || rowData[h] === "UNKNOWN") return rowData[h];
  //domainName, ipAddress
  switch (h) {
    case "country":
    case "countryCode":
      return <Flag countryCode={rowData[h]} style={{ maxHeight: "1rem" }} />;
    case "payloadClient":
    case "payloadServer":
    case "channel":
      if (rowData[h].length > 250 && setShowLine) {
        return (
          <>
            <span className={classes.breakword}>
              {rowData[h].substr(0, 250) + " ... "}
            </span>
            {!addedOnDateOverride && (
              <Button
                size="small"
                color="primary"
                onClick={() => setShowLine(rowData)}
              >
                {t("View Full")}
              </Button>
            )}
          </>
        );
      } else return <span className={classes.breakword}>{rowData[h]}</span>;
    case "analyser":
      return <span className={classes.breakword}>{rowData[h]}</span>;
    case "lastStatusCode":
      return rowData[h] !== -1 ? rowData[h] : "UNKNOWN";
    case "md5":
    case "sampleMD5":
      return (
        <Link
          className={classes.breakword}
          to={"/md5/" + rowData[h] + "/report"}
        >
          <LinkWrap>{rowData[h]}</LinkWrap>
        </Link>
      );
    case "domain":
    case "callout":
    case "domainName":
      return (
        <Link
          className={classes.breakword}
          to={"/domain/" + rowData[h] + "/report"}
        >
          <LinkWrap>{rowData[h]}</LinkWrap>
        </Link>
      );
    case "ip":
    case "eventIP":
    case "contactIP":
    case "ipAddress":
      return (
        <Link to={"/ip/" + rowData[h] + "/report"}>
          <LinkWrap>{rowData[h]}</LinkWrap>
        </Link>
      );
    case "family":
      return isSimplified() ? (
        rowData[h]
      ) : (
        <Link to={"/tag/" + rowData[h] + "/report"}>
          <LinkWrap>{rowData[h]}</LinkWrap>
        </Link>
      );
    case "urlMd5":
      if (rowData["urlMd5"] && rowData["urlMd5"] !== "UNKNOWN") {
        return (
          <Link
            className={classes.breakword}
            to={["/url", rowData["urlMd5"], "report"].join("/")}
          >
            <LinkWrap>{rowData["urlMd5"]}</LinkWrap>
          </Link>
        );
      } else return "";
    case "clusterName":
      if ("UNKNOWN" !== rowData["clusterName"]) {
        return isSimplified() ? (
          rowData["clusterName"]
        ) : (
          <Link
            className={classes.breakword}
            to={["/cluster", rowData["clusterName"], "report"].join("/")}
          >
            <LinkWrap>{rowData["clusterName"]}</LinkWrap>
          </Link>
        );
      } else return "UNKNOWN";
    case "signatureID":
      return isSimplified() ? (
        rowData[h]
      ) : (
        <Link to={"/sid/" + rowData[h] + "/report"}>
          <LinkWrap>{rowData[h]}</LinkWrap>
        </Link>
      );
    case "addedOn":
      if (!!addedOnDateOverride) return addedOnDateOverride;
      else if (Array.isArray(rowData[h])) {
        let sorted = [...rowData[h]];
        sorted.sort((a, b) => b - a);
        return (
          <>
            {sorted[0]}
            {rowData[h].length > 1 && (
              <Button
                color="primary"
                size="small"
                onClick={() => setShowLine(rowData)}
              >
                {rowData[h].length - 1} {t("More")}
              </Button>
            )}
          </>
        );
      } else return rowData[h];
    case "url":
      // if no urlMD5 should then do a md5 on the url and use that
      if (rowData["urlMd5"] && rowData["urlMd5"] !== "UNKNOWN") {
        // https://gitlab.acid.azure.chi/ino/attackdetection/beaver-ui/-/issues/92 - when ct, there's a bug, so add http://
        if (rowData["sourceId"] === "ct") {
          return (
            <Link
              className={classes.breakword}
              to={
                "/url/" +
                md5(
                  rowData["url"].match(/^http:\/\//)
                    ? rowData["url"]
                    : "http://" + rowData["url"]
                ) +
                "/report"
              }
            >
              <LinkWrap>{rowData[h]}</LinkWrap>
            </Link>
          );
        }
        return (
          <Link
            className={classes.breakword}
            to={"/url/" + rowData["urlMd5"] + "/report"}
          >
            <LinkWrap>
              <DecodeButton>{rowData[h]}</DecodeButton>
            </LinkWrap>
          </Link>
        );
      } else if (rowData["url"]) {
        return (
          <Link
            className={classes.breakword}
            to={"/url/" + md5(rowData["url"]) + "/report"}
          >
            <LinkWrap>{rowData[h]}</LinkWrap>
          </Link>
        );
      } else return rowData[h];
    default:
      return rowData[h];
  }
};

export const showInTable = (field, type) => {
  //debugger;
  switch (type) {
    case "Cyber Threat Infrastructure (CTI)":
      return ![
        "sourceId",
        "urlMD5",
        "country",
        "region",
        "city",
        "asn",
      ].includes(field);
    case "Events":
      return ![
        "id",
        "uuid",
        "vulnerable",
        "asn",
        "asName",
        "others",
        "infection",
      ].includes(field);
    case "IP Location":
      return ![
        "ip",
        "countryCode",
        "areaCode",
        "regionCode",
        "metroCode",
      ].includes(field);
    case "Malware Callouts (Live PCAPs)":
      return !["payloadServerMD5", "payloadClientMd5"].includes(field);
    case "Mt Logan Events":
    case "Mt Blanc Events":
    case "Mt Everest Events":
    case "Mt Chetamon Events":
      return !["url", "eventId"].includes(field);
    default:
      return true;
  }
};

// this view is the details once you click on a header in the summary (e.g. beaver)

export function TypeDetail({
  data,
  show,
  showSubView,
  setShowSubView,
  testing,
}: TypeDetailProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const [showLine, setShowLine] = React.useState(null);

  React.useEffect(() => {
    if (
      !data.subreports[show]?.reports
        .map((s) => s.typeName)
        .includes(showSubView)
    ) {
      setShowSubView(null);
      // should be showing the first type with data.
      if (!!data.subreports[show]?.reports) {
        for (let report of data.subreports[show].reports) {
          if (report.data.length) {
            setShowSubView(report.typeName);
            break;
          }
        }
      }
    }
  }, [data.subreports, setShowSubView, show, showSubView]);

  return (
    <>
      <TypeDetailModal
        showSubView={showSubView}
        showLine={showLine}
        setShowLine={setShowLine}
      />
      <Box>
        {data.subreports[show]?.errors &&
        data.subreports[show].errors.length ? (
          <Box mb={2} style={{ color: theme.palette.error.main }}>
            {data.subreports[show].errors.map((e, i) => (
              <Box>
                {i === 0 && (
                  <>
                    <WarningOutlined />{" "}
                  </>
                )}
                {e}
              </Box>
            ))}
          </Box>
        ) : null}
      </Box>
      {data.subreports[show] &&
        data.subreports[show].reports.length > 1 &&
        data.subreports[show].reports.map((report, i) => (
          <Button
            onClick={() => setShowSubView(report.typeName)}
            variant={report.typeName === showSubView ? "outlined" : "text"}
            size={report.typeName === showSubView ? "large" : "medium"}
            disabled={!report.data.length}
            key={i}
          >
            {report.typeName}
          </Button>
        ))}
      {!showSubView && <p>{t("None")}</p>}
      {data.subreports[show] &&
        data.subreports[show].reports.map((report, i) => {
          let headers: string[] = [];
          if (report.data && report.data.length) {
            headers = Object.keys(report.data[0]);
            if (report.typeName === "IP Location") {
              headers = [
                "countryName",
                "city",
                "postalCode",
                "longitude",
                "latitude",
                "asnumber",
                "asname",
                "databaseDate",
              ];
            } else if (report.typeName === "IDS Rule") {
              headers = [
                "signatureID",
                "revisionID",
                "description",
                "ipAddress",
                "portNumber",
                "domainName",
                "sampleMD5",
                "addedOn",
              ];
            }
          }
          return (
            <Box
              style={{
                display: showSubView === report.typeName ? null : "none",
              }}
              mb={2}
              key={"sysreport" + report.typeName + i}
            >
              {report.data && report.data.length ? (
                <SimpleTableCardView
                  title={t(report.typeName)}
                  help={
                    _.snakeCase("tooltip." + report.typeName) !==
                    t(_.snakeCase("tooltip." + report.typeName))
                      ? _.snakeCase("tooltip." + report.typeName)
                      : null
                  }
                  headers={headers
                    .filter((field) => showInTable(field, report.typeName))
                    .filter(
                      (field) =>
                        !(
                          report.typeName ===
                            "Cyber Threat Infrastructure (CTI)" &&
                          field === "notificationDate"
                        )
                    )
                    .map((h, j) => {
                      return {
                        title: t(_.startCase(h)).replace(/M(d|D) 5/, "MD5"),
                        field: h,
                        render: (rowData) =>
                          renderField(
                            h,
                            rowData,
                            classes,
                            t,
                            showLine,
                            setShowLine
                          ),
                        width: getTypeDetailWidth(h),
                      };
                    })}
                  data={report.data.map((r) =>
                    _.mapValues(r, (v) => (v == null ? "" : v))
                  )}
                  testing={testing}
                />
              ) : (
                <Card>
                  <CardHeader title={report.typeName} className={classes.ch} />
                  <CardContent>{t("None")}</CardContent>
                </Card>
              )}
            </Box>
          );
        })}
    </>
  );
}
