import { originalURL, transformedURL } from "./transformations";
import { spiderReport, urlSource, urlStatus } from "./urlSummary";
import { communication } from "../../../models/report/url/communication";

export interface urlReportError {
  at: any;
  bt: string;
  errorMessage: string;
}

export interface urlReportData {
  dom: string;
  report: urlReport;
  communications: communication[];
  domamnt: number;
  sourceText: string;
  numSameFavicon: number;
  numSameTitle: number;
  md5: string;
  screenshotLinks?: string[];
  numActualURL?: number;
  reportErrors: urlReportError[];
}

export interface urlReport {
  artifact: { url: string; receivedDate: number; md5: string };
  md5: string;
  urlTitle: string;
  spiderReport?: spiderReport;
  status: urlStatus;
  urlCluster: any;
  sources: urlSource[];
  domainResolutions?: {
    domain: string;
    ip: string;
    countryCode: string;
    city: string;
    region: string;
    asNumber: string;
    asName: string;
    addedOn: string;
  }[];
  originalURLsFromTransformedURL?: originalURL[];
  transformedURLsFromOriginalURL?: transformedURL[];
  samePayloadURLs?: {
    requestedURL: string;
    source: string;
    addedOn: string;
  }[];
  requestedURLs?: {
    url: string;
    source: string;
    receivedDate: string;
  }[];
  ufsURLRequest?: {
    requestedURL: string;
  };
}

export enum URLReportSections {
  Summary = "SUMMARY",
  Dom = "DOM",
  SourceText = "SOURCE_TEXT",
  Communications = "COMMUNICATIONS",
  Title = "TITLE",
  FavIcon = "FAVICON",
  Transformations = "TRANSFORMATIONS",
  DNSResolutions = "DNS_RESOLUTIONS",
  Payload = "PAYLOAD",
  ActualURL = "ACTUAL_URL",
  Screenshot = "SCREENSHOT",
}

// TODO - show number of items in buttons <badge> and hide if disabled
export const buttonGroups = [
  URLReportSections.Summary,
  URLReportSections.Communications,
  URLReportSections.DNSResolutions,
  URLReportSections.Screenshot,
  URLReportSections.Dom,
  URLReportSections.SourceText,
];
export const buttonGroupPivots = [
  URLReportSections.Transformations,
  URLReportSections.Payload,
  URLReportSections.ActualURL,
  URLReportSections.FavIcon,
  URLReportSections.Title,
];
