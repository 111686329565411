import { createSlice } from "@reduxjs/toolkit";

export type ElasticURLSearchState = {
  searchText: string;
  searchTerms: any;
  searchResults: any;
  error: any;
};

export const slice = createSlice({
  name: "elasticurlsearch",
  initialState: {
    searchText: null,
    searchTerms: null,
    searchResults: null,
    error: null,
  } as ElasticURLSearchState,
  reducers: {
    setSearchText: (state, { payload }) => {
      state.searchText = payload;
    },
    setSearchTerms: (state, { payload }) => {
      state.searchTerms = payload;
    },
    setSearchResults: (state, { payload }) => {
      state.searchResults = payload;
    },
    setSearchError: (state, { payload }) => {
      state.error = payload;
    },
  },
});

export const {
  setSearchText,
  setSearchTerms,
  setSearchResults,
  setSearchError,
} = slice.actions;

export default slice.reducer;

// use the Redux chrome plugin to inspect the state
export const elasticURLSearchSelector = (state: any) => state.elasticurlsearch;
