import React, { useEffect } from "react";
import _ from "lodash";
import Axios, { Method } from "axios";

import { useDispatch, useSelector } from "react-redux";
import { beaverNGSelector, fetchReport } from "../../../../../slices/beaverng";

import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  LinearProgress,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import PageFullWidth from "../../../../../commons/components/layout/pages/PageFullWidth";
import PageHeader from "../../../../../commons/components/layout/pages/PageHeader";
import { useStyles } from "../common/sharedStyles";
import { ReportType } from "../../../models/report/reportType";
import { ParsedError, parseErrorTitle } from "../common/ParsedError";
import { SimpleTableCardView } from "../common/SimpleTableView";

import DeleteIcon from "@material-ui/icons/Delete";
import { AddSpamPhishingCriteria } from "./AddSpamPhishingCriteria";
import useAppLayout from "commons/components/hooks/useAppLayout";
import { DelSpamCriteria } from "components/beaver/helpers/ServerProperties";
import { UserProfileProps } from "commons/components/layout/topnav/UserProfile";

export function SpamPhishingList() {
  const { t } = useTranslation();

  const title = t("Spam Phishing");
  return (
    <>
      <Helmet>
        <title>{["BEAVER", title].join(" ")}</title>
        <meta
          name="description"
          content={["BEAVER", title, "-", t("page.home.description")].join(" ")}
        />
      </Helmet>
      <PageHeader title={title} />
      <PageFullWidth>
        <SpamPhishingReport />
      </PageFullWidth>
    </>
  );
}

export type SpamPhishingReportProps = {
  testing?: boolean;
};

export function SpamPhishingReport({ testing }: SpamPhishingReportProps) {
  const [retry, setRetry] = React.useState(0);
  const [adding, setAdding] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const classes = useStyles();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { currentUser } = useAppLayout();

  const { responseData, reportType, error } = useSelector(beaverNGSelector);

  // will run on first mount (or dispatch / retry changes)
  useEffect(() => {
    dispatch(fetchReport(ReportType.SPAM_PHISHING));
  }, [dispatch, retry]);

  // start by just showing the summary once loaded
  if (
    !error &&
    (!responseData || !reportType || reportType !== ReportType.SPAM_PHISHING)
  ) {
    // show a loading bar until it loads, and show the error if there is one
    return (
      <>
        {t("Loading")} ...
        <LinearProgress />
      </>
    );
  } else if (error) {
    return (
      <Card>
        <CardHeader
          className={classes.ch}
          avatar={<Avatar className={classes.errorIcon}>!</Avatar>}
          title={parseErrorTitle(error)}
        />
        <CardContent>
          <ParsedError error={error} />
        </CardContent>
        <CardActions>
          <Button size="small" onClick={() => setRetry(retry + 1)}>
            {t("Retry")}
          </Button>
        </CardActions>
      </Card>
    );
  } else {
    return (
      <>
        <Box mb={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setAdding(!adding)}
          >
            {t("Add Criteria")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: 5 }}
            onClick={() => setDeleting(!deleting)}
          >
            {t("Delete Criteria")}
          </Button>
        </Box>
        <Grid container justify="center" spacing={2}>
          {adding && (
            <Grid item xs={12} md={4} lg={3}>
              <AddSpamPhishingCriteria
                setAdding={setAdding}
                currentUser={currentUser}
              />
            </Grid>
          )}
          <Grid item xs={12} md={adding ? 8 : 12} lg={adding ? 9 : 12}>
            <SpamPhishingCriteraList
              testing={testing}
              criteriaList={responseData.flatCriterias}
              deleting={deleting}
              currentUser={currentUser}
            />
          </Grid>
        </Grid>
      </>
    );
  }
}

export interface SpamPhishingCriteria {
  id: number;
  searchType: string;
  searchCriteria: string;
  isRegex: boolean;
  attribution: string;
  authorUser?: string;
  addedDate: string;
}

export type SpamPhishingCriteraListProps = {
  testing?: boolean;
  criteriaList: SpamPhishingCriteria[];
  deleting: boolean;
  currentUser: UserProfileProps;
};

export function SpamPhishingCriteraList({
  testing,
  criteriaList,
  deleting,
  currentUser,
}: SpamPhishingCriteraListProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [delResponse, setDelResponse] = React.useState(null);
  const [postError, setPostError] = React.useState(null);

  function doDelete(id: number) {
    // do axios url-encoded post to /spam/search/list/remove with id=<id> as form data
    // then refresh the criteriaList (do dispatch again)
    let data = new URLSearchParams();
    data.append("id", "" + id);
    Axios({
      method: DelSpamCriteria().method as Method,
      url: DelSpamCriteria().url,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      data: data,
      auth:
        currentUser.server && !currentUser.server.includes("localhost")
          ? {
              username: currentUser ? currentUser.username : null,
              password: currentUser
                ? currentUser.onetime
                  ? window
                      // eslint-disable-next-line no-eval
                      .atob(eval(window.atob(currentUser.onetime)))
                      .substr(13)
                  : null
                : null,
            }
          : null,
    })
      .then((res) => {
        console.log(res);
        setDelResponse(res.data);
        // refresh table
        dispatch(fetchReport(ReportType.SPAM_PHISHING));
      })
      .catch((err) => {
        // show the post error if applicable
        console.log(err);
        setPostError(err.message);
      });
  }

  return (
    <>
      <SimpleTableCardView
        title={t("Criteria List")}
        testing={testing}
        data={_.cloneDeep(criteriaList)}
        headers={[
          { title: t("Type"), field: "searchType" },
          { title: t("Attribution"), field: "attribution" },
          {
            title: t("Criteria"),
            field: "searchCriteria",
            render: (r) => (
              <>
                <Chip
                  size="small"
                  label={r.isRegex ? "regex" : "string"}
                  color={r.isRegex ? "secondary" : "default"}
                />{" "}
                {r.searchCriteria}{" "}
                {deleting && (
                  <DeleteIcon
                    style={{ color: "darkred", cursor: "pointer" }}
                    onClick={() => doDelete(r.id)}
                  />
                )}
              </>
            ),
          },
          { title: t("Adder"), field: "authorUser" },
          { title: t("Date Added"), field: "addedDate" },
        ]}
      />
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={delResponse}
        autoHideDuration={6000}
        onClose={() => setDelResponse(null)}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setDelResponse(null)}
          severity="success"
        >
          {t("Deleted") + ": "} {delResponse && delResponse.searchType},{" "}
          {delResponse && delResponse.attribution},{" "}
          {delResponse && delResponse.searchCriteria}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={postError}
        autoHideDuration={6000}
        onClose={() => setPostError(null)}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setPostError(null)}
          severity="error"
        >
          {t("An Error Occured") + ": '" + postError + "' "}
        </MuiAlert>
      </Snackbar>
    </>
  );
}
