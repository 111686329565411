import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";

import { useDispatch, useSelector } from "react-redux";
import { uploadURLSelector, fetchReport } from "slices/uploadurls";

import { fetchSources, sourceSelector } from "slices/sources";

import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  LinearProgress,
  Tooltip,
} from "@material-ui/core";

import { LinkWrap } from "components/beaver/components/report/common/LinkWrap";

import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import PageFullWidth from "../../../../commons/components/layout/pages/PageFullWidth";
import PageHeader from "../../../../commons/components/layout/pages/PageHeader";

import { useStyles } from "../report/common/sharedStyles";
import { ParsedError, parseErrorTitle } from "../report/common/ParsedError";
import { ReportType } from "../../models/report/reportType";
import { SimpleTableView } from "../report/common/SimpleTableView";

import md5 from "md5";

// TODO - test file ?

export function UploadedURLs() {
  const { t } = useTranslation();

  const title = t("Uploaded URLs");
  return (
    <>
      <Helmet>
        <title>{["BEAVER", title].join(" ")}</title>
        <meta
          name="description"
          content={["BEAVER", title, "-", t("page.home.description")].join(" ")}
        />
      </Helmet>
      <PageHeader title={title} />
      <PageFullWidth>
        <UploadedURLsList />
      </PageFullWidth>
    </>
  );
}

export function UploadedURLsList() {
  const [retry, setRetry] = useState(0);
  const classes = useStyles();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { responseData, reportType, error } = useSelector(uploadURLSelector);

  useEffect(() => {
    dispatch(fetchReport(ReportType.UPLOAD_URL));
  }, [dispatch, retry]);
  // start by just showing the summary once loaded
  if (
    !error &&
    (!responseData || !reportType || reportType !== ReportType.UPLOAD_URL)
  ) {
    // show a loading bar until it loads, and show the error if there is one
    // TODO - translate the loading text
    return (
      <>
        {t("Loading")} ...
        <LinearProgress />
      </>
    );
  } else if (error) {
    // debugger;
    return (
      <Card>
        <CardHeader
          className={classes.ch}
          avatar={<Avatar className={classes.errorIcon}>!</Avatar>}
          title={parseErrorTitle(error)}
        />
        <CardContent>
          <ParsedError error={error} />
        </CardContent>
        <CardActions>
          {/* By incrementing the state value which is monitoired by useEffect, the useEffect is triggered*/}
          <Button size="small" onClick={() => setRetry(retry + 1)}>
            {t("Retry")}
          </Button>
        </CardActions>
      </Card>
    );
  } else {
    //TODO add upload button that links to /upload
    return (
      <UploadedURLsTable
        data={responseData[ReportType.UPLOAD_URL.toString()]}
      />
    );
  }
}

interface UploadedURLDataStatus {
  url: {
    md5: string;
    source: string;
  };
  request?: {
    //TODO - ignore if lastStatusCode is zero
    lastStatusCode?: number;
    lastConnectionOn?: string;
    lastSuccessOn?: string;
  };
  queueStatus: string;
  // TODO these are used to add info to status
  isCrawled: boolean;
  hasArtifact: boolean;
  hasBeenTransformed: boolean;
  clusterName?: string;
}

export interface UploadedURLData {
  sourceID?: string;
  url: string;
  status: UploadedURLDataStatus;

  userName: string;
  uploadedOn: string;
  downloadedFileMd5?: string;
}

function hasKangarooError(status: UploadedURLDataStatus): boolean {
  return (
    status.request &&
    !status.request.lastSuccessOn &&
    status.request.lastStatusCode === 200
  );
}

function hasStatusCodeSuccess(status: UploadedURLDataStatus): boolean {
  return (
    status.request &&
    status.request.lastSuccessOn &&
    _.floor(status.request.lastStatusCode / 100) === 2
  );
}

function hasConnectionSuccess(status: UploadedURLDataStatus): boolean {
  return status.request && status.request.lastConnectionOn !== null;
}

function getStatus(status: UploadedURLDataStatus) {
  if ("NOT_IN_QUEUE" !== status.queueStatus) {
    if (["LOCKED_FOREVER", "LOCKED_WITH_ERROR"].includes(status.queueStatus))
      return "LOCKED*";
    return status.queueStatus;
  }
  if (
    !hasStatusCodeSuccess(status) &&
    (hasConnectionSuccess(status) || hasKangarooError(status))
  ) {
    return "CRAWLED*";
  }

  return "CRAWLED";
}

function getStatusInfo(status: UploadedURLDataStatus) {
  switch (true) {
    case "LOCKED" === status.queueStatus:
      return "Locked, should be crawled in a few minutes";
    case "LOCKED_FOREVER" === status.queueStatus:
      return "Locked for too long, needs human intervention";
    case "LOCKED_WITH_ERROR" === status.queueStatus:
      return "Locked with an error, needs human intervention";
    case "QUEUED" === status.queueStatus:
      return "Queued, should be crawled soon";
    case status.isCrawled && hasKangarooError(status):
      return "Error during crawl";
    case status.isCrawled && !hasConnectionSuccess(status):
      return "Crawled, but no connection possible";
    case status.isCrawled && hasStatusCodeSuccess(status):
      return "Crawled with 2xx status code";
    case status.isCrawled && !hasStatusCodeSuccess(status):
      return (
        "Crawled with " +
        (status.request
          ? status.request.lastStatusCode
            ? status.request.lastStatusCode
            : "unknown"
          : "unknown") +
        " status code"
      );
    default:
      return "In the database, but never crawled directly";
  }
}

function getStatusCode(status: UploadedURLDataStatus) {
  if (status.request && status.request.lastStatusCode) {
    return status.request.lastStatusCode;
  }

  if (
    !hasStatusCodeSuccess(status) &&
    (hasConnectionSuccess(status) || hasKangarooError(status))
  ) {
    return "Unknown Status Code";
  }

  return null;
}

export type UploadedURLsTableProps = {
  data: UploadedURLData[];
  testing?: boolean;
};

export function UploadedURLsTable({ data, testing }: UploadedURLsTableProps) {
  const { t } = useTranslation();
  const [onlyMe, setOnlyMe] = React.useState(false);

  const dispatch = useDispatch();
  const { sources } = useSelector(sourceSelector);

  React.useEffect(() => {
    dispatch(fetchSources(sources));
  }, [dispatch, sources]);

  const getHeaders = () => {
    let headers = [
      {
        title: "MD5",
        field: "md5",
        render: (r) => (
          <Link
            style={{
              overflowX: "auto",
              whiteSpace: "pre-wrap",
              wordBreak: "break-word",
            }}
            to={["/url", r.md5, "report"].join("/")}
          >
            <LinkWrap>{r.md5}</LinkWrap>
          </Link>
        ),
        width: "20%",
      },
      {
        title: "URL",
        field: "url",
        render: (r) => (
          <Link
            style={{
              overflowX: "auto",
              whiteSpace: "pre-wrap",
              wordBreak: "break-word",
            }}
            to={["/url", r.md5, "report"].join("/")}
          >
            <LinkWrap>{r.url}</LinkWrap>
          </Link>
        ),
        width: "30%",
      },
      {
        title: t("Status"),
        field: "status",
        render: (r) => {
          let backgroundColor = "orangered";

          if (r.status === "CRAWLED") {
            backgroundColor = "limegreen";
          } else if (r.status === "CRAWLED*") {
            backgroundColor = "orange";
          }

          return r.status ? (
            <Tooltip title={t(r.statusInfo)} placement="left" arrow>
              <Chip
                style={{
                  borderColor: backgroundColor,
                  color: backgroundColor,
                }}
                variant="outlined"
                label={t(r.status)}
              />
            </Tooltip>
          ) : (
            // there is no status, so this means teh quick json loaded, but not the
            // result json with the status in it, so show the loading indicator
            <CircularProgress size="1.5em" />
          );
        },
        width: "8%",
      },
      {
        title: t("Status Code"),
        field: t("statusCode"),
        width: "8%",
      },
      { title: t("Source"), field: "source", width: "6%" },
      { title: t("Cluster"), field: "cluster", width: "8%" },
      {
        title: t("Downloaded File"),
        field: "downloadedFileMd5",
        render: (r) => (
          <Link
            style={{
              overflowX: "auto",
              whiteSpace: "pre-wrap",
              wordBreak: "break-word",
            }}
            to={["/md5", r.downloadedFileMd5, "report"].join("/")}
          >
            <LinkWrap>{r.downloadedFileMd5}</LinkWrap>
          </Link>
        ),
        width: "8%",
      },
      { title: t("Added On"), field: "uploadedOn", width: "8%" },
    ];

    return headers;
  };

  // TODO - add the upload button (just do a link to /upload)
  return (
    <>
      <Link to="/upload" style={{ textDecoration: "none" }}>
        <Button variant="contained" color="primary">
          {t("Upload a URL")}
        </Button>
      </Link>
      <Button
        style={{ marginLeft: "0.5em" }}
        variant="contained"
        color="primary"
        onClick={() => setOnlyMe(!onlyMe)}
      >
        {onlyMe ? t("All uploaded") : t("Uploaded by me")}
      </Button>
      <p>{t("Showing the last 1000 URLs uploaded only")}</p>
      <SimpleTableView
        data={data
          .filter((r) => !onlyMe || r.userName)
          .map((r) => ({
            url: r.url,
            md5: r.status ? r.status.url.md5 : md5(r.url),
            status: r.status ? getStatus(r.status) : null,
            statusInfo: r.status ? getStatusInfo(r.status) : null,
            statusCode: r.status ? getStatusCode(r.status) : null,
            cluster: r.status ? r.status.clusterName : null,
            downloadedFileMd5: r.downloadedFileMd5,
            userName: r.userName,
            uploadedOn: r.uploadedOn,
            source:
              sources && sources[r.sourceID]
                ? sources[r.sourceID].displayString
                : r.sourceID,
          }))}
        headers={getHeaders()}
        testing={testing}
        fixedLayout={true}
      />
    </>
  );
}
