import React from "react";
import { useTranslation } from "react-i18next";
import { cloneDeep } from "lodash";
import { Link } from "react-router-dom";
import {
  Badge,
  Box,
  Button,
  Chip,
  Card,
  CardHeader,
  CardContent,
} from "@material-ui/core";

import { SimpleTableView } from "../../common/SimpleTableView";
import { URLMD5Cluster } from "../../../../models/report/url/urlMd5Cluster";
import { LinkWrap } from "../../common/LinkWrap";

import { useStyles } from "../../common/sharedStyles";
import { Flag } from "components/beaver/helpers/Flag";
import { useDispatch, useSelector } from "react-redux";
import { fetchClusters, clusterSelector } from "slices/clusters";

export type URLMD5TagListTableProps = {
  testing: boolean;
  title?: string;
  show?: string;
  clustersObj: URLMD5Cluster;
};

export function URLMD5ClustersTable({
  testing,
  title,
  show,
  clustersObj,
}: URLMD5TagListTableProps) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [onlyLast30, setOnlyLast30] = React.useState(false);
  const { clusters } = useSelector(clusterSelector);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchClusters(clusters));
  }, [dispatch, clusters]);

  const headers = [
    {
      title: t("Cluster Name"),
      field: "clusterName",
      render: (row) => (
        <Link to={["/cluster", row.clusterName, "report"].join("/")}>
          <LinkWrap>
            <Badge color="secondary" max={10000} badgeContent={row.total}>
              <Chip label={row.clusterName} clickable color="primary" />
            </Badge>
          </LinkWrap>
        </Link>
      ),
    },
    {
      title: t("Total"),
      field: "total",
    },
    {
      title: t("Last 30 Days"),
      field: "last30",
    },
    {
      title: t("Subcluster Name"),
      field: "subcluster_name",
      render: (row) =>
        row.subcluster_name.split(",").map((s, i) =>
          "" !== s ? (
            <Box mr={0.5} style={{ display: "inline" }} key={i}>
              <Link
                to={
                  ["/cluster", row.clusterName, "report"].join("/") +
                  "?subcluster=" +
                  s
                }
              >
                <LinkWrap>
                  <Badge
                    badgeContent={
                      clusters && clusters[s] ? clusters[s].total : null
                    }
                    color="secondary"
                    max={10000}
                  >
                    <Chip label={s} clickable color="primary" />
                  </Badge>
                </LinkWrap>
              </Link>
            </Box>
          ) : null
        ),
      width: "40%",
    },
    {
      title: t("Cluster Country"),
      field: "cluster_country",
      render: (r) => (
        <Flag
          style={{ maxHeight: "1.25rem" }}
          countryCode={"" + r.cluster_country}
        />
      ),
    },
    { title: t("Sector"), field: "sector" },
  ];

  const headersToShow =
    "phishing" === show
      ? headers
      : "cpanel" === show
      ? [
          ...headers.slice(0, 1),
          {
            title: t("Category"),
            field: "clusterCategory",
            render: (r) => r.clusterCategory.toUpperCase(),
          },
          {
            title: t("Last 30 Days"),
            field: "last30",
          },
          ...headers.slice(1, 2),
        ]
      : headers.splice(0, 2);

  return (
    <Card>
      <CardHeader className={classes.ch} title={title} />
      <CardContent>
        <Button color="primary" onClick={() => setOnlyLast30(!onlyLast30)}>
          {!onlyLast30
            ? t("Only show Last 30 days")
            : t("Show All Cpanel Clusters")}
        </Button>
        <SimpleTableView
          testing={testing}
          data={
            "phishing" === show
              ? clustersObj.phishingClusters &&
                clustersObj.phishingClusters.length
                ? onlyLast30
                  ? cloneDeep(clustersObj.phishingClusters).filter(
                      (c) => c.last30
                    )
                  : cloneDeep(clustersObj.phishingClusters)
                : []
              : "cpanel" === show
              ? clustersObj.cpanelClusters && clustersObj.cpanelClusters.length
                ? onlyLast30
                  ? cloneDeep(clustersObj.cpanelClusters).filter(
                      (c) => c.last30
                    )
                  : cloneDeep(clustersObj.cpanelClusters)
                : []
              : clustersObj.urlMD5Clusters && clustersObj.urlMD5Clusters.length
              ? cloneDeep(clustersObj.urlMD5Clusters)
              : []
          }
          headers={headersToShow}
        />
      </CardContent>
    </Card>
  );
}
