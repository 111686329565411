import React from "react";
import { cloneDeep } from "lodash";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import { SimpleTableCardView } from "../../common/SimpleTableView";

import { metaScanModel } from "../../../../models/report/md5/metaScanModel";

import { useStyles } from "../../common/sharedStyles";

import { useTranslation } from "react-i18next";

export type MetaScanViewProps = {
  metaScanReport: metaScanModel;
  testing?: boolean;
};

export function MetaScanView({ metaScanReport, testing }: MetaScanViewProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box>
      <Card>
        <CardHeader className={classes.ch} title={t("MetaScan Report")} />
        <CardContent>
          <TableContainer>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell>{t("Analysis Date")}</TableCell>
                  <TableCell>{metaScanReport.creationDate}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>MD5</TableCell>
                  <TableCell>{metaScanReport.md5}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("Scan Date")}</TableCell>
                  <TableCell>{metaScanReport.scanDate}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>SHA1</TableCell>
                  <TableCell>{metaScanReport.sha1}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>SHA256</TableCell>
                  <TableCell>{metaScanReport.sha256}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("File Type")}</TableCell>
                  <TableCell>{metaScanReport.description}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("Detection Ratio")}</TableCell>
                  <TableCell>
                    {metaScanReport.total === 0 ? (
                      <span data-testid="zerotest">0</span>
                    ) : (
                      Math.floor(
                        (metaScanReport.positives / metaScanReport.total) * 100
                      ) +
                      "% (" +
                      metaScanReport.positives +
                      " / " +
                      metaScanReport.total +
                      ")"
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>

      <Box mt={2}>
        {/* TODO - figure out the update and scanner version values*/}
        <SimpleTableCardView
          testing={testing}
          title={t("Anti-Virus")}
          data={cloneDeep(Object.values(metaScanReport.results))}
          headers={[
            { title: t("AV"), field: "scannerName" },
            { title: t("Result"), field: "result" },
            { title: t("Update"), field: "" },
            { title: t("Scanner Version"), field: "" },
          ]}
        />
      </Box>
    </Box>
  );
}
