import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { beaverNGSelector, fetchReport } from "../../../../../slices/beaverng";
import { useTranslation } from "react-i18next";

import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  LinearProgress,
} from "@material-ui/core";

import { ReportType } from "../../../models/report/reportType";

import { useStyles } from "../common/sharedStyles";
import { TagTable } from "./views/TagTable";
import { ParsedError, parseErrorTitle } from "../common/ParsedError";

export function TagReport({ param }) {
  const [retry, setRetry] = React.useState(0);
  const classes = useStyles();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { responseData, reportType, error } = useSelector(beaverNGSelector);

  // will run on first mount
  useEffect(() => {
    dispatch(fetchReport(ReportType.TAG, param.replaceAll("+", " ")));
  }, [dispatch, param, retry]);

  // start by just showing the summary once loaded
  if (
    !error &&
    (!responseData || !reportType || reportType !== ReportType.TAG)
  ) {
    // show a loading bar until it loads, and show the error if there is one
    // TODO - translate the loading text
    return (
      <>
        {t("Loading")} ...
        <LinearProgress />
      </>
    );
  } else if (error) {
    return (
      <Card>
        <CardHeader
          className={classes.ch}
          avatar={<Avatar className={classes.errorIcon}>!</Avatar>}
          title={parseErrorTitle(error)}
        />
        <CardContent>
          <ParsedError error={error} />
        </CardContent>
        <CardActions>
          <Button size="small" onClick={() => setRetry(retry + 1)}>
            {t("Retry")}
          </Button>
        </CardActions>
      </Card>
    );
  } else {
    return (
      <>
        <p>{t("Limited to 500 MD5s")} </p>
        <TagTable
          title={t("MD5s Tagged by Users with") + " " + responseData.family}
          theTags={responseData.userMd5sTag}
        />
        <Box mt={2}>
          <TagTable
            title={
              t("MD5s Tagged (Generated) with") + " " + responseData.family
            }
            theTags={responseData.generatedMd5sTag}
          />
        </Box>
      </>
    );
  }
}
