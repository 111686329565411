import { createSlice } from "@reduxjs/toolkit";
import Axios from "axios";
import { TagListJSON } from "../components/beaver/helpers/ServerProperties";

// TODO - define error more properly (e.g. string?)
export type TagState = {
  tags: any;
  error: any;
};

export const slice = createSlice({
  name: "tags",
  initialState: {} as TagState,
  reducers: {
    setTags: (state, { payload }) => {
      state.tags = {};
      payload?.alltags?.forEach(
        (c) =>
          (state.tags[c.family.toLowerCase()] = {
            tagcount: c.tagcount,
            colour: c.colour,
            user: c.user,
            // not adding unix date / formatted date to redux state because won't be used other than in tag list (see AllTags for how it's done with moment)
            date: c.date,
            // it's redundant but you need family name for the list in TagList
            family: c.family,
          })
      );
    },
    setError: (state, { payload }) => {
      state.error = payload;
    },
  },
});

export default slice.reducer;

// use the Redux chrome plugin to inspect the state
export const tagsSelector = (state: TagState) => state.tags;

/**
 * This will fetch the tags if they have not already been fetched,
 * hence why you send the tags in the fetch
 *
 * @param tags you pass the current tags from the reducer
 */
export const fetchTags = (tags) => async (dispatch) => {
  //TODO - this should make the localstorage null if it can't parse- put this somewhere and export
  const user = localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser"))
    : {};

  if (!tags)
    Axios.get(TagListJSON(), {
      auth:
        user.server && !user.server.includes("localhost")
          ? {
              username: user ? user.username : null,
              password: user
                ? user.onetime
                  ? // eslint-disable-next-line no-eval
                    window.atob(eval(window.atob(user.onetime))).substr(13)
                  : null
                : null,
            }
          : null,
    }).then(
      (response) => {
        dispatch(slice.actions.setTags(response.data));
      },
      (error) => {
        // error being ommited from console.log so as not to pollute the tests
        dispatch(
          slice.actions.setError(
            error.response
              ? JSON.stringify(error.response)
              : JSON.stringify({
                  status: error.message.match(/\d+/g)
                    ? parseInt(error.message.match(/\d+/g)[0])
                    : 500,
                  message: error.message,
                  data: JSON.stringify(error),
                })
          )
        );
      }
    );
};
