import React, { ReactFragment } from "react";
import { Box, Typography } from "@material-ui/core";
import useAppLayout from "../../../../components/hooks/useMyLayout";

type PageHeaderProps = {
  title: string | ReactFragment;
};

const PageHeader: React.FC<PageHeaderProps> = ({ title }) => {
  const layout = useAppLayout();
  if (layout.useTBSLayout) return null;

  return (
    <Box display="inline-block" pt={3}>
      <Typography variant="h6">{title}</Typography>
    </Box>
  );
};

export default PageHeader;
