import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { cloneDeep } from "lodash";
import { useTranslation } from "react-i18next";
import { SimpleTableCardView } from "../../common/SimpleTableView";
import { fetchSources, sourceSelector } from "slices/sources";
import { useStyles } from "../../common/sharedStyles";
import {
  Card,
  Box,
  CardHeader,
  CardContent,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
} from "@material-ui/core";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { LinkWrap } from "../../common/LinkWrap";
import { ClipboardButton, ClipboardValue } from "../../common/ClipboardButton";

import { DecodeButton } from "../../common/DecodeButton";
const md5fn = require("md5");
export interface PayloadDetail {
  url: string;
  urlMd5: string;
  sourceId: string;
  addedOn: number;
  artifactMd5: string;
}

export function PivotPayload({
  samePayloadURLs,
  payloadDetails,
}: {
  samePayloadURLs: any;
  payloadDetails?: PayloadDetail[];
}) {
  const dispatch = useDispatch();
  const { sources } = useSelector(sourceSelector);

  React.useEffect(() => {
    dispatch(fetchSources(sources));
  }, [dispatch, sources]);
  const { t } = useTranslation();
  const classes = useStyles();

  // tracks index of which payload details you are looking at
  const [curPayload, setCurPayload] = React.useState(0);

  return (
    <>
      {!!payloadDetails && !!payloadDetails.length && (
        <Box className="simpleTableView" mb={2}>
          <Card>
            <CardHeader
              className={classes.ch}
              title={
                <>
                  {t("Payload Details")}{" "}
                  {/* Only show this if there is more than one payloadDetail to be shown */}
                  {payloadDetails.length > 1 && (
                    <>
                      <Button
                        size="small"
                        disabled={curPayload === 0}
                        onClick={() => {
                          setCurPayload(curPayload - 1);
                        }}
                      >
                        <ArrowBackIos />
                      </Button>
                      {curPayload + 1} {t("of")} {payloadDetails.length}
                      <Button
                        size="small"
                        disabled={curPayload === payloadDetails.length - 1}
                        onClick={() => {
                          setCurPayload(curPayload + 1);
                        }}
                      >
                        <ArrowForwardIos />
                      </Button>
                    </>
                  )}
                </>
              }
            />
            <CardContent>
              <TableContainer>
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell>{t("Request")}</TableCell>
                      <TableCell className={classes.breakword}>
                        <DecodeButton>
                          <ClipboardValue
                            value={payloadDetails[curPayload].url}
                          />
                        </DecodeButton>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t("Request MD5")}</TableCell>
                      <TableCell>
                        <ClipboardValue
                          value={payloadDetails[curPayload].urlMd5}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t("Source")}</TableCell>
                      <TableCell>
                        {sources && sources[payloadDetails[curPayload].sourceId]
                          ? sources[payloadDetails[curPayload].sourceId][
                              "displayString"
                            ]
                          : payloadDetails[curPayload].sourceId}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t("Added On")}</TableCell>
                      <TableCell>
                        {payloadDetails[curPayload].addedOn}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t("Payload MD5")}</TableCell>
                      <TableCell>
                        <Link
                          to={[
                            "/md5",
                            payloadDetails[curPayload].artifactMd5,
                            "report",
                          ].join("/")}
                        >
                          <LinkWrap>
                            {payloadDetails[curPayload].artifactMd5}
                          </LinkWrap>
                        </Link>{" "}
                        <ClipboardButton
                          value={payloadDetails[curPayload].artifactMd5}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Box>
      )}
      {samePayloadURLs ?
      <SimpleTableCardView
        title={"Pivot: " + t("URLs with the same payload")}
        data={cloneDeep(samePayloadURLs)}
        headers={[
          {
            title: t("Downloaded from"),
            field: "requestedURL",
            render: (r) => (
              <Link to={["/url", md5fn(r.requestedURL), "report"].join("/")}>
                <span className={classes.breakword}>
                  <LinkWrap>
                    <DecodeButton>{r.requestedURL}</DecodeButton>
                  </LinkWrap>
                </span>
              </Link>
            ),
          },
          {
            title: t("Source"),
            field: "source",
            render: (r) =>
              sources && sources[r.source]
                ? sources[r.source]["displayString"]
                : r.source,
          },
          { title: t("Date Received"), field: "addedOn" },
        ]}/>
        : (
          <Card>
            <CardHeader className={classes.ch} title={"Pivot: " + t("URLs with the same payload")} />
            <CardContent>
              {t("No Data to Display")}
            </CardContent>
          </Card>
        )}
    </>
  );
}
