import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { cloneDeep } from "lodash";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  LinearProgress,
} from "@material-ui/core";
import Axios from "axios";
import { URLReportJSONTitle } from "../../../../helpers/ServerProperties";
import { SimpleTableView } from "../../common/SimpleTableView";
import { ParsedError, parseErrorTitle } from "../../common/ParsedError";

import md5 from "md5";

import { useStyles } from "../../common/sharedStyles";
import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import { fetchSources, sourceSelector } from "slices/sources";

import { LinkWrap } from "components/beaver/components/report/common/LinkWrap";

import WarningOutlined from "@material-ui/icons/WarningOutlined";
import InfoIcon from "@material-ui/icons/Info";

function PivotTitleView({ md5 }) {
  const classes = useStyles();
  const [responseData, setResponseData] = useState(null);
  const [error, setError] = useState(null);
  const [retry, setRetry] = useState(0);

  const user = localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser"))
    : {};
  const { t } = useTranslation();

  // start by just showing the summary
  useEffect(() => {
    Axios.get(URLReportJSONTitle(md5), {
      auth:
        user.server && !user.server.includes("localhost")
          ? {
              username: user ? user.username : null,
              password: user
                ? user.onetime
                  ? // eslint-disable-next-line no-eval
                    window.atob(eval(window.atob(user.onetime))).substr(13)
                  : null
                : null,
            }
          : null,
    }).then(
      (response) => {
        console.log(response);
        setResponseData(response.data);
      },
      (error) => {
        console.error(error);
        setError(error.message);
      }
    );
    //unsure why adding user here makes multiple infinite requests
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [md5, retry]);

  // show a loading bar until it loads, and show the error if there is one
  if (!responseData && !error) {
    return (
      <>
        {t("Loading")} ...
        <LinearProgress />
      </>
    );
  } else if (error) {
    return (
      <Card>
        <CardHeader
          className={classes.ch}
          avatar={<Avatar className={classes.errorIcon}>!</Avatar>}
          title={t("Pivot Title - An Error Occured!") + parseErrorTitle(error)}
        />
        <CardContent>
          <ParsedError error={error} />
        </CardContent>
        <CardActions>
          <Button size="small" onClick={() => setRetry(retry + 1)}>
            {t("Retry")}
          </Button>
        </CardActions>
      </Card>
    );
  } else if (responseData) {
    return <PivotTitleCard data={responseData} />;
  } else {
    return null;
  }
}

function PivotTitleCard({
  data,
}: {
  data: {
    urlsWithSameTitle: { url: string; source: string; receivedDate: string }[];
    urlsWithSameTitleCount?: number;
    urlsWithSameTitleQuick?: boolean;
  };
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { sources } = useSelector(sourceSelector);

  React.useEffect(() => {
    dispatch(fetchSources(sources));
  }, [dispatch, sources]);

  return (
    <Card>
      <CardHeader className={classes.ch} title={"Pivot: " + t("Title")} />
      <CardContent>
        {!!data.urlsWithSameTitleQuick && !!data.urlsWithSameTitleCount && (
          <Box mb={2}>
            <InfoIcon style={{ verticalAlign: "middle" }} />{" "}
            {t(
              "An alternavtive query was run because the dataset was too large and so you may see duplicate urls below"
            )}
          </Box>
        )}
        {data.urlsWithSameTitle && data.urlsWithSameTitle.length >= 1000 ? (
          <Box mb={2}>
            <WarningOutlined style={{ verticalAlign: "middle" }} />{" "}
            {t("Please note that results are limited to 1000 entries")}
          </Box>
        ) : null}
        {data.urlsWithSameTitle ? (
          <SimpleTableView
            data={cloneDeep(data.urlsWithSameTitle)}
            headers={[
              {
                title: "URL",
                field: "url",
                render: (r) => (
                  <Link
                    className={classes.breakword}
                    to={["/url", md5(r.url), "report"].join("/")}
                  >
                    {" "}
                    <LinkWrap>{r.url}</LinkWrap>
                  </Link>
                ),
              },
              {
                title: t("Source"),
                field: "source",
                render: (r) =>
                  sources && sources[r.source]
                    ? sources[r.source].displayString
                    : r.source,
              },
              {
                title: t("Date Received"),
                field: "receivedDate",
                width: "10%",
              },
            ]}
          />
        ) : null}
      </CardContent>
    </Card>
  );
}

export default PivotTitleView;
