import React from "react";

import {
  Box,
  Button,
  FormControl,
  Grid,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import PageFullWidth from "../../../../../commons/components/layout/pages/PageFullWidth";
import PageHeader from "../../../../../commons/components/layout/pages/PageHeader";
import useAppLayout from "commons/components/hooks/useAppLayout";
import { UserProfileProps } from "commons/components/layout/topnav/UserProfile";
import { AddToPriorityBulkJSON } from "components/beaver/helpers/ServerProperties";
import Axios, { Method } from "axios";

export function AddPriorityBulk() {
  const { t } = useTranslation();
  // current user being passed for testing purposes
  const { currentUser } = useAppLayout();

  const title = t("Add to priority in bulk");
  return (
    <>
      <Helmet>
        <title>{["BEAVER", title].join(" ")}</title>
        <meta
          name="description"
          content={["BEAVER", title, "-", t("page.home.description")].join(" ")}
        />
      </Helmet>
      <PageHeader title={title} />
      <PageFullWidth>
        <AddPriorityBulkForm currentUser={currentUser} />
      </PageFullWidth>
    </>
  );
}

export function AddPriorityBulkForm({
  currentUser,
}: {
  currentUser: UserProfileProps;
}) {
  const { t } = useTranslation();
  const [hashes, setHashes] = React.useState("");
  const [comment, setComment] = React.useState("");
  const [res, setRes] = React.useState(null);
  const [resForWarns, setResForWarns] = React.useState(null);
  const [err, setErr] = React.useState(null);

  const doAddPriority = () => {
    // this is doen via application/json (of course)
    /* e.g. 
      {"bulk":{"comment":"test","md5s":[{"val":"86385447ce35c120511647c1ade5cef9"},
                                        {"val":"a10a04142f78888d78e1d264fe75ebfc"}]}}
    */

    const data = {
      bulk: {
        comment: comment,
        // split the multiline hash box, remove all that are empty after trimming,
        // then wrap it in object with key val and trim too (tested in browser console)
        md5s: hashes
          .split("\n")
          .filter((h) => h.trim() !== "")
          .map((h) => ({ val: h.trim() })),
      },
    };

    if (data?.bulk?.md5s?.length > 0)
      Axios({
        method: AddToPriorityBulkJSON().method as Method,
        url: AddToPriorityBulkJSON().url,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
        auth:
          currentUser.server && !currentUser.server.includes("localhost")
            ? {
                username: currentUser ? currentUser.username : null,
                password: currentUser
                  ? currentUser.onetime
                    ? window
                        // eslint-disable-next-line no-eval
                        .atob(eval(window.atob(currentUser.onetime)))
                        .substr(13)
                    : null
                  : null,
              }
            : null,
      })
        .then((res) => {
          console.log(res);
          setRes(res.data);
          setResForWarns(res.data);
        })
        .catch((err) => {
          console.log(err);
          setErr(err);
        });
  };

  return (
    <>
      <Grid container style={{ marginBottom: 20 }} spacing={2}>
        <Grid item xs={12} md={6}>
          <Paper style={{ padding: 15 }}>
            <Box style={{ display: "flex", flexDirection: "column" }}>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1em",
                }}
              >
                <Typography variant="subtitle1">
                  {t(
                    "Add a list of hashes (MD5/SHA1/SHA256) to the priority list (one per line)."
                  )}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  {t("Limited to 30")}
                </Typography>
              </Box>
              <FormControl style={{ marginTop: 15 }}>
                <TextField
                  variant="outlined"
                  multiline
                  rows={7}
                  label={t("Hashes")}
                  value={hashes}
                  onChange={(e) => setHashes("" + e.target.value)}
                />
              </FormControl>
              <FormControl style={{ marginTop: 15 }}>
                <TextField
                  label={t("Comment")}
                  value={comment}
                  onChange={(e) => setComment("" + e.target.value)}
                />
              </FormControl>
              <Typography variant="body2" style={{ marginTop: 10 }}>
                {/* {t("As")}: {currentUser.username} */}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => doAddPriority()}
                style={{ marginTop: 15 }}
              >
                {t("Add to priority list")}
              </Button>
              <Button style={{ marginTop: 10 }}>{t("Cancel")}</Button>
              {resForWarns &&
              Object.keys(resForWarns).filter((k) => k !== "total").length ? (
                <>
                  {t("Warnings")}:
                  {Object.keys(resForWarns)
                    .filter((k) => k !== "total")
                    .map((e, i) => (
                      <Typography key={i} variant="body1" color="textSecondary">
                        {e} - {resForWarns[e]}
                      </Typography>
                    ))}
                </>
              ) : null}
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={6000}
        open={!!res || !!err}
        onClose={() => {
          setRes(null);
          setErr(null);
        }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={err || (res && res.total === "0") ? "error" : "success"}
          onClose={() => {
            setRes(null);
            setErr(null);
          }}
        >
          {err?.response?.data && typeof err.response.data === "string"
            ? err.response.data
            : err
            ? t("An Error Occured")
            : res && res.total === "0"
            ? t("No Entries added")
            : res && res.total + " " + t("Added to Priority List")}
        </MuiAlert>
      </Snackbar>
    </>
  );
}
