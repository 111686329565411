import React, { useEffect } from "react";
import _ from "lodash";

import { useDispatch, useSelector } from "react-redux";
import { beaverNGSelector, fetchReport } from "../../../../../slices/beaverng";

import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  LinearProgress,
  Modal,
  Paper,
  Tooltip,
  useTheme,
} from "@material-ui/core";

import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import PageFullWidth from "../../../../../commons/components/layout/pages/PageFullWidth";
import PageHeader from "../../../../../commons/components/layout/pages/PageHeader";
import { useStyles } from "../common/sharedStyles";
import { ReportType } from "../../../models/report/reportType";
import { ParsedError, parseErrorTitle } from "../common/ParsedError";
import { SimpleTableCardView } from "../common/SimpleTableView";

// use the same pivot points as MD5 Yara Rules view - Refactor if used again elsewhere
import {
  YaraRuleFullJsonView,
  YaraRulePivot,
} from "components/beaver/components/report/md5/views/YaraAssemblyLineView";
// icon for pivot
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
// icon for details
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";

export function YaraRulesList() {
  const { t } = useTranslation();

  const title = t("Yara Rules");
  return (
    <>
      <Helmet>
        <title>{["BEAVER", title].join(" ")}</title>
        <meta
          name="description"
          content={["BEAVER", title, "-", t("page.home.description")].join(" ")}
        />
      </Helmet>
      <PageHeader title={title} />
      <PageFullWidth>
        <YaraRulesReport />
      </PageFullWidth>
    </>
  );
}

export type YaraRulesReportProps = {
  testing?: boolean;
};

export function YaraRulesReport({ testing }: YaraRulesReportProps) {
  const [retry, setRetry] = React.useState(0);
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();

  const dispatch = useDispatch();

  const { responseData, reportType, error } = useSelector(beaverNGSelector);

  // will run on first mount
  useEffect(() => {
    dispatch(fetchReport(ReportType.LIVE_HUNT));
  }, [dispatch, retry]);

  // start by just showing the summary once loaded
  if (
    !error &&
    (!responseData || !reportType || reportType !== ReportType.LIVE_HUNT)
  ) {
    // show a loading bar until it loads, and show the error if there is one
    // TODO - translate the loading text
    return (
      <>
        {t("Loading")} ...
        <LinearProgress />
      </>
    );
  } else if (error) {
    return (
      <Card>
        <CardHeader
          className={classes.ch}
          avatar={<Avatar className={classes.errorIcon}>!</Avatar>}
          title={parseErrorTitle(error)}
        />
        <CardContent>
          <ParsedError error={error} />
        </CardContent>
        <CardActions>
          <Button size="small" onClick={() => setRetry(retry + 1)}>
            {t("Retry")}
          </Button>
        </CardActions>
      </Card>
    );
  } else {
    if (responseData.yaraRules) {
      return <LiveHunt testing={testing} yaraRules={responseData.yaraRules} />;
    } else
      return (
        <Paper style={{ padding: theme.spacing(2) }} elevation={2}>
          {t(
            "Yara Rule Matches are currently being processed on the background. Please retry in a few minutes."
          )}
          <Box mt={2}>
            <Button size="small" onClick={() => setRetry(retry + 1)}>
              {t("Retry")}
            </Button>
          </Box>
        </Paper>
      );
  }
}

export interface YaraRulesType {
  id: number;
  ruleId: string;
  ruleVersion: string;
  ruleDescription: string;
  ruleName: string;
  ruleSource: string;
  sharing: string;
  category: string;
  malwareType: string;
  hits: number;
}

export interface LiveHuntProps {
  testing: boolean;
  yaraRules: YaraRulesType[];
}

export function LiveHunt({ testing, yaraRules }: LiveHuntProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [showRuleId, setShowRuleId] = React.useState(null);
  const [showRuleName, setShowRuleName] = React.useState(null);
  const [typeToShow, setTypeToShow] = React.useState(null);

  // you pretty much have to use modal!
  return (
    <Box>
      <Modal
        open={showRuleId && "pivot" === typeToShow ? true : false}
        onClose={() => {
          setTypeToShow(null);
          setShowRuleId(null);
        }}
      >
        <Paper
          style={{
            overflow: "auto",
            maxHeight: "80vh",
            width: "80vw",
            display: "block",
            margin: "auto",
            marginTop: "7vh",
          }}
        >
          <YaraRulePivot
            showRuleId={showRuleId}
            showRuleName={showRuleName}
            setTypeToShow={setTypeToShow}
            testing={testing}
          />
        </Paper>
      </Modal>

      <Modal
        open={showRuleId && "json" === typeToShow ? true : false}
        onClose={() => {
          setTypeToShow(null);
          setShowRuleId(null);
        }}
      >
        <Paper
          style={{
            overflow: "auto",
            maxHeight: "80vh",
            width: "80vw",
            display: "block",
            margin: "auto",
            marginTop: "7vh",
          }}
        >
          <YaraRuleFullJsonView
            showRuleId={showRuleId}
            showRuleName={showRuleName}
            setTypeToShow={setTypeToShow}
          />
        </Paper>
      </Modal>

      <SimpleTableCardView
        title={t("Yara Rules List")}
        testing={testing}
        data={_.cloneDeep(yaraRules)}
        headers={[
          {
            title: "",
            field: "id",
            render: (r) => (
              <span>
                <Tooltip title={t("MD5s with this Yara Rule")}>
                  <Button
                    color="primary"
                    onClick={() => {
                      if (r.id === showRuleId && "pivot" === typeToShow)
                        setShowRuleId(null);
                      else {
                        setShowRuleId(r.id);
                        setShowRuleName(r.ruleName);
                        setTypeToShow("pivot");
                      }
                    }}
                  >
                    <SearchOutlinedIcon />
                  </Button>
                </Tooltip>
                <Tooltip title={t("JSON for this Yara Rule")}>
                  <Button
                    color="primary"
                    onClick={() => {
                      if (r.id === showRuleId && "json" === typeToShow)
                        setShowRuleId(null);
                      else {
                        setShowRuleId(r.id);
                        setShowRuleName(r.ruleName);
                        setTypeToShow("json");
                      }
                    }}
                  >
                    <MoreHorizOutlinedIcon />
                  </Button>
                </Tooltip>
              </span>
            ),
          },
          {
            title: t("Rule ID"),
            field: "ruleId",
            render: (r) => (
              <span className={classes.breakword}>{r.ruleId}</span>
            ),
          },
          {
            title: t("Rule Description"),
            field: "ruleDescription",
            render: (r) => (
              <span className={classes.breakword}>{r.ruleDescription}</span>
            ),
          },
          {
            title: t("Rule Name"),
            field: "ruleName",
            render: (r) => (
              <span className={classes.breakword}>{r.ruleName}</span>
            ),
          },
          { title: t("Hits"), field: "hits", render: (r) => <b>{r.hits}</b> },
          {
            title: t("Rule Version"),
            field: "ruleVersion",
          },
          {
            title: t("Sharing"),
            field: "sharing",
          },
          {
            title: t("Category"),
            field: "category",
            render: (r) =>
              r.category +
              (r.malwareType ? " / " + r.malwareType : "") +
              (r.malware ? " - " + r.malware : ""),
          },

          {
            title: t("Actor Type"),
            field: "actorType",
          },
        ]}
      />
    </Box>
  );
}
