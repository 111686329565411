import React from "react";
import "./i18n";

import { createRoot } from "react-dom/client";
import App from "./components/app/App";
import "fontsource-roboto";
import { Provider } from "react-redux";
import rootReducer from "./slices";
import { configureStore } from "@reduxjs/toolkit";

const store = configureStore({
  reducer: rootReducer,
});

createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <App />
  </Provider>
);
