import { useNavigate } from "react-router-dom";
import { Chip } from "@material-ui/core";
import React from "react";
import { AbstractTag } from "../../../../models/report/md5/tags";
import { isSimplified } from "components/beaver/helpers/ServerProperties";

export function TagChip({
  tag: { colour, family },
  onDeleteFn,
}: {
  tag: AbstractTag;
  onDeleteFn?: () => void;
}) {
  const navigate = useNavigate();
  return (
    <Chip
      color="primary"
      style={{
        backgroundColor: colour ? colour : "primary",
        cursor: !isSimplified() ? "pointer" : "default",
      }}
      label={family}
      clickable
      onDelete={onDeleteFn}
      onClick={() => {
        if (!isSimplified()) navigate("/tag/" + family + "/report");
      }}
    />
  );
}
