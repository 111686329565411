import { createSlice } from "@reduxjs/toolkit";
import {
  MD5ReportJSON,
  MD5ReportJSONQuick,
  SHA1ReportJSON,
  SHA1ReportJSONQuick,
  SHA256ReportJSON,
  SHA256ReportJSONQuick,
} from "../components/beaver/helpers/ServerProperties";
import { ReportType } from "../components/beaver/models/report/reportType";
import { doRequest } from "./beaverng";

// TODO : define this more definitely (e.g. error?: string),
export type MD5ReportState = {
  responseData: any;
  error: string;
  reportType: ReportType;
};

export const slice = createSlice({
  name: "md5report",
  initialState: {} as MD5ReportState,
  reducers: {
    clearReport: (state) => {
      state.error = null;
      state.reportType = null;
      state.responseData = {};
    },
    setReportType: (state, { payload }) => {
      state.reportType = payload;
    },
    setReport: (state, { payload }) => {
      // see if the data is already there and not quick  before setting
      if (
        state?.responseData?.malwareReport?.quick ||
        payload?.malwareReport?.md5 !== state?.responseData?.malwareReport?.md5
      )
        state.responseData = { ...state.responseData, ...payload };
    },
    setError: (state, { payload }) => {
      state.error = payload;
    },
  },
});

export default slice.reducer;

// the state is {uploadurl:{uploadurl:{}}} to begin with - notice the extra query that wraps everything (set from the index)!
// use the Redux chrome plugin to inspect the state
// TODO - figure out the typescript type of state, dispatch, etc.
export const md5ReportSelector = (state) => state.md5report;

export const clearReport = () => async (dispatch) => {
  console.log("clearing md5 report");
  dispatch(slice.actions.clearReport);
};

// type is e.g. md5 or domain, value is e.g. the md5 or domain value / DNS
export const fetchReport =
  (type: ReportType, value?: string) => async (dispatch) => {
    if (ReportType.MD5 === type) {
      doRequest(slice, dispatch, MD5ReportJSONQuick(value), type);
      doRequest(slice, dispatch, MD5ReportJSON(value), type);
    } else if (ReportType.SHA1 === type) {
      doRequest(slice, dispatch, SHA1ReportJSONQuick(value), ReportType.MD5);
      doRequest(slice, dispatch, SHA1ReportJSON(value), ReportType.MD5);
    } else if (ReportType.SHA256 === type) {
      doRequest(slice, dispatch, SHA256ReportJSONQuick(value), ReportType.MD5);
      doRequest(slice, dispatch, SHA256ReportJSON(value), ReportType.MD5);
    } else {
      console.log(`Dispatch ${type} with value ${value} does not exist`);
    }
  };
