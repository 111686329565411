import React from "react";
import { Link } from "react-router-dom";
import { Box, Button, Typography } from "@material-ui/core";
import { getI18n, useTranslation } from "react-i18next";
import useAppLayout from "commons/components/hooks/useAppLayout";
import { MD5ReportJSONStatus } from "components/beaver/helpers/ServerProperties";
import { isLab, isStaging } from "components/beaver/helpers/ServerProperties";
import axios from "axios";

//TODO - maybe you want some sad beaver's here for consistency ?
export function ParsedError({ error }: { error: string }) {
  const { t } = useTranslation();
  // cannot use the coupling e.g. {setCurrentUser}=useAppLayout because of tests
  const appLayout = useAppLayout();
  const safeError = error
    .replace(/"[pP]assword":"[^"]*"/g, '"password":"************"')
    .replace(/"[aA]uthorization":"[^"]*"/g, '"Authorization":"************"')
    .replace(
      /\\"[pP]assword\\":\\"[^"]*"/g,
      '\\"password\\":\\"************\\"'
    )
    .replace(
      /\\"[aA]uthorization\\":\\"[^"]*"/g,
      '\\"Authorization\\":\\"************\\"'
    );
  const err = JSON.parse(safeError);

  // this will wait 2 seconds and redirect the user to the login screen if creds are no good.
  React.useEffect(() => {
    console.log(safeError);

    if (401 === err.status) {
      // debugger;
      // store the location if a 401 error so that when the user logs in again, we can program it to go there (unless LAB or STG)!
      if (!isLab() && !isStaging())
        window.localStorage.goToURLAfterLogin = document.location.href;
      setTimeout(() => {
        // there is clearly something wrong with the user, so make it null and refresh which
        // should force the login screen to appear.
        // note:  appLayout is null only during tests or you have a problem
        if (appLayout) appLayout.setCurrentUser(null);
        if (localStorage.ssoPrefix) localStorage.removeItem("ssoPrefix");
        if (localStorage.currentUser) localStorage.removeItem("currentUser");
        localStorage.lastError = t("Session Expired or Invalid Credentials");
        window.location.reload();
        //window.location.replace("/ng/logout");
      }, 0);
    }
  });

  const [processingMD5start, setProcessingMD5start] = React.useState(null);
  const [processingMD5finished, setProcessingMD5finished] =
    React.useState(null);
  const [timeToProcess, setTimeToProcess] = React.useState(null);
  const [processKilled, setProcessKilled] = React.useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const statusCheck = (myInterval) =>
    axios
      .get(
        MD5ReportJSONStatus(window.location.pathname.match(/[a-f0-9]{32}/)[0])
      )
      .then((res) => {
        if (res.data["processingMD5finished"]) {
          setProcessingMD5finished(
            new Date(res.data["processingMD5finished"]).toString()
          );
          setTimeToProcess(res.data["timeToProcess"]);
          if (myInterval) clearInterval(myInterval);
        } else {
          // if there is no processingMD5start info, that means beaver crashed unfortunately, so kill the interval otherwise we will have infinite logs
          if (!!res.data["processingMD5start"]) {
            if (myInterval) clearInterval(myInterval);
            setProcessKilled(true);
          } else {
            setProcessingMD5start(
              new Date(res.data["processingMD5start"]).toString()
            );
          }
        }
      });

  React.useEffect(() => {
    if (
      [500, 502].includes(err["status"]) &&
      !!window.location.pathname.match(/md5\/[a-f0-9]{32}\/report/)
    ) {
      // no more status check - sample md5's load fast enough
      // do an axios get every 30 seconds
      // statusCheck(null);
      // reset the process kill flag
      setProcessKilled(false);
      // const myInterval = setInterval(() => statusCheck(myInterval), 30000);
      // kill the statuscheck after 5 minutes
      // setInterval(() => {
      //   setProcessKilled(true);
      //   clearInterval(myInterval);
      // }, 300000);
    }
  }, [err]);

  switch (err["status"]) {
    case 400:
      return (
        <>
          <Typography variant="h6">
            {t("Report or Page not found")} :(
          </Typography>
          <br />
          <ErrorDetails err={err} safeError={safeError} />
        </>
      );
    case 401:
      return (
        <>
          <Typography variant="h6">
            {t("Session Expired or Invalid Credentials")} :(
          </Typography>
          <br />
          <ErrorDetails err={err} safeError={safeError} />
          <p>{t("You may need to logout and login again")} :(</p>
          <Link to="/logout" style={{ textDecoration: "none" }}>
            <Button variant="contained" color="primary">
              {t("Logout")}
            </Button>
          </Link>
        </>
      );
    case 404:
      return (
        <>
          <Box pt={6} textAlign="center">
            {/* <LinkOffIcon color="secondary" fontSize="inherit" /> */}
            <img
              src="/ng/images/beaver_error_logo.png"
              alt="SAD BEAVER"
              style={{ maxHeight: "50vh", mixBlendMode: "darken" }}
            />
            {/* when the hash can't be found, don't show some of the text as per issue #352 */}
            {err && err.data && !err.data.match("file could be found") ? (
              <>
                <Box pb={2}>
                  <Typography variant="h2">
                    {t("Report or Page not found")} :(
                  </Typography>
                </Box>
                <Box pb={2}>
                  <Typography variant="h5">
                    {t(
                      "You may want to refresh the page.  If that does not work, please let the Beaver / INO team know of the following"
                    )}
                    :
                  </Typography>
                </Box>
              </>
            ) : null}
          </Box>
          <ErrorDetails err={err} safeError={safeError} />
        </>
      );
    case 500:
      return (
        <>
          <Box pt={6} textAlign="center">
            {/* <LinkOffIcon color="secondary" fontSize="inherit" /> */}
            <img
              src="/ng/images/beaver_error_logo.png"
              alt="SAD BEAVER"
              style={{ maxHeight: "50vh", mixBlendMode: "darken" }}
            />
            {/* Not really a server error if it's still loading */}
            {!processingMD5start ? (
              <Box pb={2}>
                <Typography variant="h2">{t("Server Error")} :(</Typography>
              </Box>
            ) : (
              <Box pb={2}>
                <Typography variant="h2">
                  {t("MD5 Report is taking a while to load")} ...
                </Typography>
              </Box>
            )}
            {processKilled && (
              <Box pb={2}>
                <b>
                  <Typography variant="h5">
                    {t("Report took too much time to load")} :(
                  </Typography>
                </b>
              </Box>
            )}
            {!processKilled && !!processingMD5start && !processingMD5finished && (
              <Box pb={2}>
                <b>
                  <Typography variant="h5">
                    {t("Report is processing as of time")}: {processingMD5start}
                    .{t("Please wait")} ... ({t("Do not refresh page")} !)
                  </Typography>
                </b>
              </Box>
            )}
            {!processKilled && !!processingMD5finished && (
              <Box pb={2}>
                <b>
                  <Typography variant="h5">
                    {t("Report is done processing as of")}{" "}
                    {processingMD5finished} {t("and took")}
                    {timeToProcess}ms{" "}
                    {t("to complete. Pls. refresh page to view.")}
                  </Typography>
                </b>
              </Box>
            )}
            {!processingMD5start && (
              <Box pb={2}>
                <Typography variant="h5">
                  {t(
                    "You may want to refresh the page.  If that does not work, please let the Beaver / INO team know of the following details"
                  )}
                  :
                </Typography>
              </Box>
            )}
          </Box>
          <ErrorDetails err={err} safeError={safeError} />
        </>
      );
    case 502:
      return (
        <>
          {!processingMD5start ? (
            <Box pb={2}>
              <Typography variant="h6">
                {t("Server Gateway Error")} :(
              </Typography>
            </Box>
          ) : (
            <Box pb={2}>
              <Typography variant="h2">
                {t("MD5 Report is taking a while to load")} ...
              </Typography>
            </Box>
          )}
          {processKilled && (
            <Box pb={2}>
              <b>
                <Typography variant="h5">
                  {t("Report took too much time to load")} :(
                </Typography>
              </b>
            </Box>
          )}
          {!processKilled && !!processingMD5start && !processingMD5finished && (
            <Box pb={2}>
              <b>
                <Typography variant="h5">
                  {t("Report is processing as of time")}: {processingMD5start}.
                  {t("Please wait")} ... ({t("Do not refresh page")} !)
                </Typography>
              </b>
            </Box>
          )}
          {!processKilled && !!processingMD5finished && (
            <Box pb={2}>
              <b>
                <Typography variant="h5">
                  {t("Report is done processing as of")} {processingMD5finished}{" "}
                  {t("and took")}
                  {timeToProcess}ms{" "}
                  {t("to complete. Pls. refresh page to view.")}
                </Typography>
              </b>
            </Box>
          )}
          <br />
          {!processingMD5start && (
            <ErrorDetails err={err} safeError={safeError} />
          )}
        </>
      );
    case 503:
      return (
        <>
          <Typography variant="h6">{t("Server Error")} :(</Typography>
          <br />
          <ErrorDetails err={err} safeError={safeError} />
        </>
      );
    case 504:
      return (
        <>
          <Typography variant="h6">{t("Server Error")} :(</Typography>
          <br />
          <ErrorDetails err={err} safeError={safeError} />
        </>
      );
    case 403:
      if (err["data"] && "not_in_beaver_group" === err["data"]["error"]) {
        return (
          <>
            <Box pt={6} textAlign="center" fontSize={200}>
              {/* <LinkOffIcon color="secondary" fontSize="inherit" /> */}
              <img
                src="/ng/images/beaver_error_logo.png"
                alt="SAD BEAVER"
                style={{ maxHeight: "50vh", mixBlendMode: "darken" }}
              />
              <Box pb={2}>
                <Typography variant="h2">{t(err["data"]["error"])}</Typography>
              </Box>
              <Box>
                <Typography variant="h5">{t(err["data"]["msg"])}</Typography>
              </Box>
            </Box>
          </>
        );
      } else {
        return (
          <>
            <Typography variant="h6">
              {t(
                "We apologize, access to this information is not available at this time. Please check back in a short while for updates. Please contact"
              )}{" "}
              analytics.ci@cyber.gc.ca {t("for any questions")}.
            </Typography>
          </>
        );
      }
    default:
      return <ErrorDetails err={err} safeError={safeError} />;
  }
}

export function ErrorDetails({ err, safeError }) {
  const { t } = useTranslation();
  const [showDetails, setShowDetails] = React.useState(false);
  const [message, setMessage] = React.useState(null);

  React.useEffect(() => {
    if (err && err.data) {
      try {
        if (typeof err.data === "object" && err.data.message)
          setMessage(err.data.message);
        else setMessage(JSON.parse(err.data).message);
      } catch (e) {
        setMessage(err.data);
      }
    }
  }, [err]);

  return (
    <>
      {!!message && !JSON.stringify(message).includes("<!DOCTYPE html>") ? (
        <Typography variant="h6">
          {/* we don't want to see the whoops stuff in general as implied in issue #352 */}
          {message && JSON.stringify(message).match("Whoops!,")
            ? JSON.stringify(message).replace("Whoops!,", "")
            : JSON.stringify(message)}
        </Typography>
      ) : null}
      {!!message && !JSON.stringify(message).match("file could be found") ? (
        <Box mt={2} mb={2}>
          {!showDetails && (
            <Button color="primary" onClick={() => setShowDetails(true)}>
              {t("Show Error Details")}
            </Button>
          )}
          {showDetails && (
            <>
              <p>{t("Details")}:</p>
              <p>{safeError}</p>
            </>
          )}
        </Box>
      ) : null}
    </>
  );
}

export function parseErrorTitle(error) {
  const err = JSON.parse(error);
  switch (err["status"]) {
    case 401:
      return getI18n().t("Session Expired or Invalid Credentials");
    case 404:
      return getI18n().t("Not Found!");
    case 403:
      return getI18n().t("Access Restricted");
    default:
      // show the user the error code too
      return getI18n().t("An Error Occured!") + " (" + err["status"] + ")";
  }
}
