export interface PriorityListViewResponseData {
  currentUserName: string;
  list?: PriorityListEntry[];
  toReturn?: PriorityListEntry[];
}

export interface PriorityListEntry {
  addedDate: string;
  md5: string;
  user: string;
  comment?: string;
}

export enum PriorityListType {
  all = "all",
  user = "user",
  human = "human",
  bot = "bot",
}
