import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BorealisQueryJSON } from "../components/beaver/helpers/ServerProperties";
import { BorealisModules } from "components/beaver/models/borealis/borealisModel";

export type borealisSearchState = {
  searchText: string;
  modulesState: { [key: string]: boolean };
  responses: any[];
  errorText: object;
};

export const slice = createSlice({
  name: "borealissearch",
  initialState: {
    searchText: "",
    // all on by default
    modulesState: BorealisModules.reduce((ac, a) => ({ ...ac, [a]: true }), {}),
    responses: null,
    errorText: null,
  } as borealisSearchState,
  reducers: {
    setSearchText: (state, { payload }) => {
      state.searchText = payload;
    },
    setModulesState: (state, { payload }) => {
      state.modulesState = payload;
    },
    setResponses: (state, { payload }) => {
      state.responses = payload;
    },
    setErrorText: (state, { payload }) => {
      state.errorText = payload;
    },
  },
});

export const { setSearchText, setModulesState, setResponses, setErrorText } =
  slice.actions;

export default slice.reducer;

export const borealisSearchSelector = (state) => state.borealissearch;

export const runBorealisSearch =
  (
    modulesState: object,
    searchText: string,
    offlineMode: boolean,
    setSearching: Function,
    handleError: Function
  ) =>
  async (dispatch) => {
    const user = localStorage.getItem("currentUser")
      ? JSON.parse(localStorage.getItem("currentUser"))
      : {};

    const terms = searchText
      .split("\n")
      .map((r) => encodeURIComponent(encodeURIComponent(r.trim())))
      .filter((r) => !!r);

    // run all terms as seperate borealis queries and get them all as responses
    axios
      .all(
        terms.map((r) =>
          axios.get(
            BorealisQueryJSON(
              r,
              Object.keys(modulesState).filter((m) => !!modulesState[m]),
              offlineMode
            ),
            {
              auth:
                user && user.server && !user.server.includes("localhost")
                  ? {
                      username: user ? user.username : null,
                      password: user
                        ? user.onetime
                          ? window
                              // eslint-disable-next-line no-eval
                              .atob(eval(window.atob(user.onetime)))
                              .substr(13)
                          : null
                        : null,
                    }
                  : null,
            }
          )
        )
      )
      .then(
        (resps) => {
          resps.forEach((r) => console.log(r));
          dispatch(
            slice.actions.setResponses(
              resps.map((r) => ({
                ...r.data,
                // we need to add the request URL so we can get parse the header in search view
                requestURL: r.request.responseURL,
              }))
            )
          );
          setSearching(false);
        },
        (e) => {
          dispatch(
            slice.actions.setErrorText(
              e?.message ? e.message : JSON.stringify(e)
            )
          );
          setSearching(false);
          // extra check for if you are logged out etc.
          handleError(e);
        }
      )
      .catch((e) => {
        dispatch(
          slice.actions.setErrorText(e?.message ? e.message : JSON.stringify(e))
        );
        setSearching(false);
        handleError(e);
      });
  };
