export const searchExamples = {
  searchtext:
    "50.63.110.1\n" +
    "canada.ca\n" +
    "dpbqyqxynbip.ru\n" +
    "86385447ce35c120511647c1ade5cef9\n" +
    "0adb15f80d1f00974b392c86c72c6e01\n" +
    // "AS523\n" +
    "6d5679ac48c5dd9b8a49d9ceb61fd88dddf64e8a\n" +
    "ca6a054154acb559a495cfea1923933a65c94a325834a3cf9f46d85d3adaacf5\n" +
    "user@company.com\n" +
    "noresult@test.com\n" +
    "tag[ShadowNet]\n" +
    "tag[trickbot]\n" +
    "tag[Bluebot]\n" +
    "cluster[canada]\n" +
    "cluster[covid19]\n" +
    "kit[yahoo]\n" +
    "kit[050e844816702397447a9d66aa13dacf]\n" +
    "kit[025abe529ca54e0f504d44a808f956fc]\n" +
    "2027384\n" +
    "b51e37992f4465d51d5f8e908ee7a0e2\n" +
    "e6de9bd28b04074523450145ef02b7aa\n" +
    "ad47f06610c806f0d1bb883f24645a6e\n" +
    "3cfb562bfdd9cbfefdddf1d9c7f4cba7\n" +
    "a7a3239980ab276f3f9e9dfa4df873b3\n" +
    "18074a4e9c27aed0609f8d054c981e88",
  searchtextsimple:
    "50.63.110.1\n" +
    "canada.ca\n" +
    "dpbqyqxynbip.ru\n" +
    "https://qazaqtravel.kz/admin/login.php\n" +
    "86385447ce35c120511647c1ade5cef9\n" +
    "0adb15f80d1f00974b392c86c72c6e01\n" +
    "6d5679ac48c5dd9b8a49d9ceb61fd88dddf64e8a\n" +
    "ca6a054154acb559a495cfea1923933a65c94a325834a3cf9f46d85d3adaacf5\n" +
    "b51e37992f4465d51d5f8e908ee7a0e2\n" +
    "e6de9bd28b04074523450145ef02b7aa\n" +
    "ad47f06610c806f0d1bb883f24645a6e\n" +
    "18074a4e9c27aed0609f8d054c981e88",
  borealisSearchExample:
    "50.63.110.1\n" +
    "canada.ca\n" +
    "dpbqyqxynbip.ru\n" +
    "http://kvbjaur.biz/cerb\n",
};
