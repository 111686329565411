import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import { cloneDeep } from "lodash";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  LinearProgress,
} from "@material-ui/core";
import Axios from "axios";

import { fetchSources, sourceSelector } from "../../../../../../slices/sources";
import { URLReportJSONActualURL } from "../../../../helpers/ServerProperties";
import { SimpleTableView } from "../../common/SimpleTableView";
import { ParsedError, parseErrorTitle } from "../../common/ParsedError";

import { useStyles } from "../../common/sharedStyles";
import { useTranslation } from "react-i18next";

import WarningOutlined from "@material-ui/icons/WarningOutlined";
import { LinkWrap } from "../../common/LinkWrap";

import { DecodeButton } from "../../common/DecodeButton";

const md5fn = require("md5");

export type PivotActualURLViewProps = {
  md5: string;
  requestedURLs: {
    url: string;
    isTransformed: boolean;
    isRedirection: boolean;
    error: string;
    type: string;
    source: string;
    md5: string;
    receivedDate: number;
  }[];
};

export function PivotActualURLView({
  md5,
  requestedURLs,
}: PivotActualURLViewProps) {
  const classes = useStyles();
  const [responseData, setResponseData]: [any, React.Dispatch<any>] = useState({
    loaded: false,
  });
  const [error, setError] = useState(null);
  const [retry, setRetry] = useState(0);
  const dispatch = useDispatch();
  const { sources } = useSelector(sourceSelector);
  useEffect(() => {
    dispatch(fetchSources(sources));
  }, [dispatch, sources]);

  //TODO - this should make the localstorage null if it can't parse- put this somewhere and export
  const user = localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser"))
    : {};
  const { t } = useTranslation();

  useEffect(() => {
    //TODO - consider moving this to redux maybe
    Axios.get(URLReportJSONActualURL(md5), {
      auth:
        user.server && !user.server.includes("localhost")
          ? {
              username: user ? user.username : null,
              password: user
                ? user.onetime
                  ? // eslint-disable-next-line no-eval
                    window.atob(eval(window.atob(user.onetime))).substr(13)
                  : null
                : null,
            }
          : null,
    }).then(
      (response) => {
        // TODO - remove this
        console.log(response);
        response.data.loaded = true;
        setResponseData(response.data);
      },
      (error) => {
        console.error(error);
        setError(error.message);
      }
    );
    // unsure why adding user here makes multiple requests
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [md5, retry]);

  // show a loading bar until it loads, and show the error if there is one
  if (responseData.loaded === false && error == null) {
    return (
      <>
        {t("Loading")} ...
        <LinearProgress />
      </>
    );
  } else if (error != null) {
    return (
      <Card>
        <CardHeader
          className={classes.ch}
          avatar={<Avatar className={classes.errorIcon}>!</Avatar>}
          title={t("Pivot ActualURL") + " - " + parseErrorTitle(error)}
        />
        <CardContent>
          <ParsedError error={error} />
        </CardContent>
        <CardActions>
          <Button size="small" onClick={() => setRetry(retry + 1)}>
            {t("Retry")}
          </Button>
        </CardActions>
      </Card>
    );
  }

  // show the card with the simple data view (map the data) here
  // TODO - show the full source - do a 'map' on the urlsWithSameTitle to do that
  //   * will likely need to modify the fetch to also return the sourcemap similar to urlreport!
  return (
    <Card>
      <CardHeader
        className={classes.ch}
        title={
          "Pivot: " + t("Other URLs that redirected to the same 'actual url'")
        }
      />
      <CardContent>
        {responseData.numActualURLLarge && (
          <p>
            <WarningOutlined />{" "}
            {t("Only 10,000 of the total") +
              " " +
              responseData.numActualURL.toLocaleString() +
              " " +
              t("actual URLs are being shown")}
          </p>
        )}
        <SimpleTableView
          // doing a spread operator (e.g. [...rURLs, ...rd.rURLs]) is returing an error, so am merging the clones of them)
          data={[
            ...cloneDeep(requestedURLs),
            ...cloneDeep(responseData.requestedURLs),
          ]}
          headers={[
            {
              title: t("Requested URL"),
              field: "url",
              render: (r) => (
                <Link to={["/url", md5fn(r.url), "report"].join("/")}>
                  <span className={classes.breakword}>
                    <LinkWrap>
                      <DecodeButton>{r.url}</DecodeButton>
                    </LinkWrap>
                  </span>
                </Link>
              ),
            },
            {
              title: t("Source"),
              field: "source",
              render: (r) =>
                sources && sources[r.source]
                  ? sources[r.source].displayString
                  : r.source,
            },
            {
              title: t("Date Received"),
              field: "receivedDate",
            },
          ]}
        />
      </CardContent>
    </Card>
  );
}
