import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

import {
  FileToolsOuputType,
  FileTypeModel,
} from "../../../../models/report/md5/fileTypeModel";

import { useStyles } from "../../common/sharedStyles";

import { useTranslation } from "react-i18next";

export type FileTypeProps = {
  fileTypeReports: FileTypeModel[];
};

// TODO - write tests for new FileTypeView (e.g. with fileInfo)
export function FileTypeView({ fileTypeReports }: FileTypeProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [reportNum, setReportNum] = React.useState(fileTypeReports.length - 1);

  return (
    <Box>
      <Card>
        <CardHeader className={classes.ch} title={t("File Report")} />
        <CardContent>
          <TableContainer>
            <Table size="small">
              <TableBody>
                {fileTypeReports.length < 2 ? null : (
                  <TableRow>
                    <TableCell>{t("Available Reports")}</TableCell>
                    <TableCell>
                      {fileTypeReports.map((r, i) => (
                        <React.Fragment key={i}>
                          <Button
                            onClick={() => setReportNum(i)}
                            color="primary"
                            variant={reportNum === i ? "outlined" : "text"}
                            size={reportNum === i ? "large" : "medium"}
                          >
                            {fileTypeReports[i].version}
                          </Button>
                          {"  "}
                        </React.Fragment>
                      ))}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell>{t("Analysis Date")}</TableCell>
                  <TableCell>
                    {fileTypeReports[reportNum].creationDate}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>MD5</TableCell>
                  <TableCell>{fileTypeReports[reportNum].md5}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("Report Version")}</TableCell>
                  <TableCell>{fileTypeReports[reportNum].version}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("File Type")}</TableCell>
                  <TableCell>
                    {fileTypeReports[reportNum].sampleType
                      ? fileTypeReports[reportNum].sampleType
                      : fileTypeReports[reportNum].type.sampleType}
                  </TableCell>
                </TableRow>
                {fileTypeReports[reportNum].fileInfo ? (
                  <>
                    <TableRow>
                      <TableCell>{t("Mime")}</TableCell>
                      <TableCell className={classes.breakword}>
                        {fileTypeReports[reportNum].fileInfo.mime}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t("Magic")}</TableCell>
                      <TableCell className={classes.breakword}>
                        {fileTypeReports[reportNum].fileInfo.magic}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t("ASCII")}</TableCell>
                      <TableCell className={classes.breakword}>
                        {fileTypeReports[reportNum].fileInfo.ascii}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t("Hex")}</TableCell>
                      <TableCell className={classes.breakword}>
                        {fileTypeReports[reportNum].fileInfo.hex}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t("Entropy")}</TableCell>
                      <TableCell>
                        {fileTypeReports[reportNum].fileInfo.entropy}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t("Size")}</TableCell>
                      <TableCell>
                        {fileTypeReports[reportNum].fileInfo.size}
                      </TableCell>
                    </TableRow>
                  </>
                ) : null}
                <TableRow>
                  <TableCell>{t("Result")}</TableCell>
                  <TableCell>{fileTypeReports[reportNum].result}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("Message")}</TableCell>
                  <TableCell className={classes.breakword}>
                    {fileTypeReports[reportNum]?.messages[0]?.type
                      ? fileTypeReports[reportNum].messages[0].type +
                        "  " +
                        fileTypeReports[reportNum]?.messages[0]?.message
                      : t("None")}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>{" "}
        </CardContent>
      </Card>
      {fileTypeReports[reportNum].toolsOutput ? (
        <Box mt={2}>
          <Grid container spacing={2}>
            {Object.keys(fileTypeReports[reportNum].toolsOutput).map(
              (val: FileToolsOuputType, i: number) => {
                return (
                  <Grid key={"fileview" + i} item xs={12} md={6} lg={4}>
                    <Card>
                      <CardHeader className={classes.ch} title={val} />
                      <CardContent>
                        <TableContainer>
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell>{t("Order")}</TableCell>
                                <TableCell>
                                  {t("Possible File Types")}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {fileTypeReports[reportNum].toolsOutput[
                                val
                              ].possibleTypes.map((valb: string, j: number) => {
                                return (
                                  <TableRow key={"subview-" + i + "-" + j}>
                                    <TableCell>{j + 1}</TableCell>
                                    <TableCell>{valb}</TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <GetFileTypeSubView
                          type={val}
                          output={fileTypeReports[reportNum].toolsOutput[val]}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                );
              }
            )}
          </Grid>
        </Box>
      ) : null}
    </Box>
  );
}

type GetFileTypeSubProps = {
  type: FileToolsOuputType;
  output: any;
};

function GetFileTypeSubView({ type, output }: GetFileTypeSubProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  switch (type) {
    case FileToolsOuputType.FILE:
      return (
        <Box mt={3}>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>{t("Output")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{output.output}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      );
    case FileToolsOuputType.TRID:
      return (
        <Box mt={3}>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>{t("Outputs")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {output.output.map((val: string, i: number) => {
                  return (
                    <TableRow key={"subtype" + type + i}>
                      <TableCell>{val}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      );
    case FileToolsOuputType.EXIFTOOL:
      return (
        <Box mt={3}>
          <p>Result Elements:</p>
          <pre className={classes.code}>
            {Object.keys(output.resultElements).map(
              (val: string, i: number) => {
                // we probably want to hide the directory
                if (!val.includes("Directory"))
                  return val + ": " + output.resultElements[val] + "\n";
                return "";
              }
            )}
          </pre>
        </Box>
      );
    default:
      return null;
  }
}
