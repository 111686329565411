import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import _ from "lodash";

import { useDispatch, useSelector } from "react-redux";
import { beaverNGSelector, fetchReport } from "slices/beaverng";
import { fetchSources, sourceSelector } from "slices/sources";

import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  LinearProgress,
  MenuItem,
  Select,
} from "@material-ui/core";

import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import PageFullWidth from "../../../../../commons/components/layout/pages/PageFullWidth";
import PageHeader from "../../../../../commons/components/layout/pages/PageHeader";
import { useStyles } from "../common/sharedStyles";
import { ReportType } from "../../../models/report/reportType";
import { ParsedError, parseErrorTitle } from "../common/ParsedError";
import { SimpleTableCardView } from "../common/SimpleTableView";
import { YaraRulesType } from "./LiveHunt";
import { LinkWrap } from "../common/LinkWrap";

export function YaraRulesHitList() {
  const { t } = useTranslation();
  let { id }: { id: string } = useParams();
  const [name, setName] = React.useState(null);

  const title =
    t("Hits for Rule") + " " + id + (name ? ' -  "' + name + '"' : "");
  return (
    <>
      <Helmet>
        <title>{["BEAVER", title].join(" ")}</title>
        <meta
          name="description"
          content={["BEAVER", title, "-", t("page.home.description")].join(" ")}
        />
      </Helmet>
      <PageHeader title={title} />
      <PageFullWidth>
        <YaraRulesHitReport id={id} setName={setName} />
      </PageFullWidth>
    </>
  );
}

export type YaraRulesHitReportProps = {
  testing?: boolean;
  id: string;
  setName;
};

export function YaraRulesHitReport({
  testing,
  id,
  setName,
}: YaraRulesHitReportProps) {
  const [retry, setRetry] = React.useState(0);

  const classes = useStyles();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { responseData, reportType, error } = useSelector(beaverNGSelector);
  const { sources } = useSelector(sourceSelector);

  // will run on first mount
  useEffect(() => {
    dispatch(fetchReport(ReportType.LIVE_HUNT_RULE, id));
    dispatch(fetchSources(sources));
  }, [dispatch, id, retry, sources]);

  useEffect(() => {
    if (responseData && responseData.name) setName(responseData.name);
  }, [responseData, setName]);

  // start by just showing the summary once loaded
  if (
    !error &&
    (!responseData || !reportType || reportType !== ReportType.LIVE_HUNT_RULE)
  ) {
    // show a loading bar until it loads, and show the error if there is one
    // TODO - translate the loading text
    return (
      <>
        {t("Loading")} ...
        <LinearProgress />
      </>
    );
  } else if (error) {
    return (
      <Card>
        <CardHeader
          className={classes.ch}
          avatar={<Avatar className={classes.errorIcon}>!</Avatar>}
          title={parseErrorTitle(error)}
        />
        <CardContent>
          <ParsedError error={error} />
        </CardContent>
        <CardActions>
          <Button size="small" onClick={() => setRetry(retry + 1)}>
            {t("Retry")}
          </Button>
        </CardActions>
      </Card>
    );
  } else {
    return (
      <LiveHuntRule
        setName={setName}
        testing={testing}
        sources={sources}
        id={id}
        yaraRule={responseData.yaraRule}
        yaraRules={responseData.yaraRules}
      />
    );
  }
}

export interface YaraRuleType {
  md5: string;
  fileName: string;
  uploadedOn: number;
  sourceID: string;
}

export interface LiveHuntRuleProps {
  setName: Function;
  testing: boolean;
  sources;
  id: string;
  yaraRules: YaraRulesType[];
  yaraRule: YaraRuleType[];
}

export function LiveHuntRule({
  yaraRules,
  yaraRule,
  setName,
  testing,
  sources,
  id,
}: LiveHuntRuleProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedID, setSelectedID] = React.useState(id);
  return (
    <>
      {yaraRules && (
        <>
          {t("Select another Yara Rule")}:{" "}
          <Select
            value={selectedID}
            onChange={(e) => {
              setName(null);
              setSelectedID("" + e.target.value);
              navigate("/robin/rules/" + e.target.value);
            }}
          >
            {yaraRules.map((y, i) => (
              <MenuItem key={i} value={y.id}>
                {y.id + " - " + y.hits}
              </MenuItem>
            ))}
          </Select>
        </>
      )}

      <Box mt={2}>
        <SimpleTableCardView
          title={t("Yara Rule Hit List")}
          testing={testing}
          data={_.cloneDeep(yaraRule)}
          headers={[
            {
              title: t("Sample MD5"),
              field: "md5",
              render: (r) => (
                <Link to={["/md5", r.md5, "report"].join("/")}>
                  <LinkWrap>{r.md5}</LinkWrap>
                </Link>
              ),
            },
            { title: t("File Type"), field: "fileName" },
            { title: t("Date Added"), field: "uploadedOn" },
            {
              title: t("Source"),
              field: "sourceID",
              render: (r) =>
                sources && sources[r.sourceID]
                  ? sources[r.sourceID].displayString
                  : r.sourceID,
            },
          ]}
        />
      </Box>
    </>
  );
}
