import React from "react";
import _ from "lodash";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Link,
  useTheme,
} from "@material-ui/core";
import {
  SubTypeReportData,
  TypeReportData,
} from "../../../../models/report/type/TypeReportData";
import { SubReportType } from "../../../../models/report/type/SubReportType";
import { PhishingKitSummaryItem } from "components/beaver/components/report/common/type/PhishingKitSummaryItem";
import { isSimplified } from "components/beaver/helpers/ServerProperties";

import { useStyles } from "../sharedStyles";
import { useTranslation } from "react-i18next";
import { WarningOutlined } from "@material-ui/icons";
import { HelpToolTip } from "components/beaver/components/report/common/HelpButton";
import { BorealisButton } from "../LinkWrap";

const md5 = require("md5");

export type TypeSummaryProps = {
  data: TypeReportData;
  setShow: (show: SubReportType) => void;
};

// This view is the one within the Summary on the type (e.g. sid, ip, domain) page

export function TypeSummary({ data, setShow }: TypeSummaryProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Grid container spacing={3}>
      {data.allowedSystems.map((val, i) => {
        return data.subreports[val]?.reports.length > 0 ? (
          <Grid key={"allowedsystem" + i} item xs={12} md={4} xl={3}>
            <Card style={{ height: "100%" }}>
              <CardHeader
                title={
                  <Link
                    className={classes.linkInHeader}
                    onClick={() => setShow(data.subreports[val].system)}
                  >
                    {t("sys." + data.subreports[val].system.toUpperCase())}
                  </Link>
                }
                className={classes.ch}
              />
              <CardContent style={{ height: "100%" }}>
                {data.subreports[val].errors &&
                data.subreports[val].errors.length ? (
                  <Box mb={2} style={{ color: theme.palette.error.main }}>
                    {data.subreports[val].errors.map((e, i) => (
                      <Box key={i}>
                        {i === 0 && (
                          <>
                            <WarningOutlined />{" "}
                          </>
                        )}
                        {e}
                      </Box>
                    ))}
                  </Box>
                ) : null}
                <TypeReportSummary
                  data={data.subreports[val]}
                  setShow={setShow}
                  system={data.subreports[val].system}
                />
                <PhishingKitSummaryItem data={data} val={val} />
              </CardContent>
            </Card>
          </Grid>
        ) : null;
      })}
    </Grid>
  );
}

export type TypeReportSummaryProps = {
  data: SubTypeReportData;
  setShow: (show: SubReportType) => void;
  system: SubReportType;
};

export function TypeReportSummary({
  data,
  setShow,
  system,
}: TypeReportSummaryProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  // map report.typeName as header, then summaries for them
  return (
    <>
      {data.reports.map((report, i) => {
        return (
          <Box key={"sysreport" + report.typeName + i} mb={4}>
            {report?.summaries?.length > 0 ? (
              report.typeName === "Cyber Threat Infrastructure (CTI)" &&
              report.summaries.filter((r) => r.list === null).length !==
                0 ? null : (
                <h3>
                  <Link
                    onClick={() => setShow(system)}
                    style={{ cursor: "pointer" }}
                  >
                    {t(report.typeName)}
                    {_.snakeCase("tooltip." + report.typeName) !==
                      t(_.snakeCase("tooltip." + report.typeName)) && (
                      <HelpToolTip
                        help={t(_.snakeCase("tooltip." + report.typeName))}
                      />
                    )}
                  </Link>
                </h3>
              )
            ) : (
              <></>
            )}
            {/* This is a special case, where we have an extra query to get more data */}
            {report.typeName === "Cyber Threat Infrastructure (CTI)" &&
            report.summaries.filter((r) => r.list !== null).length === 0
              ? null
              : ("Malware Callouts (Sandboxes)" === report.typeName &&
                data?.uniqueMalwareCalloutMD5s?.length
                  ? [{ title: "Samples", list: data.uniqueMalwareCalloutMD5s }]
                  : report.summaries
                )?.map((summary, j) => {
                  // if there is nothing in the list, don't show it unless it's the first one
                  return j === 0 || summary?.list?.length > 0 ? (
                    <Box key={"sysr" + report.typeName + summary.title + j}>
                      {/* only show the sub-title if there's more than one */}
                      {report.summaries && report.summaries.length > 1 ? (
                        <p>
                          <b>
                            {t(
                              system === "DFS" && summary.title === "Source"
                                ? "Domain.Source.summary"
                                : ["Source", "IP", "Domain"].includes(
                                    summary.title
                                  )
                                ? summary.title + ".summary"
                                : summary.title
                            )}
                          </b>
                          {summary.list && summary.list.length > 10
                            ? "BISON" === system
                              ? " (" + t("latest 10") + ") "
                              : " (" + t("first 10") + ") "
                            : null}
                        </p>
                      ) : null}
                      <Box mb={2}>
                        {summary.list ? (
                          "BISON" === system ? (
                            _.cloneDeep(summary.list)
                              .reverse()
                              .filter((v, i) => i < 10)
                              .map((item, k) => {
                                return (
                                  <Box key={k}>
                                    <SampleLine
                                      item={item}
                                      title={summary.title}
                                    />
                                  </Box>
                                );
                              })
                          ) : (
                            summary.list
                              .filter((v, i) => i < 10)
                              .map((item, k) => {
                                return (
                                  <Box key={k}>
                                    <SampleLine
                                      item={item}
                                      title={summary.title}
                                    />
                                  </Box>
                                );
                              })
                          )
                        ) : (
                          <span className={classes.lightText}>{t("None")}</span>
                        )}
                      </Box>
                    </Box>
                  ) : null;
                })}
          </Box>
        );
      })}
    </>
  );
}

function SampleLine({ item, title }) {
  const classes = useStyles();
  switch (title) {
    case "Samples":
    case "Unique MD5":
    case "MD5 in priority":
      return (
        <RouterLink className={classes.data} to={"/md5/" + item + "/report"}>
          {item}
        </RouterLink>
      );
    case "Callout":
      // determine if ip, then show ip, otherwise show domain!
      return (
        <>
          <RouterLink
            className={classes.data}
            to={
              (/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi.test(
                item
              )
                ? "/ip/"
                : "/domain/") +
              item +
              "/report"
            }
          >
            {item}
          </RouterLink>
          <BorealisButton iconStyle={{ padding: 0, fontSize: "1.2rem" }}>
            {item}
          </BorealisButton>
        </>
      );
    case "Domain":
      return (
        <>
          <RouterLink
            className={classes.data}
            to={"/domain/" + item + "/report"}
          >
            {item}
          </RouterLink>
          <BorealisButton iconStyle={{ padding: 0, fontSize: "1.2rem" }}>
            {item}
          </BorealisButton>
        </>
      );
    case "IP":
      return (
        <>
          <RouterLink className={classes.data} to={"/ip/" + item + "/report"}>
            {item}
          </RouterLink>
          <BorealisButton iconStyle={{ padding: 0, fontSize: "1.2rem" }}>
            {item}
          </BorealisButton>
        </>
      );
    case "Most Recent URLs":
      return (
        <>
          <RouterLink
            className={classes.dataAndBreakword}
            to={"/url/" + md5(item) + "/report"}
          >
            {item.length > 200 ? item.substring(0, 200) + " ..." : item}
          </RouterLink>
          <BorealisButton iconStyle={{ padding: 0, fontSize: "1.2rem" }}>
            {item}
          </BorealisButton>
        </>
      );
    case "Families":
      return isSimplified() ? (
        <span className={classes.data}>{item}</span>
      ) : (
        <RouterLink className={classes.data} to={"/tag/" + item + "/report"}>
          {item}
        </RouterLink>
      );
    case "Clusters":
      return isSimplified() ? (
        <span className={classes.data}>{item}</span>
      ) : (
        <RouterLink
          className={classes.data}
          to={"/cluster/" + item + "/report"}
        >
          {item}
        </RouterLink>
      );
    default:
      return <span className={classes.data}>{item}</span>;
  }
}
