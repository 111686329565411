import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";

import { useStyles } from "../../common/sharedStyles";
import { useTranslation } from "react-i18next";

import { dbCNCMapModel } from "../../../../models/report/md5/dbCNCMapCalloutModel";

import {
  JA3FingerprintModel,
  SandboxAlertModel,
} from "../../../../models/report/md5/md5ReportModel";

import { CalloutsBox, FingerprintsBox, SandBoxAlertsBox } from "./BearView";

import { UniqueIdentifiersBox } from "./ShadowServerCWView";

export interface sunbeltModel {
  creationDate: string;
}

export type SunbeltViewProps = {
  sunbeltReport: sunbeltModel;
  dbCNCMap: dbCNCMapModel[];
  sandBoxAlerts: SandboxAlertModel[];
  fingerprints: JA3FingerprintModel[];
  testing?: boolean;
};

export function SunbeltView({
  sunbeltReport,
  dbCNCMap,
  sandBoxAlerts,
  fingerprints,
  testing,
}: SunbeltViewProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box>
      <Card>
        <CardHeader className={classes.ch} title={t("Sunbelt")} />
        <CardContent>
          <TableContainer>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell>{t("Analysis Date")}</TableCell>
                  <TableCell>{sunbeltReport.creationDate}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>

      <SandBoxAlertsBox sandBoxAlerts={sandBoxAlerts} testing={testing} />

      <CalloutsBox
        dbCNCMap={dbCNCMap}
        testing={testing}
        hideLocationAndAssociation={true}
      />

      <UniqueIdentifiersBox callouts={dbCNCMap} />

      <FingerprintsBox fingerprints={fingerprints} testing={testing} />
    </Box>
  );
}
