import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { LinkWrap } from "components/beaver/components/report/common/LinkWrap";

import SubdirectoryArrowRightIcon from "@material-ui/icons/SubdirectoryArrowRight";

import { communication } from "../../../../models/report/url/communication";

import { useStyles } from "../../common/sharedStyles";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { ClipboardButton } from "../../common/ClipboardButton";
import { toUnicode } from "punycode";

import { determineStatusColour } from "../../../../helpers/determineStatusColour";

import { DecodeButton } from "../../common/DecodeButton";

export type CommunicationsViewProps = {
  comms?: communication[];
};

// FYI using the SimpleTableView will be difficult here because of the child parts of the view
export function CommunicationsView({ comms }: CommunicationsViewProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  const getMd5FromComment = (comment: string): string => {
    return comment?.split(";")[1]?.split(":")[1];
  };

  if (!comms) return null;

  return (
    <Card>
      <CardHeader className={classes.ch} title={t("Communications")} />
      <CardContent>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>{t("Request")}</TableCell>
                <TableCell>{t("MIME Type")}</TableCell>
                <TableCell>{t("Content MD5")}</TableCell>
                <TableCell>{t("Content Size")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {comms.map((communication, i) => {
                return (
                  <React.Fragment key={"commsparent" + i}>
                    <TableRow>
                      <TableCell>
                        <Chip
                          label={communication.entry.response.statusCode}
                          style={{
                            color: "#FFF",
                            backgroundColor: determineStatusColour(
                              communication.entry.response.statusCode
                            ),
                          }}
                        />
                      </TableCell>
                      <TableCell className={classes.pre}>
                        {
                          <>
                            {communication.entry.request.method
                              ? communication.entry.request.method
                              : "GET"}{" "}
                            <LinkWrap noBeaverLink>
                              {communication.entry.request.url}
                            </LinkWrap>
                            <DecodeButton>
                              <ClipboardButton
                                value={toUnicode(
                                  communication.entry.request.url
                                )}
                              />
                            </DecodeButton>
                          </>
                        }
                      </TableCell>
                      <TableCell>
                        {communication.entry.response.content.mimeType
                          ? communication.entry.response.content.mimeType?.split(
                              ";"
                            )[0]
                          : "N/A"}
                      </TableCell>
                      <TableCell>
                        {communication.entry.response.bodySize ? (
                          <Link
                            to={[
                              "/md5",
                              communication.entry.response.contentMd5
                                ? communication.entry.response.contentMd5
                                : getMd5FromComment(
                                    communication.entry.response.content.comment
                                  ),
                              "report",
                            ].join("/")}
                          >
                            <LinkWrap>
                              {communication.entry.response.contentMd5
                                ? communication.entry.response.contentMd5
                                : getMd5FromComment(
                                    communication.entry.response.content.comment
                                  )}
                            </LinkWrap>
                          </Link>
                        ) : (
                          "N/A"
                        )}
                      </TableCell>
                      <TableCell>
                        {communication.entry.response.bodySize}
                      </TableCell>
                    </TableRow>
                    {communication.children.map((child, j) => {
                      return (
                        <TableRow
                          key={
                            "commschild" + communication.entry.request.url + j
                          }
                        >
                          <TableCell>
                            <Chip
                              label={child.entry.response.statusCode}
                              style={{
                                color: "#FFF",
                                backgroundColor: determineStatusColour(
                                  child.entry.response.statusCode
                                ),
                              }}
                            />
                          </TableCell>
                          <TableCell className={classes.pre}>
                            <SubdirectoryArrowRightIcon />{" "}
                            {child.entry.request.method + " "}
                            <LinkWrap noBeaverLink>
                              {child.entry.request.url}
                            </LinkWrap>
                            <DecodeButton>
                              <ClipboardButton
                                value={toUnicode(child.entry.request.url)}
                              />
                            </DecodeButton>
                          </TableCell>
                          <TableCell>
                            {
                              child.entry.response.content.mimeType?.split(
                                ";"
                              )[0]
                            }
                          </TableCell>
                          <TableCell>
                            {child.entry.response.bodySize ? (
                              <Link
                                to={[
                                  "/md5",
                                  getMd5FromComment(
                                    child.entry.response.content.comment
                                  ),
                                  "report",
                                ].join("/")}
                              >
                                <LinkWrap>
                                  {getMd5FromComment(
                                    child.entry.response.content.comment
                                  )}
                                </LinkWrap>
                              </Link>
                            ) : (
                              "N/A"
                            )}
                          </TableCell>
                          <TableCell>{child.entry.response.bodySize}</TableCell>
                        </TableRow>
                      );
                    })}
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}
