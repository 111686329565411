import React from "react";
import { Link } from "react-router-dom";
import { LinkWrap } from "../report/common/LinkWrap";
import {
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  Grid,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useStyles } from "../report/common/sharedStyles";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
import ExpandLessOutlinedIcon from "@material-ui/icons/ExpandLessOutlined";
import { SimpleTableView } from "../report/common/SimpleTableView";
import { ClipboardButton } from "../report/common/ClipboardButton";
import { HelpToolTip } from "../report/common/HelpButton";
import { BorealisModulesHelp } from "components/beaver/help/BorealisHelp";
import { BorealisNumberChip } from "./BorealisReport";

export function BorealisDNSReolution({
  dnsResolutionList,
  query,
}: {
  dnsResolutionList: any[];
  query: string;
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  // controls when the user wants to minimize the DNS table
  const [minimizedDNS, setMinimizedDNS] = React.useState(false);
  const [showTable, setShowTable] = React.useState(false);

  return (
    <Box mt={2}>
      <Card>
        <CardHeader
          onClick={() => setMinimizedDNS(!minimizedDNS)}
          style={{ cursor: "pointer" }}
          title={
            dnsResolutionList.length ? (
              <>
                {t("DNS Resolution") + " "}
                {dnsResolutionList.filter((d) => "ALLOWED" !== d["status"])
                  .length ? (
                  <BorealisNumberChip
                    label={
                      dnsResolutionList.filter((d) => "ALLOWED" !== d["status"])
                        .length
                    }
                  />
                ) : null}
              </>
            ) : (
              t("DNS Resolution")
            )
          }
          className={classes.ch}
          action={
            <>
              <HelpToolTip help={"" + BorealisModulesHelp["DNS"][1]} />
              <Tooltip
                title={
                  minimizedDNS
                    ? t("Show DNS Resolutions")
                    : t("Hide DNS Resolutions")
                }
                arrow
                placement="left"
              >
                <IconButton onClick={() => setMinimizedDNS(!minimizedDNS)}>
                  {minimizedDNS ? (
                    <ExpandMoreOutlinedIcon />
                  ) : (
                    <ExpandLessOutlinedIcon />
                  )}
                </IconButton>
              </Tooltip>
            </>
          }
        />

        {!minimizedDNS && (
          <CardContent>
            <Grid container justifyContent="flex-start">
              <Grid item>
                <Button
                  color="primary"
                  onClick={() => setShowTable(!showTable)}
                  //
                >
                  {showTable ? t("Hide Table") : t("Show Table")}
                </Button>
              </Grid>
            </Grid>
            {/* Show a view that will show boxes in grid that are coloured depending on blocked or not */}
            {!showTable && (
              <Grid container spacing={2}>
                {dnsResolutionList
                  .sort((a, b) =>
                    ("" + a.resolver).localeCompare("" + b.resolver)
                  )
                  .map((d, i) => (
                    <Grid
                      key={i}
                      item
                      style={{
                        backgroundColor:
                          d.status === "BLOCKED" ? "red " : "green",
                      }}
                    >
                      <b>{d.resolver}</b>
                    </Grid>
                  ))}
              </Grid>
            )}
            {/* Let's put the resolver and status in each individual result */}
            {showTable && (
              <SimpleTableView
                headers={[
                  { title: t("Resolver"), field: "resolver" },
                  {
                    title: t("IP"),
                    field: "ip",
                    render: (r) =>
                      r.ip && "UNKNOWN" !== r.ip ? (
                        <>
                          <Link to={["/ip", r.ip, "report"].join("/")}>
                            <LinkWrap>{r.ip}</LinkWrap>
                          </Link>
                          <ClipboardButton value={r.ip} />
                        </>
                      ) : (
                        r.ip
                      ),
                  },
                  {
                    title: t("Domain"),
                    field: "domain",
                    render: (r) =>
                      r.domain === query ? (
                        r.domain
                      ) : (
                        <>
                          <Link to={["/domain", r.domain, "report"].join("/")}>
                            <LinkWrap>{r.domain}</LinkWrap>
                          </Link>
                          <ClipboardButton value={r.domain} />
                        </>
                      ),
                  },
                  { title: t("Added On"), field: "addedOn" },
                  {
                    title: t("Status"),
                    field: "status",
                    render: (r) =>
                      "BLOCKED" === r.status ? (
                        <span style={{ color: "red" }}>
                          <b>{r.status}</b>
                        </span>
                      ) : (
                        <span style={{ color: "green" }}>{r.status}</span>
                      ),
                  },
                ]}
                data={dnsResolutionList}
              />
            )}
          </CardContent>
        )}
      </Card>
    </Box>
  );
}
