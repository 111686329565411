import React from "react";
import {
  Box,
  Button,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import _ from "lodash";

export function ModuleDataView({ moduleData }: { moduleData: any }) {
  const { t } = useTranslation();
  const [viewJSON, setViewJSON] = React.useState(false);

  // Go one layer deeper, e.g. if child is an object, can reamap one l ayer only otherwise
  // just return a stringified response
  function showTableFromJSON(moduleData) {
    return Object.keys(moduleData).map((k, i) => {
      if ("object" === typeof moduleData[k]) {
        return Object.keys(moduleData[k]).map((kk, j) => (
          <TableRow key={k + "_" + j + "_" + kk + "_" + j}>
            <TableCell style={{ width: "40%" }}>
              {_.startCase(t(k)) + " -> " + _.startCase(t(kk))}
            </TableCell>
            <TableCell>
              {Array.isArray(moduleData[k][kk]) ||
              "object" == typeof moduleData[k][kk]
                ? JSON.stringify(moduleData[k][kk], null, 2)
                : "" + moduleData[k][kk]}
            </TableCell>
          </TableRow>
        ));
      } else {
        return (
          <TableRow key={k + "_" + i}>
            <TableCell style={{ width: "30%" }}>{_.startCase(t(k))}</TableCell>
            <TableCell>
              {Array.isArray(moduleData[k]) || "object" == typeof moduleData[k]
                ? JSON.stringify(moduleData[k], null, 2)
                : "" + moduleData[k]}
            </TableCell>
          </TableRow>
        );
      }
    });
  }

  return (
    <>
      {/* This button will allow the user to see the JSON if they so wish  */}
      <Button color="primary" onClick={() => setViewJSON(!viewJSON)}>
        {viewJSON ? t("Show View") : t("Show JSON")}
      </Button>
      {/* TODO - consider a ClipboardButton to copy the JSON to clipboard (stringify first pls) */}
      {!viewJSON && Array.isArray(moduleData) ? (
        moduleData.map((md, i) => (
          <Box key={"md_" + i} mt={!!i ? 2 : 0}>
            {moduleData.length > 1 && (
              <Typography>{t("Entry") + " #" + (i + 1) + ":"}</Typography>
            )}
            <TableContainer>
              <Table size="small">
                <TableBody>{showTableFromJSON(md)}</TableBody>
              </Table>
            </TableContainer>
          </Box>
        ))
      ) : !viewJSON && "object" === typeof moduleData ? (
        <>
          <TableContainer>
            <Table size="small">
              <TableBody>{showTableFromJSON(moduleData)}</TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <pre>{JSON.stringify(moduleData, null, 2)}</pre>
      )}
    </>
  );
}
