import React, { ReactFragment } from "react";
import { withStyles } from "@material-ui/core/styles";

import {
  Box,
  IconButton,
  Modal,
  Paper,
  Tooltip,
  TooltipProps,
  Typography,
} from "@material-ui/core";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import { Close } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

// this will just reutrn the icon button with the help symbol that will
// display the fragment you want in a modal
// note that title is translate for you (if in fr/tranlsation.json), so just pass string
// for the help, you need to supply help_fr (optional) otherwise will only show english (mandatory)
export function HelpButton({
  help,
  help_fr,
  title,
  noMargin,
}: {
  help: ReactFragment;
  help_fr?: ReactFragment;
  title?: string;
  noMargin?: boolean;
}) {
  const { t, i18n } = useTranslation();
  const [showModal, setShowModal] = React.useState(false);

  return (
    <>
      <Modal
        open={!!showModal}
        onClose={() => {
          setShowModal(false);
        }}
      >
        <Paper
          style={{
            overflow: "auto",
            maxHeight: "80vh",
            width: "80vw",
            display: "block",
            margin: "auto",
            marginTop: "7vh",
          }}
        >
          <Box m={2}>
            <Box
              style={{
                float: "right",
                cursor: "pointer",
              }}
              onClick={() => setShowModal(false)}
            >
              <Close />
            </Box>
            <Box mb={2}>
              <Typography variant="h5">
                {t(title ? title : "More Information")}
              </Typography>
            </Box>
            <Box m={2}>
              {help_fr && i18n.language.includes("fr") ? help_fr : help}
            </Box>
          </Box>
        </Paper>
      </Modal>
      <Tooltip title={t(title ? title : "More Information")}>
        <IconButton size="small" onClick={() => setShowModal(true)}>
          <Box mt={noMargin ? 0 : 0.5} mr={noMargin ? 0 : 0.5}>
            <HelpOutlineOutlinedIcon />
          </Box>
        </IconButton>
      </Tooltip>
    </>
  );
}

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(15),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

// this just shows the help as a title menu
// note that help is translated for you (if in fr/translation.json), you only need pass the string
export function HelpToolTip({
  help,
  placement,
}: {
  help: string;
  placement?: TooltipProps["placement"];
}) {
  const { t } = useTranslation();

  return (
    <>
      <HtmlTooltip title={t(help)} arrow>
        <IconButton
          style={{ cursor: "default" }}
          size="small"
          onClick={() => {}}
        >
          <HelpOutlineOutlinedIcon />
        </IconButton>
      </HtmlTooltip>
    </>
  );
}
