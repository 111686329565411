import {
  md5ReportModel,
  md5ReportType,
} from "../models/report/md5/md5ReportModel";

// TODO - test / handle case when there are no results (although unlikely)
export function getButtonBadgeNum(type: string, data: md5ReportModel) {
  //debugger;
  if (
    type !== "TK_YARA" &&
    (!data.malwareReport.reports[type] ||
      !data.malwareReport.reports[type].length)
  )
    return null;
  // look up the type on a case by case basis

  if (data.badgeValues && data.badgeValues[type.replace(/^TK_/, "")] !== -1)
    return data.badgeValues[type.replace(/^TK_/, "")];
  else {
    switch (true) {
      case md5ReportType?.ShadowServer === type:
        return Object.values(
          data.malwareReport?.reports?.[md5ReportType.ShadowServer]?.[0]
            ?.results
        ).filter((r: any) => r.result).length;
      case md5ReportType?.Yara === type:
        return (
          data.malwareReport?.reports?.[md5ReportType.Yara]?.[0]?.events
            ?.length + data?.yaraAssemblyLineRules?.length
        );
      case md5ReportType.VirusTotal === type:
        return data.malwareReport?.reports?.[md5ReportType.VirusTotal]?.[0]
          ?.size;
      case md5ReportType.MetaSploit === type:
        return data.malwareReport?.reports?.[md5ReportType.MetaSploit]?.[0]
          .positives;
      case md5ReportType?.Pendora === type:
      case /^BEAR_/.test(type):
        return 1;
      case /^FE_/.test(type):
        return 1;
      default:
        return null;
    }
  }
}
