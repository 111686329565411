import React from "react";
import { Card, isWidthDown, makeStyles, withWidth } from "@material-ui/core";

function CardCentered(props) {
  const useStyles = makeStyles((theme) => ({
    card: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: "4px",
      [theme.breakpoints.down("sm")]: {
        backgroundColor: theme.palette.background.default,
        width: "100%",
        maxWidth: "25rem",
        padding: "0rem 1rem 1.5rem",
      },
      [theme.breakpoints.up("sm")]: {
        width: "25rem",
        padding: "0 2rem 1.5rem",
      },
    },
  }));
  const classes = useStyles();

  return (
    <Card
      elevation={isWidthDown("xs", props.width) ? 0 : 4}
      className={classes.card}
      style={{
        display: "flex",
        flexDirection: "column",
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxWidth: "25rem",
      }}
    >
      {props.children}
    </Card>
  );
}

export default withWidth()(CardCentered);
