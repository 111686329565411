import React from "react";
import { useTranslation } from "react-i18next";
import PageHeader from "../../commons/components/layout/pages/PageHeader";
import PageCenter from "../../commons/components/layout/pages/PageCenter";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  useTheme,
} from "@material-ui/core";
import useAppLayout from "../../commons/components/hooks/useAppLayout";
import { BeaverStats } from "./BeaverStats";
import { isExternal } from "components/beaver/helpers/ServerProperties";
import {
  ParsedError,
  parseErrorTitle,
} from "components/beaver/components/report/common/ParsedError";
import { useStyles } from "components/beaver/components/report/common/sharedStyles";
import Axios from "axios";
import { isLab, isStaging } from "components/beaver/helpers/ServerProperties";
import { useNavigate } from "react-router-dom";

function Home() {
  const { t } = useTranslation();
  const theme = useTheme();
  const { getBanner } = useAppLayout();
  const [error, setError] = React.useState(null);
  const [version, setVersion] = React.useState(null);
  const [date, setDate] = React.useState(null);
  const classes = useStyles();
  const navigate = useNavigate();

  React.useEffect(() => {
    // if there is a url stored from before a 401 event and subsequently you are home (but not LAB or STG), restore that
    if (!isLab() && !isStaging() && !!window.localStorage.goToURLAfterLogin) {
      let goToURLAfterLogin = window.localStorage.goToURLAfterLogin;
      window.localStorage.removeItem("goToURLAfterLogin");
      if (goToURLAfterLogin.split("/ng/").length > 1)
        navigate(goToURLAfterLogin.split("/ng/").slice(1).join("/ng/"));
    }
    if (
      window.location.host.match(/gc\.ca$/) ||
      window.location.host.match(/lab$/)
    )
      Axios.get("/ng/version.json").then((res) => {
        // console.log(res);
        if (res.data.date) setDate(res.data.date);
        if (res.data.version) setVersion(res.data.version);
      });
  }, [navigate]);

  if (error) {
    return (
      <Box mt={2}>
        <Card>
          <CardHeader
            className={classes.ch}
            avatar={<Avatar className={classes.errorIcon}>!</Avatar>}
            title={parseErrorTitle(error)}
          />
          <CardContent>
            <ParsedError error={error} />
          </CardContent>
        </Card>
      </Box>
    );
  } else {
    return (
      <>
        <PageHeader title={t("page.home.title")} />
        <PageCenter width={75}>
          <Box color={theme.palette.primary.main} fontSize="10vw">
            {getBanner(theme)}
          </Box>
          <Box pb={2}>
            <Typography variant="h5">{t("page.home.welcome")}</Typography>
          </Box>
          <Typography variant="body1">
            {isExternal() ? t("page.home.descext") : t("page.home.desc")}
          </Typography>
          <Box pt={8} style={{ width: "90%", margin: "auto" }}>
            <BeaverStats setError={setError} />
          </Box>
          <Box mt={2}>
            {version && t("Beaver NG version ") + version}
            {date && t(" built on ") + date}
          </Box>
          {/*Basically only allow lab and internal to go legacy beaver mode*/}
          {/* {!window.location.host.match(
            /beaver\.(ops|ccirc-|collaboration)+/
          ) ? (
            <Box mt={2}>
              <Button
                onClick={() => {
                  // allow legacy mode for 10 minutes
                  localStorage.legacy = new Date().getTime() + 10 * 60 * 1000;
                  window.location.href = "/";
                }}
              >
                {t("Use Legacy Beaver")}
              </Button>
            </Box>
          ) : null} */}
        </PageCenter>
      </>
    );
  }
}

export default Home;
