import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Grid,
} from "@material-ui/core";
import { LinkWrap } from "components/beaver/components/report/common/LinkWrap";
import md5 from "md5";
import moment from "moment";

import {
  HallowHunterModel,
  bearModel,
} from "../../../../models/report/md5/bearModel";
import {
  dbCNCMapModel,
  isChannelEncrypted,
} from "../../../../models/report/md5/dbCNCMapCalloutModel";

import { ScreenshotView } from "../../url/views/ScreenshotView";

import { useStyles } from "../../common/sharedStyles";

import { useTranslation } from "react-i18next";
import { SimpleTableCardView } from "../../common/SimpleTableView";
import { Link } from "react-router-dom";
import {
  DNSQueriesModel,
  JA3FingerprintModel,
  SandboxAlertModel,
  MD5ResourcesModel,
} from "components/beaver/models/report/md5/md5ReportModel";

export type BearViewProps = {
  bearReport: bearModel;
  type: string;
  dbCNCMap: dbCNCMapModel[];
  sandBoxAlerts: SandboxAlertModel[];
  fingerprints: JA3FingerprintModel[];
  dnsQueries?: DNSQueriesModel[];
  reportAddedDates?: { [message: string]: string };
  resources?: MD5ResourcesModel;
  testing?: boolean;
};

export function BearView({
  bearReport,
  type,
  dbCNCMap,
  testing,
  sandBoxAlerts,
  fingerprints,
  dnsQueries,
  reportAddedDates,
  resources,
}: BearViewProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  // good sample with alertrs, etc. :  https://beaver.ccirc.lab/md5/071db9fc5442249b021800ec2f4f0fff/report#BEAR_VMWinXPProSP3InetSim

  const [additionalMessages, setAdditionalMessages] = React.useState([]);

  React.useEffect(() => {
    setAdditionalMessages(
      Object.keys(reportAddedDates).filter((r) => r.indexOf(type) > 0)
    );
  }, [reportAddedDates, type]);

  const [creationDate, setCreationDate] = React.useState("");

  React.useEffect(() => {
    setCreationDate(moment(bearReport?.creationDate).format("YYYYMMDD"));
  }, [bearReport?.creationDate]);

  const screenshotExists =
    resources &&
    resources[type] &&
    resources[type][creationDate] &&
    resources[type][creationDate].length;

  return (
    <Box>
      <Card>
        <CardHeader className={classes.ch} title={t("BEAR Report")} />
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12} md={screenshotExists ? 9 : 12}>
              <TableContainer>
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell>{t("Name")}</TableCell>
                      <TableCell>{type}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t("Analysis Date")}</TableCell>
                      <TableCell>{bearReport?.creationDate}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>MD5</TableCell>
                      <TableCell>{bearReport?.md5}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t("Report Version")}</TableCell>
                      <TableCell>{bearReport?.version}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t("Sandbox Name")}</TableCell>
                      <TableCell>{bearReport?.engineName}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t("Engine Version")}</TableCell>
                      <TableCell>{bearReport?.engineVersion}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t("Result")}</TableCell>
                      <TableCell>{bearReport?.result}</TableCell>
                    </TableRow>
                    {bearReport?.messages[0] || additionalMessages.length ? (
                      <TableRow>
                        <TableCell>{t("Message")}</TableCell>
                        <TableCell>
                          {bearReport?.messages && bearReport?.messages.length
                            ? bearReport?.messages[0].type +
                              "  " +
                              bearReport?.messages[0].message
                            : null}
                          {additionalMessages.map((r, i) => (
                            <React.Fragment key={"msgfrag" + i}>
                              {r
                                // NOTE:  these messages are tricky !  if the backend changes, they probably won't appear correctly !!
                                // there are a bunch of messages in the string seperated by \n's
                                .split("\n")
                                .filter(
                                  // firstly, there are some json tags that show up in the messages - these are typically less than 50 chars so filter them
                                  // secondly, there is a duplicate message to the bearReport.messages[0], but has a comma, it's the only one with a comma
                                  (s) => {
                                    let t = s;
                                    if (s.includes("messages=")) {
                                      t = s.split("messages=")[1];
                                    }
                                    return (
                                      t.length > 50 && t.indexOf(',"') < 20
                                    );
                                  }
                                )
                                .map((s, j) => (
                                  <p key={"msgfrag2" + s + "-" + r}>
                                    {
                                      // some of the messages are in JSON, so we need to strip out the json part
                                      s.includes("messages=")
                                        ? s.split("messages=")[1]
                                        : s
                                    }
                                  </p>
                                ))}
                            </React.Fragment>
                          ))}
                        </TableCell>
                      </TableRow>
                    ) : null}
                    {!screenshotExists ? (
                      <TableRow>
                        <TableCell>{t("Screenshot")}</TableCell>
                        <TableCell>{t("None")}</TableCell>
                      </TableRow>
                    ) : null}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            {screenshotExists ? (
              <Grid item xs={12} md={3}>
                {!!resources && resources[type] && (
                  <ResourcesBox
                    resources={resources[type]}
                    creationDate={creationDate}
                    md5={bearReport?.md5}
                  />
                )}
              </Grid>
            ) : null}
          </Grid>
        </CardContent>
      </Card>

      <SandBoxAlertsBox sandBoxAlerts={sandBoxAlerts} testing={testing} />

      {!!bearReport?.hhreport && (
        <HollowHunter hhreport={bearReport?.hhreport} />
      )}

      <CalloutsBox dbCNCMap={dbCNCMap} testing={testing} />

      <Box mt={2}>
        {dnsQueries && dnsQueries.length ? (
          <SimpleTableCardView
            testing={testing}
            title={t("DNS Queries")}
            headers={[
              { title: t("Query"), field: "query" },
              { title: t("Type"), field: "type" },
              { title: t("Server"), field: "server" },
              {
                title: t("Answers"),
                field: "answer",
                render: (r) =>
                  r.answers.map((a, i) => (
                    <React.Fragment key={i}>
                      <Link to={["/ip", a, "report"].join("/")}>
                        <LinkWrap>{a}</LinkWrap>
                      </Link>{" "}
                    </React.Fragment>
                  )),
              },
              {
                title: t("TTLs"),
                field: "ttl",
                render: (r) => r.ttls.join(", "),
              },
            ]}
            data={dnsQueries.map((d) => ({
              query: d.query,
              type: d.type,
              server: d.server,
              answer: d.answers && d.answers.length ? d.answers[0] : "",
              answers: d.answers,
              ttl: d.ttls && d.ttls.length ? d.ttls[0] : 0,
              ttls: d.ttls,
            }))}
          />
        ) : (
          <Card>
            <CardHeader className={classes.ch} title={t("DNS Queries")} />
            <CardContent>{t("No DNS Queries Reported")}</CardContent>
          </Card>
        )}
      </Box>

      <FingerprintsBox fingerprints={fingerprints} testing={testing} />
    </Box>
  );
}

export function ResourcesBox({
  resources,
  creationDate,
  md5,
}: {
  resources: any;
  creationDate: string;
  md5: string;
}) {
  return (
    <Box style={{ cursor: "pointer" }}>
      {resources[creationDate] && resources[creationDate].length ? (
        <ScreenshotView
          screenshotLinks={resources[creationDate]
            .filter((s) => "SCREENSHOT" === s.type)
            .map((s) => s.link)}
          dontShowInCard={true}
          md5={md5}
          cluster={null}
          height={"225px"}
        />
      ) : null}
    </Box>
  );
}

export type SandBoxAlertsBoxProps = {
  sandBoxAlerts: SandboxAlertModel[];
  testing?: boolean;
};

export function SandBoxAlertsBox({
  sandBoxAlerts,
  testing,
}: SandBoxAlertsBoxProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box mt={2}>
      {sandBoxAlerts && sandBoxAlerts.length ? (
        <SimpleTableCardView
          title={t("Alerts")}
          testing={testing}
          headers={[
            {
              title: t("Rule SID"),
              field: "sid",
              render: (r) => (
                <Link to={["/sid", r.sid, "report"].join("/")}>
                  <LinkWrap>{r.sid}</LinkWrap>
                </Link>
              ),
            },
            {
              title: t("Message"),
              field: "msg",
            },
            {
              title: t("Domain"),
              field: "domain",
              render: (r) =>
                r.domain ? (
                  <Link to={["/domain", r.domain, "report"].join("/")}>
                    <LinkWrap>{r.domain}</LinkWrap>
                  </Link>
                ) : (
                  t("N/A")
                ),
            },
            {
              title: t("IP"),
              field: "ip",
              render: (r) => (
                <Link to={["/ip", r.ip, "report"].join("/")}>
                  <LinkWrap>{r.ip}</LinkWrap>
                </Link>
              ),
            },
            {
              title: t("Port"),
              field: "port",
            },
          ]}
          data={sandBoxAlerts.map((s, i) => ({
            sid: s.rule.sid,
            msg: s.rule.msg,
            domain: s.domain,
            ip: s.ip,
            port: s.port,
          }))}
        />
      ) : (
        <Card>
          <CardHeader className={classes.ch} title={t("Alerts")} />
          <CardContent>{t("No Alerts Reported")}</CardContent>
        </Card>
      )}
    </Box>
  );
}

export type CalloutsBoxProps = {
  dbCNCMap: dbCNCMapModel[];
  testing?: boolean;
  hideLocationAndAssociation?: boolean;
};

export function CalloutsBox({
  dbCNCMap,
  testing,
  hideLocationAndAssociation,
}: CalloutsBoxProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [fullChannel, setFullChannel] = React.useState([]);
  const maxChannelLength = 400;

  const getSafeDomainLink = (domain) => {
    // this will attempt to return a link, and failing that will just return text
    try {
      let decodedDomain = decodeURI(domain);
      if ("UNKNOWN" === domain) {
        return domain;
      } else if (domain.match(/^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/)) {
        // the "domain" is really an ip address, so link to the ip report
        return (
          <Link to={["/ip", domain, "report"].join("/")}>
            <LinkWrap>{domain}</LinkWrap>
          </Link>
        );
      } else {
        return (
          <Link to={["/domain", decodedDomain, "report"].join("/")}>
            <LinkWrap>{decodedDomain}</LinkWrap>
          </Link>
        );
      }
    } catch (e) {
      return domain;
    }
  };

  return (
    <Box mt={2}>
      {dbCNCMap && dbCNCMap.length ? (
        <Box mt={2}>
          <SimpleTableCardView
            testing={testing}
            title={t("Callouts")}
            headers={[
              {
                title: t("Callout"),
                field: "callout",
                render: (r) => getSafeDomainLink(r.callout),
                width: "5%",
              },
              {
                title: t("Protocol"),
                field: "protocol",
                width: "2%",
              },
              {
                title: t("Port"),
                field: "port",
                width: "2%",
              },
              {
                title: t("IP"),
                field: "ip",
                render: (r) => (
                  <Link to={["/ip", r.ip, "report"].join("/")}>
                    <LinkWrap>{r.ip}</LinkWrap>
                  </Link>
                ),
                width: "5%",
              },
              {
                title: t("Location"),
                field: "location",
                width: "5%",
              },
              {
                title: t("Association"),
                field: "association",
                width: "5%",
              },
              {
                title: t("Channel"),
                field: "channel",
                render: (r) => (
                  <>
                    <span className={classes.breakword}>
                      {!fullChannel.includes(r.tableData.id) &&
                      r.channel &&
                      r.channel.length > maxChannelLength
                        ? r.channel.substr(0, maxChannelLength - 1)
                        : r.channel}
                    </span>
                    {!fullChannel.includes(r.tableData.id) &&
                    r.channel &&
                    r.channel.length > maxChannelLength ? (
                      <Button
                        size="small"
                        color="primary"
                        style={{
                          marginLeft: 15,
                          textTransform: "none",
                          color: "lightgrey",
                        }}
                        onClick={() =>
                          setFullChannel(fullChannel.concat([r.tableData.id]))
                        }
                      >
                        ({t("Click to Expand")})
                      </Button>
                    ) : null}
                  </>
                ),
                width: "50%",
              },
              {
                title: t("URL"),
                field: "channelURL",
                render: (r) =>
                  !r.channelURL ? (
                    ""
                  ) : (
                    <Link
                      to={[
                        "/url",
                        md5(
                          "http" +
                            (r.port === 443 ? "s" : "") +
                            "://" +
                            (!!r.dnsName && r.dnsName !== "UNKNOWN"
                              ? r.dnsName
                              : r.ip) +
                            r.channelURL
                        ),
                        "report",
                      ].join("/")}
                    >
                      <LinkWrap>
                        <span className={classes.breakword}>
                          {r.channelURL}
                        </span>
                      </LinkWrap>
                    </Link>
                  ),
              },
            ].filter(
              (a, i) => !hideLocationAndAssociation || ![5, 6].includes(i)
            )}
            data={dbCNCMap.map((r) => ({
              callout: r.callout.domain ? r.callout.domain : "UNKNOWN",
              protocol: r.callout.protocols.join(", "),
              port: r.callout.port,
              ip: r.callout.ip,
              dnsName:
                r.domains && r.domains.length ? r.domains[0].domain : "UNKNOWN",
              location:
                r.domains?.[0]?.ipLocation?.countryCode &&
                r.domains?.[0]?.ipLocation?.city
                  ? [
                      r.domains[0].ipLocation?.countryCode,
                      r.domains[0].ipLocation?.city,
                    ].join(" - ")
                  : "UNKNOWN",
              association: r.domains?.[0]?.ipLocation?.asNumber
                ? r.domains[0].ipLocation?.asNumber
                : "UNKNOWN",
              channel: isChannelEncrypted(r.callout.channel)
                ? t("SSL/TLS Encrypted")
                : r.callout.channel,
              //looks like the url is from the domain + channel md5'ed
              channelURL:
                !r.callout.channel || isChannelEncrypted(r.callout.channel)
                  ? ""
                  : r.callout.channel.match(/GET\s\/.*/)
                  ? r.callout.channel.split(" ")[1]
                  : "",
            }))}
          />
        </Box>
      ) : (
        <Card>
          <CardHeader className={classes.ch} title={t("Callouts")} />
          <CardContent>{t("No Callouts Reported")}</CardContent>
        </Card>
      )}
    </Box>
  );
}

export function HollowHunter({ hhreport }: { hhreport: HallowHunterModel }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const hhreportProcesses = hhreport.rawProcessReports
    ? hhreport.rawProcessReports
    : hhreport.processes;
  const hhVersion = hhreport.rawProcessReports
    ? hhreport.rawProcessReports[0].hollowsHunterVersion
    : hhreport.hollowsHunterVersion;

  return (
    <Box mt={2}>
      <Card>
        <CardHeader className={classes.ch} title={t("Injection Techniques")} />
        {hhreportProcesses ? (
          <CardContent>
            <b>{t("Process Information")}</b>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>{t("Injection Information")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {hhreportProcesses.map((p, i) => (
                    <TableRow key={i}>
                      <TableCell>
                        {t("Target Process")}:{" "}
                        {p.mainExecutablePath
                          ? p.mainExecutablePath
                          : p["main_image_path"]}
                        <br />
                        {t("Tool")}: "Hollows Hunter", {t("Version")}:{" "}
                        {hhVersion}, {t("Injection Technique")}:{" "}
                        <b>
                          {Object.keys(p.scanned.modified)
                            .filter((p) => "total" !== p)
                            .filter((q) => !!p.scanned.modified[q])
                            .join(", ")}
                        </b>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box mt={2}>
              <b>{t("Drop Information")}</b>
            </Box>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>{t("Drops")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* see the bearModle.ts or hhreport section of mocked 04ecfc3fa0c53151d976f2d6fbd65c31.json for how the nestign of drops look like */}
                  <TableRow>
                    <TableCell>
                      {hhreportProcesses.map((p) => p.drops).flat(1).length
                        ? hhreportProcesses
                            .map((p) => p.drops)
                            .flat(1)
                            .map((m, i) => (
                              <Box key={i}>
                                <Link to={["/md5", m.md5, "report"].join("/")}>
                                  <LinkWrap>{m.md5}</LinkWrap>
                                </Link>
                              </Box>
                            ))
                        : "None"}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>{t("Blacklisted Drops")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {hhreport.processes &&
                      hhreport.processes.map((p) => p.blacklisted).flat(1)
                        .length
                        ? hhreport.processes
                            .map((p) => p.blacklisted)
                            .flat(1)
                            .map((m, i) => (
                              <Box key={i}>
                                <Link to={["/md5", m.md5, "report"].join("/")}>
                                  <LinkWrap>{m.md5}</LinkWrap>
                                </Link>{" "}
                                {m?.scans[0]?.module_file
                                  ? " (" + m.scans[0].module_file + ")"
                                  : ""}
                              </Box>
                            ))
                        : "None"}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        ) : (
          <CardContent>{t("No Injections Reported")}</CardContent>
        )}
      </Card>
    </Box>
  );
}

export type FingerprintsBoxProps = {
  fingerprints: JA3FingerprintModel[];
  testing: boolean;
};

export function FingerprintsBox({
  fingerprints,
  testing,
}: FingerprintsBoxProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box mt={2}>
      {fingerprints && fingerprints.length ? (
        <SimpleTableCardView
          testing={testing}
          title={t("JA3 Signatures")}
          headers={[
            {
              title: t("JA3 MD5"),
              field: "ja3md5",
            },
            {
              title: t("IP"),
              field: "ip",
              render: (r) => (
                <Link to={["/ip", r.ip, "report"].join("/")}>
                  <LinkWrap>{r.ip}</LinkWrap>
                </Link>
              ),
            },
            {
              title: t("Domain"),
              field: "domain",
              render: (r) => (
                <>
                  {r.domainsString
                    ? r.domains.map((d, i) => (
                        <React.Fragment key={i}>
                          <Link to={["/domain", d.domain, "report"].join("/")}>
                            <LinkWrap>{d.domain}</LinkWrap>
                          </Link>{" "}
                        </React.Fragment>
                      ))
                    : t("N/A")}
                </>
              ),
            },
            {
              title: t("Port"),
              field: "port",
            },
          ]}
          data={fingerprints.map((f, i) => ({
            ja3md5: f.fingerprint.md5,
            ip: f.fingerprint.destinationIP,
            domains: f.domains,
            domain: f.domains && f.domains.length ? f.domains[0].domain : "",
            port: f.fingerprint.destinationPort,
          }))}
        />
      ) : (
        <Card>
          <CardHeader className={classes.ch} title={t("JA3 Signatures")} />
          <CardContent>{t("No Fingerprints Reported")}</CardContent>
        </Card>
      )}
    </Box>
  );
}
