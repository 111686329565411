import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { useStyles } from "../../common/sharedStyles";
import {
  md5ReportModel,
  md5ReportType,
  md5ReportTypeMap,
} from "components/beaver/models/report/md5/md5ReportModel";
import { getButtonBadgeNum } from "../../../../helpers/getButtonBadgeNum";
import { HelpToolTip } from "components/beaver/components/report/common/HelpButton";

export type SubgroupSummaryViewProps = {
  data: md5ReportModel;
  type: string;
  setShow: Function;
};

export const SubgroupSummaryView = ({
  data,
  type,
  setShow,
}: SubgroupSummaryViewProps) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { malwareReport } = data;
  const { types } = malwareReport;

  let title = "";
  switch (type) {
    case "SIGNATURE_SUMMARY":
      title = t("Signature Summary");
      break;
    case "STATIC_SUMMARY":
      title = t("Static Summary");
      break;
    case "DYNAMIC_SUMMARY":
      title = t("Dynamic Summary");
      break;
    default:
      title = t("Unknown Summary");
      break;
  }

  const specialCount = [
    md5ReportType.Hash,
    md5ReportType.Viz,
    md5ReportType.Entropy,
    md5ReportType.FileType,
  ];
  const countAlways = [md5ReportType.Yara];

  const typeCount =
    md5ReportTypeMap?.[type.split("_")?.[0]?.toLowerCase()]?.map((val) => {
      let count = 0;
      if (types.includes(val) || countAlways.includes(val)) {
        count = getButtonBadgeNum(val, data);
      }
      if (specialCount.includes(val) && types.includes(val)) {
        count = 1;
      }
      // console.log([type, val, count].join(" : "));
      return {
        text: val,
        count,
      };
    }) || [];

  return (
    <Box>
      <Card>
        <CardHeader title={title} className={classes.ch} />
        <CardContent>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>{t("Report Type")}</TableCell>
                  <TableCell>{t("Events")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {typeCount.map((type) => (
                  <TableRow key={type.text}>
                    <TableCell>
                      <Chip
                        className={classes.tags}
                        clickable={
                          Object.keys(malwareReport.reports).includes(
                            type.text
                          ) ||
                          (type.text === md5ReportType.Yara && !!type.count)
                        }
                        color={
                          Object.keys(malwareReport.reports).includes(
                            type.text
                          ) ||
                          (type.text === md5ReportType.Yara && !!type.count)
                            ? "primary"
                            : "default"
                        }
                        label={type.text.replace("TK_", "")}
                        onClick={() => {
                          if (
                            Object.keys(malwareReport.reports).includes(
                              type.text
                            ) ||
                            (type.text === md5ReportType.Yara && !!type.count)
                          )
                            setShow(type.text);
                        }}
                      />
                      {/* If there is a key with the type.text in the translation.json's then show the help tooltip with that text */}
                      {i18n.exists("md5.report.summarychip." + type.text) && (
                        <HelpToolTip
                          help={t("md5.report.summarychip." + type.text)}
                        />
                      )}
                    </TableCell>
                    <TableCell>{type.count}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </Box>
  );
};
