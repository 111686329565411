import React, { useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { sidReportSelector, fetchReport } from "slices/sidreport";
import { fetchSources, sourceSelector } from "slices/sources";

import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import { DetailsCard } from "../common/type/DetailsCard";
import { TypeDetail } from "../common/type/TypeDetail";
import { TimeLine } from "../common/type/TimeLine";
import { useStyles } from "../common/sharedStyles";
import { ReportType } from "../../../models/report/reportType";
import { SubReportType } from "../../../models/report/type/SubReportType";
import { ParsedError, parseErrorTitle } from "../common/ParsedError";

import { useTranslation } from "react-i18next";
import { useRefDimensions } from "../../../../hooks/useRefDimensions";

export type SIDReportProps = { sid: string };

export function SIDReport({ sid }: SIDReportProps) {
  const classes = useStyles();
  const [retry, setRetry] = useState(0);
  const { t } = useTranslation();
  const [detailsCardRef, setDetailsCardRef] = useState(useRef().current);

  const dimensions = useRefDimensions(detailsCardRef);
  // initialize the redux hook
  const dispatch = useDispatch();

  const { responseData, reportType, error } = useSelector(sidReportSelector);
  const { sources } = useSelector(sourceSelector);
  const [showSubView, setShowSubView] = React.useState(null);

  // will run on first mount
  useEffect(() => {
    dispatch(fetchReport(ReportType.SID, sid));
  }, [sid, dispatch, retry]);

  useEffect(() => {
    dispatch(fetchSources(sources));
  }, [sources, dispatch, retry]);

  const getSource = (source: string): string => {
    if (source.match(/^et/)) {
      return sources && sources["et"]
        ? sources["et"].displayString +
            ("etpro" === source
              ? " - Pro Ruleset"
              : "eto" === source
              ? " - Open Ruleset"
              : "")
        : source;
    }
    return sources && sources[source] ? sources[source].displayString : source;
  };

  if (
    !error &&
    (!responseData || !reportType || reportType !== ReportType.SID)
  ) {
    return (
      <>
        {t("Loading")} ...
        <LinearProgress />
      </>
    );
  } else if (error) {
    return (
      <Card>
        <CardHeader
          className={classes.ch}
          avatar={<Avatar className={classes.errorIcon}>!</Avatar>}
          title={parseErrorTitle(error)}
        />
        <CardContent>
          <ParsedError error={error} />
        </CardContent>
        <CardActions>
          <Button size="small" onClick={() => setRetry(retry + 1)}>
            {t("Retry")}
          </Button>
        </CardActions>
      </Card>
    );
  }
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <DetailsCard
            type={responseData.type}
            value={responseData.value}
            data={responseData}
            setShow={() => {}}
            setShowSubView={setShowSubView}
            ref={setDetailsCardRef}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <TimeLine
            data={responseData}
            height={dimensions.height}
            minHeight={400}
          />
        </Grid>
      </Grid>

      {responseData.sidrules && responseData.sidrules.length ? (
        <Box mb={2}>
          <Card>
            <CardHeader
              title={
                t("SID Rule") +
                (responseData.sidrules[0].privateRule
                  ? " (TLP:AMBER+STRICT)"
                  : "")
              }
              className={classes.ch}
            />
            <CardContent>
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        {t("SID Rule")}{" "}
                        {responseData.sidrules[0].privateRule ? (
                          <b> (TLP:AMBER+STRICT)</b>
                        ) : (
                          " (TLP:CLEAR)"
                        )}
                      </TableCell>
                      <TableCell className={classes.code}>
                        {responseData.sidrules[0].rule}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t("Rule Source")}</TableCell>
                      <TableCell>
                        {getSource(responseData.sidrules[0].rule_source_id)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t("Rule Language")}</TableCell>
                      <TableCell>
                        {responseData.sidrules[0].rule_language}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t("Added On")}</TableCell>
                      <TableCell>{responseData.sidrules[0].added_on}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Box>
      ) : null}

      <Card>
        <CardHeader
          title={<>{t("sys." + SubReportType.BEAVER.toUpperCase())}</>}
          className={classes.ch}
        />
        <CardContent>
          <TypeDetail
            data={responseData}
            show={SubReportType.BEAVER}
            showSubView={showSubView}
            setShowSubView={setShowSubView}
          />
        </CardContent>
      </Card>
    </>
  );
}
