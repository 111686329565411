import React from "react";

export const TimeLineHelpTitle = "About Timeline";

export function TimeLineHelp() {
  return (
    <>
      <p>
        The timeline view lets you view the timeline of the artifact in
        question. You can quickly view different events such as Domain, malware,
        IOCs, and URLs.
      </p>
      <figure style={{ marginTop: "2em" }}>
        <figcaption>The timeline view:</figcaption>
        <img src="/ng/images/help/timeline1.jpg" alt="Timeline View" />
      </figure>

      <figure style={{ marginTop: "2em" }}>
        <figcaption>
          You can click on a point in the timeline to view more information
          about the point:
        </figcaption>
        <img src="/ng/images/help/timeline2.gif" alt="Click in timeline View" />
      </figure>

      <figure style={{ marginTop: "2em" }}>
        <figcaption>
          You can also zoom in by holding your mouse and choosing an area on the
          graph:
        </figcaption>
        <img src="/ng/images/help/timeline3.gif" alt="Zoom timeline view" />
      </figure>
    </>
  );
}

export function TimeLineHelpFr() {
  return (
    <>
      <p>
        La vue chronologique vous permet d'afficher la chronologie de l'artefact
        dans question. Vous pouvez afficher rapidement différents événements
        tels que le domaine, les logiciels malveillants, IOC et URL.
      </p>
      <figure style={{ marginTop: "2em" }}>
        <figcaption>La vue chronologique:</figcaption>
        <img src="/ng/images/help/timeline1.jpg" alt="Timeline View" />
      </figure>

      <figure style={{ marginTop: "2em" }}>
        <figcaption>
          Vous pouvez cliquer sur un point de la chronologie pour afficher plus
          d'informations sur le sujet:
        </figcaption>
        <img src="/ng/images/help/timeline2.gif" alt="Click in timeline View" />
      </figure>

      <figure style={{ marginTop: "2em" }}>
        <figcaption>
          Vous pouvez également zoomer en maintenant votre souris enfoncée et en
          choisissant une zone sur le graphique:
        </figcaption>
        <img src="/ng/images/help/timeline3.gif" alt="Zoom timeline view" />
      </figure>
    </>
  );
}
