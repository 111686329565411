import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import clsx from "clsx";
import {
  Box,
  ClickAwayListener,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Toolbar,
  Tooltip,
  useTheme,
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";

import useAppLayout from "../../hooks/useAppLayout";
import LeftNavGroup from "./LeftNavGroup";
import LeftNavItem from "./LeftNavItem";

const drawerWidth = 263;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: 0,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(7) + 1,
    },
  },
  toolbar: {
    padding: 0,
  },
  title: {
    alignItems: "center",
    display: "inline-flex",
    flex: "0 0 auto",
    fontSize: "1.5rem",
    letterSpacing: "-1px",
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
  toggler: {
    height: "100%",
    width: "100%",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

export type LeftNavElement = {
  hidden?: boolean;
  type: "item" | "group" | "divider";
  element: LeftNavItemProps | LeftNavGroupProps | null;
};

export type LeftNavItemProps = {
  id: number | string;
  text: string;
  icon?: React.ReactElement<any>;
  route: string;
  nested?: boolean;
  width?: "xs" | "sm" | "md" | "lg" | "xl";
};

export type LeftNavGroupProps = {
  open?: boolean;
  id: number | string;
  title: string;
  icon: React.ReactElement<any>;
  items: LeftNavItemProps[];
};

type LeftNavDrawerProps = {
  width?: "xs" | "sm" | "md" | "lg" | "xl";
};

const LeftNavDrawer: React.FC<LeftNavDrawerProps> = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { layoutProps, getLogo, drawerState, toggleDrawer } = useAppLayout();
  const classes = useStyles();

  const onCloseDrawerIfOpen = () => {
    if (isWidthDown("sm", props.width) && drawerState) {
      toggleDrawer();
    }
  };

  const header = (
    <Box>
      <Toolbar className={classes.toolbar}>
        <Link to={"/"} className={classes.title} onClick={onCloseDrawerIfOpen}>
          <Box style={{ display: "flex", padding: "0 10px" }}>
            {getLogo(theme)}
          </Box>
          <Box style={{ display: "flex" }}>{layoutProps.appName}</Box>
        </Link>
      </Toolbar>
      <Divider />
    </Box>
  );

  const hide = (
    <List disablePadding>
      <ListItem button key="chevron" onClick={toggleDrawer}>
        <ListItemIcon>
          {drawerState ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </ListItemIcon>
        <ListItemText primary={t("drawer.collapse")} />
      </ListItem>
    </List>
  );

  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={onCloseDrawerIfOpen}
    >
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: drawerState,
          [classes.drawerClose]: !drawerState,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: drawerState,
            [classes.drawerClose]: !drawerState,
          }),
        }}
      >
        {drawerState ? (
          header
        ) : (
          <Tooltip
            title={layoutProps.appName}
            aria-label={layoutProps.appName}
            placement="right"
          >
            {header}
          </Tooltip>
        )}
        <List disablePadding>
          {layoutProps.leftnav.elements.map(function (e, i) {
            const key = `{navel-${i}}`;
            if (e.hidden) return null;
            else if (e.type === "item") {
              return (
                <LeftNavItem key={key} {...(e.element as LeftNavItemProps)} />
              );
            } else if (e.type === "group") {
              return (
                <LeftNavGroup
                  key={key}
                  open={drawerState}
                  {...(e.element as LeftNavGroupProps)}
                />
              );
            } else if (e.type === "divider") {
              return <Divider key={key} />;
            } else {
              return null;
            }
          })}
        </List>
        <Divider />
        {/*Basically only allow lab and internal to go legacy beaver mode when drawer is open*/}
        {/* {drawerState &&
        !window.location.host.match(/beaver\.(ops|ccirc-|collaboration)+/) ? (
          <Button
            onClick={() => {
              // allow legacy mode for 10 minutes
              localStorage.legacy = new Date().getTime() + 10 * 60 * 1000;
              window.location.href = window.location.href.replace("/ng/", "/");
            }}
          >
            {t("Use Legacy Beaver")}
          </Button>
        ) : null} */}
        {drawerState ? (
          hide
        ) : (
          <Tooltip
            title={t("drawer.expand")}
            aria-label={t("drawer.expand")}
            placement="right"
          >
            {hide}
          </Tooltip>
        )}
        <Box className={clsx(classes.toggler)} onClick={toggleDrawer} />
      </Drawer>
    </ClickAwayListener>
  );
};

export default withWidth()(LeftNavDrawer);
