import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box } from "@material-ui/core";
import { TypeReportData } from "../../../../models/report/type/TypeReportData";
import { SubReportType } from "../../../../models/report/type/SubReportType";

import { useStyles } from "../sharedStyles";
import { useTranslation } from "react-i18next";

export function PhishingKitSummaryItem({
  data,
  val,
}: {
  data: TypeReportData;
  val: SubReportType;
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  if (
    "MOOSE" === data.subreports[val].system &&
    data.subreports[val]?.phishingInfo?.phishingKits?.length
  )
    return (
      <>
        <p>
          <b>{t("Phishing Kits")}</b>
        </p>
        {data.subreports[val].phishingInfo.phishingKits.map((p, i) => (
          <Box key={i}>
            <RouterLink
              to={["/phishing_kit", p.artifactMd5, "report"].join("/")}
              className={classes.data}
            >
              {p.artifactMd5}
            </RouterLink>
          </Box>
        ))}

        {data.subreports[val].phishingInfo.phishingKits
          .map((p) => p.clusterName)
          .concat(
            data.subreports[val].phishingInfo.phishingKits.map(
              (p) => p.subclusterName
            )
          )
          .filter((f) => f).length ? (
          <>
            <p>
              <b>{t("Phishing Kit Clusters")}</b>
            </p>

            {data.subreports[val].phishingInfo.phishingKits
              .map((p) => p.clusterName)
              .concat(
                data.subreports[val].phishingInfo.phishingKits.map(
                  (p) => p.subclusterName
                )
              )
              .filter((f) => f)
              .map((c, i) => (
                <Box key={i}>
                  <RouterLink className={classes.data} to={[].join("/")}>
                    {c}
                  </RouterLink>
                </Box>
              ))}
          </>
        ) : null}
      </>
    );
  else return null;
}
