import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

// ch is card header, nm is no margin
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ch: {
      // backgroundColor: theme.palette.secondary.light,
      // color: theme.palette.secondary.contrastText,
      padding: theme.spacing(0.5),
      paddingLeft: theme.spacing(1),
      borderBottom: "2px solid rgba(224, 224, 224, 1)",
    },
    cherror: {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.secondary.contrastText,
      padding: theme.spacing(0.5),
      paddingLeft: theme.spacing(1),
      borderBottom: "2px solid rgba(224, 224, 224, 1)",
    },
    inlineTags: { marginRight: theme.spacing(1), display: "inline" },
    tags: { marginRight: theme.spacing(1) },
    code: {
      fontSize: "1.2em",
      overflowX: "auto",
    },
    preview: {
      backgroundColor: theme.palette.action.selected,
      height: "100%",
      margin: "0",
    },
    previewModal: {
      overflow: "hidden",
      height: "80vh",
      width: "90vw",
      maxWidth: "1600px",
      margin: "10vh auto auto",
    },
    previewModalLayout: {
      height: "100%",
      width: "100%",
      padding: "20px",
      display: "flex",
      flexDirection: "column",
    },
    previewSetting: {
      display: "flex",
      alignItems: "center",
      gap: "15px",
      backgroundColor: theme.palette.action.selected,
      padding: "10px 15px",
      borderRadius: "5px",
      width: "fit-content",
    },
    vl: {
      borderLeft: "1px solid " + theme.palette.text.primary,
      height: "100%",
    },
    ol: {
      fontSize: "1em",
      padding: "20px 20px 20px 0px",
      margin: "0px",
    },
    li: {
      paddingLeft: "10px",
      overflowWrap: "anywhere",
      "&::marker": {
        color: theme.palette.text.hint,
      },
    },
    settingBlock: {
      display: "flex",
      alignItems: "center",
      gap: "15px",
      backgroundColor: theme.palette.action.selected,
      padding: "10px 15px",
      borderRadius: "5px",
      width: "fit-content",
    },
    tableToolbar: {
      "& input": { padding: "8px !important" },
      "& fieldset": { border: "transparent !important" },
    },
    errorIcon: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
      fontWeight: "bold",
    },
    nm: {
      paddingTop: 0,
      paddingBottom: 0,
      textAlign: "left",
      display: "block",
      overflow: "hidden",
    },
    data: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
    },
    lightText: {
      color: theme.palette.primary.light,
    },
    linkInHeader: {
      // color: theme.palette.secondary.contrastText,
      textDecorationLine: "underline",
      cursor: "pointer",
    },
    accordianheading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: "25%",
      flexShrink: 0,
    },
    accorianSecondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    info: {
      color: theme.palette.text.secondary,
    },
    row: {
      height: "40px",
    },
    centerCell: {
      display: "flex",
      alignItems: "center",
      gap: "5px",
      width: "max-content",
    },
    breakword: {
      wordBreak: "break-word",
    },
    dataAndBreakword: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
      wordBreak: "break-word",
    },
    pre: {
      overflowX: "auto",
      whiteSpace: "pre-wrap",
      wordBreak: "break-word",
    },
    domCode: {
      overflowX: "auto",
      whiteSpace: "pre-wrap",
    },
    shortFormMenu: {
      display: "inline",
      paddingLeft: theme.spacing(0.75),
      paddingRight: theme.spacing(0.75),
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      margin: 0,
      minWidth: 0,
    },
    dontDisplay: {
      display: "none",
    },
    // TODO - have to play with the stickyCell top position for mobile
    // maybe for mobile you need a new view with table rows expandable ?
    stickyCell: { top: 60, position: "sticky" },
  })
);
