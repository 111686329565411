import { createSlice } from "@reduxjs/toolkit";
import Axios from "axios";
import { PhishingKitListJSON } from "../components/beaver/helpers/ServerProperties";

// TODO - define error more properly (e.g. string?)
export type phishingClusterState = {
  phishingclusters: any;
  error: any;
};

export const slice = createSlice({
  name: "phishingclusters",
  initialState: {} as phishingClusterState,
  reducers: {
    setPhishingClusters: (state, { payload }) => {
      state.phishingclusters = {};
      payload?.clusterFrequency?.forEach(
        (c) =>
          (state.phishingclusters[c.cluster_name] = {
            total: c.total,
          })
      );
    },
    setError: (state, { payload }) => {
      state.error = payload;
    },
  },
});

export default slice.reducer;

// use the Redux chrome plugin to inspect the state
export const phishingClusterSelector = (state: phishingClusterState) =>
  state.phishingclusters;

/**
 * This will fetch the phishingclusters if they have not already been fetched,
 * hence why you send the phishingclusters in the fetch
 *
 * @param phishingclusters you pass the current phishingclusters from the reducer
 */
export const fetchPhishingClusters = (phishingclusters) => async (dispatch) => {
  //TODO - this should make the localstorage null if it can't parse- put this somewhere and export
  const user = localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser"))
    : {};

  if (!phishingclusters)
    Axios.get(PhishingKitListJSON(), {
      auth:
        user.server && !user.server.includes("localhost")
          ? {
              username: user ? user.username : null,
              password: user
                ? user.onetime
                  ? // eslint-disable-next-line no-eval
                    window.atob(eval(window.atob(user.onetime))).substr(13)
                  : null
                : null,
            }
          : null,
    }).then(
      (response) => {
        dispatch(slice.actions.setPhishingClusters(response.data));
      },
      (error) => {
        // error being ommited from console.log so as not to pollute the tests
        dispatch(
          slice.actions.setError(
            error.response
              ? JSON.stringify(error.response)
              : JSON.stringify({
                  status: error.message.match(/\d+/g)
                    ? parseInt(error.message.match(/\d+/g)[0])
                    : 500,
                  message: error.message,
                  data: JSON.stringify(error),
                })
          )
        );
      }
    );
};
