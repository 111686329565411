import React from "react";
import { Link } from "react-router-dom";
import { Chip } from "@material-ui/core";
import { SimpleTableView } from "../../common/SimpleTableView";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { LinkWrap } from "../../common/LinkWrap";

export interface CustomTag {
  user: string;
  date: string;
  family: string;
  colour: string;
  tagcount: number;
}

export type UserCreatedTagsProps = {
  customTags: CustomTag[];
  testing?: boolean;
};

export function UserCreatedTags({ customTags, testing }: UserCreatedTagsProps) {
  const [userCreatedTags, setUserCreatedTags] = React.useState<any[]>(
    testing ? customTags : []
  );

  // date in db is of type date, which becomes string in json, but need to parse to date for viewing, so
  // on load, just convert it.
  React.useEffect(() => {
    let tags = customTags.map((c) => {
      // there's a chance that the date is unparsable, so need to consider that
      // NOTE:  if it stops sorting properly by date, it's probably because of this parser
      const momentDate = moment(c.date, "MMM. DD, YYYY");
      let unixDate = momentDate.unix();
      let internalDateFormat = momentDate.format("YYYYMMDD");

      if (isNaN(unixDate)) unixDate = 0;
      return {
        family: c.family,
        colour: c.colour,
        user: c.user,
        date: c.date,
        unixDate: unixDate,
        internalDateFormat:
          internalDateFormat === "Invalid date" ? null : internalDateFormat,
        tagcount: c.tagcount,
      };
    });
    tags.sort((a, b) => b.unixDate - a.unixDate);

    setUserCreatedTags(tags);
  }, [customTags]);

  const { t } = useTranslation();
  return (
    <SimpleTableView
      showLoadingInsteadOfNoDataWarning
      headers={[
        {
          title: t("Tag Name"),
          field: "family",
          render: (rowData) => (
            <Link to={"/tag/" + rowData.family + "/report"}>
              <LinkWrap noBorealisLink>{rowData.family}</LinkWrap>
            </Link>
          ),
        },
        {
          title: t("Color For Tag"),
          field: "colour",
          render: (rowData) =>
            rowData.colour ? (
              <Chip
                label={
                  rowData.colour === "#5cd45e" ? "generated" : rowData.colour
                }
                color="primary"
                style={{ backgroundColor: rowData.colour }}
                size="small"
              />
            ) : (
              ""
            ),
        },
        {
          // sort by the unixDate, show the date String
          title: t("User"),
          field: "user",
          render: (rowData) => (
            <span>{rowData.user ? rowData.user : t("System Generated")}</span>
          ),
        },
        {
          // sort by the unixDate, show the date String
          title: t("Creation Date"),
          field: "unixDate",
          render: (r) => <span>{r.internalDateFormat}</span>,
        },
        { title: t("Count"), field: "tagcount" },
      ]}
      data={
        userCreatedTags
      } /* This wasn't input from props, so mutable (hence no cloneDeep) - material table needs mutable data*/
      testing={testing}
    />
  );
}
