export interface dbCNCMapCalloutModel {
  domain: string;
  protocols: string[];
  port: string;
  ip: string;
  channel: string;
  serverResponse?: string;
}

export interface dbCNCMapDomainModel {
  domain: string;
  ipLocation: {
    countryCode: string;
    city: string;
    asNumber: string;
    asName: string;
  };
}

export interface dbCNCMapHttpCalloutModel {
  url: string;
  method: string;
  httpVersion: string;
  responseParsed: boolean;
  responseBody: string;
  entitiyBody: string;
  protocols: string[];
  ip: string;
  domain: string;
  port: number;
  isLive: true;
  channel: string;
  serverResponse: string;
  isAmbiguous: boolean;

  requestHeaders: {
    name:
      | "Host"
      | "User-Agent"
      | "Content-Type"
      | "Content-Length"
      | "Connection";
    buffer: { buffer: string[]; len: number };
  }[];

  responseHeaders: {
    name:
      | "Server"
      | "Date"
      | "Transfer-Encoding"
      | "Connection"
      | "Content-Type" //not sure really has these two
      | "Content-Length";
    buffer: { buffer: string[]; len: number };
  }[];
}

export interface dbCNCMapModel {
  callout: dbCNCMapCalloutModel;
  httpCallout?: dbCNCMapHttpCalloutModel;
  domains: dbCNCMapDomainModel[];
}

export const ENCRYPTION_HEADERS = [
  ["16", "3"], //"\u0016\u0003", // SSL handshake, SSLv3
];

export const isChannelEncrypted = (channel: string) => {
  if (!channel) return false;
  for (const h of ENCRYPTION_HEADERS) {
    // if the length of channel < length of header, skip to next one
    if (channel.length < h.length) continue;

    let oneDidNotMatch: boolean = false;
    for (let i = 0; i < h.length; i++) {
      if (h[i] !== toHex(channel[i])) {
        oneDidNotMatch = true;
        break;
      }
    }
    // whole sequence matched, so header is there. otherwise try next one
    if (!oneDidNotMatch) return true;
  }
  return false;
};

// converts a string to hex e.g. "blah" is "626c6168"
function toHex(str: string): string {
  var result = "";
  for (var i = 0; i < str.length; i++) {
    result += str.charCodeAt(i).toString(16);
  }
  return result;
}
