import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import useAppLayout from "../../hooks/useAppLayout";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  page: {
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "auto",
    width: "100%",
  },
  cccsPage: { marginTop: theme.spacing(3) },
  tbsPage: {
    marginTop: theme.spacing(-4),
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(-6),
    },
    [theme.breakpoints.up("sm")]: {
      width: "95%",
    },
  },
}));

export default function PageFullWidth({ children }) {
  const classes = useStyles();
  const layout = useAppLayout();

  return (
    <Box
      className={clsx(classes.page, {
        [classes.cccsPage]: !layout.layoutProps.useTBSLayout,
        [classes.tbsPage]: layout.layoutProps.useTBSLayout,
      })}
    >
      {children}
    </Box>
  );
}
