import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useStyles } from "../common/sharedStyles";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { beaverNGSelector, fetchReport } from "../../../../../slices/beaverng";
import { ReportType } from "../../../models/report/reportType";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  LinearProgress,
} from "@material-ui/core";
import { URLMD5ClusterReport } from "./views/URLMD5ClusterReport";
import { ParsedError } from "../common/ParsedError";

export type URLMD5ClusterProps = {
  cluster: string;
  setClusterType: Function;
  clusterType: string;
};

export function URLMD5Cluster({
  cluster,
  setClusterType,
  clusterType,
}: URLMD5ClusterProps) {
  const [retry, setRetry] = useState(0);
  const classes = useStyles();
  const { t } = useTranslation();

  const [subcluster, setSubcluster] = useState(null);
  const { search } = useLocation();

  const dispatch = useDispatch();

  useEffect(() => {
    let subClusterValue = new URLSearchParams(search).get("subcluster");
    setSubcluster(subClusterValue);
    dispatch(fetchReport(ReportType.URL_MD5_TAG, cluster, subClusterValue));
  }, [cluster, dispatch, search]);

  const { responseData, reportType, error } = useSelector(beaverNGSelector);

  useEffect(() => {
    if (responseData?.urlMD5ClusterDetails?.length) {
      setClusterType(
        "clustertype." + responseData.urlMD5ClusterDetails[0].submissionType
      );
    }
  }, [responseData, setClusterType]);

  if (
    !error &&
    (!responseData || !reportType || reportType !== ReportType.URL_MD5_TAG)
  ) {
    return (
      <>
        {t("Loading")} ...
        <LinearProgress />
      </>
    );
  } else if (error) {
    return (
      <Card>
        <CardHeader
          className={classes.ch}
          avatar={<Avatar className={classes.errorIcon}>!</Avatar>}
          title={t("An Error Occured!")}
        />
        <CardContent>
          <ParsedError error={error} />
        </CardContent>
        <CardActions>
          <Button size="small" onClick={() => setRetry(retry + 1)}>
            {t("Retry")}
          </Button>
        </CardActions>
      </Card>
    );
  } else {
    return (
      <URLMD5ClusterReport
        data={responseData}
        clusterType={clusterType}
        subcluster={subcluster}
      />
    );
  }
}
