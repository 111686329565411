import React from "react";

import { Link as RouterLink } from "react-router-dom";

import { Box } from "@material-ui/core";
import { useStyles } from "../../common/sharedStyles";
import { SubReportType } from "../../../../models/report/type/SubReportType";

import { useTranslation } from "react-i18next";
import { SimpleTableCardView } from "../../common/SimpleTableView";
import { LinkWrap } from "../../common/LinkWrap";

import { useDispatch, useSelector } from "react-redux";
import { fetchSources, sourceSelector } from "slices/sources";

export function PhishingTypeCard({ show, responseData }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { sources } = useSelector(sourceSelector);

  // will run on first mount
  React.useEffect(() => {
    dispatch(fetchSources(sources));
  }, [dispatch, sources]);

  if (
    SubReportType.MOOSE === show &&
    responseData.subreports["MOOSE"]?.phishingInfo?.phishingKits?.length
  )
    return (
      <Box mb={2}>
        <SimpleTableCardView
          title={t("Phishing Kits")}
          headers={[
            {
              title: t("MD5"),
              field: "artifactMd5",
              render: (r) => (
                <RouterLink
                  className={classes.breakword}
                  to={["/phishing_kit", r.artifactMd5, "report"].join("/")}
                >
                  <LinkWrap>{r.artifactMd5}</LinkWrap>
                </RouterLink>
              ),
            },
            {
              title: t("Source"),
              field: "sourceId",
              render: (r) =>
                sources
                  ? sources[r.sourceId]
                    ? sources[r.sourceId]["displayString"]
                    : r.sourceId
                  : r.sourceId,
            },
            {
              title: t("Date Received"),
              field: "receivedOn",
              width: "2%",
              render: (r) => (
                <span className={classes.breakword}>{r.receivedOn}</span>
              ),
            },
            {
              title: t("File Name"),
              field: "filename",
              render: (r) => (
                <span className={classes.breakword}>{r.filename}</span>
              ),
            },
            {
              title: t("Original Zip MD5"),
              field: "originalZipMD5",
              render: (r) => (
                <span className={classes.breakword}>{r.originalZipMD5}</span>
              ),
            },
            {
              title: t("URL"),
              field: "url",
              render: (r) => (
                <RouterLink
                  className={classes.breakword}
                  to={["/url", r.url, "report"].join("/")}
                >
                  <LinkWrap>{r.url}</LinkWrap>
                </RouterLink>
              ),
            },
            {
              title: t("Cluster Name"),
              field: "clusterName",
              render: (r) => (
                <RouterLink
                  className={classes.breakword}
                  to={["/phishing_kit", r.clusterName, "report"].join("/")}
                >
                  <LinkWrap>{r.clusterName}</LinkWrap>
                </RouterLink>
              ),
            },
            {
              title: t("Subcluster Name"),
              field: "subclusterName",
              render: (r) => (
                <RouterLink
                  className={classes.breakword}
                  to={["/phishing_kit", r.subclusterName, "report"].join("/")}
                >
                  <LinkWrap>{r.subclusterName}</LinkWrap>
                </RouterLink>
              ),
            },
            {
              title: t("Added On"),
              field: "addedOn",
              render: (r) => (
                <span className={classes.breakword}>
                  {r.addedOn ? r.addedOn : ""}
                </span>
              ),
              width: "2%",
            },
          ]}
          data={responseData.subreports["MOOSE"].phishingInfo.phishingKits.map(
            (m) => ({ ...m })
          )}
        />
      </Box>
    );
  else return null;
}
