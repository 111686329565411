import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import { cloneDeep } from "lodash";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import { LinkWrap } from "components/beaver/components/report/common/LinkWrap";

import Axios from "axios";

import { fetchSources, sourceSelector } from "slices/sources";
import { URLReportJSONFavicon } from "../../../../helpers/ServerProperties";
import { SimpleTableView } from "../../common/SimpleTableView";
import { ParsedError, parseErrorTitle } from "../../common/ParsedError";

import { useStyles } from "../../common/sharedStyles";
import { useTranslation } from "react-i18next";

import WarningOutlined from "@material-ui/icons/WarningOutlined";
import InfoIcon from "@material-ui/icons/Info";
import { FaviconView } from "./FaviconView";

const md5fn = require("md5");

export type PivotFaviconViewProps = {
  md5: string;
  iconResources?: string[];
  setFaviconLoadError: Function;
};

export function PivotFaviconView({
  md5,
  iconResources,
  setFaviconLoadError,
}: PivotFaviconViewProps) {
  const classes = useStyles();
  const [responseData, setResponseData]: [any, React.Dispatch<any>] = useState({
    loaded: false,
  });
  const [error, setError] = useState(null);
  const [retry, setRetry] = useState(0);

  //TODO - this should make the localstorage null if it can't parse- put this somewhere and export
  const user = localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser"))
    : {};
  const { t } = useTranslation();

  useEffect(() => {
    //TODO - consider moving this to redux maybe
    Axios.get(URLReportJSONFavicon(md5), {
      auth:
        user.server && !user.server.includes("localhost")
          ? {
              username: user ? user.username : null,
              password: user
                ? user.onetime
                  ? // eslint-disable-next-line no-eval
                    window.atob(eval(window.atob(user.onetime))).substr(13)
                  : null
                : null,
            }
          : null,
    }).then(
      (response) => {
        // TODO - remove this
        console.log(response);
        response.data.loaded = true;
        setResponseData(response.data);
      },
      (error) => {
        console.error(error);
        setError(error.message);
      }
    );
    // unsure why adding user here makes multiple requests
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [md5, retry]);

  // show a loading bar until it loads, and show the error if there is one
  if (responseData.loaded === false && error == null) {
    return (
      <>
        {t("Loading")} ...
        <LinearProgress />
      </>
    );
  } else if (error != null) {
    return (
      <Card>
        <CardHeader
          className={classes.ch}
          avatar={<Avatar className={classes.errorIcon}>!</Avatar>}
          title={t("Pivot Favicon") + " - " + parseErrorTitle(error)}
        />
        <CardContent>
          <ParsedError error={error} />
        </CardContent>
        <CardActions>
          <Button size="small" onClick={() => setRetry(retry + 1)}>
            {t("Retry")}
          </Button>
        </CardActions>
      </Card>
    );
  }

  // show the card with the simple data view (map the data) here
  // TODO - show the full source - do a 'map' on the urlsWithSameTitle to do that
  //   * will likely need to modify the fetch to also return the sourcemap similar to urlreport!
  return (
    <PivotFaviconCard
      iconResources={iconResources}
      urlsWithSameFaviconLarge={responseData.urlsWithSameFaviconLarge}
      urlsWithSameFavicon={responseData.urlsWithSameFavicon}
      setFaviconLoadError={setFaviconLoadError}
    />
  );
}

function PivotFaviconCard({
  iconResources,
  urlsWithSameFaviconLarge,
  urlsWithSameFavicon,
  setFaviconLoadError,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { sources } = useSelector(sourceSelector);
  const dispatch = useDispatch();
  const [favicons, setFavicons] = useState([]);

  useEffect(() => {
    dispatch(fetchSources(sources));
  }, [dispatch, sources]);

  useEffect(() => {
    let ficons = cloneDeep(urlsWithSameFavicon);
    ficons.sort((a, b) => b.receivedDate - a.receivedDate);
    setFavicons(ficons);
  }, [urlsWithSameFavicon]);

  return (
    <Card>
      <CardHeader className={classes.ch} title={t("Pivot Favicon")} />
      <CardContent>
        {!!setFaviconLoadError && favicons.length !== 0 && (
          <Box mb={2}>
            <InfoIcon style={{ verticalAlign: "middle" }} />{" "}
            {t(
              "This favicon has a loading error but results are being shown since the urls below all share the same favicon md5 in the database!"
            )}
          </Box>
        )}
        {!!favicons && favicons.length > 1000 && (
          <Box mb={2}>
            <WarningOutlined style={{ verticalAlign: "middle" }} />{" "}
            {t(
              "This favicon is used by more than 1000 urls, here are last 1000 results"
            )}
          </Box>
        )}
        {!!iconResources && iconResources.length > 1 ? (
          <Box my={2}>
            <WarningOutlined style={{ verticalAlign: "middle" }} />{" "}
            {[
              t("Note that only the first Favicon of"),
              iconResources.length,
              t("is being shown"),
            ].join(" ")}
          </Box>
        ) : null}
        {iconResources && iconResources.length ? (
          <TableContainer>
            <Table size="small" style={{ maxWidth: "300px" }}>
              <TableBody>
                <TableRow>
                  <TableCell>{t("Favicon")}</TableCell>
                  <TableCell>
                    <FaviconView
                      faviconLinks={iconResources}
                      setFaviconLoadError={setFaviconLoadError}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        ) : null}
        <SimpleTableView
          // from beaver#914 "BeaverTour" - "we should limit results to 1000"
          data={favicons.slice(0, 1000)}
          headers={[
            {
              title: "URL",
              field: "url",
              render: (r) => (
                <Link to={["/url", md5fn(r.url), "report"].join("/")}>
                  <LinkWrap>
                    <span className={classes.breakword}>{r.url}</span>
                  </LinkWrap>
                </Link>
              ),
            },
            {
              title: t("Source"),
              field: "source",
              render: (r) =>
                sources && sources[r.source]
                  ? sources[r.source]["displayString"]
                  : r.source,
            },
            { title: t("Date Received"), field: "receivedDate" },
          ]}
        />
      </CardContent>
    </Card>
  );
}
