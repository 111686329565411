import React from "react";
import { Link } from "react-router-dom";

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import { LinkWrap } from "components/beaver/components/report/common/LinkWrap";
import { SimpleTableCardView } from "../../common/SimpleTableView";
import { useStyles } from "../../common/sharedStyles";
import { getDomainFromURL } from "../../../../helpers/DomainUtil";

import { PhishingClusterData } from "../../../../models/report/phishing/phishingClusterModel";
import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import { fetchSources, sourceSelector } from "slices/sources";

const md5 = require("md5");

export type PhishingClusterReportProps = {
  data: PhishingClusterData;
  testing?: boolean;
};

export function PhishingClusterReport({
  data,
  testing,
}: PhishingClusterReportProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [uniqueList, setUniqueList] = React.useState([]);

  const dispatch = useDispatch();
  const { sources } = useSelector(sourceSelector);

  React.useEffect(() => {
    dispatch(fetchSources(sources));
  }, [dispatch, sources]);

  React.useEffect(() => {
    // now populate with neccessary info e.g. domain, url, filename, added_on with sets for it
    let unique = {};
    data.clusterSearch.forEach((c) => {
      // we are adding a md5 key and combining values (except md5), later we make an array of it
      if (!unique[c.artifact_md5]) {
        unique[c.artifact_md5] = {
          artifact_md5: c.artifact_md5,
          url: new Set().add(c.url ? c.url : ""),
          filename: new Set().add(c.filename),
          added_on: new Set().add(c.added_on),
          domain: new Set().add(getDomainFromURL(c.url)),
          source_id: new Set().add(c.source_id),
        };
      } else {
        unique[c.artifact_md5]["url"].add(c.url ? c.url : "");
        unique[c.artifact_md5]["filename"].add(c.filename);
        unique[c.artifact_md5]["added_on"].add(c.added_on);
        unique[c.artifact_md5]["domain"].add(getDomainFromURL(c.url));
        unique[c.artifact_md5]["source_id"].add(c.source_id);
      }
    });
    // sort the sets for display in order
    Object.values(unique).forEach((c) => {
      // the added on won't ever be 0 or nan, but just in case
      c["added_on"] = Array.from(c["added_on"])
        .filter((v) => !isNaN(+v) || +v === 0)
        .sort()[0];
      c["domain"] = Array.from(c["domain"]).sort();
      c["url"] = Array.from(c["url"]).sort();
      c["filename"] = Array.from(c["filename"]).sort();
      c["source_id"] = Array.from(c["source_id"]).sort();
    });

    setUniqueList(Object.values(unique));
  }, [data.clusterSearch]);

  return (
    <Box>
      <Grid container>
        <Grid item xs={11} lg={7}>
          <Card>
            <CardHeader
              title={t("Phishing Kit Cluster Details")}
              className={classes.ch}
            />
            <CardContent>
              <TableContainer>
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell>{t("Cluster / Subcluster")}</TableCell>
                      <TableCell>{data.cluster}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t("Artifacts")}</TableCell>
                      <TableCell>{uniqueList.length}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Box mt={2}>
        <SimpleTableCardView
          testing={testing}
          title={t("Phishing Kits in Cluster or Subcluster")}
          data={uniqueList.map((l) => ({
            artifact_md5: l.artifact_md5,
            // the list (set) is joined with commas for display in the CSV / PDF material-table output files
            domainCSV: l.domain.join(", "),
            domain: l.domain,
            urlCSV: l.url.join(", "),
            url: l.url,
            filenameCSV: l.filename.join(", "),
            filename: l.filename,
            added_on: l.added_on,
            source_id: l.source_id
              .map((ll) =>
                !ll
                  ? ""
                  : sources && sources[ll]
                  ? sources[ll].displayString
                  : ll
              )
              .join(", "),
          }))}
          headers={[
            {
              title: t("Phishing Kit MD5"),
              field: "artifact_md5",
              render: (rowData) => (
                <Link
                  className={classes.breakword}
                  to={["/phishing_kit", rowData.artifact_md5, "report"].join(
                    "/"
                  )}
                >
                  <LinkWrap>{rowData.artifact_md5}</LinkWrap>
                </Link>
              ),
            },
            {
              title: t("Domain"),
              field: "domainCSV",
              render: (rowData) =>
                Array.from(rowData.domain).map((domain, i) => (
                  <React.Fragment key={i}>
                    <Link
                      className={classes.breakword}
                      to={["/domain", domain, "report"].join("/")}
                    >
                      <LinkWrap>{domain}</LinkWrap>
                    </Link>
                    <br />
                  </React.Fragment>
                )),
            },
            {
              title: t("Origin URL"),
              field: "urlCSV",
              render: (rowData) =>
                Array.from(rowData.url).map((url, i) => (
                  <React.Fragment key={i}>
                    <Link
                      className={classes.breakword}
                      to={["/url", md5(url), "report"].join("/")}
                    >
                      <LinkWrap>{url}</LinkWrap>
                    </Link>{" "}
                    <br />
                  </React.Fragment>
                )),
            },
            {
              title: t("File Name"),
              field: "filenameCSV",
              render: (r) =>
                Array.from(r.filename).map((filename, i) => (
                  <React.Fragment key={i}>
                    <Box className={classes.breakword}>{filename}</Box>
                  </React.Fragment>
                )),
            },
            {
              title: t("Source"),
              field: "source_id",
            },
            { title: t("Added On"), field: "added_on" },
          ]}
        />
      </Box>
    </Box>
  );
}
