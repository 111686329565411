import React from "react";

import { Box, Button, Grid, Paper } from "@material-ui/core";

import { Helmet } from "react-helmet";

import { useTranslation } from "react-i18next";
import PageFullWidth from "../../../../commons/components/layout/pages/PageFullWidth";
import PageHeader from "../../../../commons/components/layout/pages/PageHeader";
import { isExternal, isInternal } from "../../helpers/ServerProperties";

import { AssemblyLineBanner } from "./AssemblyLineBanner";
import { SampleUpload } from "./SampleUpload";
import { URLUpload } from "./URLUpload";

export function UploadPage() {
  const { t } = useTranslation();
  const [accepted, setAccepted] = React.useState(false);

  const title = t("Upload");

  React.useEffect(() => {
    // don't want to nag for upload, so give them 14 days
    if (
      localStorage.disclaimerAccepted &&
      !isNaN(localStorage.disclaimerAccepted)
    ) {
      if (
        Date.now() - localStorage.disclaimerAccepted >
        1000 * 60 * 60 * 24 * 14
      ) {
        localStorage.disclaimerAccepted = null;
        setAccepted(false);
      } else {
        setAccepted(true);
      }
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{["BEAVER", title].join(" ")}</title>
        <meta
          name="description"
          content={["BEAVER", title, "-", t("page.home.description")].join(" ")}
        />
      </Helmet>
      <PageHeader title={title} />
      <PageFullWidth>
        {/* show assembly line banner if is internal or external but don't show disclaimer */}
        {isExternal() || accepted ? (
          isExternal() ? (
            <AssemblyLineBanner />
          ) : (
            <Grid container spacing={3}>
              <Grid item sm={6} xs={12}>
                {isInternal() ? <AssemblyLineBanner /> : <SampleUpload />}
              </Grid>
              <Grid item sm={6} xs={12}>
                <URLUpload />
              </Grid>
            </Grid>
          )
        ) : (
          <ShowDisclaimer setAccepted={setAccepted} />
        )}
      </PageFullWidth>
    </>
  );
}

function ShowDisclaimer({ setAccepted }) {
  const { t } = useTranslation();
  return (
    <>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12} sm={11} md={9} lg={7} xl={6}>
          <Paper>
            <Box p={3}>
              <h2>{t("Disclaimer")} </h2>
              <p>{t("disclaimer-text")}</p>
              <Box mt={3} textAlign="center">
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setAccepted(true);
                    localStorage.disclaimerAccepted = Date.now();
                  }}
                >
                  {t("Agree")}
                </Button>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
