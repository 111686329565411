export enum SubReportType {
  SUMMARY = "SUMMARY",
  BISON = "BISON",
  BEAVER = "BEAVER",
  DFS = "DFS",
  EFS = "EFS",
  MAXMIND = "MAXMIND",
  MOOSE = "MOOSE",
  RACCOON = "RACCOON",
}
