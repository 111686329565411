import React from "react";
import { Helmet } from "react-helmet";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import PageHeader from "../../commons/components/layout/pages/PageHeader";
import { useParams } from "react-router-dom";
import PageFullWidth from "../../commons/components/layout/pages/PageFullWidth";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  makeStyles,
} from "@material-ui/core";
import { MD5Report } from "../beaver/components/report/md5/MD5Report";
import { URLReport } from "../beaver/components/report/url/URLReport";
import { DomainReport } from "../beaver/components/report/domain/DomainReport";
import { IpReport } from "../beaver/components/report/ip/IpReport";
import { MISPLoganReport } from "../beaver/components/report/mispLogan/MISPLoganReport";
import { SIDReport } from "../beaver/components/report/sid/SIDReport";
import { EmailReport } from "../beaver/components/report/email/EmailReport";
import { PhishingReport } from "../beaver/components/report/phishing/PhishingReport";
import { TagReport } from "../beaver/components/report/tag/TagReport";
import { URLMD5Cluster } from "../beaver/components/report/url/URLMD5Cluster";
import { isSimplified } from "components/beaver/helpers/ServerProperties";

const useStyles = makeStyles((theme) => ({
  ch: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.contrastText,
  },
  errorIcon: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    fontWeight: "bold",
  },
}));

enum ValidReportTypes {
  MD5Report = "md5",
  DomainReport = "domain",
  IPReport = "ip",
  URLReport = "url",
  SIDReport = "sid",
  EmailAddressReport = "email_address",
  PhishingKitReport = "phishing_kit",
  SHA1Report = "sha1",
  SHA256Report = "sha256",
  TagReport = "tag",
  URLMD5Report = "cluster",
  MISPLoganReport = "logan",
}

export function GetReport({ type, value, setClusterType, clusterType }) {
  switch (type.toLowerCase()) {
    case ValidReportTypes.MD5Report:
      return <MD5Report value={value} />;
    case ValidReportTypes.SHA1Report:
      // the sha1 and sha256 endpoints are different, but they just return md5 reports
      // the boolean value just tells the report to show the sha1 / sha256 value in the sample summary
      return <MD5Report value={value} sha1={true} />;
    case ValidReportTypes.SHA256Report:
      return <MD5Report value={value} sha256={true} />;
    case ValidReportTypes.URLReport:
      return <URLReport md5={value} />;
    case ValidReportTypes.DomainReport:
      return <DomainReport domain={value} />;
    case ValidReportTypes.IPReport:
      return <IpReport ip={value} />;
    case ValidReportTypes.MISPLoganReport:
      return <MISPLoganReport eventid={value} />;
    case !isSimplified() && ValidReportTypes.SIDReport:
      return <SIDReport sid={value} />;
    case !isSimplified() && ValidReportTypes.EmailAddressReport:
      return <EmailReport email_address={value} />;
    case !isSimplified() && ValidReportTypes.PhishingKitReport:
      return <PhishingReport param={value} />;
    case !isSimplified() && ValidReportTypes.TagReport:
      return <TagReport param={value} />;
    case !isSimplified() && ValidReportTypes.URLMD5Report:
      return (
        <URLMD5Cluster
          cluster={value}
          setClusterType={setClusterType}
          clusterType={clusterType}
        />
      );
    default:
      //TODO - return some angry beaver picture or something
      return <TBD type={type} />;
  }
}

export function ReportView() {
  // this is using the params of router i.e. from path="/:type/:value/report"
  let { type, value } = useParams<{ type: string; value: string }>();
  const [clusterType, setClusterType] = React.useState(null);

  const { t, i18n } = useTranslation();

  const title =
    ValidReportTypes.MD5Report === type
      ? t("Sample Report")
      : type === ValidReportTypes.URLMD5Report
      ? (i18n.language.includes("fr") ? t("Report") + " " : "") +
        value.replaceAll("+", " ") +
        (clusterType ? " " + t(clusterType) : "") +
        (i18n.language.includes("en") ? " " + t("Report") : "")
      : type === ValidReportTypes.TagReport
      ? i18n.language.includes("fr")
        ? t("Report") + " '" + value.replaceAll("+", " ") + "'"
        : "'" + value.replaceAll("+", " ") + "' " + t("Report")
      : !Object.values(ValidReportTypes)
          .map((t) => t.toString())
          .includes(type.toLowerCase())
      ? t("page.reportview.invalid") +
        " '" +
        _.startCase(type).toUpperCase() +
        "'"
      : t(_.startCase(type) + " Report");

  return (
    <>
      <Helmet>
        <title>
          BEAVER {_.toUpper(type)} {value}
        </title>
        <meta
          name="description"
          content={[
            "BEAVER",
            _.toUpper(type),
            value,
            "-",
            t("page.home.description"),
          ].join(" ")}
        />
      </Helmet>
      <PageHeader title={title} />
      <PageFullWidth>
        <GetReport
          type={type}
          value={value}
          setClusterType={setClusterType}
          clusterType={clusterType}
        />
      </PageFullWidth>
    </>
  );
}

export type TBDType = { type: string };

export function TBD({ type }: TBDType) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Card>
      <CardHeader
        className={classes.ch}
        avatar={<Avatar className={classes.errorIcon}>:(</Avatar>}
        title={
          t("The") +
          " '" +
          _.startCase(type) +
          "' " +
          t("View Does Not Exist or is Not Done Yet")
        }
      />
      <CardContent>
        <p>
          {t("Sorry, the report for")} '{type.toUpperCase()}'{" "}
          {t("does not exist or is not complete (yet)")}.
        </p>
        <p>
          {t(
            "If you believe this is an error or need this view more urgently, please email the BeAVER / INO team!"
          )}
        </p>
      </CardContent>
    </Card>
  );
}
