export function getUserForHTTPCall() {
  const user = localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser"))
    : {};

  return user.server && !user.server.includes("localhost")
    ? {
        username: user ? user.username : null,
        password: user
          ? user.onetime
            ? // eslint-disable-next-line no-eval
              window.atob(eval(window.atob(user.onetime))).substr(13)
            : null
          : null,
      }
    : null;
}
