import React from "react";
import { cloneDeep } from "lodash";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import { SimpleTableCardView } from "../../common/SimpleTableView";

import { virusTotalModel } from "../../../../models/report/md5/virusTotalModel";

import { useStyles } from "../../common/sharedStyles";
import { useTranslation } from "react-i18next";

import moment from "moment";

export type VirusTotalViewProps = {
  vtReports: virusTotalModel[];
  testing?: boolean;
};

export function VirusTotalView({ vtReports, testing }: VirusTotalViewProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  // state for pagination (seems to be newest first)
  const [reportToShow, setReportToShow] = React.useState(0);
  const [vtReport, setVtReport] = React.useState(
    vtReports[vtReports.length - 1]
  );
  // controls report to be shown based on pagination
  React.useEffect(
    () => setVtReport(vtReports[reportToShow]),
    [vtReports, reportToShow]
  );

  return (
    <Box>
      <Card>
        <CardHeader className={classes.ch} title={t("Virus Total Report")} />
        <CardContent>
          {vtReports.length > 1 && (
            <Box style={{ float: "right" }}>
              {vtReports.map((a, i) => (
                <Button
                  key={i}
                  variant={i === reportToShow ? "contained" : "text"}
                  color="primary"
                  onClick={() => setReportToShow(i)}
                >
                  {moment(
                    a["scanDate"] ? a["scanDate"] : "" + a["creationDate"]
                  ).format("YYYYMMDD")}
                </Button>
              ))}
            </Box>
          )}
          <TableContainer>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell>{t("Analysis Date")}</TableCell>
                  <TableCell>{vtReport.creationDate}</TableCell>
                </TableRow>
                {!!vtReport.scanDate && (
                  <TableRow>
                    <TableCell>{t("Scan Date")}</TableCell>
                    <TableCell>{vtReport.scanDate}</TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell>MD5</TableCell>
                  <TableCell>{vtReport.md5}</TableCell>
                </TableRow>
                {!!vtReport.total && (
                  <TableRow>
                    <TableCell>{t("Detected")}</TableCell>
                    <TableCell>
                      {vtReport.positives} / {vtReport.total}
                    </TableCell>
                  </TableRow>
                )}
                {!!vtReport.permalink && (
                  <TableRow>
                    <TableCell>{t("Link")}</TableCell>
                    <TableCell>
                      <Link
                        href={vtReport.permalink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {vtReport.permalink}
                      </Link>
                    </TableCell>
                  </TableRow>
                )}
                {!!vtReport.messages && !!vtReport.messages.length && (
                  <TableRow>
                    <TableCell>{t("Message")}</TableCell>
                    <TableCell>
                      {vtReport.messages[0].type +
                        " " +
                        vtReport.messages[0].message}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>

      <Box mt={2}>
        {/* legacy beaver shows an error when response code is 0 (code from 2013) */}
        {vtReport.response_code ? (
          <SimpleTableCardView
            testing={testing}
            title={t("Anti-Virus")}
            data={cloneDeep(Object.values(vtReport.results))}
            headers={[
              { title: "AV", field: "scannerName" },
              { title: t("Result"), field: "result" },
              { title: t("Update"), field: "updateDate" },
              { title: t("Scanner Version"), field: "scannerVersion" },
            ]}
          />
        ) : (
          <Card>
            <CardHeader className={classes.ch} title={t("Anti-Virus")} />
            <CardContent>
              <TableContainer>
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell>{t("Result")}</TableCell>
                      <TableCell>
                        {t(
                          "The requested resource is not among the finished, queued or pending scans"
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        )}
      </Box>
    </Box>
  );
}
