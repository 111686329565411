// from https://stackoverflow.com/questions/44656610/download-a-string-as-txt-file-in-react
export const downloadStringAsFile = (
    data: string,
    filename: string,
    type?: string
  ) => {
    const element = document.createElement("a");
    const file = new Blob([data], { type: type ? type : "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = filename;
    document.body.appendChild(element); // for Firefox
    element.click();
  };