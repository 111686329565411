import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { beaverNGSelector, fetchReport } from "../../../../../slices/beaverng";

import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  LinearProgress,
} from "@material-ui/core";

import { ReportType } from "../../../models/report/reportType";

import { useStyles } from "../common/sharedStyles";
import { PhishingClusterReport } from "./views/PhishingClusterReport";
import { PhishingItemReport } from "./views/PhishingItemReport";
import { useTranslation } from "react-i18next";
import { ParsedError } from "../common/ParsedError";

export type PhishingReportProps = {
  param: string;
};

export function PhishingReport({ param }: PhishingReportProps) {
  const [retry, setRetry] = React.useState(0);
  const classes = useStyles();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { responseData, reportType, error } = useSelector(beaverNGSelector);

  // will run on first mount
  useEffect(() => {
    dispatch(fetchReport(ReportType.PHISHING, param));
  }, [dispatch, param, retry]);

  // start by just showing the summary once loaded
  if (
    !error &&
    (!responseData || !reportType || reportType !== ReportType.PHISHING)
  ) {
    // show a loading bar until it loads, and show the error if there is one
    // TODO - translate the loading text
    return (
      <>
        {t("Loading")} ...
        <LinearProgress />
      </>
    );
  } else if (error) {
    return (
      <Card>
        <CardHeader
          className={classes.ch}
          avatar={<Avatar className={classes.errorIcon}>!</Avatar>}
          title={t("An Error Occured!")}
        />
        <CardContent>
          <ParsedError error={error} />
        </CardContent>
        <CardActions>
          <Button size="small" onClick={() => setRetry(retry + 1)}>
            {t("Retry")}
          </Button>
        </CardActions>
      </Card>
    );
  } else {
    // need to determine if it is a tag or report based on contents of response
    // just need to see if the responseData has "cluster" or not
    if (responseData["cluster"])
      return <PhishingClusterReport data={responseData} />;
    else {
      return <PhishingItemReport data={responseData} />;
    }
  }
}
