/* eslint-disable no-useless-escape */

/**
 * This was taken from Beaver.
 */

/**
 *Checks each line of the search box against the regex list,
 * Changes the error text, and returns false on error
 * returns true on success
 */
var IndicatorValidate = function (isSearch, simplified, borealis) {
  var errorText = "";
  var regexList = borealis ? {
    ip: /^((?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(?:(?:\.)|(?:\[\.\]))){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))$/,
    domain:
      /^((?!-|\.)(([a-zA-Z0-9\*\.\-_])|(\[\.\]))*)((\.)|(\[\.\]))((xn\-\-.{4,20})|([a-zA-Z\*]{1,10}))$/,
    url: /^((?:(?:(?:h[txX]|f)[txX]ps?(?:\:\/\/)?)(?:[\w\d]+(?:\:[\w\d]+)?\@)?((?:(?:[^.-](?:(?:[a-zA-Z0-9\.\-_])|(?:\[\.\]))*)(?:(?:\.)|(?:\[\.\]))(?:(?:xn\-\-.{4,20})|(?:[a-zA-Z]{1,10})))|(?:(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(?:(?:\.)|(?:\[\.\]))){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)))(?:\:[\d]{1,5})?(\/[\_\<\>\,\!\|\"\'\:\~\/\w\.\-\?&#=;\[\]%\\\@\)\(\\*+]*)?))$/,
  } : simplified ? {
    ip: /^((?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(?:(?:\.)|(?:\[\.\]))){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))$/,
    md5: /^[a-fA-Z0-9]{32}$/,
    sha1: /(^[a-fA-F0-9]{40}$)/,
    sha256: /(^[a-fA-F0-9]{64}$)/,
    domain:
      /^((?!-|\.)(([a-zA-Z0-9\*\.\-_])|(\[\.\]))*)((\.)|(\[\.\]))((xn\-\-.{4,20})|([a-zA-Z\*]{1,10}))$/,
    url: /^((?:(?:(?:h[txX]|f)[txX]ps?(?:\:\/\/)?)(?:[\w\d]+(?:\:[\w\d]+)?\@)?((?:(?:[^.-](?:(?:[a-zA-Z0-9\.\-_])|(?:\[\.\]))*)(?:(?:\.)|(?:\[\.\]))(?:(?:xn\-\-.{4,20})|(?:[a-zA-Z]{1,10})))|(?:(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(?:(?:\.)|(?:\[\.\]))){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)))(?:\:[\d]{1,5})?(\/[\_\<\>\,\!\|\"\'\:\~\/\w\.\-\?&#=;\[\]%\\\@\)\(\\*+]*)?))$/,
  } : {
    ip: /^((?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(?:(?:\.)|(?:\[\.\]))){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))$/,
    md5: /^[a-fA-Z0-9]{32}$/,
    sha1: /(^[a-fA-F0-9]{40}$)/,
    sha256: /(^[a-fA-F0-9]{64}$)/,
    domain:
      /^((?!-|\.)(([a-zA-Z0-9\*\.\-_])|(\[\.\]))*)((\.)|(\[\.\]))((xn\-\-.{4,20})|([a-zA-Z\*]{1,10}))$/,
    email_address:
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
    url: /^((?:(?:(?:h[txX]|f)[txX]ps?(?:\:\/\/)?)(?:[\w\d]+(?:\:[\w\d]+)?\@)?((?:(?:[^.-](?:(?:[a-zA-Z0-9\.\-_])|(?:\[\.\]))*)(?:(?:\.)|(?:\[\.\]))(?:(?:xn\-\-.{4,20})|(?:[a-zA-Z]{1,10})))|(?:(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(?:(?:\.)|(?:\[\.\]))){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)))(?:\:[\d]{1,5})?(\/[\_\<\>\,\!\|\"\'\:\~\/\w\.\-\?&#=;\[\]%\\\@\)\(\\*+]*)?))$/,
    phishing_kit: /^kit\[[a-zA-Z0-9_-]+\]$/,
    idsrule: /^[0-9]{7,8}$/,
  };
  if (isSearch && !simplified) {
    //regexList.asn= /([Aa][sS][0-9]+$)/;
    //AttackDetection/Beaver#370
    //regexList.yara=/^yid\:[0-9]+$/;
    regexList.tag = /^tag\[(.*)\]$/;
    regexList.cluster = /^cluster\[(.*)\]$/;
  } else {
    regexList.urlpath =
      /^(?:((?:\/)[\_\<\>\,\!\|\"\'\:\~\/\w\.\-\?\-&#=;\[\]%\\\@\)\(\*\+]*)?)$/;
  }

  var validTerms = null;

  this.getList = function () {
    return regexList;
  };

  this.setErrorText = function (text) {
    errorText = text;
  };
  this.getErrorText = function () {
    return errorText;
  };

  this.setValidTerms = function (terms) {
    validTerms = terms;
  };

  this.getValidTerms = function () {
    return validTerms;
  };

  /**
   * Literally the ugliest method of all time.
   */
  IndicatorValidate.prototype.validate = function (textAreaText) {
    var regexList = this.getList();
    if (textAreaText == null) {
      this.setErrorText("Empty Input.");
      return false;
    }
    var text = textAreaText.split("\n");
    var output = [];
    var withWildcard = 0;
    for (var i = 0; i < text.length; i++) {
      var flag = true;
      if (textAreaText) {
        var value = text[i].trim();
        if (value.indexOf("*") !== -1) {
          withWildcard++;
        }
        for (var exp in regexList) {
          if (value === "") {
            flag = false;
          } else if (regexList[exp].test(value)) {
            if (exp === "url") {
              var match = regexList[exp].exec(value);
              output[
                match[1]
                  .replace(/h[tTxX][tTxX]p/g, "http")
                  .replace(/\[\.\]/g, ".")
              ] = "url";

              /*
							var dTemp = match[2].replace(/\[\.\]/g, ".");
							if(regexList['ip'].test(dTemp))
								output[dTemp] = 'ip';
							else
								output[dTemp] = 'domain';
								*/

              //TODO Add urlpath later
              //if(match[3] != null && match[3].localeCompare("/") != 0)
              //	output[match[3].replace(/\[\.\]/g, ".")] = 'urlpath';
              flag = false;
              break;
            } else if (exp === "ip" || exp === "domain") {
              output[value.replace(/\[\.\]/g, ".")] = exp;
              flag = false;
            } else if (exp === "email_address") {
              var dTemp = value.replace(/\[\.\]/g, ".");
              dTemp = dTemp.replace(/\[AT\]/g, "@");
              // Wait, make another test to make sure the domain is good
              var parts = dTemp.split("@");
              if (regexList["domain"].test(parts[1])) {
                output[dTemp] = exp;
                flag = false;
              }
            } else {
              if (exp === "urlpath" && flag === false) {
                break;
              } else if (exp === "urlpath") {
                output[text[i].trim().replace(/\[\.\]/g, ".")] = "urlpath";
              } else {
                if (exp === "md5" || exp === "sha1" || exp === "sha256")
                  output[text[i].trim().toLowerCase()] = exp;
                else output[text[i].trim()] = exp;
              }
              flag = false;
            }
          }
        }
      }

      if (flag || withWildcard > 1) {
        if (flag) {
          this.setErrorText("'" + text[i] + "' malformed on line " + (i + 1));
        } else if (withWildcard > 1) {
          this.setErrorText(
            "You can only search 1 indicator with wildcard(s) per search"
          );
        }
        this.setValidTerms(null);
        return false;
      }
    }
    this.setValidTerms(output);
    return true;
  };

  IndicatorValidate.prototype.getError = function () {
    errorText = this.getErrorText();
    if (errorText !== "") {
      return errorText;
    } else return false;
  };

  IndicatorValidate.prototype.getTermsAfterValidation = function () {
    if (this.getValidTerms() != null) {
      return this.getValidTerms();
    } else {
      console.error("No valid terms after non-valid validation call");
    }
  };
};

export default IndicatorValidate;
