import React from "react";
import { Box, Card, CardContent, CardHeader } from "@material-ui/core";

import { useStyles } from "../../common/sharedStyles";
import { useTranslation } from "react-i18next";

export type DomViewProps = {
  dom: string;
  title: string;
};

export function DOMView({ dom, title }: DomViewProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Card>
      <CardHeader className={classes.ch} title={t(title)} />
      <CardContent>
        { dom ? (
          <Box mt={3}>
             <pre className={classes.domCode}>{dom}</pre>
          </Box>
        ) : t("No Data to Display")}
      </CardContent>
    </Card>
  );
}
