import React from "react";
import { cloneDeep } from "lodash";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import { SimpleTableCardView } from "../../common/SimpleTableView";

import { shadowServerModel } from "../../../../models/report/md5/shadowServerModel";

import { useStyles } from "../../common/sharedStyles";

import { useTranslation } from "react-i18next";

export type ShadowServerViewProps = {
  reportType: string;
  shadowServerReport: shadowServerModel;
  testing?: boolean;
};

export function ShadowServerView({
  reportType,
  shadowServerReport,
  testing,
}: ShadowServerViewProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box>
      <Card>
        <CardHeader className={classes.ch} title={t("Shadow Server Report")} />
        <CardContent>
          <TableContainer>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell>{t("Type")}</TableCell>
                  <TableCell>{reportType}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("Analysis Date")}</TableCell>
                  <TableCell>{shadowServerReport.creationDate}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>MD5</TableCell>
                  <TableCell>{shadowServerReport.md5}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>SHA1</TableCell>
                  <TableCell>{shadowServerReport.sha1}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("First Seen")}</TableCell>
                  <TableCell>{shadowServerReport.firstSeen}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("Last Seen")}</TableCell>
                  <TableCell>{shadowServerReport.lastSeen}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("Extension")}</TableCell>
                  <TableCell>{shadowServerReport.type}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("Ssdeep")}</TableCell>
                  <TableCell>{shadowServerReport.ssdeep}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>

      <Box mt={2}>
        {/* TODO - figure out the update and scanner version values*/}
        <SimpleTableCardView
          testing={testing}
          title={t("Anti-Virus")}
          data={cloneDeep(Object.values(shadowServerReport.results))}
          headers={[
            { title: "AV", field: "scannerName" },
            { title: t("Result"), field: "result" },
            { title: t("Update"), field: "" },
            { title: t("Scanner Version"), field: "" },
          ]}
        />
      </Box>
    </Box>
  );
}
