import React from "react";
import _ from "lodash";
import Axios from "axios";

import { useDispatch, useSelector } from "react-redux";
import { fetchSources, sourceSelector } from "slices/sources";

import {
  Box,
  LinearProgress,
  Modal,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  TableContainer,
  Typography,
} from "@material-ui/core";

import { PassiveDNSIPDomainJSON } from "components/beaver/helpers/ServerProperties";

import { useStyles } from "../sharedStyles";
import { useTranslation } from "react-i18next";
import { Close } from "@material-ui/icons";

import { renderField } from "./TypeDetail";

export function TypeDetailModal({
  showSubView,
  showLine,
  setShowLine,
}: {
  showSubView: string;
  showLine: object;
  setShowLine: Function;
}) {
  const { t } = useTranslation();

  const [err, setErr] = React.useState(null);
  const [domainIP, setDomainIP] = React.useState(null);
  const [searching, setSearching] = React.useState(false);

  const user = localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser"))
    : {};

  React.useEffect(() => {
    if (showLine && "PassiveDNS" === showSubView) {
      console.log(showLine);
      setSearching(true);
      // do rest call with showLine.ip and showLine.domain
      // do axios search
      Axios.get(PassiveDNSIPDomainJSON(showLine["ip"], showLine["domain"]), {
        auth:
          user.server && !user.server.includes("localhost")
            ? {
                username: user ? user.username : null,
                password: user
                  ? user.onetime
                    ? // eslint-disable-next-line no-eval
                      window.atob(eval(window.atob(user.onetime))).substr(13)
                    : null
                  : null,
              }
            : null,
      }).then(
        (response) => {
          // TODO - remove this
          console.log(response);
          setDomainIP(response.data);
          setSearching(false);
        },
        (error) => {
          // if it's a 401, logout because bad creds, the user can then log back in
          // a 404 shouldn't happen, this is when the user logs out elsewhere setting the
          // prefix's all wrong causing the 404, so logout here too.
          if (
            error.response &&
            (error.response.status === 401 || error.response.status === 404)
          ) {
            setTimeout(() => {
              setErr(t("You may need to logout and login again"));
            }, 1200);
            setTimeout(() => {
              if (localStorage.ssoPrefix) localStorage.removeItem("ssoPrefix");
              if (localStorage.currentUser)
                localStorage.removeItem("currentUser");
              window.location.reload();
            }, 3000);
          } else {
            // otherwise show the message
            console.error(error);
            setErr(error.message);
            setSearching(false);
          }
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSubView, showLine]);

  return (
    <Modal
      open={!!showLine}
      onClose={() => {
        setShowLine(null);
        setSearching(false);
        setDomainIP(null);
      }}
    >
      <Paper
        style={{
          overflow: "auto",
          maxHeight: "80vh",
          width: "80vw",
          display: "block",
          margin: "auto",
          marginTop: "7vh",
        }}
      >
        <TypeDetailModalContent
          showSubView={showSubView}
          showLine={showLine}
          setShowLine={setShowLine}
          err={err}
          searching={searching}
          domainIP={domainIP}
        />
      </Paper>
    </Modal>
  );
}

export function TypeDetailModalContent({
  showSubView,
  showLine,
  setShowLine,
  err,
  searching,
  domainIP,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { sources } = useSelector(sourceSelector);

  // will run on first mount
  React.useEffect(() => {
    dispatch(fetchSources(sources));
  }, [dispatch, sources]);

  return (
    <Box m={2}>
      <Box
        style={{
          float: "right",
          cursor: "pointer",
        }}
        onClick={() => setShowLine(null)}
      >
        <Close />
      </Box>
      <Box mb={2}>
        <Typography variant="h5">
          {"PassiveDNS" === showSubView
            ? t("Submission Details")
            : t("Row Details")}
        </Typography>
      </Box>

      <TableContainer>
        <Table size="small">
          <TableBody>
            {
              // if passivedns, filter the showLine's sourceId, sourceName, addedOn
              showLine &&
                ("PassiveDNS" === showSubView
                  ? Object.keys(showLine).filter(
                      (s) => !["sourceName", "sourceId", "addedOn"].includes(s)
                    )
                  : Object.keys(showLine)
                ).map((h, i) =>
                  "object" !== typeof showLine[h] &&
                  "__id" !== h &&
                  !!showLine[h] ? (
                    <TableRow key={i}>
                      <TableCell
                        variant="head"
                        style={{ verticalAlign: "top" }}
                      >
                        {t(_.startCase(h)).replace(/M(d|D) 5/, "MD5")}
                      </TableCell>
                      <TableCell>
                        {renderField(h, showLine, classes, t, null, null)}
                      </TableCell>
                    </TableRow>
                  ) : null
                )
            }
          </TableBody>
        </Table>
      </TableContainer>
      {/* also add the ip domain endpoint results if passivedns */}
      {!err && !searching && domainIP && (
        <Box m={4}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t("Submission Date")}</TableCell>
                  <TableCell>{t("Source ID")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {domainIP.map((di, i) => (
                  <TableRow key={i}>
                    <TableCell>{di["addedOn"]}</TableCell>
                    <TableCell>
                      {sources
                        ? sources[di["sourceID"]]
                          ? sources[di["sourceID"]]["displayString"]
                          : di["sourceID"]
                        : di["sourceID"]}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
      {!err && !!searching && (
        <Box m={4}>
          {t("Loading")} ...
          <LinearProgress />
        </Box>
      )}
      {!!err && (
        <Box m={4}>
          <p style={{ color: "darkred", fontWeight: "bold" }}>{err}</p>
        </Box>
      )}
    </Box>
  );
}
