import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";

import {
  hashModel,
  PEHashSectionModel,
} from "../../../../models/report/md5/hashModel";

import { useStyles } from "../../common/sharedStyles";

import { useTranslation } from "react-i18next";

import { ClipboardValue } from "components/beaver/components/report/common/ClipboardButton";

export type HashVeiwProps = { hashReport: hashModel };

export function HashView({ hashReport }: HashVeiwProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box>
      <Card>
        <CardHeader className={classes.ch} title={t("Hash Report")} />
        <CardContent>
          <TableContainer>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell>{t("Analysis Date")}</TableCell>
                  <TableCell>{hashReport.creationDate}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("Report Version")}</TableCell>
                  <TableCell>{hashReport.version}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>MD5</TableCell>
                  <TableCell>
                    {hashReport.md5} <ClipboardValue value={hashReport.md5} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>SHA1</TableCell>
                  <TableCell>
                    {hashReport.sha1}
                    <ClipboardValue value={hashReport.sha1} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>SHA256</TableCell>
                  <TableCell>
                    {hashReport.sha256}
                    <ClipboardValue value={hashReport.sha256} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("SSDEEP Block Size")}</TableCell>
                  <TableCell>{hashReport.ssdeep_blocksize}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("SSDEEP Hash 1")}</TableCell>
                  <TableCell>{hashReport.ssdeep_hash1}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("SSDEEP Hash 2")}</TableCell>
                  <TableCell>{hashReport.ssdeep_hash2}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>SHA512</TableCell>
                  <TableCell>{hashReport.sha512}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("File Size")}</TableCell>
                  <TableCell>{hashReport.file_size}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("Result")}</TableCell>
                  <TableCell>{hashReport.result}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("Message")}</TableCell>
                  <TableCell>
                    {hashReport.messages && hashReport.messages.length
                      ? hashReport.messages[0].type +
                        "  " +
                        hashReport.messages[0].message
                      : null}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>{" "}
        </CardContent>
      </Card>
      <Box mt={2}>
        <Grid container spacing={3}>
          {hashReport.PEHash.sections.map((val: PEHashSectionModel, i) => {
            // TODO - Verify - i assume if empty==true, don't show it?
            if (val.empty) return null;
            return (
              <Grid key={"pehashview" + i} item xs={12} md={6} xl={4}>
                <Card>
                  <CardHeader
                    className={classes.ch}
                    title={val.titleType + " - " + val.title}
                  />
                  <CardContent>
                    <TableContainer>
                      <Table size="small">
                        <TableBody>
                          <TableRow>
                            <TableCell>MD5</TableCell>
                            <TableCell>{val.md5}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>SHA1</TableCell>
                            <TableCell>{val.sha1}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{t("SSDEEP Block Size")}</TableCell>
                            <TableCell>{val.ssdeep}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>SSDEEP Hash 1</TableCell>
                            <TableCell>{val.ssdeep1}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>SSDEEP Hash 2</TableCell>
                            <TableCell>{val.ssdeep2}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
}
