import React from "react";
import { Link } from "react-router-dom";

import { SimpleTableCardView } from "../../common/SimpleTableView";
import { useTranslation } from "react-i18next";
import { TagChip } from "./TagChip";
import { TagsForHash } from "../../../../models/report/md5/tags";
import { LinkWrap } from "../../common/LinkWrap";

import { useDispatch, useSelector } from "react-redux";
import { fetchTags, tagsSelector } from "slices/tags";
import { Badge } from "@material-ui/core";

export type TagTableProps = {
  theTags: TagsForHash[];
  title: string;
  testing?: boolean;
};

export function TagTable({ theTags, title, testing }: TagTableProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { tags } = useSelector(tagsSelector);

  React.useEffect(() => {
    dispatch(fetchTags(tags));
  }, [dispatch, tags]);

  return (
    <SimpleTableCardView
      title={title}
      headers={[
        {
          title: "MD5",
          field: "md5",
          render: (rowData) => (
            <Link to={"/md5/" + rowData.md5 + "/report"}>
              <LinkWrap>{rowData.md5}</LinkWrap>
            </Link>
          ),
        },
        { title: t("Received Date"), field: "receivedDate" },
        {
          title: t("Generated Tags"),
          field: "generatedTags",
          render: (rowData) =>
            rowData.generatedTags.map((r, i) => (
              <Badge
                key={"gen_" + i}
                badgeContent={
                  tags && tags[r.family.toLowerCase()]
                    ? tags[r.family.toLowerCase()].tagcount
                    : null
                }
                color="secondary"
                max={999}
              >
                <TagChip tag={r} />
              </Badge>
            )),
        },
        {
          title: t("User Tags"),
          field: "userTags",
          render: (rowData) =>
            rowData.userTagsOrig.map((r, i) => (
              <Badge
                key={"user_" + i}
                badgeContent={
                  tags && tags[r.family.toLowerCase()]
                    ? tags[r.family.toLowerCase()].tagcount
                    : null
                }
                color="secondary"
                max={999}
              >
                <TagChip tag={r} />
              </Badge>
            )),
        },
      ]}
      data={theTags.map((t) => ({
        md5: t.md5,
        receivedDate: t.receivedDate,
        generatedTags: t.generatedTags,
        userTags: t.userTags.map((g) => g.family),
        userTagsOrig: t.userTags,
      }))}
      testing={testing}
    />
  );
}
