import React from "react";
import { IconButton } from "@material-ui/core";
import TranslateIcon from "@material-ui/icons/Translate";
import { ClipboardButton } from "./ClipboardButton";

// TODO - support more than just when Clipboard button or a string used, e.g. object ???
export function DecodeButton({
  children,
}: {
  children: React.ReactElement | string;
}) {
  // these are a lot of states  - why ???  one for if you should decode or not
  // based on if the value and the decoded value are the same (decoded means changing
  // the % stuff in a string to the words), then i want to store the original
  // and decoded value so i can flip it when the user wants to via userselectundecode
  const [shouldDecode, setShouldDecode] = React.useState(false);
  const [originalValue, setOriginalValue] = React.useState("");
  const [decodedValue, setDecodedValue] = React.useState("");
  const [userSelectUnDecode, setUserSelectUnDecode] = React.useState(false);
  const [clipboardType, setClipboardType] = React.useState(false);

  React.useEffect(() => {
    let val, decodedVal;
    if ("string" === typeof children) {
      val = children;
      try {
        decodedVal = decodeURI(children);
      } catch (e) {
        console.log("Could not decode URI: " + children);
      }
    } else if (children.props && children.props.value) {
      val = children.props.value;
      try {
        decodedVal = decodeURI(children.props.value);
      } catch (e) {
        console.log("Could not decode URI: " + children.props.value);
      }
      setClipboardType(true);
    }

    if (val !== decodedVal) {
      // show the real val hide other.
      setShouldDecode(true);
      setOriginalValue(val);
      setDecodedValue(decodedVal);
    }
  }, [children]);

  // just return the regular clipboard thing if no need to decode
  return shouldDecode ? (
    clipboardType ? (
      <>
        {userSelectUnDecode ? originalValue : decodedValue}
        <ClipboardButton
          value={userSelectUnDecode ? originalValue : decodedValue}
        />{" "}
        <IconButton
          size="small"
          onClick={(e) => {
            setUserSelectUnDecode(!userSelectUnDecode);
            // might be in a link (e.g. domain report), so in that case, don't follow link when click on button
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <TranslateIcon />
        </IconButton>
      </>
    ) : (
      <>
        {userSelectUnDecode ? originalValue : decodedValue}
        <IconButton
          size="small"
          onClick={(e) => {
            setUserSelectUnDecode(!userSelectUnDecode);
            // ditto the above stop link from happening stuff
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <TranslateIcon />
        </IconButton>
      </>
    )
  ) : (
    <>{children}</>
  );
}
