import { createSlice } from "@reduxjs/toolkit";
import Axios from "axios";
import { URLMD5ClustersListJSON } from "../components/beaver/helpers/ServerProperties";

// TODO - define error more properly (e.g. string?)
export type ClusterState = {
  clusters: any;
  error: any;
};

export const slice = createSlice({
  name: "clusters",
  initialState: {} as ClusterState,
  reducers: {
    setClusters: (state, { payload }) => {
      state.clusters = {};
      payload?.urlMD5Clusters?.forEach(
        (c) =>
          (state.clusters[c.clusterName] = {
            total: c.total,
            country: c.country,
            sector: c.sector,
          })
      );
    },
    setError: (state, { payload }) => {
      state.error = payload;
    },
  },
});

export default slice.reducer;

// use the Redux chrome plugin to inspect the state
export const clusterSelector = (state: ClusterState) => state.clusters;

/**
 * This will fetch the clusters if they have not already been fetched,
 * hence why you send the clusters in the fetch
 *
 * @param clusters you pass the current clusters from the reducer
 */
export const fetchClusters = (clusters) => async (dispatch) => {
  //TODO - this should make the localstorage null if it can't parse- put this somewhere and export
  const user = localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser"))
    : {};

  if (!clusters)
    Axios.get(URLMD5ClustersListJSON(), {
      auth:
        user.server && !user.server.includes("localhost")
          ? {
              username: user ? user.username : null,
              password: user
                ? user.onetime
                  ? // eslint-disable-next-line no-eval
                    window.atob(eval(window.atob(user.onetime))).substr(13)
                  : null
                : null,
            }
          : null,
    }).then(
      (response) => {
        dispatch(slice.actions.setClusters(response.data));
      },
      (error) => {
        // error being ommited from console.log so as not to pollute the tests
        dispatch(
          slice.actions.setError(
            error.response
              ? JSON.stringify(error.response)
              : JSON.stringify({
                  status: error.message.match(/\d+/g)
                    ? parseInt(error.message.match(/\d+/g)[0])
                    : 500,
                  message: error.message,
                  data: JSON.stringify(error),
                })
          )
        );
      }
    );
};
