import React from "react";
import { Link } from "react-router-dom";
import { SimpleTableCardView } from "../../common/SimpleTableView";
import { getDomainFromURL } from "../../../../helpers/DomainUtil";
import { PhishingClusterListItem } from "../../../../models/report/phishing/phishingListModel";
import { useTranslation } from "react-i18next";
import { ClipboardButton } from "../../common/ClipboardButton";
import { useDispatch, useSelector } from "react-redux";
import { fetchSources, sourceSelector } from "slices/sources";
import { LinkWrap } from "../../common/LinkWrap";
import { Box } from "@material-ui/core";

const md5 = require("md5");

export type PhishingTableProps = {
  testing: boolean;
  clusterList: PhishingClusterListItem[];
};

export function PhishingTable({ testing, clusterList }: PhishingTableProps) {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { sources } = useSelector(sourceSelector);

  React.useEffect(() => {
    dispatch(fetchSources(sources));
  }, [dispatch, sources]);

  return (
    <SimpleTableCardView
      testing={testing}
      title={t("Phishing Kits List")}
      data={clusterList.map((c) => {
        return {
          artifact_md5: c.artifact_md5,
          received_on: c.received_on,
          url: c.url ? c.url : "",
          domain: c.url ? getDomainFromURL(c.url) : "",
          cluster: c.cluster_name ? c.cluster_name : "",
          subcluster: c.subcluster_name ? c.subcluster_name : "",
          source_id: !c.source_id
            ? ""
            : sources && sources[c.source_id]
            ? sources[c.source_id].displayString
            : c.source_id,
        };
      })}
      headers={[
        {
          title: t("Phishing Kit MD5"),
          field: "artifact_md5",
          render: (row) => (
            // #374 Please fix the first column width so the copy icon is always at the end on the same line as the md5.
            <Box style={{ whiteSpace: "nowrap" }}>
              <Link
                to={["/phishing_kit", row.artifact_md5, "report"].join("/")}
              >
                <LinkWrap>{row.artifact_md5}</LinkWrap>
              </Link>
              <ClipboardButton value={row.artifact_md5} />
            </Box>
          ),
          width: "10%",
        },
        {
          title: t("Received Date"),
          field: "received_on",
          width: "5%",
        },
        {
          title: t("Source"),
          field: "source_id",
          width: "5%",
        },
        {
          title: t("Domain Name"),
          field: "domain",
          render: (rowData) => {
            if (rowData.domain)
              return (
                <Box style={{ wordBreak: "break-word" }}>
                  <Link to={["/domain", rowData.domain, "report"].join("/")}>
                    <LinkWrap>{rowData.domain}</LinkWrap>
                  </Link>
                  <ClipboardButton value={rowData.domain} />
                </Box>
              );
            else return "";
          },
        },
        {
          title: t("Origin URL"),
          field: "url",
          render: (rowData) => (
            <Box style={{ wordBreak: "break-word" }}>
              <Link to={["/url", md5(rowData.url), "report"].join("/")}>
                <LinkWrap>{rowData.url}</LinkWrap>
              </Link>
              <ClipboardButton value={rowData.url} />
            </Box>
          ),
          width: "30%",
        },
      ]}
    />
  );
}
