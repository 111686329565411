import { reportMessage } from "./reportMessage";

export enum FileToolsOuputType {
  FILE = "FILE",
  TRID = "TRID",
  EXIFTOOL = "EXIFTOOL",
}

export interface FileToolsOutputModel {
  possibleTypes: string[];
  // the output can be either a string or string[]
  output?: string | string[];
  resultElements?: { [element: string]: string };
}

export interface FileTypeModel {
  creationDate: string;
  md5: string;
  version: string;
  sampleType?: string;
  result: string;
  messages: reportMessage[];
  // see https://stackoverflow.com/questions/44243060/use-enum-as-restricted-key-type-in-typescript
  // note that without the questionmark you will need all three, which isn't a guarantee (but mostly is there tho)
  toolsOutput?: {
    [key in keyof typeof FileToolsOuputType]?: FileToolsOutputModel;
  };
  // the new verison will have this
  fileInfo?: {
    ascii: string;
    entropy: number;
    hex: string;
    magic: string;
    mime: string;
    size: number;
  };
  type?: {
    sampleType: string;
  };
}
