import { createSlice } from "@reduxjs/toolkit";
import Axios from "axios";
import { SourceListJSON } from "../components/beaver/helpers/ServerProperties";

export interface SourceInfo {
  displayString: string;
  paths: string[];
  name: string;
  state: string;
  category: string;
  value: string;
  ordinal: number;
}

export interface SourceList {
  [sourceName: string]: SourceInfo;
}

// TODO - define error more properly (e.g. string?)
export type SourceState = {
  sources: SourceList;
  error: any;
};

export const slice = createSlice({
  name: "sources",
  initialState: {} as SourceList,
  reducers: {
    setSources: (state, { payload }) => {
      state.sources = payload;
    },
    setError: (state, { payload }) => {
      state.error = payload;
    },
  },
});

export default slice.reducer;

// use the Redux chrome plugin to inspect the state
export const sourceSelector = (state: SourceState) => state.sources;

/**
 * This will fetch the sources if they have not already been fetched,
 * hence why you send the sources in the fetch
 *
 * @param sources you pass the current sources from the reducer
 */
export const fetchSources = (sources) => async (dispatch) => {
  //TODO - this should make the localstorage null if it can't parse- put this somewhere and export
  const user = localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser"))
    : {};

  if (!sources)
    Axios.get(SourceListJSON(), {
      auth:
        user.server && !user.server.includes("localhost")
          ? {
              username: user ? user.username : null,
              password: user
                ? user.onetime
                  ? // eslint-disable-next-line no-eval
                    window.atob(eval(window.atob(user.onetime))).substr(13)
                  : null
                : null,
            }
          : null,
    }).then(
      (response) => {
        dispatch(slice.actions.setSources(response.data));
      },
      (error) => {
        // error being ommited from console.log so as not to pollute the tests
        dispatch(
          slice.actions.setError(
            error.response
              ? JSON.stringify(error.response)
              : JSON.stringify({
                  status: error.message.match(/\d+/g)
                    ? parseInt(error.message.match(/\d+/g)[0])
                    : 500,
                  message: error.message,
                  data: JSON.stringify(error),
                })
          )
        );
      }
    );
};
