export enum ReportType {
  MD5 = "md5",
  SHA1 = "sha1",
  SHA256 = "sha256",
  URL = "url",
  SID = "sid",
  IP = "ip",
  EMAIL = "email_address",
  DOMAIN = "domain",
  PHISHING = "phishing_kit",
  PHISHING_LIST = "phishing_kit_full_list",
  TAG = "tag",
  TAG_LIST = "tag_list",
  URL_MD5_TAG_LIST = "url_md5_tag_list",
  CPANEL_URL_MD5_TAG_LIST = "cpanel_url_md5_tag_list",
  PHISHING_URL_MD5_TAG_LIST = "phishing_url_md5_tag_list",
  URL_MD5_TAG = "url_md5_tag",
  PRIORITY_LIST_ALL = "priority_list_all",
  PRIORITY_LIST_USER = "priority_list_user",
  PRIORITY_LIST_HUMAN = "priority_list_human",
  PRIORITY_LIST_BOT = "priority_list_bot",
  UPLOAD_SAMPLE = "upload_sample",
  UPLOAD_URL = "upload_url",
  LIVE_HUNT = "live_hunt",
  LIVE_HUNT_RULE = "live_hunt_rule",
  SPAM_PHISHING = "spam_phishing",
}
