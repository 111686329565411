import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import _ from "lodash";
import gnirts from "gnirts";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Axios from "axios";
import { OptionsObject, useSnackbar } from "notistack";
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  useTheme,
  IconButton,
} from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";

import CardCentered from "../../commons/components/layout/pages/CardCentered";
import useAppLayout from "../../commons/components/hooks/useAppLayout";
import {
  isLang,
  Lang,
  onToggleLanguage,
} from "../../commons/components/layout/topnav/ThemeSelection";
import { isExternal } from "components/beaver/helpers/ServerProperties";
import { AccountCircle } from "@material-ui/icons";
import {
  isLab,
  isStaging,
  isGeekweek,
} from "components/beaver/helpers/ServerProperties";

type ValidHostProp = {
  name: string;
  server: string;
};

const getValidHosts = (): ValidHostProp[] => {
  switch (window.location.hostname) {
    case "localhost":
      return [
        { name: "Mock Data", server: "mock" },
        { name: "Localhost", server: "http://localhost:9001" },
        { name: "LabProxy", server: "labproxy" },
      ];
    case "beaver.staging.ccirc.lab":
      return [
        // { name: "Lab Staging", server: "https://beaver.staging.ccirc.lab" },
        { name: "LabStgProxy", server: "labstgproxy" },
      ];
    case "beaver.ccirc.lab":
      return [
        // { name: "Lab", server: "https://beaver.ccirc.lab" },
        { name: "LabProxy", server: "labproxy" },
      ];
    case "beaver.int.ccirc-ccric.gc.ca":
      return [
        { name: "Internal", server: "https://beaver.int.ccirc-ccric.gc.ca" },
      ];
    case "beaver.ino.u.azure.chimera.cyber.gc.ca":
      return [
        {
          name: "Internal",
          server: "https://beaver.ino.u.azure.chimera.cyber.gc.ca",
        },
      ];
    case "beaver.ccirc-ccric.gc.ca":
      return [{ name: "External", server: "https://beaver.ccirc-ccric.gc.ca" }];
    case "beaver.ops.cyber.gc.ca":
      return [{ name: "BeAVER", server: "https://beaver.ops.cyber.gc.ca" }];
    case "beaver.collaboration.cyber.gc.ca":
      return [
        { name: "BeAVER", server: "https://beaver.collaboration.cyber.gc.ca" },
      ];
    default:
      return [{ name: "Mock Data", server: "mock" }];
  }
};

function UserPassLogin() {
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { setCurrentUser } = useAppLayout();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [server, setServer] = useState<any>(getValidHosts()[0].server);
  const snackBarOptions: OptionsObject = {
    variant: "error",
    autoHideDuration: 4000,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    onClick: (snack) => {
      closeSnackbar();
    },
  };
  const [version, setVersion] = React.useState(null);
  const [date, setDate] = React.useState(null);
  const [doingSSO, setDoingSSO] = React.useState(false);
  const [lastError, setLastError] = React.useState(null);
  const [lastErrorDesc, setLastErrorDesc] = React.useState(null);

  const [b2cAuthUrl, setB2CAuthUrl] = React.useState(null);
  const [aadAuthUrl, setAADAuthUrl] = React.useState(null);

  const navigate = useNavigate();
  const location = useLocation();

  const isFromPortal = () => {
    return (
      document.referrer &&
      document.referrer.match(
        /portal-portail\.cyber\.gc\.ca|cccs-portal-dev\.powerappsportals\.com|cccs-staging\.powerappsportals\.com|cccs-portal-tst\.powerappsportals\.com/
      )
    );
  };

  const [forceUsernamePassword, setForceUsernamePassword] =
    React.useState(false);

  React.useEffect(() => {
    // if the url path is not /ng/ and it's lab or stg then save to window.localStorage.goToURLAfterLogin if not exists (for when they first login, not 401)
    if (
      !isLab() &&
      !isStaging() &&
      !window.localStorage.goToURLAfterLogin &&
      !["/ng/", "/ng/login"].includes(window.location.pathname)
    )
      window.localStorage.goToURLAfterLogin = document.location.href;

    if (location.pathname === "/logout") {
      navigate("");
    }

    if (
      window.location.host.match(/gc\.ca$/) ||
      window.location.host.match(/lab$/)
    )
      Axios.get("/ng/version.json").then((res) => {
        // console.log(res);
        if (res.data.date) setDate(res.data.date);
        if (res.data.version) setVersion(res.data.version);
      });

    // if referred by the cccs portal, then just trigger sso login
    if (isFromPortal()) {
      // need to give it that extra few seconds for everything to sync up or something
      setDoingSSO(true);
    }

    // if there is an error that needs to be shown (e.g. from OIDC) then show it and promptly remove it from localStorage.
    if (localStorage.lastError) {
      setLastError(localStorage.lastError);
      if (localStorage.lastErrorDesc)
        setLastErrorDesc(localStorage.lastErrorDesc);
      localStorage.lastError = "";
      localStorage.lastErrorDesc = "";
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // todo - how to login? likely use a rest call to beaver server that returns the user's email (for the gravitar)
  function login(event) {
    // This is obvisouly not how you should do authentification... perhaps you can call an API?
    if (
      !["mock", "http://localhost:9001"].includes(server) &&
      (username === null ||
        username === null ||
        username === "" ||
        password === null ||
        password === null ||
        password === "")
    ) {
      enqueueSnackbar(
        t("You need a username and password for this server"),
        snackBarOptions
      );
      //   reject("Empty Login");
      event.preventDefault();
    } else if ("labproxy" === server) {
      event.preventDefault();
      console.log("lab proxy");
      axios
        .post("https://beaver.ccirc.lab/lauth/login", {
          username: username,
          password: password,
        })
        .catch((e) =>
          enqueueSnackbar(
            e.message && e.message.includes("401")
              ? t("Invalid Username / Password")
              : e.message,
            snackBarOptions
          )
        )
        .then((resp) => {
          if (resp) {
            //login successful since didn't do a 401
            console.log(resp);
            let data: any = resp["data"];
            if (data) {
              localStorage.setItem("ssoPrefix", "/lauth/api");
              setCurrentUser({
                username: username,
                name: _.startCase(username),
                email: null,
                avatar: null,
                server: "https://beaver.ccirc.lab",
                onetime: window.btoa(
                  gnirts.getCode(window.btoa("" + Date.now()))
                ),
                login: Date.now(),
                canSeeBorealis: isExternal()
                  ? false
                  : isGeekweek()
                  ? data.canSeeBorealis
                  : true,
              });
              // just do a page refresh here since localStorage set with username info
              window.location.reload();
            }
          }
        });
    } else if ("labstgproxy" === server) {
      event.preventDefault();
      console.log("lab stg proxy");
      axios
        .post("https://beaver.staging.ccirc.lab/lauth/login", {
          username: username,
          password: password,
        })
        .catch((e) =>
          enqueueSnackbar(
            e.message && e.message.includes("401")
              ? t("Invalid Username / Password")
              : e.message,
            snackBarOptions
          )
        )
        .then((resp) => {
          if (resp) {
            //login successful since didn't do a 401
            console.log(resp);
            let data: any = resp["data"];
            if (data) {
              localStorage.setItem("ssoPrefix", "/lauth/api");
              setCurrentUser({
                username: username,
                name: _.startCase(username),
                email: null,
                avatar: null,
                server: "https://beaver.staging.ccirc.lab",
                onetime: window.btoa(
                  gnirts.getCode(window.btoa("" + Date.now()))
                ),
                login: Date.now(),
                canSeeBorealis: isExternal()
                  ? false
                  : isGeekweek()
                  ? data.canSeeBorealis
                  : true,
              });
              // just do a page refresh here since localStorage set with username info
              window.location.reload();
            }
          }
        });
    } else {
      // first attempt to axios in
      if ("mock" !== server) {
        event.preventDefault();
        axios
          .get(
            server + "/statsJson",
            "http://localhost:9001" !== server
              ? {
                  auth: { username: username, password: password },
                }
              : null
          )
          .then(() => {
            setCurrentUser({
              username: username ? username : "LocalUser",
              name: !username
                ? "Local User"
                : username.includes("@")
                ? _.startCase(username.match(/[^@]+/)[0])
                : _.startCase(username),
              email: username.includes("@") ? username : null,
              avatar: null,
              // avatar: "https://cdn.iconscout.com/icon/free/png-256/avatar-380-456332.pngMG"
              server: server,
              onetime: window.btoa(
                gnirts.getCode(window.btoa(Date.now() + password))
              ),
              login: Date.now(),
              canSeeBorealis: !isExternal(),
            });
            // just do a page refresh here since localStorage set with username info
            window.location.reload();
          })
          .catch((e) => {
            enqueueSnackbar(e.message, snackBarOptions);
            console.log(e);
          });

        //   .then((res) => {
        //     debugger;
        //     console.log(res);
        //     setCurrentUser({
        //       username: username ? username : "LocalUser",
        //       name: username ? username : "Local User",
        //       email: null,
        //       avatar: null,
        //       // avatar: "https://cdn.iconscout.com/icon/free/png-256/avatar-380-456332.pngMG"
        //       server: server,
        //     });
        //     // resolve(true);
        //   })
        //   .catch((err) => {
        //     debugger;
        //     console.log(err);
        //   });
      } else {
        setCurrentUser({
          username: username ? username : "MockUser",
          name: !username
            ? "Mock User"
            : (username.includes("@")
                ? _.startCase(username.match(/[^@]+/)[0])
                : _.startCase(username)) + " (Mock)",
          email: username.includes("@") ? username + " (Mock)" : null,
          avatar: null,
          // avatar: "https://cdn.iconscout.com/icon/free/png-256/avatar-380-456332.pngMG"
          server: server,
          onetime: window.btoa(
            gnirts.getCode(window.btoa(Date.now() + password))
          ),
          login: Date.now(),
          canSeeBorealis: !isExternal(),
        });
        // resolve(true);
      }
    }
  }

  const setUserForSSOFunctions = async () => {
    setCurrentUser({
      username: "...", //will be populated later via whoami
      name: "...", // will be populated later
      email: null,
      avatar: null,
      server: server,
      onetime: window.btoa(gnirts.getCode(window.btoa(Date.now() + password))),
      login: Date.now(),
      canSeeBorealis: false,
    });
    localStorage.setItem("ssoPrefix", "/auth/api");
  };

  const setUserForSSO = () => {
    setUserForSSOFunctions().then(() => {
      // if the b2cAuthUrl is set, go there, otherwise refresh the page for auto redirect.
      if (b2cAuthUrl) window.location.href = b2cAuthUrl;
      else if (aadAuthUrl) window.location.href = aadAuthUrl;
      else window.location.reload();
    });
  };

  React.useEffect(() => {
    if ("https://beaver.ops.cyber.gc.ca" === server) {
      Axios.get("https://beaver.ops.cyber.gc.ca/auth/loginjson").then((res) => {
        // set the auth url if there is one
        if (res.data && res.data.auth_url) {
          setB2CAuthUrl(res.data.auth_url);
          // if it's from the portal, just change the page to the auth after setting up
          // the user - no need to check states, etc.
          if (isFromPortal())
            setUserForSSOFunctions().then(() => {
              window.location.href = res.data.auth_url;
            });
        }
      });
    } else if ("https://beaver.int.ccirc-ccric.gc.ca" === server) {
      Axios.get("https://beaver.int.ccirc-ccric.gc.ca/auth/loginjson").then(
        (res) => {
          // set the auth url if there is one
          if (res.data && res.data.auth_url) setAADAuthUrl(res.data.auth_url);
        }
      );
    } else if ("https://beaver.ino.u.azure.chimera.cyber.gc.ca" === server) {
      Axios.get(
        "https://beaver.ino.u.azure.chimera.cyber.gc.ca/auth/loginjson"
      ).then((res) => {
        // set the auth url if there is one
        if (res.data && res.data.auth_url) setAADAuthUrl(res.data.auth_url);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [server]);

  if (doingSSO) {
    return (
      <Box zIndex={1200} display={"flex"} flexDirection={"column"}>
        <p>{t("Completing Login ...")}</p>
      </Box>
    );
  } else {
    return (
      <>
        <form onSubmit={login}>
          <Box zIndex={1200} display={"flex"} flexDirection={"column"}>
            {forceUsernamePassword ||
            ![
              "https://beaver.collaboration.cyber.gc.ca",
              "https://beaver.int.ccirc-ccric.gc.ca",
              "https://beaver.ops.cyber.gc.ca",
              "https://beaver.ino.u.azure.chimera.cyber.gc.ca",
            ].includes(server) ? (
              <>
                <TextField
                  variant={"outlined"}
                  size={"small"}
                  label={t("page.login.username")}
                  onChange={(event) => setUsername(event.target.value)}
                />
                <TextField
                  style={{ marginTop: ".5rem" }}
                  variant={"outlined"}
                  size={"small"}
                  type="password"
                  label={t("page.login.password")}
                  onChange={(event) => setPassword(event.target.value)}
                />
              </>
            ) : (
              <Box display="flex" flexGrow={1} mb={2}>
                {"https://beaver.ops.cyber.gc.ca" === server ? (
                  <Box style={{ backgroundColor: "ivory", padding: "1em" }}>
                    {t("beaver.ops.message")}
                  </Box>
                ) : (
                  <img
                    src={
                      "https://beaver.int.ccirc-ccric.gc.ca" === server ||
                      "https://beaver.ino.u.azure.chimera.cyber.gc.ca" ===
                        server
                        ? "/ng/images/edb-cybercentre-split.jpg"
                        : "/ng/images/gw9.jpg"
                    }
                    alt="beaver"
                    style={{ maxWidth: "100%" }}
                  />
                )}
              </Box>
            )}
            <Select
              style={{
                marginTop: ".5rem",
                display:
                  window.location.hash === "#MOCK" ||
                  "localhost" === window.location.hostname
                    ? null
                    : "none",
              }}
              labelId="serverl-label"
              value={server}
              onChange={(event) => setServer(event.target.value)}
            >
              {getValidHosts().map((h, i) => (
                <MenuItem key={i} value={h.server}>
                  {h.name}
                </MenuItem>
              ))}
              {window.location.hash === "#MOCK" ? (
                <MenuItem value="mock">Mock Data</MenuItem>
              ) : null}
            </Select>
            <FormHelperText
              style={{
                display:
                  window.location.hash === "#MOCK" ||
                  "localhost" === window.location.hostname
                    ? null
                    : "none",
              }}
            >
              {t("BeAVER Server")}
            </FormHelperText>
            {forceUsernamePassword ||
            ![
              "https://beaver.collaboration.cyber.gc.ca",
              "https://beaver.int.ccirc-ccric.gc.ca",
              "https://beaver.ops.cyber.gc.ca",
              "https://beaver.ino.u.azure.chimera.cyber.gc.ca",
            ].includes(server) ? (
              <Button
                type="submit"
                style={{ marginTop: "1.5rem" }}
                variant={"contained"}
                color={"primary"}
              >
                {t("page.login.button")}
              </Button>
            ) : null}
            {!forceUsernamePassword &&
            [
              "http://localhost:9001",
              "https://beaver.ops.cyber.gc.ca",
              "https://beaver.int.ccirc-ccric.gc.ca",
              "https://beaver.collaboration.cyber.gc.ca",
              "https://beaver.ino.u.azure.chimera.cyber.gc.ca",
            ].includes(server) ? (
              <Button
                style={{ marginTop: ".5rem" }}
                variant={"contained"}
                color={"primary"}
                onClick={setUserForSSO}
              >
                {"https://beaver.ops.cyber.gc.ca" === server
                  ? t("Sign in with My Cyber Portal")
                  : [
                      "https://beaver.collaboration.cyber.gc.ca",
                      "https://beaver.int.ccirc-ccric.gc.ca",
                      "https://beaver.ino.u.azure.chimera.cyber.gc.ca",
                    ].includes(server)
                  ? t("page.login.button")
                  : t("Sign In With Falcon AD")}
              </Button>
            ) : null}
            {!isExternal() ? (
              <>
                <Box style={{ textAlign: "right" }} pt={2}>
                  <Button onClick={() => onToggleLanguage()}>
                    {isLang(Lang.EN) ? "Français" : "English"}
                  </Button>
                  {["https://beaver.collaboration.cyber.gc.ca"].includes(
                    server
                  ) && (
                    <Box style={{ float: "left" }}>
                      <IconButton
                        size="small"
                        onClick={() =>
                          setForceUsernamePassword(!forceUsernamePassword)
                        }
                      >
                        <AccountCircle />
                      </IconButton>
                    </Box>
                  )}
                </Box>
              </>
            ) : null}
          </Box>
        </form>
        {lastError && (
          <Box mt={2}>
            <Paper>
              <Box
                p={2}
                display="flex"
                style={{ color: "darkred", alignItems: "center", gap: "1em" }}
              >
                <ErrorIcon /> {lastError}
                {lastErrorDesc ? " - " + lastErrorDesc : null}
              </Box>
            </Paper>
          </Box>
        )}
        <Box mt={2}>
          <Typography variant="caption">
            {!!version && t("Beaver NG version ") + version}
            {!!date && t(" built on ") + date}
          </Typography>
        </Box>
      </>
    );
  }
}

function LoginScreen() {
  const theme = useTheme();
  const { getBanner, useTBSLayout } = useAppLayout();

  if (useTBSLayout) {
    return (
      <Grid container justify="center" spacing={2}>
        <Grid item xs={11} md={5}>
          <Box color={theme.palette.primary.main} fontSize="30pt">
            {getBanner(theme)}
          </Box>
          <UserPassLogin />
        </Grid>
      </Grid>
    );
  }
  return (
    <CardCentered>
      <Box
        my={"3rem"}
        display="flex"
        flexDirection="row"
        justifyContent="center"
      >
        <img
          alt=""
          src="/ng/images/beaver_banner.png"
          style={{ width: "130%", maxWidth: "100vw" }}
        />
      </Box>
      <UserPassLogin />
    </CardCentered>
  );
}

export default LoginScreen;
