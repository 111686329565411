import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { beaverNGSelector, fetchReport } from "../../../../../slices/beaverng";

import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  LinearProgress,
} from "@material-ui/core";

import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import PageFullWidth from "commons/components/layout/pages/PageFullWidth";
import PageHeader from "commons/components/layout/pages/PageHeader";
import { useStyles } from "../common/sharedStyles";
import { ReportType } from "../../../models/report/reportType";

import { SimpleTableView } from "../common/SimpleTableView";
import {
  PriorityListType,
  PriorityListViewResponseData,
} from "../../../models/report/priority/PriorityListType";
import { ParsedError, parseErrorTitle } from "../common/ParsedError";
import { isExternal } from "components/beaver/helpers/ServerProperties";
import { LinkWrap } from "../common/LinkWrap";

export function PriorityList() {
  const { t } = useTranslation();
  const { type, user }: { type: PriorityListType; user?: string } = useParams();
  const typeUse = type ? type : PriorityListType.all;

  const title =
    t("Priority List") +
    (PriorityListType.all === typeUse
      ? " - " + t("All")
      : !user
      ? " - " + t(_.startCase(typeUse))
      : " - " + _.startCase(user.split(".").join(" ")));

  return (
    <>
      <Helmet>
        <title>{["BEAVER", title].join(" ")}</title>
        <meta
          name="description"
          content={["BEAVER", title, "-", t("page.home.description")].join(" ")}
        />
      </Helmet>
      <PageHeader title={title} />
      <PageFullWidth>
        <PriorityListReport type={typeUse} user={user} />
      </PageFullWidth>
    </>
  );
}

const getReportType = (type: PriorityListType) => {
  switch (type) {
    case PriorityListType.all:
      return ReportType.PRIORITY_LIST_ALL;
    case PriorityListType.user:
      return ReportType.PRIORITY_LIST_USER;
    case PriorityListType.human:
      return ReportType.PRIORITY_LIST_HUMAN;
    case PriorityListType.bot:
      return ReportType.PRIORITY_LIST_BOT;
  }
};

export type PriorityListReportProps = {
  testing?: boolean;
  type: PriorityListType;
  user?: string;
};

export function PriorityListReport({
  testing,
  type,
  user,
}: PriorityListReportProps) {
  const [retry, setRetry] = React.useState(0);
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { responseData, reportType, error } = useSelector(beaverNGSelector);

  // will run on first mount
  useEffect(() => {
    dispatch(fetchReport(getReportType(type), user));
  }, [dispatch, type, user, retry]);

  // start by just showing the summary once loaded
  if (
    !error &&
    (!responseData || !reportType || getReportType(type) !== reportType)
  ) {
    // show a loading bar until it loads, and show the error if there is one
    // TODO - translate the loading text
    return (
      <>
        {t("Loading")} ...
        <LinearProgress />
      </>
    );
  } else if (error) {
    return (
      <Card>
        <CardHeader
          className={classes.ch}
          avatar={<Avatar className={classes.errorIcon}>!</Avatar>}
          title={parseErrorTitle(error)}
        />
        <CardContent>
          <ParsedError error={error} />
        </CardContent>
        <CardActions>
          <Button size="small" onClick={() => setRetry(retry + 1)}>
            {t("Retry")}
          </Button>
        </CardActions>
      </Card>
    );
  } else {
    return (
      <PriorityListView
        type={type}
        user={user}
        responseData={responseData}
        testing={testing}
      />
    );
  }
}

export type PriorityListViewProps = {
  type: PriorityListType;
  user?: string;
  responseData: PriorityListViewResponseData;
  testing?: boolean;
};

export function PriorityListView({
  type,
  user,
  responseData,
  testing,
}: PriorityListViewProps) {
  const { t } = useTranslation();

  const storedUser = localStorage.getItem("currentUser");
  const fetchedUser = storedUser ? JSON.parse(storedUser) : null;
  const loggedInUser =
    fetchedUser && fetchedUser.username
      ? fetchedUser.username.toLowerCase()
      : null;

  const getHeaders = () => {
    let headers = [
      { title: t("Added Date"), field: "addedDate" },
      {
        title: "MD5",
        field: "md5",
        render: (rowData) => (
          <Link to={"/md5/" + rowData.md5 + "/report"}>
            <LinkWrap>{rowData.md5}</LinkWrap>
          </Link>
        ),
      },
      {
        title: t("Comment"),
        field: "comment",
      },
    ];

    if (!isExternal())
      headers.splice(2, 0, {
        title: t("User"),
        field: "user",
        render: (rowData) => (
          <Link to={"/priority/user/" + rowData.user}>
            <LinkWrap>{rowData.user}</LinkWrap>
          </Link>
        ),
      });

    return headers;
  };

  const data =
    PriorityListType.user !== type
      ? _.cloneDeep(responseData.list)
      : _.cloneDeep(responseData.toReturn);

  return (
    <>
      {PriorityListType.user !== type ? (
        <Box ml={1}>{t("Note that only the last 2 weeks are shown")}</Box>
      ) : null}
      <Box mt={1}>
        {Object.values(PriorityListType).map((ty, i) => (
          <Link
            key={i}
            to={generateLink(ty, loggedInUser)}
            style={{ textDecoration: "none" }}
          >
            <Button>
              {t(
                "View " +
                  (PriorityListType.user === ty ? "Your" : _.startCase(ty)) +
                  " Submissions"
              )}
            </Button>
          </Link>
        ))}
      </Box>
      <SimpleTableView
        title={
          PriorityListType.user !== type
            ? t("Priority List") + " - " + t(_.startCase(type))
            : user && user !== loggedInUser
            ? t("Priority List for") +
              " " +
              _.startCase(user.split(".").join(" "))
            : t("Your Priority List")
        }
        headers={getHeaders()}
        data={data}
        testing={testing}
      />
    </>
  );
}

function generateLink(ty: PriorityListType, loggedInUser: string = ""): string {
  if (PriorityListType.user === ty) {
    return `/priority/${ty}/${loggedInUser}`;
  }

  return `/priority/${ty}`;
}
