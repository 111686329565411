/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

export function URLSearchHelp() {
  return (
    <>
      <p>
        <br />
      </p>
      <p>BeAVER URL Search Reference Guide</p>
      <p>
        <br />
      </p>

      <p>
        <a>The URL Search Page</a>
      </p>
      <p>
        <br />
      </p>
      <p>
        <span>
          <img
            alt="url search"
            src="/ng/images/help/urlsearchimages/Image_001.jpg"
          />
        </span>
      </p>
      <p>
        <br />
      </p>
      <p>
        The URL search page is dedicated to enabling analysts to explore the
        BeAVER database using arbitrary strings, rather than relying on
        suggested or otherwise-sourced IoCs and artifacts. Analysts can query
        strings and patterns using this interface and receive results that match
        or come close to matching from our database.
      </p>
      <p>
        Please note this function is in its beta phase and is continuously
        undergoing modification and updates and as such, this reference guide
        may have details that no longer apply, or have been moved or updated.
        For planned updates, please see the last section of this reference guide{" "}
        <span>Future Work in Progress</span>.
      </p>
      <p>
        <a>Basic Search Options</a>
      </p>
      <p>
        <br />
      </p>
      <p>
        <a>Specifying A Date Range</a>
      </p>
      <p>
        <br />
      </p>
      <p>
        Use the calendar icons to the end of each line under “From Date” and “To
        Date” to show the calendar and choose your preferred date. Both fields
        do not have to be filled in.
      </p>
      <p>
        <br />
      </p>
      <p>Use cases:</p>
      <ul id="l1">
        <li data-list-text="">
          <p>
            From Date
            <span>is not</span>
          </p>
          <ul id="l2">
            <li data-list-text="o">
              <p>
                Results will contain everything after, and exclusive of the{" "}
                <i>From Date</i>
              </p>
            </li>
          </ul>
        </li>
        <li data-list-text="">
          <p>
            To Date
            <span>is not</span>
          </p>
          <ul id="l3">
            <li data-list-text="o">
              <p>
                Results will contain everything up until, and exclusive of the{" "}
                <i>To Date</i>
              </p>
            </li>
          </ul>
        </li>
        <li data-list-text="">
          <p>
            Both <i>From </i>and <i>To </i>dates are filed in
          </p>
          <ul id="l4">
            <li data-list-text="o">
              <p>
                Results will contain everything between, and exclusive of those
                two dates
              </p>
              <p>
                <br />
              </p>
              <p>Examples:</p>
            </li>
          </ul>
        </li>
        <li data-list-text="">
          <p>
            In the below example, the results will contain everything on
            09/02/2022 up to and including everything on 11/14/2022
          </p>
          <p>
            <br />
          </p>
          <p>
            <span>
              <img
                alt="url search"
                src="/ng/images/help/urlsearchimages/Image_002.jpg"
              />
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>Tips:</p>
        </li>
        <li data-list-text="">
          <p>
            To clear the date(s) from the search, click on the calendar icon for
            the date you wish to clear and
          </p>
          <p>choose “Clear” from the bottom left corner of the calendar.</p>
          <p>
            <br />
          </p>
          <p>
            <a>
              <span>
                <img
                  alt="url search"
                  src="/ng/images/help/urlsearchimages/Image_003.jpg"
                />
              </span>
            </a>
          </p>
          <p>
            <br />
          </p>
          <p>
            <a>Setting the Result Set Size</a>
          </p>
          <p>
            <br />
          </p>
          <p>
            <span>
              <img
                alt="url search"
                src="/ng/images/help/urlsearchimages/Image_004.jpg"
              />
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            The number of results is set, by default, to 100. This number can be
            increased or decreased based on your preferences. This is the number
            of results that will be pulled from the databased for your query.
            Sometimes a larger number is needed, but beware that increasing this
            number too high will slow the query down.
          </p>
          <p>
            Tip: The result set that is pulled from the database is the result
            set you will be working with after the query executes. Any searches
            you perform on the results page will not reference the database
            directly but will instead reference the result set that was pulled.
          </p>
          <p>Predefining the Result Set</p>
          <p>
            <br />
          </p>
          <p>
            <span>
              <img
                alt="url search"
                src="/ng/images/help/urlsearchimages/Image_005.jpg"
              />
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            The option to “Sort by Received Date” is checked by default, to
            provide the most recent results. When it is checked, the query
            itself will pull the most recent, relevant results from the database
            and will also order the result set on the next page in order of date
            received, descending. If this box is unchecked, it will query the
            database for results from all time most relevant to the query. The
            received date refers to the date we received the submission
            displayed in the result set.
          </p>
          <p>
            <br />
          </p>
          <p>Use Cases:</p>
        </li>
        <li data-list-text="">
          <p>
            Query is for <i>test</i>, and the “Sort by Received Date” box is
            checked
          </p>
          <ul id="l5">
            <li data-list-text="o">
              <p>
                The result set will include the last n URLs that match the
                string <i>test </i>from present date, going backward, where n is
                the number specified for “Number of Results” (default 100)
              </p>
            </li>
          </ul>
        </li>
        <li data-list-text="">
          <p>
            Query is for <i>test</i>, and the “Sort by Received Date” box is not
            checked
          </p>
          <ul id="l6">
            <li data-list-text="o">
              <p>
                The result set will include the most relevant matches from the
                database regardless of the date we received them.
              </p>
              <p>Examples:</p>
            </li>
          </ul>
        </li>
        <li data-list-text="">
          <p>
            The query below is for the string <i>test </i>and the “Sort by
            Received Date” box is checked. The result set is comprised of all
            URLs that match <u>most recently</u> regardless of whether it is the
            best match in the entire database or not (Note the highest relevancy
            score is 10.77. Keep reading for more insight). These results will
            be more relevant from a time-based perspective, but perhaps not from
            other analytical perspectives.
          </p>
          <p>
            <br />
          </p>
          <p>
            <br />
          </p>
          <p>
            <span>
              <img
                alt="url search"
                src="/ng/images/help/urlsearchimages/Image_006.jpg"
              />
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <span>
              <img
                alt="url search"
                src="/ng/images/help/urlsearchimages/Image_007.jpg"
              />
            </span>{" "}
            <span>
              <img
                alt="url search"
                src="/ng/images/help/urlsearchimages/Image_008.jpg"
              />
            </span>
          </p>
          <p>
            <br />
          </p>
        </li>
        <li data-list-text="">
          <p>
            The query below is again for the string <i>test </i>but the “Sort by
            Received Date” box is not checked. The result set is comprised of
            those URLs that have the highest match in the database regardless of
            the date we received the submission (Note the highest relevancy
            score is 100, but the date we received that URL is in 2021). These
            results will be more relevant from a matching-based perspective, but
            not from a time-based perspective.
          </p>
          <p>
            <br />
          </p>
          <p>
            <span>
              <img
                alt="url search"
                src="/ng/images/help/urlsearchimages/Image_009.jpg"
              />
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <span>
              <img
                alt="url search"
                src="/ng/images/help/urlsearchimages/Image_010.jpg"
              />
            </span>{" "}
            <span>
              <img
                alt="url search"
                src="/ng/images/help/urlsearchimages/Image_011.jpg"
              />
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <a>Excluding Results with Errors</a>
          </p>
          <p>
            <br />
          </p>
          <p>
            <span>
              <img
                alt="url search"
                src="/ng/images/help/urlsearchimages/Image_012.jpg"
              />
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <span>
              <img
                alt="url search"
                src="/ng/images/help/urlsearchimages/Image_013.png"
              />
            </span>
          </p>
          <p>
            Choosing this option will ensure the result set only contains those
            results which were crawled and returned with a successful HTTP
            status code. This will exclude those that were returned with error
            codes such as 403,<span> 404,</span> 503, etc.
          </p>
          <p>
            <a>Query Syntax</a>
          </p>
          <p>
            <br />
          </p>
          <p>Use the “Query” line to enter your search requirements.</p>
          <p>
            <br />
          </p>
          <p>
            <a>Overview</a>
          </p>
          <p>
            <br />
          </p>
        </li>
        <li data-list-text="">
          <p>
            To quote a string, the double quotes must be escaped to pass to the
            query engine.
          </p>
        </li>
        <li data-list-text="">
          <p>Boolean operators are case sensitive</p>
        </li>
        <li data-list-text="">
          <p>Search strings are not case sensitive</p>
        </li>
        <li data-list-text="">
          <p>Regex can be used by surrounding the query with forward slashes</p>
        </li>
        <li data-list-text="">
          <p>Wildcards ? and * can be used in the regular query syntax</p>
        </li>
        <li data-list-text="">
          <p>
            Dashes can be included in a search string but must be quoted (quotes
            must be escaped)
          </p>
          <p>
            <span>
              <img
                alt="url search"
                src="/ng/images/help/urlsearchimages/Image_014.png"
              />
            </span>
          </p>
        </li>
        <li data-list-text="">
          <p>
            Slashes are not able to be searched in a quoted string at this time
          </p>
        </li>
        <li data-list-text="">
          <p>Default escape character is the backslash \</p>
          <p>
            <br />
          </p>
          <p>
            <a>Case Sensitivity</a>
          </p>
          <p>
            <br />
          </p>
          <p>
            Search terms and results are not case-sensitive. Your search results
            will remain the same for <i>TEST</i>, <i>test </i>and <i>Test</i>.
            Boolean search operators, however, <b>are </b>case sensitive and
            your results will differ greatly between <i>test and text </i>and{" "}
            <i>test AND text</i>. When searching using Boolean search operators,
            make sure they are capitalized.
          </p>
          <p>
            <br />
          </p>
          <p>Use Cases:</p>
        </li>
        <li data-list-text="">
          <p>
            The query is for <i>TEST</i>
          </p>
          <ul id="l7">
            <li data-list-text="o">
              <p>
                Results will include everything with the term <i>test</i>
              </p>
            </li>
          </ul>
        </li>
        <li data-list-text="">
          <p>
            The query is for <i>test</i>
          </p>
          <ul id="l8">
            <li data-list-text="o">
              <p>
                Results will include everything with the term <i>test</i>, the
                same as the above query
              </p>
            </li>
          </ul>
        </li>
        <li data-list-text="">
          <p>
            The query is for <i>test and text</i>
          </p>
          <ul id="l9">
            <li data-list-text="o">
              <p>
                Results will include everything with the terms <i>test</i>,{" "}
                <i>and</i>, and <i>text</i>
              </p>
            </li>
          </ul>
        </li>
        <li data-list-text="">
          <p>
            The query is for <i>test AND text</i>
          </p>
          <ul id="l10">
            <li data-list-text="o">
              <p>
                Results will include everything with the terms <i>test </i>and{" "}
                <i>text</i>
              </p>
              <p>
                <br />
              </p>
              <p>Examples:</p>
            </li>
          </ul>
        </li>
        <li data-list-text="">
          <p>
            Searching for <i>CRA </i>returns case-insensitive results,
            containing the term <i>cra</i>
          </p>
          <p>
            <br />
          </p>
          <p>
            <span>
              <img
                alt="url search"
                src="/ng/images/help/urlsearchimages/Image_015.jpg"
              />
            </span>
          </p>
          <p>
            <br />
          </p>
        </li>
        <li data-list-text="">
          <p>
            Using lowercase operators will include the operators in the search
          </p>
          <p>
            <br />
          </p>
          <p>
            <span>
              <img
                alt="url search"
                src="/ng/images/help/urlsearchimages/Image_016.jpg"
              />
            </span>
          </p>
          <p>
            <br />
          </p>
        </li>
        <li data-list-text="">
          <p>Using uppercase operators will return the preferred results</p>
          <p>
            <br />
          </p>
          <p>
            <span>
              <img
                alt="url search"
                src="/ng/images/help/urlsearchimages/Image_017.jpg"
              />
            </span>
          </p>
          <p>
            <a>Wildcards</a>
          </p>
          <p>
            <br />
          </p>
          <p>
            URL search supports single and multiple character wildcard searches.
            To perform a single character wildcard search, for terms that match
            that with the single character replaced, use the <i>? </i>symbol. To
            perform a multiple character wildcard search, looking for 0 or more
            characters, use the <i>* </i>symbol. Note: You cannot use a{" "}
            <i>* </i>or <i>? </i>symbol as the first character of a search.
          </p>
          <p>
            <br />
          </p>
          <p>Use Cases:</p>
        </li>
        <li data-list-text="">
          <p>
            Query is for <i>te?t</i>
          </p>
          <ul id="l11">
            <li data-list-text="o">
              <p>
                Results will include terms like <i>text</i>, <i>test</i>,{" "}
                <i>tent</i>, etc.
              </p>
            </li>
          </ul>
        </li>
        <li data-list-text="">
          <p>
            Query is for <i>te*t</i>
          </p>
          <ul id="l12">
            <li data-list-text="o">
              <p>
                Results will include terms like <i>text</i>, <i>tent</i>,{" "}
                <i>tenant</i>, etc
              </p>
            </li>
          </ul>
        </li>
        <li data-list-text="">
          <p>
            Query is for <i>t??t</i>
          </p>
          <ul id="l13">
            <li data-list-text="o">
              <p>
                Results will include terms like <i>tent</i>, <i>text</i>,{" "}
                <i>tout</i>, <i>tilt</i>, etc
              </p>
            </li>
          </ul>
        </li>
        <li data-list-text="">
          <p>
            Query is for <i>t*t</i>
          </p>
          <ul id="l14">
            <li data-list-text="o">
              <p>
                Results will include any term beginning and ending with the
                letter <i>t</i>
              </p>
            </li>
          </ul>
        </li>
        <li data-list-text="">
          <p>
            Query is for <i>test*</i>
          </p>
          <ul id="l15">
            <li data-list-text="o">
              <p>
                Results will include terms like <i>test</i>, <i>tester</i>,{" "}
                <i>testing</i>, etc
              </p>
              <p>
                <br />
              </p>
              <p>Examples:</p>
              <p>
                <br />
              </p>
            </li>
          </ul>
        </li>
        <li data-list-text="">
          <p>
            In the below example I searched for <i>int*rac</i>. I received
            results for <i>interac</i>, <i>intrac </i>and <i>interact</i>:
          </p>
          <p>
            <br />
          </p>
          <p>
            <span>
              <img
                alt="url search"
                src="/ng/images/help/urlsearchimages/Image_018.jpg"
              />
            </span>
          </p>
          <p>
            <a>Search Operators</a>
          </p>
          <p>
            <br />
          </p>
          <p>
            Search operators allow terms to be combined through logic operators.
            URL Search supports <i>AND</i>, <i>OR</i>, and <i>NOT </i>as Boolean
            operators (Note: Boolean operators must be ALL CAPS). The <i>OR </i>
            operator is the default conjunction operator. If no Boolean operator
            is used between two terms, the <i>OR </i>operator is used.
          </p>
          <p>
            The <i>OR </i>operator links two terms and finds a matching URL if
            it contains either of the terms. The <i>AND </i>operator matches
            URLs where both terms exist.
          </p>
          <p>
            The <i>NOT </i>operator excludes URLs that contain the term after{" "}
            <i>NOT</i>. Note: The <i>NOT </i>operator cannot be used with just
            one term.
          </p>
          <p>
            <br />
          </p>
          <p>Use Cases:</p>
        </li>
        <li data-list-text="">
          <p>
            Query is for <i>test text</i>
          </p>
          <ul id="l16">
            <li data-list-text="o">
              <p>
                Results will contain URLs with either term, as though the{" "}
                <i>OR </i>operator was used
              </p>
            </li>
          </ul>
        </li>
        <li data-list-text="">
          <p>
            Query is for <i>test OR text</i>
          </p>
          <ul id="l17">
            <li data-list-text="o">
              <p>
                Results will contain URLs with either term, the same as the
                above query
              </p>
            </li>
          </ul>
        </li>
        <li data-list-text="">
          <p>
            Query is for <i>test AND text</i>
          </p>
          <ul id="l18">
            <li data-list-text="o">
              <p>
                Results will contain <u>only</u> URLs with both terms
              </p>
            </li>
          </ul>
        </li>
        <li data-list-text="">
          <p>
            Query is for <i>text NOT text</i>
          </p>
          <ul id="l19">
            <li data-list-text="o">
              <p>
                Results will contain URLs containing <i>test</i>, that{" "}
                <u>do not</u> contain the term <i>text</i>
              </p>
              <p>
                <br />
              </p>
              <p>Examples:</p>
            </li>
          </ul>
        </li>
        <li data-list-text="">
          <p>
            Loan travel
            <span>
              is used, and URLs that contain either term are returned.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <span>
              <img
                alt="url search"
                src="/ng/images/help/urlsearchimages/Image_019.jpg"
              />
            </span>
          </p>
        </li>
        <li data-list-text="">
          <p>
            NOT Cra
            <span>
              operator cannot be used with only one term. In the results below,
              you can see the term is clearly included.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <span>
              <img
                alt="url search"
                src="/ng/images/help/urlsearchimages/Image_020.jpg"
              />
            </span>
          </p>
          <p>
            <a>Regex</a>
          </p>
          <p>
            <br />
          </p>
          <p>
            Regex can be used as well to search the URLs. To use Regex with URL
            search, you must surround the query with forward slashes.
          </p>
          <p>
            <br />
          </p>
          <p>Use Cases:</p>
        </li>
        <li data-list-text="">
          <p>
            Searching for <i>interac </i>with a common misspelling <i>intrac</i>
          </p>
          <ul id="l20">
            <li data-list-text="o">
              <p>
                Query is <i>/int(e?)rac/</i>
              </p>
            </li>
          </ul>
        </li>
        <li data-list-text="">
          <p>
            Searching for different spellings of <i>bank</i>
          </p>
          <ul id="l21">
            <li data-list-text="o">
              <p>
                Query is <i>/ban((k)|(que))/</i>
              </p>
              <p>
                <br />
              </p>
              <p>Examples:</p>
            </li>
          </ul>
        </li>
        <li data-list-text="">
          <p>
            I wanted to search for misspellings of bank. Searching with the
            query <i>/ban((k)|(que))/ </i>I received the following in my
            results:
          </p>
          <p>
            <br />
          </p>
          <p>
            <span>
              <img
                alt="url search"
                src="/ng/images/help/urlsearchimages/Image_021.jpg"
              />
            </span>
          </p>
          <p>
            <br />
          </p>
          <p>
            <a>
              <span>
                <img
                  alt="url search"
                  src="/ng/images/help/urlsearchimages/Image_022.jpg"
                />
              </span>
            </a>
          </p>
          <p>
            <br />
          </p>
          <p>
            <span>
              <img
                alt="url search"
                src="/ng/images/help/urlsearchimages/Image_023.jpg"
              />
            </span>
          </p>
          <p>
            <br />
          </p>
        </li>
        <li data-list-text="">
          <p>
            Sometimes abbreviations are used in phishing links. To search for
            both <i>scotia </i>and <i>sco </i>I used the query{" "}
            <i>/sco(tia)?/ </i>which returned results for both <i>scotia </i>as
            well as <i>sco</i>
          </p>
          <p>
            <br />
          </p>
          <p>
            <span>
              <img
                alt="url search"
                src="/ng/images/help/urlsearchimages/Image_024.jpg"
              />
            </span>
          </p>
          <p>Dashes</p>
          <p>
            <br />
          </p>
          <p>
            Dashes can be searched for in a string literal but must be quoted
            with the quotation marks escaped. If included as a part of a term,
            they will not be included.
          </p>
          <p>
            <br />
          </p>
          <p>Use Cases:</p>
        </li>
        <li data-list-text="">
          <p>
            Query is for <i>test-text</i>
          </p>
          <ul id="l22">
            <li data-list-text="o">
              <p>
                Results will include URLs containing either term as though the
                query had been <i>test text</i>
              </p>
              <p>
                or <i>text OR text</i>
              </p>
            </li>
          </ul>
        </li>
        <li data-list-text="">
          <p>
            Query is for <i>test AND – AND text</i>
          </p>
          <ul id="l23">
            <li data-list-text="o">
              <p>
                Results will include URLs containing test, - , and text, but not
                necessarily in that order
              </p>
            </li>
          </ul>
        </li>
        <li data-list-text="">
          <p>Query is for \&quot;test-text\&quot;</p>
          <ul id="l24">
            <li data-list-text="o">
              <p>Results will be for the exact string match test-text</p>
              <p>
                <br />
              </p>
              <p>Examples:</p>
            </li>
          </ul>
        </li>
        <li data-list-text="">
          <p>
            I wanted to search specifically for the string <i>cra-arc</i>. In
            order to obtain this in that exact order, the query was{" "}
            <i>\”cra-arc\” </i>so the double quotes would pass to the query
            engine
          </p>
        </li>
      </ul>
      <p>
        <br />
      </p>
      <p>
        <span>
          <img
            alt="url search"
            src="/ng/images/help/urlsearchimages/Image_025.jpg"
          />
        </span>
      </p>
      <p>
        <a>Exploring Search Results</a>
      </p>
      <p>
        <br />
      </p>
      <p>
        <span>
          <img
            alt="url search"
            src="/ng/images/help/urlsearchimages/Image_026.jpg"
          />
        </span>
      </p>
      <p>
        <br />
      </p>
      <p>
        Results page search can also be used to search other columns as well
      </p>
      <p>
        <br />
      </p>
      <p>
        <br />
      </p>
      <p>
        <span>
          <img
            alt="url search"
            src="/ng/images/help/urlsearchimages/Image_027.jpg"
          />
        </span>
      </p>
      <p>
        <br />
      </p>
      <p>
        <span>
          <img
            alt="url search"
            src="/ng/images/help/urlsearchimages/Image_028.jpg"
          />
        </span>
      </p>
      <p>
        <br />
      </p>
      <p>
        Search results can be narrowed down using the search bar in the results
        window
      </p>
      <p>
        <br />
      </p>
      <p>
        <br />
      </p>
      <p>
        <span>
          <img
            alt="url search"
            src="/ng/images/help/urlsearchimages/Image_029.jpg"
          />
        </span>
      </p>
    </>
  );
}
