import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Link, Typography } from "@material-ui/core";

import PageCenter from "../../../../commons/components/layout/pages/PageCenter";
import { isExternal } from "../../helpers/ServerProperties";

export const AssemblyLineBanner = () => {
  const { t } = useTranslation();

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignContent="space-between"
        minHeight={{ xs: "30rem", sm: "35rem" }}
      >
        <Box
          flex={1}
          display="flex"
          justifyContent="center"
          flexDirection="column"
        >
          <PageCenter>
            <Box
              my={"3rem"}
              display="flex"
              flexDirection="row"
              alignItems="center"
              alignSelf="center"
              justifyContent="center"
            >
              <img
                alt="AssemblyLine Logo"
                src="/ng/images/assembly_line_logo.svg"
                style={{ maxWidth: "98vw", maxHeight: "25vh" }}
              />
            </Box>
            <Typography variant="h5">
              {t(
                "To upload potentially malicious artifacts please submit through AssemblyLine."
              )}
            </Typography>
            <Box pt="1rem">
              <Link
                href="https://malware.cyber.gc.ca/"
                target="_blank"
                rel="noopener"
                style={{ textDecoration: "none" }}
              >
                <Button variant="contained" color="primary">
                  {t("Submit")}
                </Button>
              </Link>
            </Box>
          </PageCenter>
        </Box>
        {isExternal() && (
          <Box
            display="flex"
            flexDirection="column"
            alignSelf="center"
            textAlign="center"
          >
            <Typography>
              <span>
                {t(
                  "To submit malicious artifacts anonymously instead, please click"
                )}
              </span>{" "}
              <Link
                href="https://portal-portail.cyber.gc.ca/en/file-upload/"
                target="_blank"
                rel="noopener"
              >
                <span>{t("here")}</span>
              </Link>
              .
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};
