import React from "react";
import { cloneDeep } from "lodash";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InfoIcon from "@material-ui/icons/Info";
import {
  SimpleTableCardView,
  SimpleTableView,
} from "../../common/SimpleTableView";

import {
  pendoraModel,
  pendoraReportExportModel,
  pendoraReportImportModel,
  pendoraReportResourceInfoModel,
} from "../../../../models/report/md5/pendoraModel";

import { useStyles } from "../../common/sharedStyles";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { LinkWrap } from "../../common/LinkWrap";

const md5 = require("md5");

export type PendoraViewProps = {
  pendoraReport: pendoraModel;
  testing?: boolean;
};

export function PendoraView({ pendoraReport, testing }: PendoraViewProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box>
      <Card>
        <CardHeader className={classes.ch} title={t("PEndora Report")} />
        <CardContent>
          <TableContainer>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell>{t("Analysis Date")}</TableCell>
                  <TableCell>{pendoraReport.creationDate}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("Report Version")}</TableCell>
                  <TableCell>{pendoraReport.version}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>MD5</TableCell>
                  <TableCell>{pendoraReport.md5}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>SHA1</TableCell>
                  <TableCell>{pendoraReport.report.sha1}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>SHA256</TableCell>
                  <TableCell>{pendoraReport.report.sha256}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("Tool Version")}</TableCell>
                  <TableCell>{pendoraReport.engineVersion}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("File Type")}</TableCell>
                  <TableCell>{pendoraReport.report.filetype}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("File Name")}</TableCell>
                  <TableCell>{pendoraReport.report.filename}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("Import Hash")}</TableCell>
                  <TableCell>{pendoraReport.report.importHash}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("Timestamp")}</TableCell>
                  <TableCell>{pendoraReport.report.timestamp}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("Entry Point")}</TableCell>
                  <TableCell>{pendoraReport.report.entryPoint}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{"PEID Packers"}</TableCell>
                  <TableCell>
                    {pendoraReport.report.peidPackers?.join(", ")}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("Result")}</TableCell>
                  <TableCell>{pendoraReport.result}</TableCell>
                </TableRow>
                {!!pendoraReport.messages && !!pendoraReport.messages.length && (
                  <TableRow>
                    <TableCell>{t("Message")}</TableCell>
                    <TableCell>
                      {pendoraReport.messages[0].type +
                        " " +
                        pendoraReport.messages[0].message}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>

      {/* TODO - MAYBE - Put these Cards in Accoridans afterwards*/}
      {/* TODO - add icons - get images somehow & do File Pivots */}

      <Box mt={2}>
        <Card>
          <CardHeader className={classes.ch} title={t("Version Information")} />
          <CardContent>
            {pendoraReport.report.versionInfo ? (
              <TableContainer>
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell>{t("Compiled Script")}</TableCell>
                      <TableCell>
                        {pendoraReport.report.versionInfo.CompiledScript}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t("Translation")}</TableCell>
                      <TableCell>
                        {pendoraReport.report.versionInfo.Translation}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t("File Description")}</TableCell>
                      <TableCell>
                        {pendoraReport.report.versionInfo.FileDescription}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t("File Version")}</TableCell>
                      <TableCell>
                        {pendoraReport.report.versionInfo.FileVersion}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              t("None")
            )}
          </CardContent>
        </Card>
      </Box>

      {/* 

      something like this:

Object.keys(a).map(k=>({[k]:[{functionName: 'a', location:'a', guessed: true, ordinal: -1}]})).reduce(function(result, item, index, array) {
  
result[Object.keys(item)[0]] = Object.values(item)[0]; //a, b, c
  return result;
}, {})

*/}

      <Box mt={2}>
        <ImportsView
          imports={
            pendoraReport.report.imports
              ? pendoraReport.report.imports
              : pendoraReport.report.Imports
              ? Object.keys(pendoraReport.report.Imports)
                  .map((k) => ({
                    [k]: [
                      {
                        functionName: Object.keys(
                          pendoraReport.report.Imports[k]
                        )[0],
                        location:
                          "" +
                          Object.values(pendoraReport.report.Imports[k])[0],
                        guessed: false,
                        ordinal: "-1",
                      },
                    ],
                  }))
                  .reduce(function (result, item, index, array) {
                    result[Object.keys(item)[0]] = Object.values(item)[0]; //a, b, c
                    return result;
                  }, {})
              : {}
          }
          testing={testing}
        />
      </Box>
      <Box mt={2}>
        <SimpleTableCardView
          testing={testing}
          title={t("Exports")}
          data={Object.keys(
            pendoraReport.report.exports ? pendoraReport.report.exports : {}
          ).map((k) => ({
            name: pendoraReport.report.exports[k].name,
            ordinal: pendoraReport.report.exports[k].ordinal,
            address: pendoraReport.report.exports[k].address,
          }))}
          headers={[
            { title: t("Name"), field: "name" },
            { title: t("Ordinal"), field: "ordinal" },
            { title: t("Address"), field: "address" },
          ]}
        />
      </Box>
      <Box mt={2}>
        <ResourceInfo
          resourceInfo={
            pendoraReport.report.resourceInfo
              ? pendoraReport.report.resourceInfo
              : []
          }
        />
      </Box>
      <Box mt={2}>
        <SimpleTableCardView
          testing={testing}
          title={t("Debug Paths")}
          data={
            pendoraReport.report.debugPaths
              ? cloneDeep(pendoraReport.report.debugPaths)
              : []
          }
          headers={[
            { title: t("Path"), field: "name" },
            { title: t("CV Signature"), field: "cvsignature" },
            { title: "Signature", field: "signature" },
            { title: "Age", field: "age" },
          ]}
        />
      </Box>
      <Box mt={2}>
        <SimpleTableCardView
          testing={testing}
          title={t("Icons")}
          data={
            pendoraReport.report.icons
              ? pendoraReport.report.icons.map((val) => {
                  return {
                    iconMd5: val.iconMd5,
                    dimension: val.width + "x" + val.height,
                    info:
                      "ID:" +
                      val.id +
                      " Group: " +
                      val.group +
                      " Mode: " +
                      val.mode,
                  };
                })
              : []
          }
          headers={[
            { title: t("MD5"), field: "iconMd5" },
            { title: t("Dimension"), field: "dimension" },
            { title: t("Info"), field: "info" },
          ]}
        />
      </Box>
      <Box mt={2}>
        <SimpleTableCardView
          testing={testing}
          title={t("Sections")}
          data={
            pendoraReport.report.sections
              ? cloneDeep(pendoraReport.report.sections)
              : pendoraReport.report.Sections
              ? Object.keys(pendoraReport.report.Sections).map((k) => ({
                  name: k,
                  virtualAddress: pendoraReport.report.Sections[k][0]
                    ? pendoraReport.report.Sections[k][0]
                    : null,
                  virtualSize: pendoraReport.report.Sections[k][1]
                    ? pendoraReport.report.Sections[k][1]
                    : null,
                  rawSize: pendoraReport.report.Sections[k][2]
                    ? pendoraReport.report.Sections[k][2]
                    : null,
                }))
              : []
          }
          headers={[
            { title: t("Name"), field: "name" },
            { title: t("Virtual Address"), field: "virtualAddress" },
            { title: t("Virtual Size"), field: "virtualSize" },
            { title: t("Raw Size"), field: "rawSize" },
          ]}
        />
      </Box>
      <Box mt={2}>
        <Card>
          <CardHeader className={classes.ch} title={t("URLs")} />
          <CardContent>
            {pendoraReport.report.urls && pendoraReport.report.urls.length ? (
              <List dense>
                {pendoraReport.report.urls.map((url, i) => (
                  <ListItem key={i}>
                    <Link to={["/url", md5(url), "report"].join("/")}>
                      <LinkWrap>{url}</LinkWrap>
                    </Link>
                  </ListItem>
                ))}
              </List>
            ) : (
              t("None")
            )}
          </CardContent>
        </Card>
      </Box>
      <Box mt={2}>
        <Card>
          <CardHeader className={classes.ch} title={t("IPs")} />
          <CardContent>
            {pendoraReport.report.ips && pendoraReport.report.ips.length ? (
              <List dense>
                {pendoraReport.report.ips.map((ip, i) => (
                  <ListItem key={i}>
                    <Link to={["/ip", ip, "report"].join("/")}>
                      <LinkWrap>{ip}</LinkWrap>
                    </Link>
                  </ListItem>
                ))}
              </List>
            ) : (
              t("None")
            )}
          </CardContent>
        </Card>
      </Box>
      <Box mt={2}>
        <Card>
          <CardHeader className={classes.ch} title={t("Hosts")} />
          <CardContent>
            <Box className={classes.info}>
              <InfoIcon />{" "}
              {t("This section is likely to contain false positives")}
            </Box>
            {pendoraReport.report.hosts && pendoraReport.report.hosts.length ? (
              <List dense>
                {pendoraReport.report.hosts.map((host, i) => (
                  <ListItem key={i}>
                    <Link to={["/domain", host, "report"].join("/")}>
                      <LinkWrap>{host}</LinkWrap>
                    </Link>
                  </ListItem>
                ))}
              </List>
            ) : (
              t("None")
            )}
          </CardContent>
        </Card>
        {/* <SimpleStringArrayCard
          title={t("Hosts")}
          array={
            pendoraReport.report.hosts
              ? pendoraReport.report.hosts
              : pendoraReport.report.Hosts
              ? pendoraReport.report.Hosts
              : []
          }
          
        /> */}
      </Box>
      <Box mt={2}>
        <SimpleStringArrayCard
          title={t("Anti-Analysis Strings")}
          array={
            pendoraReport.report.antiAnalysisStrings
              ? pendoraReport.report.antiAnalysisStrings
              : pendoraReport.report.AntiAnalysisStrings
              ? pendoraReport.report.AntiAnalysisStrings
              : []
          }
        />
      </Box>
      <Box mt={2}>
        <SimpleStringArrayCard
          title={t("TLS Callbacks")}
          array={
            pendoraReport.report.tlsCallBacks
              ? pendoraReport.report.tlsCallBacks
              : []
          }
        />
      </Box>
      <Box mt={2}>
        <CertChainCard
          title={t("Certification Chain")}
          certChain={
            pendoraReport.report.certChain ? pendoraReport.report.certChain : ""
          }
        />
      </Box>
    </Box>
  );
}

type ResourceInfoProps = {
  resourceInfo: pendoraReportResourceInfoModel[];
};

function ResourceInfo({ resourceInfo }: ResourceInfoProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Card>
      <CardHeader className={classes.ch} title={t("Resources Information")} />
      <CardContent>
        <Box>
          {resourceInfo && resourceInfo.length
            ? resourceInfo.map((val, i) => {
                // TODO - Verify - i assume if empty==true, don't show it?
                if (val.empty) return null;
                return (
                  <Accordion key={"resoruceinfo" + i}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={"panel-content-" + i}
                      id={"panel-header-" + i}
                    >
                      <Typography className={classes.accordianheading}>
                        {"Resource " + i}
                      </Typography>
                      <Typography className={classes.accorianSecondaryHeading}>
                        {val.FileType +
                          " - " +
                          val.Type +
                          " (" +
                          val.Size +
                          ")"}
                      </Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                      <TableContainer>
                        <Table size="small">
                          <TableBody>
                            <TableRow>
                              <TableCell>{t("File Type")}</TableCell>
                              <TableCell>{val.FileType}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>{t("Language")}</TableCell>
                              <TableCell>{val.Language}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>{t("Offset")}</TableCell>
                              <TableCell>{val.Offset}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>{t("Size")}</TableCell>
                              <TableCell>{val.Size}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>{t("Sub-language")}</TableCell>
                              <TableCell>{val["Sub-language"]}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>{t("Type")}</TableCell>
                              <TableCell>{val.Type}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </AccordionDetails>
                  </Accordion>
                );
              })
            : t("None")}
        </Box>
      </CardContent>
    </Card>
  );
}

type SimpleStringArrayCardProps = {
  helpText?: string;
  title: string;
  array: string[];
};

function SimpleStringArrayCard({
  helpText,
  title,
  array,
}: SimpleStringArrayCardProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Card>
      <CardHeader className={classes.ch} title={title} />
      <CardContent className={classes.breakword}>
        {helpText ? (
          <Box mb={3} className={classes.info}>
            <InfoIcon /> {helpText}
          </Box>
        ) : null}

        {array.length === 0 ? t("None") : array.join(", ")}
      </CardContent>
    </Card>
  );
}

type CertChainCardProps = {
  helpText?: string;
  title: string;
  certChain: string;
};

function CertChainCard({ helpText, title, certChain }: CertChainCardProps) {
  const classes = useStyles();
  const [showChain, setShowChain] = React.useState(false);
  const { t } = useTranslation();

  return (
    <Card>
      <CardHeader className={classes.ch} title={title} />
      <CardContent>
        {helpText ? (
          <Box mb={3} className={classes.info}>
            <InfoIcon /> {helpText}
          </Box>
        ) : null}
        {certChain ? (
          <>
            <Button onClick={() => setShowChain(!showChain)}>
              {showChain ? t("Hide") : t("Show")} {t("Certificate Chain")}
            </Button>
            <pre
              style={showChain ? null : { display: "none" }}
              className={classes.code}
            >
              {certChain}
            </pre>
          </>
        ) : (
          t("None")
        )}
      </CardContent>
    </Card>
  );
}

type ImportsViewProps = {
  imports: pendoraReportImportModel | pendoraReportExportModel;
  title?: string;
  testing?: boolean;
};

// TODO - make this view prettier - the Tabs (e.g. vertical tabs or horizontal tabs)
//    do not work in mobile mode for some reason
function ImportsView({ imports, title, testing }: ImportsViewProps) {
  const classes = useStyles();
  const [view, setView] = React.useState(0);
  const { t } = useTranslation();

  return (
    <Card>
      <CardHeader
        className={classes.ch}
        title={
          (title ? title : t("Imports")) +
          " (" +
          Object.keys(imports).length +
          ")"
        }
      />
      <CardContent>
        {imports && Object.keys(imports).length ? null : <Box>{t("None")}</Box>}

        {imports && Object.keys(imports).length
          ? Object.keys(imports).map((val, i) => {
              return (
                <Button
                  key={"tabpanels" + i}
                  onClick={() => setView(i)}
                  variant={view === i ? "outlined" : "text"}
                  size={view === i ? "large" : "medium"}
                >
                  {val}
                </Button>
              );
            })
          : null}

        {imports && Object.keys(imports).length
          ? Object.keys(imports).map((val, i) => {
              return (
                <Box key={"tabpanels" + i} hidden={view !== i} mt={2}>
                  <SimpleTableView
                    testing={testing}
                    title={val}
                    data={imports[val].map((v) => {
                      return {
                        functionName: v.functionName,
                        location: v.location,
                        ordinal: v.ordinal,
                        guessed: v.guessed ? t("Yes") : t("No"),
                      };
                    })}
                    headers={[
                      { title: t("Function Name"), field: "functionName" },
                      { title: t("Location"), field: "location" },
                      { title: t("Ordinal"), field: "ordinal" },
                      {
                        title: t("Name guesssed by the System?"),
                        field: "guessed",
                      },
                    ]}
                  />
                </Box>
              );
            })
          : null}
      </CardContent>
    </Card>
  );
}
