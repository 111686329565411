import React from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
} from "@material-ui/core";
import { useStyles } from "components/beaver/components/report/common/sharedStyles";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // TODO - You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    console.log(error);
    console.log(errorInfo);

    // You can use this.navigate to navigate to a different route if needed.
    const { navigate, location } = this.props;
    navigate(location.pathname);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <WentWrong />;
    }

    return this.props.children;
  }
}

function ErrorBoundaryWithHooks(props) {
  const navigate = useNavigate();
  const location = useLocation();

  return <ErrorBoundary {...props} navigate={navigate} location={location} />;
}

function WentWrong() {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const handleRetry = () => {
    // You can use navigate to reload the current route or navigate to a different one.
    navigate(location.pathname);
  };

  return (
    <Box pt={4}>
      <Card>
        <CardHeader
          className={classes.ch}
          avatar={<Avatar className={classes.errorIcon}>!</Avatar>}
          title={t("Something Went Wrong")}
        />
        <CardContent>
          <p>
            {t(
              "An error occurred in rendering this page. You can click on Retry below or report the problem to the Beaver / INO team."
            )}
          </p>
        </CardContent>
        <CardActions>
          <Button size="small" onClick={handleRetry}>
            {t("Retry")}
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
}

export default ErrorBoundaryWithHooks;
