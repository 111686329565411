import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";

import { vizModel } from "../../../../models/report/md5/vizModel";

import { SamplePreview } from "./PreviewSampleView";

import { useStyles } from "../../common/sharedStyles";
import { useTranslation } from "react-i18next";

export type VizViewProps = {
  canPreview: boolean;
  vizReport: vizModel;
};

export function VizView({ canPreview, vizReport }: VizViewProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box>
      <Card>
        <CardHeader className={classes.ch} title={t("Viz Report")} />
        <CardContent>
          <TableContainer>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell>{t("Analysis Date")}</TableCell>
                  <TableCell>{vizReport.creationDate}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>MD5</TableCell>
                  <TableCell>{vizReport.md5}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("Result")}</TableCell>
                  <TableCell>{vizReport.result}</TableCell>
                </TableRow>
                {!!vizReport.messages && !!vizReport.messages.length && (
                  <TableRow>
                    <TableCell>{t("Message")}</TableCell>
                    <TableCell>
                      {vizReport.messages[0].type +
                        " " +
                        vizReport.messages[0].message}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
      <Box mt={2}>
        <Card elevation={2}>
          <CardHeader className={classes.ch} title={t("Code")} />
          <CardContent>
            <SamplePreview
              md5={vizReport.md5}
              canPreview={canPreview}
              vizData={vizReport.code}
            />
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
}
