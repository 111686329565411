import React, { useEffect, useState } from "react";
import { useStyles } from "../../common/sharedStyles";
import { useTranslation } from "react-i18next";
import { URLMD5ClusterData } from "../../../../models/report/url/urlMd5ClusterData";
import {
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import { SimpleTableView } from "../../common/SimpleTableView";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { LinkWrap } from "../../common/LinkWrap";
import _ from "lodash";
import { Flag } from "components/beaver/helpers/Flag";

export type URLMD5ClusterReportProps = {
  data: URLMD5ClusterData;
  testing?: boolean;
  clusterType: string;
  subcluster?: string;
};

export function URLMD5ClusterReport({
  data,
  testing,
  clusterType,
  subcluster,
}: URLMD5ClusterReportProps) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [title, setTitle] = useState<string | never>(
    t("URL MD5 Cluster Report")
  );
  const [reports, setReports] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setReports(data.urlMD5ClusterDetails);
    setTitle(
      data.urlMD5ClusterDetails[0]
        ? data.urlMD5ClusterDetails[0].clusterName
        : "N/A"
    );
  }, [data]);

  return (
    <Box>
      <Grid container>
        <Grid item xs={11} lg={7}>
          <Card>
            <CardHeader title={t("Summary")} className={classes.ch} />
            <CardContent>
              <TableContainer>
                <Table size="small" style={{ marginTop: "0.3em" }}>
                  <TableBody>
                    <TableRow>
                      <TableCell>{t("Cluster")}</TableCell>
                      <TableCell>
                        <Badge
                          color="secondary"
                          badgeContent={data.totalArtifacts}
                          max={10000}
                        >
                          <Chip color="primary" label={title} />
                        </Badge>
                      </TableCell>
                    </TableRow>
                    {!!subcluster && (
                      <TableRow>
                        <TableCell>
                          <b>{t("Subcluster")}</b>
                        </TableCell>
                        <TableCell>
                          <b>{subcluster}</b>
                        </TableCell>
                      </TableRow>
                    )}
                    {!!data.subclusters && (
                      <TableRow>
                        <TableCell>{t("Subclusters")}</TableCell>
                        <TableCell>
                          {/* need these as chips with numbers */}
                          {Object.keys(data.subclusters).map((s, i) => (
                            <Box mr={0.5} style={{ display: "inline" }} key={i}>
                              <Link
                                to={
                                  [
                                    "/cluster",
                                    data.urlMD5ClusterDetails[0].clusterName,
                                    "report",
                                  ].join("/") +
                                  "?subcluster=" +
                                  s
                                }
                              >
                                <LinkWrap>
                                  <Badge
                                    color="secondary"
                                    badgeContent={data.subclusters[s]}
                                    max={10000}
                                  >
                                    <Chip label={s} clickable color="primary" />
                                  </Badge>
                                </LinkWrap>
                              </Link>
                            </Box>
                          ))}
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell>{t("Artifacts Total")}</TableCell>
                      <TableCell>{data.totalArtifacts}</TableCell>
                    </TableRow>
                    {!!data.clusterDst &&
                      Object.keys(data.clusterDst).map((d, i) => (
                        <TableRow key={i}>
                          <TableCell>{_.upperFirst(_.toLower(d))}</TableCell>
                          <TableCell>{data.clusterDst[d]}</TableCell>
                        </TableRow>
                      ))}
                    {!!data.cpanelArtifactTotal && (
                      <TableRow>
                        <TableCell>{t("CPanel Artifacts")}</TableCell>
                        <TableCell>{data.cpanelArtifactTotal}</TableCell>
                      </TableRow>
                    )}
                    {!!data.phishingKitArtifactTotal && (
                      <TableRow>
                        <TableCell>
                          {t("Phishing/Smishing Artifacts")}
                        </TableCell>
                        <TableCell>{data.phishingKitArtifactTotal}</TableCell>
                      </TableRow>
                    )}
                    {!!data.phishingKitArtifactTotal && (
                      <TableRow>
                        <TableCell>{t("Phishing Kit Artifacts")}</TableCell>
                        <TableCell>{data.phishingKitArtifactTotal}</TableCell>
                      </TableRow>
                    )}
                    {!!data.phishingSmishingTotalCountrySector &&
                      !!data.phishingSmishingTotalCountrySector.country && (
                        <TableRow>
                          <TableCell>{t("Country")}</TableCell>
                          <TableCell>
                            <Flag
                              countryCode={
                                data.phishingSmishingTotalCountrySector.country
                              }
                              style={{ maxHeight: "1.1rem" }}
                            />
                          </TableCell>
                        </TableRow>
                      )}
                    {!!data.phishingSmishingTotalCountrySector &&
                      !!data.phishingSmishingTotalCountrySector.sector && (
                        <TableRow>
                          <TableCell>{t("Sector")}</TableCell>
                          <TableCell>
                            {data.phishingSmishingTotalCountrySector.sector}
                          </TableCell>
                        </TableRow>
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Box mt={2}>
        <Card>
          <CardHeader
            title={
              (i18n.language.includes("en") ? t(clusterType) + " " : "") +
              t("URLs") +
              (i18n.language.includes("fr")
                ? t(clusterType + ".seperator") + t(clusterType)
                : "")
            }
            className={classes.ch}
          />
          <CardContent>
            {!!subcluster && (
              <p>
                {t("Only showing results for cluster")} '{title}'{" "}
                {t("having subcluster")} '{subcluster}'{" "}
                <Button
                  color="primary"
                  onClick={() => navigate(location.pathname)}
                >
                  {t("Show All")}
                </Button>
              </p>
            )}
            <SimpleTableView
              testing={testing}
              data={reports.map((r) => {
                return { ...r };
              })}
              headers={[
                {
                  title: t("URL MD5"),
                  field: "md5",
                  render: (rowData) => (
                    <Link
                      className={classes.breakword}
                      to={["/url", rowData.md5, "report"].join("/")}
                    >
                      <LinkWrap>{rowData.md5}</LinkWrap>
                    </Link>
                  ),
                },
                {
                  title: t("URL"),
                  field: "url",
                  render: (rowData) => (
                    <Link
                      className={classes.breakword}
                      to={["/url", rowData.md5, "report"].join("/")}
                    >
                      <LinkWrap>{rowData.url}</LinkWrap>
                    </Link>
                  ),
                },
                {
                  title: t("Submission Type"),
                  field: "submissionType",
                },
                {
                  title: t("Cluster Name"),
                  field: "clusterName",
                  render: (row) =>
                    row.clusterName ? (
                      <Link
                        to={["/cluster", row.clusterName, "report"].join("/")}
                      >
                        <LinkWrap>
                          <Badge
                            color="secondary"
                            badgeContent={data.totalArtifacts}
                            max={10000}
                          >
                            <Chip
                              label={row.clusterName}
                              clickable
                              color="primary"
                            />
                          </Badge>
                        </LinkWrap>
                      </Link>
                    ) : null,
                },
                {
                  title: t("Subcluster Name"),
                  field: "subClusterName",
                  render: (row) =>
                    row.subClusterName ? (
                      <Link
                        to={
                          ["/cluster", row.clusterName, "report"].join("/") +
                          "?subcluster=" +
                          row.subClusterName
                        }
                      >
                        <LinkWrap>
                          <Badge
                            color="secondary"
                            badgeContent={
                              row.subClusterName === data["subcluster"]
                                ? data.totalArtifacts
                                : data.subclusters[row.subClusterName]
                            }
                            max={10000}
                          >
                            <Chip
                              label={row.subClusterName}
                              clickable
                              color="primary"
                            />
                          </Badge>
                        </LinkWrap>
                      </Link>
                    ) : null,
                },
                {
                  title: t("Clustered On"),
                  field: "clusteredOn",
                  defaultSort: "desc",
                },
              ]}
            />
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
}
