import React from "react";
import { Link } from "react-router-dom";
import _, { cloneDeep } from "lodash";
import md5 from "md5";

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import {
  SimpleTableCardView,
  SimpleTableView,
} from "../../common/SimpleTableView";

import {
  JA3FingerprintModel,
  SandboxAlertModel,
} from "components/beaver/models/report/md5/md5ReportModel";

import { useStyles } from "../../common/sharedStyles";

import { useTranslation } from "react-i18next";
import { dbCNCMapModel } from "components/beaver/models/report/md5/dbCNCMapCalloutModel";
import { LinkWrap } from "../../common/LinkWrap";

export interface shadowServerModel {
  creationDate: string;
  md5: string;
}

export type ShadowServerViewProps = {
  reportType: string;
  shadowServerReport: shadowServerModel;
  JA3: JA3FingerprintModel[];
  SandboxAlerts: SandboxAlertModel[];
  callouts: dbCNCMapModel[];
  testing?: boolean;
};

//note:  this is also for ShadowServerZ and FireEye View!
// TODO - verify that the fireeye and shadowserverz views look right
export function ShadowServerCWView({
  reportType,
  shadowServerReport,
  JA3,
  SandboxAlerts,
  callouts,
  testing,
}: ShadowServerViewProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [httpcallout, setHttpcallout] = React.useState(false);

  return (
    <Box>
      <Card>
        <CardHeader
          className={classes.ch}
          title={
            reportType.startsWith("FE_")
              ? t("FireEye Report")
              : t(_.startCase(reportType) + " Report")
          }
        />
        <CardContent>
          <TableContainer>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell>{t("MD5")}</TableCell>
                  <TableCell>{shadowServerReport.md5}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("Analysis Date")}</TableCell>
                  <TableCell>{shadowServerReport.creationDate}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
      <Box mt={2}>
        <SandboxAlertView
          alerts={SandboxAlerts ? SandboxAlerts : []}
          testing={testing}
        />
      </Box>
      <Box mt={2}>
        <Card>
          <CardHeader className={classes.ch} title={t("Callouts")} />
          <CardContent>
            <Button
              onClick={() => setHttpcallout(false)}
              variant={!httpcallout ? "outlined" : "text"}
              size={!httpcallout ? "large" : "medium"}
            >
              {t("All")}
            </Button>
            <Button
              onClick={() => setHttpcallout(true)}
              variant={httpcallout ? "outlined" : "text"}
              size={httpcallout ? "large" : "medium"}
            >
              {t("Enriched HTTP")}
            </Button>
            {!httpcallout ? (
              <CalloutsView
                callouts={callouts ? callouts : []}
                testing={testing}
              />
            ) : (
              <EnrichedHttpCalloutsView
                callouts={callouts ? callouts : []}
                testing={testing}
              />
            )}
          </CardContent>
        </Card>
      </Box>

      <UniqueIdentifiersBox callouts={callouts} />

      <Box mt={2}>
        <JA3SignaturesView alerts={JA3 ? JA3 : []} testing={testing} />
      </Box>
    </Box>
  );
}

function ShowUniqueItems({ items }) {
  const { t } = useTranslation();
  return items && items.length ? (
    items.map((s, i) => <p key={i}>{s}</p>)
  ) : (
    <>{t("None")}</>
  );
}

// this one's got lots of stuff:  0ad25b86f1cbdf8baf6507c1aa6c24fa

// there is actual report, creation date (Analysis date), messages,

export type CalloutsViewProps = {
  callouts: dbCNCMapModel[];
  testing: boolean;
};

//TODO - still need to add the Callouts -> Enriched HTTP view
// for the unique identifiers, you take the serverResponse and map out the server stuff that's unique for server, user agent unique for user agents (damn)
const getUniqueItemFromCalloutParam = (
  callouts: dbCNCMapModel[],
  location: "channel" | "serverResponse",
  type: "Server" | "User-Agent"
): any[] => {
  return (
    callouts
      .filter((c) => c.callout.serverResponse)
      .flatMap((c) =>
        c.callout[location]
          .split("\r\n")
          .filter((d) => d.startsWith(type + ":"))
          .map((d) =>
            d
              .split(":")
              .filter((a, i) => i !== 0)
              .join(":")
              .trim()
          )
      )
      // find unique values
      .filter((v, i, a) => a.indexOf(v) === i)
  );
};

export type JA3SignaturesProps = {
  alerts: JA3FingerprintModel[];
  testing: boolean;
};

export function ColPicker({ allCols, cols, setCols, defaultColNames }) {
  const [changeCols, setChangeCols] = React.useState(false);
  const { t } = useTranslation();
  return (
    <>
      <Button
        style={changeCols ? { display: "none" } : null}
        onClick={() => setChangeCols(true)}
      >
        {t("Select Columns")}
      </Button>
      <Box style={!changeCols ? { display: "none" } : null}>
        {t("Columns")}:
        {allCols.map((r, i) => (
          <React.Fragment key={i}>
            <Checkbox
              checked={cols.includes(r.field)}
              onChange={(e) =>
                e.target.checked
                  ? setCols(cols.concat([r.field]))
                  : setCols(cols.filter((c) => c !== r.field))
              }
            />
            {r.title}
          </React.Fragment>
        ))}
        <Button onClick={() => setCols(defaultColNames)}>
          {t("Default Columns")}
        </Button>
        <Button onClick={() => setChangeCols(false)}>{t("Close")}</Button>
      </Box>
    </>
  );
}

export function EnrichedHttpCalloutsView({
  callouts,
  testing,
}: CalloutsViewProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const allCols: { title: string; field: string; render?: Function }[] = [
    {
      title: t("IP"),
      field: "ip",
      render: (r) => (
        <Link to={["/ip", r.ip, "report"].join("/")}>
          <LinkWrap>{r.ip}</LinkWrap>
        </Link>
      ),
    },
    {
      title: t("Callout"),
      field: "callout",
      render: (r) => (
        <Link to={["/domain", r.callout, "report"].join("/")}>
          <LinkWrap>{r.callout}</LinkWrap>
        </Link>
      ),
    },
    { title: t("Port"), field: "port" },
    { title: t("Protocol"), field: "protocol" },
    {
      title: t("Domain(s)"),
      field: "domain",
      render: (r) => (
        <Link to={["/domain", r.callout, "report"].join("/")}>
          <LinkWrap>{r.callout}</LinkWrap>
        </Link>
      ),
    },
    { title: t("Method"), field: "method" },
    { title: t("User Agent"), field: "userAgent" },
    { title: t("Content Type"), field: "contentType" },
    { title: t("Content Length"), field: "contentLength" },
    {
      title: t("Channel"),
      field: "channel",
      render: (r) => <span className={classes.breakword}>{r.channel}</span>,
    },
    {
      title: t("URL Report"),
      field: "url",
      render: (r) => (
        <Link to={["/url", md5(r.url), "report"].join("/")}>
          <LinkWrap>{r.url}</LinkWrap>
        </Link>
      ),
    },
    { title: t("Response Content Type"), field: "responseContentType" },
    { title: t("Response Content Length"), field: "responseContentLength" },
    { title: t("Server Response"), field: "serverResponse" },
  ];

  const defaultColNames = [
    "ip",
    "callout",
    "port",
    "protocol",
    "method",
    "contentType",
    "channel",
    "url",
    "responseContentType",
  ];

  const [cols, setCols] = React.useState(defaultColNames);

  const getCols = () => allCols.filter((r) => cols.includes(r.field));

  return (
    <>
      <ColPicker
        allCols={allCols}
        cols={cols}
        setCols={setCols}
        defaultColNames={defaultColNames}
      />
      <SimpleTableView
        testing={testing}
        data={callouts
          .filter((r) => r.httpCallout && r.httpCallout.responseParsed)
          .map((r) => r.httpCallout)
          .map((r) => ({
            ip: r.ip,
            callout: r.domain,
            port: r.port,
            protocol: r.protocols.join(", "),
            domain: r.domain,
            method: r.method,
            userAgent: r.requestHeaders
              .filter((r) => r.name === "User-Agent")
              .map((r) =>
                r.buffer.buffer.join("").replace(/^User-Agent: /, "")
              ),
            contentType: r.requestHeaders
              .filter((r) => r.name === "Content-Type")
              .map((r) =>
                r.buffer.buffer.join("").replace(/^Content-Type: /, "")
              ),
            contentLength: r.requestHeaders
              .filter((r) => r.name === "Content-Length")
              .map((r) =>
                r.buffer.buffer.join("").replace(/^Content-Length: /, "")
              ),
            channel: r.channel,
            url: r.url,
            responseContentType: r.responseHeaders
              .filter((r) => r.name === "Content-Type")
              .map((r) =>
                r.buffer.buffer.join("").replace(/^Content-Type: /, "")
              ),
            responseContentLength: r.responseHeaders
              .filter((r) => r.name === "Content-Length")
              .map((r) =>
                r.buffer.buffer.join("").replace(/^Content-Length: /, "")
              ),
            serverResponse: r.serverResponse,
          }))}
        headers={getCols()}
      />
    </>
  );
}

// there is malwarereport -> dbcncmap -> shadowservercw[0] with the callout and httpcallout stuff
// TODO - figure out the count - it looks like an aggregation of data
export function CalloutsView({ callouts, testing }: CalloutsViewProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <SimpleTableView
      testing={testing}
      data={cloneDeep(callouts)
        .map((r) => r.callout)
        .map((r) => ({
          ip: r.ip,
          callout: r.domain,
          port: r.port,
          protocol: r.protocols.join(", "),
          domains: r.domain,
          channel: r.channel,
        }))}
      headers={[
        {
          title: t("IP"),
          field: "ip",
          render: (r) => (
            <Link to={["/ip", r.ip, "report"].join("/")}>
              <LinkWrap>{r.ip}</LinkWrap>
            </Link>
          ),
        },
        {
          title: t("Callout"),
          field: "callout",
          render: (r) => (
            <Link to={["/domain", r.callout, "report"].join("/")}>
              <LinkWrap>{r.callout}</LinkWrap>
            </Link>
          ),
        },
        { title: t("Port"), field: "port" },
        { title: t("Protocol"), field: "protocol" },
        {
          title: t("Domain"),
          field: "domains",
          render: (r) => (
            <Link to={["/domain", r.domains, "report"].join("/")}>
              <LinkWrap>{r.domains}</LinkWrap>
            </Link>
          ),
        },
        {
          title: t("Channel"),
          field: "channel",
          render: (r) => <span className={classes.breakword}>{r.channel}</span>,
        },
      ]}
    />
  );
  // adding className={classes.breakword} to the render of channel makes the table too long.
}

// there is malwarereport->fingerprintspersandbox->shadowservercw with JA3 stuff
export function JA3SignaturesView({ alerts, testing }: JA3SignaturesProps) {
  const { t } = useTranslation();
  return (
    <SimpleTableCardView
      title={t("JA3 Signatures")}
      testing={testing}
      data={cloneDeep(alerts).map((r) => ({
        ja3Md5: r.fingerprint.md5,
        ip: r.fingerprint.destinationIP,
        domain: r.domains && r.domains.length ? r.domains[0].domain : "",
        domains: r.domains,
        port: r.fingerprint.destinationPort,
      }))}
      headers={[
        { title: "JA3 MD5", field: "ja3Md5" },
        {
          title: t("IP"),
          field: "ip",
          render: (r) => (
            <Link to={["/ip", r.ip, "report"].join("/")}>
              <LinkWrap>{r.ip}</LinkWrap>
            </Link>
          ),
        },
        {
          title: t("Domain"),
          field: "domain",
          render: (r) => (
            <>
              {r.domains.map((d, i) => (
                <React.Fragment key={i}>
                  <Link to={["/domain", d.domain, "report"].join("/")}>
                    <LinkWrap> {d.domain}</LinkWrap>
                  </Link>
                  {"  "}
                </React.Fragment>
              ))}
            </>
          ),
        },
        { title: t("Port"), field: "port" },
      ]}
    />
  );
}

export type SandboxAlertsProps = {
  alerts: SandboxAlertModel[];
  testing: boolean;
};

// there is malwareReport->alertsPerSandbox->Shadow_server_CW[0] with alerts part
export function SandboxAlertView({ alerts, testing }: SandboxAlertsProps) {
  const { t } = useTranslation();
  return (
    <SimpleTableCardView
      title={t("Alerts")}
      testing={testing}
      data={cloneDeep(alerts).map((r) => ({
        sid: r.rule.sid,
        msg: r.rule.msg,
        domain: r.domain && r.domain !== "UNKNOWN" ? r.domain : "",
        ip: r.ip,
        port: r.port,
      }))}
      headers={[
        {
          title: t("Rule SID"),
          field: "sid",
          render: (r) => (
            <Link to={["/sid", r.sid, "report"].join("/")}>
              <LinkWrap>{r.sid}</LinkWrap>
            </Link>
          ),
        },
        { title: t("Message"), field: "msg" },
        {
          title: t("Domain"),
          field: "domain",
          render: (r) => (
            <Link to={["/domain", r.domain, "report"].join("/")}>
              <LinkWrap>{r.domain}</LinkWrap>
            </Link>
          ),
        },
        {
          title: t("IP"),
          field: "ip",
          render: (r) => (
            <Link to={["/domain", r.ip, "report"].join("/")}>
              <LinkWrap>{r.ip}</LinkWrap>
            </Link>
          ),
        },
        { title: t("Port"), field: "port" },
      ]}
    />
  );
}

export type UniqueIdentifiersBoxProps = { callouts: dbCNCMapModel[] };

export function UniqueIdentifiersBox({ callouts }: UniqueIdentifiersBoxProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [userAgents, setUserAgents] = React.useState([]);
  const [servers, setServers] = React.useState([]);

  React.useEffect(() => {
    setUserAgents(
      getUniqueItemFromCalloutParam(
        callouts ? callouts : [],
        "channel",
        "User-Agent"
      )
    );
    setServers(
      getUniqueItemFromCalloutParam(
        callouts ? callouts : [],
        "serverResponse",
        "Server"
      )
    );
  }, [callouts]);

  return (
    <Box mt={2}>
      <Card>
        <CardHeader className={classes.ch} title={t("Unique Identifiers")} />
        <CardContent>
          {userAgents.length || servers.length ? (
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={4}>
                <Card>
                  <CardHeader className={classes.ch} title={t("User Agents")} />
                  <CardContent>
                    <ShowUniqueItems items={userAgents} />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Card>
                  <CardHeader className={classes.ch} title={t("Servers")} />
                  <CardContent>
                    <ShowUniqueItems items={servers} />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          ) : (
            t("None")
          )}
        </CardContent>
      </Card>
    </Box>
  );
}
