import React, { useEffect } from "react";
import _ from "lodash";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  LinearProgress,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";

import { useDispatch, useSelector } from "react-redux";
import {
  complianceSelector,
  ComplianceState,
  fetchCompliance,
} from "../../../../slices/compliance";

import { isExternal } from "components/beaver/helpers/ServerProperties";
import { ParsedError, parseErrorTitle } from "../report/common/ParsedError";
import { useStyles } from "components/beaver/components/report/common/sharedStyles";

export function Compliance() {
  const { t } = useTranslation();
  const classes = useStyles();
  // initialize the redux hook
  const dispatch = useDispatch();
  const [retry, setRetry] = React.useState(0);

  const { compliance, error }: ComplianceState =
    useSelector(complianceSelector);

  //load the compliance json
  useEffect(() => {
    if (!isExternal()) dispatch(fetchCompliance(compliance));
    else window.location.replace("/ng");
  }, [dispatch, compliance]);

  if (isExternal()) {
    return <p>{t("Not available")}!</p>;
  } else if (error) {
    return (
      <Box mt={2}>
        <Card>
          <CardHeader
            className={classes.ch}
            avatar={<Avatar className={classes.errorIcon}>!</Avatar>}
            title={parseErrorTitle(error)}
          />
          <CardContent>
            <ParsedError error={error} />
          </CardContent>
          <CardActions>
            <Button size="small" onClick={() => setRetry(retry + 1)}>
              {t("Retry")}
            </Button>
          </CardActions>
        </Card>
      </Box>
    );
  } else if (!error && !compliance) {
    return (
      <Box mt={2}>
        {t("Loading")} ...
        <LinearProgress />
      </Box>
    );
  } else {
    return (
      <>
        <Helmet>
          <title>BEAVER {t("Source Info")}</title>
          <meta name="description" content={t("page.home.description")} />
        </Helmet>
        <h2>{t("Source Compliance")}</h2>
        <p>
          {t(
            "Hover over a field for more information about it. For a more in depth usage guide to TLP, refer to"
          )}{" "}
          <Link href="https://www.us-cert.gov/tlp/" target="blank">
            https://www.us-cert.gov/tlp/
          </Link>
          {!!compliance.sourceComplianceLastUpdate && (
            <span style={{ float: "right" }}>
              {t("Last Updated")}:{" "}
              {[
                compliance.sourceComplianceLastUpdate?.year,
                ("" + compliance.sourceComplianceLastUpdate?.month).padStart(
                  2,
                  "0"
                ),
                ("" + compliance.sourceComplianceLastUpdate?.day).padStart(
                  2,
                  "0"
                ),
              ].join("/")}
            </span>
          )}
        </p>
        <Box>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell className={classes.stickyCell}>
                  <Tooltip
                    arrow
                    placement="top"
                    title={t("The name of the source")}
                  >
                    <Box>{t("Name")}</Box>
                  </Tooltip>
                </TableCell>
                <TableCell
                  className={classes.stickyCell}
                  style={{ borderRight: "0.1em solid lightgrey" }}
                >
                  <Tooltip
                    arrow
                    placement="top"
                    title={t("Abbreviations/Source ID's used")}
                  >
                    <Box>{t("Abbreviation")}</Box>
                  </Tooltip>
                </TableCell>
                <TableCell className={classes.stickyCell}>
                  <Tooltip
                    arrow
                    placement="top"
                    title={t("Whether or not the source ingests malware.")}
                  >
                    <Box>{t("Malware")}</Box>
                  </Tooltip>
                </TableCell>
                <TableCell className={classes.stickyCell}>
                  <Tooltip
                    arrow
                    placement="top"
                    title={t("Whether or not the source ingests spam.")}
                  >
                    <Box>Spam</Box>
                  </Tooltip>
                </TableCell>
                <TableCell className={classes.stickyCell}>
                  <Tooltip
                    arrow
                    placement="top"
                    title={t(
                      "Whether or not the source ingests domains and URLS."
                    )}
                  >
                    <Box>{t("Domains & URLs")}</Box>
                  </Tooltip>
                </TableCell>
                <TableCell className={classes.stickyCell}>
                  <Tooltip
                    arrow
                    placement="top"
                    title={t("Whether or not the source ingests PCAPS.")}
                  >
                    <Box>{t("PCAPs")}</Box>
                  </Tooltip>
                </TableCell>
                <TableCell className={classes.stickyCell}>
                  <Tooltip
                    arrow
                    placement="top"
                    title={t("Whether or not the source ingests Reports.")}
                  >
                    <Box>{t("Report")}</Box>
                  </Tooltip>
                </TableCell>
                <TableCell
                  className={classes.stickyCell}
                  style={{ borderRight: "0.1em solid lightgrey" }}
                >
                  <Tooltip
                    arrow
                    placement="top"
                    title={t("Whether or not the source ingests other types.")}
                  >
                    <Box>{t("Other")}</Box>
                  </Tooltip>
                </TableCell>
                <TableCell className={classes.stickyCell}>
                  <Tooltip
                    arrow
                    placement="top"
                    title={t(
                      "The classification of the source according to the Traffic Light Protocol"
                    )}
                  >
                    <Box>{t("Sharing TLP")}</Box>
                  </Tooltip>
                </TableCell>
                <TableCell className={classes.stickyCell}>
                  <Tooltip
                    arrow
                    placement="top"
                    title={t("Who is able to approve distribution")}
                  >
                    <Box>{t("Sharing Approval")}</Box>
                  </Tooltip>
                </TableCell>
                <TableCell className={classes.stickyCell}>
                  <Tooltip
                    arrow
                    placement="top"
                    title={t(
                      "Available for External Partners outside of the Government of Canada"
                    )}
                  >
                    <Box>{t("Sharing")} Ext</Box>
                  </Tooltip>
                </TableCell>
                <TableCell className={classes.stickyCell}>
                  <Tooltip
                    arrow
                    placement="top"
                    title={t("Available for Government of Canada")}
                  >
                    <Box>{t("Sharing")} GC</Box>
                  </Tooltip>
                </TableCell>
                <TableCell
                  className={classes.stickyCell}
                  style={{ borderRight: "0.1em solid lightgrey" }}
                >
                  <Tooltip
                    arrow
                    placement="top"
                    title={t("Available for Use by Internal Elements")}
                  >
                    <Box>{t("Sharing")} CD</Box>
                  </Tooltip>
                </TableCell>
                <TableCell className={classes.stickyCell}>
                  <Tooltip
                    arrow
                    placement="top"
                    title={t(
                      "The classification of the source analysis according to the Traffic Light Protocol"
                    )}
                  >
                    <Box>{t("Analysis TLP")}</Box>
                  </Tooltip>
                </TableCell>
                <TableCell className={classes.stickyCell}>
                  <Tooltip
                    arrow
                    placement="top"
                    title={t("Who is able approve distribution")}
                  >
                    <Box>{t("Analysis Approval")}</Box>
                  </Tooltip>
                </TableCell>
                <TableCell className={classes.stickyCell}>
                  <Tooltip
                    arrow
                    placement="top"
                    title={t(
                      "Available for External Partners outside of the Government of Canada"
                    )}
                  >
                    <Box>{t("Analysis")} Ext</Box>
                  </Tooltip>
                </TableCell>
                <TableCell className={classes.stickyCell}>
                  <Tooltip
                    arrow
                    placement="top"
                    title={t("Available for Government of Canada")}
                  >
                    <Box>{t("Analysis")} GC</Box>
                  </Tooltip>
                </TableCell>
                <TableCell className={classes.stickyCell}>
                  <Tooltip
                    arrow
                    placement="top"
                    title={t("Available for Use by Internal Elements")}
                  >
                    <Box>{t("Analysis")} CD</Box>
                  </Tooltip>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {compliance.sourceComplianceTable.map((row, i) => (
                <TableRow key={i}>
                  {row.map((item, j) => {
                    if (j === 1) return null;
                    else if (j === 0) {
                      return (
                        <TableCell key={i + "-" + j}>
                          {row[j + 1] ? (
                            <Link href={row[j + 1]} target="blank">
                              {item}
                            </Link>
                          ) : (
                            item
                          )}
                        </TableCell>
                      );
                    } else if ([9, 14].includes(j)) {
                      let tlp =
                        compliance.tlpValues[_.toUpper(item.split(" ")[0])];
                      return (
                        <TableCell key={i + "-" + j}>
                          {tlp ? (
                            <Tooltip arrow title={t(tlp.definition)}>
                              <Box>
                                <img src={"/ng/" + tlp.icon} alt="" />
                                {tlp.displayName}
                              </Box>
                            </Tooltip>
                          ) : (
                            item
                          )}
                        </TableCell>
                      );
                    } else {
                      return (
                        <TableCell
                          key={i + "-" + j}
                          style={
                            [2, 8, 13].includes(j)
                              ? { borderRight: "0.1em solid lightgrey" }
                              : null
                          }
                        >
                          {![3, 4, 5, 6, 7, 8, 11, 12, 13, 16, 17, 18].includes(
                            j
                          ) ? (
                            item
                          ) : item === "true" ? (
                            <CheckOutlinedIcon />
                          ) : null}
                        </TableCell>
                      );
                    }
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </>
    );
  }
}
