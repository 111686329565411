import { pandoraX509CertificateModel } from "./pandoraModel";
import { dbCNCMapModel } from "./dbCNCMapCalloutModel";
import { GeneratedTag, UserTag } from "./tags";

export interface sampleSummarySourceData {
  [x: string]: string;
}
export interface samplePreviewData {
  canPreview: boolean;
  preview?: string[];
  hexDump?: string[];
}

export interface sampleSummaryChildSample {
  md5: string;
  relationshipDetail: string;
  receivedDate: string;
}

export interface sampleSummaryParentSample {
  md5: string;
  relationshipDetail: string;
  receivedDate: string;
}

export enum md5ReportType {
  Summary = "SUMMARY",
  Viz = "TK_VIZ",
  ShadowServer = "SHADOW_SERVER",
  ShadowServerCW = "SHADOW_SERVER_CW",
  ShadowServerZ = "SHADOW_SERVER_Z",
  MetaSploit = "MS",
  FileType = "TK_FILE",
  Yara = "TK_YARA",
  Entropy = "TK_ENTROPY",
  Pendora = "TK_PENDORA",
  VirusTotal = "VT",
  Hash = "TK_HASH",
  AssemblyLine = "AL",
  Sunbelt = "SUNBELT",
}

// if in doubt (e.g. something new), throw it in static for now :)
export const md5ReportTypeMap = {
  signature: [
    md5ReportType.ShadowServer,
    md5ReportType.MetaSploit,
    md5ReportType.VirusTotal,
    md5ReportType.Yara,
  ],
  static: [
    md5ReportType.AssemblyLine,
    md5ReportType.Hash,
    md5ReportType.Viz,
    md5ReportType.Pendora,
    md5ReportType.Entropy,
    md5ReportType.FileType,
  ],
  dynamic: [
    "BEAR_VMWinXPProSP3InetSim",
    "BEAR_VMWin7Ent64SP1InetSim",
    "BEAR_VMWinXPProSP3InetSim_Extended",
    "BEAR_VMWin10Pro64InetSim",
    "BEAR_BMWinXPProSP3InetSim",
    "BEAR_BMWin7Ent64SP1InetSim",
    "BEAR_BMWin10Pro64InetSim",
    "BEAR_VMAndroid_API22_X86",
    "BEAR_VMLinux4_16_7",
    md5ReportType.ShadowServerZ,
    md5ReportType.ShadowServerCW,
    md5ReportType.Sunbelt,
    "FE_WIN7_X64_SP1",
    "FE_WINXP_SP3",
    "FE_WIN7_SP1",
    "FE_WIN10_X64",
  ],
};

export interface JA3FingerprintModel {
  fingerprint: {
    md5: string;
    string: string;
    destinationIP: string;
    destinationPort: string;
  };
  domains: {
    domain: string;
  }[];
}

export interface DNSQueriesModel {
  query: string;
  type: string;
  server: string;
  answers: string[];
  ttls: number[];
}

export interface SandboxAlertModel {
  rule: {
    source: string;
    sid: string;
    rev: string;
    msg: string;
    rawRule: string;
  };
  time: number;
  ip: string;
  port: number;
  domain?: string;
}

export interface MD5ResourcesModel {
  [type: string]: {
    [date: string]: { fileName: string; link: string; type: string }[];
  };
}

export interface sampleMalwareReport {
  quick?: boolean;
  canPreview: boolean;
  sampleType: string;
  possibleTypes: string[];
  types: string[];
  errors: any[];
  typeCompletions: object;
  sources: sampleSummarySourceData;
  parentSamples: sampleSummaryParentSample[];
  childSamples: sampleSummaryChildSample[];
  // the report type can be any of the model types.
  reports: { [key in keyof typeof md5ReportType]?: object[] };
  dbCNCMap: { [key in keyof typeof md5ReportType]?: dbCNCMapModel[] };
  fingerprintsPerSandbox?: {
    [key in keyof typeof md5ReportType]: JA3FingerprintModel[];
  };
  alertsPerSandbox?: {
    [key in keyof typeof md5ReportType]: SandboxAlertModel[];
  };
  reportAddedDates?: { [message: string]: string };
}

export interface yaraAssemblyLineRule {
  sampleMd5: string;
  addedOn: number;
  yaraRuleId: number;
  ruleId: string;
  ruleVersion: string;
  ruleDescription: string;
  ruleName: string;
  ruleSource: string;
  sharing: string;
  category: string;
  malware: string;
  malwareType: string;
  actorType: string;
}

export interface md5ReportModel {
  loaded: boolean;
  relationGraph: {
    md5: string;
  };
  malwareReport: sampleMalwareReport;
  pandoraX509Certificates: pandoraX509CertificateModel;
  generatedTags: GeneratedTag[];
  userTags: UserTag[];
  enableAddPriority: boolean;
  dnsQueries?: {
    [key in keyof typeof md5ReportType]: DNSQueriesModel[];
  };
  badgeValues?: {
    [key in keyof typeof md5ReportType]: number;
  };
  yaraAssemblyLineRules?: yaraAssemblyLineRule[];
  urlMD5DownloadedFrom?: {
    requestedUrlMd5: string;
    addedOn: number;
    url?: string;
  }[];
  resources?: MD5ResourcesModel;
}

/**
 * This function will convert something like:
 *   "Sample(super\u003dFileArtifact(super\u003dArtifact(type\u003dSAMPLE, source\u003dext, md5\u003d86385447ce35c120511647c1ade5cef9,
 *   receivedDate\u003d20191025), file\u003d\\mnt\\nas12\\mwintake_external\\20191025\\86385447ce35c120511647c1ade5cef9,
 * fileName\u003d86385447ce35c120511647c1ade5cef9, container\u003dGZ, artifactStatus\u003dSample found),
 * path\u003dnas12/mwintake_external/20191025/86385447ce35c120511647c1ade5cef9, sampleType\u003dEXE)"
 *
 * to a flat object with key value pairs
 *
 * @param inputString the input string
 */
export function sampleSourceToObject(inputString: string): {
  [x: string]: string;
} {
  let kv = inputString.match(/[A-Za-z]+=[^,()=]+/g);
  // return an empty object if there will be no results
  if (kv === null) return {};
  let result = {};
  for (let v of kv) {
    let a = v.split("=");
    result[a[0]] = a[1];
  }
  return result;
}
