export const determineStatusColour = (statusCode: number | null): string => {
  let colour: string;

  if (statusCode === null) {
    colour = "#CCC";
  } else if (statusCode === 0) {
    colour = "#3FCC3F";
  } else if (statusCode >= 200 && statusCode <= 299) {
    colour = "#3FCC3F";
  } else if (statusCode >= 300 && statusCode <= 399) {
    colour = "#33A7FF";
  } else if (statusCode >= 400 && statusCode <= 499) {
    colour = "#FDA92A";
  } else if (statusCode >= 500 && statusCode <= 599) {
    colour = "#FC4F52";
  } else {
    colour = "#AAA";
  }

  return colour;
};
