import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  LinearProgress,
  Card,
  CardHeader,
  CardContent,
  Button,
  Avatar,
  CardActions,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import PageFullWidth from "commons/components/layout/pages/PageFullWidth";
import PageHeader from "commons/components/layout/pages/PageHeader";
import { Helmet } from "react-helmet";
import { BorealisModules } from "components/beaver/models/borealis/borealisModel";
import { BorealisReport, BorealisReportView } from "./BorealisReport";
import { useStyles } from "components/beaver/components/report/common/sharedStyles";
import {
  ParsedError,
  parseErrorTitle,
} from "components/beaver/components/report/common/ParsedError";
import { fetchBorealis, borealisSelector } from "slices/borealis";
import { useDispatch, useSelector } from "react-redux";

export function BorealisReportPage() {
  const { search } = useLocation();
  const navigate = useNavigate();
  const [query, setQuery] = React.useState(null);
  const [modules, setModules] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [retry, setRetry] = React.useState(0);

  const { t } = useTranslation();
  const classes = useStyles();

  const { report, error } = useSelector(borealisSelector);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (new URLSearchParams(search).get("query")) {
      setQuery(new URLSearchParams(search).get("query"));
      // all on by default
      setModules(
        null === new URLSearchParams(search).get("modules")
          ? BorealisModules.filter((m) => "BEAVER" !== m).join(",")
          : new URLSearchParams(search).get("modules")
      );
    } else {
      // if you don't supply a query, it will bring you to search
      navigate("borealisSearch");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  // if you have a query, then do the Axios request
  React.useEffect(() => {
    if (query) {
      // dispatch to get the report in redux
      dispatch(fetchBorealis(query, modules, setLoading));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const title = t("Borealis Report");

  return (
    <>
      <Helmet>
        <title>{["BEAVER", title, query].join(" ")}</title>
        <meta
          name="description"
          content={["BEAVER", title, "-", t("page.home.description")].join(" ")}
        />
      </Helmet>
      <PageHeader title={title + (query ? " - " + query : "")} />
      <PageFullWidth>
        {loading ? (
          <>
            {t("Loading")} ... <LinearProgress />
          </>
        ) : error ? (
          <Card>
            <CardHeader
              className={classes.ch}
              avatar={<Avatar className={classes.errorIcon}>!</Avatar>}
              title={parseErrorTitle(error)}
            />
            <CardContent>
              <ParsedError error={error} />
            </CardContent>
            <CardActions>
              {/* By incrementing the state value which is monitoired by useEffect, the useEffect is triggered*/}
              {/* when the hash can't be found, don't show the retry as per issue #352 */}
              {error && !error.match("file could be found") ? (
                <Button size="small" onClick={() => setRetry(retry + 1)}>
                  {t("Retry")}
                </Button>
              ) : null}
            </CardActions>
          </Card>
        ) : report.reasonPhrase ? (
          <BorealisReport report={report} error={report} />
        ) : (
          <BorealisReportView
            // just in case the modules are empty, e.g. when there is a 400 error
            report={{ results: [], modules: {}, ...report }}
            query={query}
            modules={modules ? modules : ""}
          />
        )}
      </PageFullWidth>
    </>
  );
}
