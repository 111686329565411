import React from "react";
import { Box } from "@material-ui/core";
import { hasFlag } from "country-flag-icons";
import Flags from "country-flag-icons/react/3x2";
import { Tooltip } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const countryNames = {
  en: new Intl.DisplayNames(["en"], { type: "region" }),
  fr: new Intl.DisplayNames(["fr"], { type: "region" }),
};

export function Flag({
  countryCode,
  style,
}: {
  countryCode: string;
  style?: any;
}) {
  const { i18n } = useTranslation();
  const FlagComponent =
    Flags[
      (countryCode ? countryCode : "").toUpperCase() as keyof typeof Flags
    ];
  return hasFlag(countryCode) ? (
    <Tooltip
      title={
        countryCode !== countryNames["en"].of(countryCode)
          ? countryNames[i18n.language.includes("fr") ? "fr" : "en"].of(
              countryCode
            ) +
            " (" +
            countryCode +
            ")"
          : countryCode
      }
      arrow
    >
      <Box style={{ display: "inline" }}>
        <FlagComponent style={style} />
      </Box>
    </Tooltip>
  ) : (
    <Box style={{ display: "inline", ...style }}>{countryCode}</Box>
  );
}
