import React from "react";

import { IconButton, Snackbar, Tooltip } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";

import { useTranslation } from "react-i18next";
import { toUnicode } from "punycode";

/*
 * This function will return the value and the button next to it in a React Fragment
 */
export function ClipboardValue({
  value,
  dontDecodePunycode,
  showShareIcon,
  stopPropagation,
}: {
  value: string;
  dontDecodePunycode?: boolean;
  showShareIcon?: boolean;
  stopPropagation?: boolean;
}) {
  return (
    <>
      {dontDecodePunycode ? value : toUnicode(value)}
      <ClipboardButton
        value={value}
        dontDecodePunycode={dontDecodePunycode}
        showShareIcon={showShareIcon}
        stopPropagation={stopPropagation}
      />
    </>
  );
}

/**
 * This function returns a button with a clipboard icon that when you click on will copy the
 * value provided as a prop into the clipboard.
 */
export function ClipboardButton({
  value,
  dontDecodePunycode,
  showShareIcon,
  stopPropagation,
}: {
  value: string;
  dontDecodePunycode?: boolean;
  showShareIcon?: boolean;
  stopPropagation?: boolean;
}) {
  const { t } = useTranslation();
  const [msg, setMsg] = React.useState(null);

  const copyText = async (text: string) => {
    if (msg) setMsg(null);
    navigator.clipboard.writeText(text);
    setMsg(text.length < 200 ? text : text.substring(0, 200) + " ... ");
  };

  return (
    <>
      <Tooltip title={t("Copy to clipboard")} arrow>
        <IconButton
          size="small"
          onClick={(e) => {
            copyText(dontDecodePunycode ? value : toUnicode(value));
            if (stopPropagation) e.stopPropagation();
          }}
        >
          {showShareIcon ? <ShareOutlinedIcon /> : <AssignmentOutlinedIcon />}
        </IconButton>
      </Tooltip>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={1000}
        open={!!msg}
        onClose={() => {
          setMsg(null);
        }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={"success"}
          onClose={() => {
            setMsg(null);
          }}
        >
          {showShareIcon ? t("Link Copied") : t("Text Copied")}{" "}
          {msg ? "'" + msg + "'" : ""}
        </MuiAlert>
      </Snackbar>
    </>
  );
}
