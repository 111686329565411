// TODO:  the request is required when isCrawled is true, otherwise is not,

//import { request } from "http";

//   is there a way to represent this in typescript?
export interface urlStatus {
  isCrawled: boolean;
  request?: {
    lastStatusCode: number;
    source: string;
    lastSuccessOn: number;
    lastConnectionOn: number;
  };
  queueStatus: string;
  clusterName: string;
  url?: {
    error: string;
    isTransofmred: boolean;
    isRedirection: boolean;
    type: string;
    soruce: string;
    md5: string;
    recievedDate: number;
    url: string;
  };
}

// these come from the URLStatus.java file in Beaver legacy
export function hasKangarooError(status: urlStatus): boolean {
  return (
    status.request &&
    !status.request.lastSuccessOn &&
    status.request.lastStatusCode === 200
  );
}

export function hasStatusCodeSuccess(status: urlStatus): boolean {
  return (
    status.request &&
    status.request.lastSuccessOn &&
    status.request.lastStatusCode / 100 === 2
  );
}

export function hasConnectionSuccess(status: urlStatus): boolean {
  return status.request && !!status.request.lastConnectionOn;
}

export interface urlSource {
  receivedDate: number;
  source: string;
  isTransformed: boolean;
  isRedirection: boolean;
}

export interface spiderCommunication {
  requestedURL: string;
  actualURL: string;
  statusCode: string;
}

export interface spiderReport {
  creationDate: string;
  engineName: string;
  engineVersion: string;
  md5: string;
  communication: spiderCommunication;
}
