import React from "react";
import { Link } from "react-router-dom";
import PageFullWidth from "commons/components/layout/pages/PageFullWidth";
import PageHeader from "commons/components/layout/pages/PageHeader";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import {
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  LinearProgress,
  Paper,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Axios from "axios";
import { SearchElasticURLJSON } from "components/beaver/helpers/ServerProperties";
import { SimpleTableView } from "../report/common/SimpleTableView";
import { useStyles } from "../report/common/sharedStyles";
import { LinkWrap, BorealisButton } from "../report/common/LinkWrap";
import { ClipboardButton } from "../report/common/ClipboardButton";
import { isSimplified } from "components/beaver/helpers/ServerProperties";

import { useDispatch, useSelector } from "react-redux";
import {
  elasticURLSearchSelector,
  ElasticURLSearchState,
  setSearchError,
  setSearchResults,
  setSearchTerms,
  setSearchText,
} from "../../../../slices/elasticurlsearch";
import { OtherSearches } from "components/routes/Search";
import { HelpButton } from "../report/common/HelpButton";
import { URLSearchHelp } from "components/beaver/help/URLSearchHelp";

import { fetchClusters, clusterSelector } from "slices/clusters";

export function ElasticURLSearch() {
  const { t } = useTranslation();

  const title = t("URL Search");
  return (
    <>
      <Helmet>
        <title>{["BEAVER", title].join(" ")}</title>
        <meta
          name="description"
          content={["BEAVER", title, "-", t("page.home.description")].join(" ")}
        />
      </Helmet>
      <PageHeader
        title={
          <>
            {title}
            <HelpButton help={<URLSearchHelp />} title={title} />
          </>
        }
      />
      <PageFullWidth>
        <OtherSearches currentSearchPage="url" />
        <URLSearch />
      </PageFullWidth>
    </>
  );
}

export function URLSearch() {
  const { t } = useTranslation();

  const [query, setQuery] = React.useState("");
  const [to, setTo] = React.useState("");
  const [from, setFrom] = React.useState("");
  const [resultsSize, setResultsSize] = React.useState("100");
  const [startAt, setStartAt] = React.useState("0");
  const [sortDate, setSortDate] = React.useState(true);
  const [excludeErrored, setExcludeErrored] = React.useState(true);

  const [err, setErr] = React.useState<string>(null);
  const [warn, setWarn] = React.useState<string>(null);
  const [results, setResults] = React.useState<any>(null);
  const [searching, setSearching] = React.useState(false);

  const user = localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser"))
    : {};

  const dateRegex = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;

  const dispatch = useDispatch();

  const elasticURLSearchState: ElasticURLSearchState = useSelector(
    elasticURLSearchSelector
  );

  // this code is loading the state from redux
  React.useEffect(() => {
    if (elasticURLSearchState) {
      console.log(elasticURLSearchState);
      if (elasticURLSearchState.searchText)
        setQuery(elasticURLSearchState.searchText);
      if (elasticURLSearchState?.searchTerms?.to)
        setTo(elasticURLSearchState.searchTerms.to);
      if (elasticURLSearchState?.searchTerms?.from)
        setFrom(elasticURLSearchState.searchTerms.from);
      if (elasticURLSearchState?.searchTerms?.resultsSize)
        setResultsSize(elasticURLSearchState.searchTerms.resultsSize);
      if (elasticURLSearchState?.searchTerms?.startAt)
        setStartAt(elasticURLSearchState.searchTerms.startAt);
      // appending string because we want the boolean saved (e.g. if its "false" then make it false)
      if (
        elasticURLSearchState?.searchTerms &&
        "" + elasticURLSearchState?.searchTerms?.sortDate
      )
        setSortDate(elasticURLSearchState.searchTerms.sortDate);
      if (
        elasticURLSearchState?.searchTerms &&
        "" + elasticURLSearchState?.searchTerms?.excludeErrored
      )
        setExcludeErrored(elasticURLSearchState.searchTerms.excludeErrored);
      if (elasticURLSearchState.searchResults)
        setResults(elasticURLSearchState.searchResults);
    }
  }, [elasticURLSearchState]);

  const doSearch = (newStartAt = "0") => {
    setWarn(null);
    setStartAt(newStartAt);
    setResults(null);
    // console.log(query, to, from, resultsSize);
    // check to see if there are any issues with any of the inputs, warn if is the case
    if (!query || !query.trim()) {
      setWarn(t("Please enter a query"));
      setQuery("");
      return;
    }
    if ((!!to && !to.match(dateRegex)) || (!!from && !from.match(dateRegex))) {
      setWarn(t("To / From dates (if used) should be in YYYY-MM-DD format"));
      return;
    }
    if (
      !!to &&
      !!from &&
      parseInt(to.replace(/-/g, "")) <= parseInt(from.replace(/-/g, ""))
    ) {
      setWarn(
        t("'To' date should not be less than or equal to the 'From' date")
      );
      return;
    }

    if (!resultsSize.match(/^\d+$/) || parseInt(resultsSize) <= 0) {
      setWarn(t("Number of Results must be a positive number greater than 0"));
      return;
    }

    // do the query itself
    setSearching(true);

    // saving state on search button press
    dispatch(setSearchText(query));
    dispatch(
      setSearchTerms({
        to: to,
        from: from,
        resultsSize: resultsSize,
        newStartAt: newStartAt,
        sortDate: sortDate,
        excludeErrored: excludeErrored,
      })
    );

    // do axios search
    Axios.get(
      SearchElasticURLJSON(
        query,
        to.replace(/-/g, ""),
        from.replace(/-/g, ""),
        resultsSize,
        newStartAt,
        sortDate,
        excludeErrored
      ),
      {
        auth:
          user.server && !user.server.includes("localhost")
            ? {
                username: user ? user.username : null,
                password: user
                  ? user.onetime
                    ? // eslint-disable-next-line no-eval
                      window.atob(eval(window.atob(user.onetime))).substr(13)
                    : null
                  : null,
              }
            : null,
      }
    ).then(
      (response) => {
        // TODO - remove this
        console.log(response);
        if (response.data.error && response.data.error.reason) {
          setErr(response.data.error.reason);
          dispatch(setSearchError(response.data.error.reason));
          setWarn(
            response.data?.error?.root_cause.length &&
              "parse_exception" === response.data.error.root_cause[0].type
              ? t("Syntax Error - your search could query is invalid")
              : [
                  t("There may be an error with our service"),
                  response.data.error.reason,
                ].join(": ")
          );
        } else {
          if ("object" !== typeof response.data) {
            // if it's a string and not an object, likely logged out
            setTimeout(() => {
              setErr(t("You may need to logout and login again"));
            }, 1200);
            setTimeout(() => {
              if (localStorage.ssoPrefix) localStorage.removeItem("ssoPrefix");
              if (localStorage.currentUser)
                localStorage.removeItem("currentUser");
              window.location.reload();
            }, 3000);
          }
          setResults(response.data);
          dispatch(setSearchResults(response.data));
        }
        setSearching(false);
      },
      (error) => {
        // if it's a 401, logout because bad creds, the user can then log back in - 404 similar (can't be found because logged out)
        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 404)
        ) {
          setTimeout(() => {
            setErr(t("You may need to logout and login again"));
          }, 1200);
          setTimeout(() => {
            if (localStorage.ssoPrefix) localStorage.removeItem("ssoPrefix");
            if (localStorage.currentUser)
              localStorage.removeItem("currentUser");
            window.location.reload();
          }, 3000);
        } else {
          // otherwise show the message
          console.error(error);
          setErr(error.message);
          dispatch(setSearchError(error.message));
          setSearching(false);
        }
      }
    );
  };

  return (
    <>
      <Grid
        container
        style={{ marginBottom: 20, display: !!results ? "none" : null }}
        spacing={2}
      >
        <ElasticURLSearchForm
          doSearch={doSearch}
          query={query}
          setQuery={setQuery}
          from={from}
          setFrom={setFrom}
          to={to}
          setTo={setTo}
          resultsSize={resultsSize}
          setResultsSize={setResultsSize}
          sortDate={sortDate}
          setSortDate={setSortDate}
          excludeErrored={excludeErrored}
          setExcludeErrored={setExcludeErrored}
          warn={warn}
          searching={searching}
        />
      </Grid>
      {!!searching && (
        <Box>
          {t("Loading")} ...
          <LinearProgress />
        </Box>
      )}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={3000}
        open={!!err}
        onClose={() => {
          setErr(null);
        }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={"error"}
          onClose={() => {
            setErr(null);
          }}
        >
          {t("An Error Occured") + " - " + err}
        </MuiAlert>
      </Snackbar>
      {results ? (
        <ElasticURLSearchResults
          results={results}
          excludeErrored={excludeErrored}
          from={from}
          to={to}
          startAt={startAt}
          resultsSize={resultsSize}
          setResults={setResults}
          query={query}
          doSearch={doSearch}
          dispatch={dispatch}
          setSearchResults={setSearchResults}
        />
      ) : null}
    </>
  );
}

export type ElasticURLSearchFormType = {
  doSearch: (newStartAt?: string) => void;
  query: string;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  from: string;
  setFrom: React.Dispatch<React.SetStateAction<string>>;
  to: string;
  setTo: React.Dispatch<React.SetStateAction<string>>;
  resultsSize: string;
  setResultsSize: React.Dispatch<React.SetStateAction<string>>;
  sortDate: boolean;
  setSortDate: React.Dispatch<React.SetStateAction<boolean>>;
  excludeErrored: boolean;
  setExcludeErrored: React.Dispatch<React.SetStateAction<boolean>>;
  warn: string;
  searching: boolean;
};

export function ElasticURLSearchForm({
  doSearch,
  query,
  setQuery,
  from,
  setFrom,
  to,
  setTo,
  resultsSize,
  setResultsSize,
  sortDate,
  setSortDate,
  excludeErrored,
  setExcludeErrored,
  warn,
  searching,
}: ElasticURLSearchFormType) {
  const { t } = useTranslation();

  const searchIfEnterPressed = (e) => {
    if ("Enter" === e.key) doSearch();
  };

  return (
    <Grid item xs={12} md={6}>
      <Paper style={{ padding: 15 }}>
        <Box style={{ display: "flex", flexDirection: "column" }}>
          <Box style={{ display: "inline" }}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ float: "right" }}
              onClick={() => {
                setQuery("");
                setFrom("");
                setTo("");
                setResultsSize("100");
                setSortDate(true);
                setExcludeErrored(true);
              }}
            >
              {t("Clear Search Criteria")}
            </Button>
            <Typography variant="subtitle1">
              {t("urlsearch.suggestion")}
            </Typography>
          </Box>
          <FormControl style={{ marginTop: 15 }}>
            <TextField
              label={t("Query")}
              value={query}
              onChange={(e) => setQuery("" + e.target.value)}
              onKeyDown={searchIfEnterPressed}
            />
          </FormControl>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "stretch",
              width: "100%",
              marginTop: 15,
            }}
          >
            <FormControl style={{ flex: 1 }}>
              <TextField
                label={t("From Date (Optional)")}
                value={from}
                onChange={(e) => setFrom("" + e.target.value)}
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                onKeyDown={searchIfEnterPressed}
              />
            </FormControl>
            <FormControl style={{ marginLeft: 15, flex: 1 }}>
              <TextField
                label={t("To Date (Optional)")}
                value={to}
                onChange={(e) => setTo("" + e.target.value)}
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                onKeyDown={searchIfEnterPressed}
              />
            </FormControl>
            <FormControl style={{ marginLeft: 15, flex: 1 }}>
              <TextField
                label={t("Number of Results (default 100)")}
                value={resultsSize}
                onChange={(e) => setResultsSize("" + e.target.value)}
                onKeyDown={searchIfEnterPressed}
              />
            </FormControl>
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "stretch",
              width: "100%",
              marginTop: 15,
              marginBottom: 10,
            }}
          >
            <FormControlLabel
              style={{ flex: 1 }}
              control={
                <Checkbox
                  name="sortByDate"
                  checked={sortDate}
                  onChange={() => setSortDate(!sortDate)}
                  color="primary"
                />
              }
              label={t("Sort by Received Date")}
            />
            <FormControlLabel
              style={{ marginLeft: 0, flex: 2 }}
              control={
                <Checkbox
                  name="excludeErrored"
                  checked={excludeErrored}
                  onChange={() => setExcludeErrored(!excludeErrored)}
                  color="primary"
                />
              }
              label={t("Exclude Results with Errors")}
            />
          </Box>
          {warn ? (
            <Typography style={{ marginTop: 30 }} variant="body1" color="error">
              {warn}
            </Typography>
          ) : null}
          <Button
            variant="contained"
            color="primary"
            onClick={() => doSearch()}
            style={{ marginTop: 15 }}
            type="submit"
            disabled={searching}
          >
            {t("Search")}
          </Button>
        </Box>
      </Paper>
    </Grid>
  );
}

export interface ElasticResults {
  hits: {
    max_score?: number;
    total: number;
    hits: { _source: any; highlight: any; _score: number; _id: string }[];
  };
}

export type ElasticURLSearchResultsType = {
  results: ElasticResults;
  excludeErrored: boolean;
  query: string;
  doSearch: (newStartAt?: string) => void;
  from: string;
  to: string;
  startAt: string;
  resultsSize: string;
  setResults: React.Dispatch<React.SetStateAction<string>>;
  dispatch: React.Dispatch<any>;
  setSearchResults: Function;
};

export function ElasticURLSearchResults({
  results,
  excludeErrored,
  query,
  doSearch,
  from,
  to,
  startAt,
  resultsSize,
  setResults,
  dispatch,
  setSearchResults,
}: ElasticURLSearchResultsType) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { clusters } = useSelector(clusterSelector);

  React.useEffect(() => {
    dispatch(fetchClusters(clusters));
  }, [dispatch, clusters]);

  // this will allow the user to see the error code if they don't exclude the errored urls
  const defaultHeaders = [
    {
      title: results?.hits?.max_score
        ? t("Relative Score")
        : t("Relavancy Score"),
      field: "score",
      width: "10%",
    },
    {
      title: t("Url"),
      field: "url",
      width: "90%",
      render: (r) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* adding #SUMMARY because otherwise will go to / then #SUMMARY adding two items to history */}
          <Link to={["/url", r.md5, "report#SUMMARY"].join("/")}>
            {/* add highlighting to results */}
            <LinkWrap>
              {r.highlight ? (
                <Tooltip
                  title={r.url + " - " + t("Pivot in BeAVER")}
                  placement="top"
                  arrow
                >
                  <div
                    className={classes.breakword}
                    dangerouslySetInnerHTML={{
                      __html: r.highlight
                        .replaceAll("<em>", "<em><b>")
                        .replaceAll("</em>", "</b></em>"),
                    }}
                  ></div>
                </Tooltip>
              ) : (
                r.url
              )}
            </LinkWrap>
          </Link>
          <BorealisButton>{r.url}</BorealisButton>
          <ClipboardButton value={r.url} />
        </div>
      ),
    },
    {
      title: t("Cluster"),
      field: "cluster_name",
      width: "2%",
      render: (row) => (
        <>
          {row.cluster_name ? (
            isSimplified() ? (
              <Chip label={row.cluster_name} color="primary" size="small" />
            ) : (
              <Link to={["/cluster", row.cluster_name, "report"].join("/")}>
                <LinkWrap>
                  <Badge
                    badgeContent={
                      clusters && clusters[row.cluster_name]
                        ? clusters[row.cluster_name].total
                        : null
                    }
                    color="secondary"
                    max={10000}
                  >
                    <Chip label={row.cluster_name} clickable color="primary" />
                  </Badge>
                </LinkWrap>
              </Link>
            )
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      title: t("Subcluster"),
      field: "subcluster_name",
      width: "2%",
      render: (row) => (
        <>
          {row.subcluster_name ? (
            isSimplified() ? (
              <Chip label={row.subcluster_name} color="primary" size="small" />
            ) : (
              <Link to={["/cluster", row.subcluster_name, "report"].join("/")}>
                <LinkWrap>
                  <Chip
                    label={row.subcluster_name}
                    clickable
                    color="primary"
                    size="small"
                  />
                </LinkWrap>
              </Link>
            )
          ) : (
            ""
          )}
        </>
      ),
    },
    { title: t("Cluster Destination"), field: "cluster_dst", width: "10%" },
    {
      title: t("Status"),
      field: "last_status_code",
      width: "10%",
      render: (r) => (r.last_status_code ? r.last_status_code : ""),
    },
    { title: t("Received On"), field: "received_on", width: "10%" },
  ];
  const [headers, setHeaders] = React.useState(defaultHeaders);

  React.useEffect(() => {
    //if excludeErrored, then should hide the error_code in default headers
    if (!excludeErrored) {
      setHeaders([
        ...defaultHeaders.splice(0, 5),
        { title: t("Error Code"), field: "error_code", width: "10%" },
        { title: t("Received On"), field: "received_on", width: "10%" },
      ]);
    } else {
      setHeaders(defaultHeaders);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [excludeErrored]);

  return (
    <Card>
      <CardHeader
        className={classes.ch}
        title={
          t("Results for ") +
          `'${query}' ${
            // also showing to and from dates in card header (show only to or from if they are set)
            !!from || !!to
              ? [
                  "-",
                  !!from ? t("from") : "",
                  !!from ? from : "",
                  !!to ? t("to") : "",
                  !!to ? to : "",
                ].join(" ")
              : ""
          }`
        }
      ></CardHeader>
      <CardContent>
        <p>
          {parseInt(startAt)
            ? [
                t("Showing results"),
                (parseInt(startAt) + 1).toLocaleString(),
                t("to"),
                parseInt(startAt) + 1 <
                parseInt(startAt) + parseInt(resultsSize)
                  ? results.hits.total.toLocaleString()
                  : (
                      parseInt(startAt) + parseInt(resultsSize)
                    ).toLocaleString(),
                t("of"),
                results.hits.total.toLocaleString(),
              ].join(" ")
            : [
                t("Showing first"),
                results.hits.total < parseInt(resultsSize)
                  ? results.hits.total.toLocaleString()
                  : resultsSize.toLocaleString(),
                t("of"),
                results.hits.total.toLocaleString(),
              ].join(" ")}
          {parseInt(startAt) + parseInt(resultsSize) < results.hits.total && (
            <Button
              color="primary"
              onClick={() => {
                doSearch("" + (parseInt(startAt) + parseInt(resultsSize)));
              }}
            >
              {[t("Next"), resultsSize].join(" ")}
            </Button>
          )}
          {parseInt(startAt) > 0 && (
            <Button
              color="primary"
              onClick={() => {
                doSearch("" + (parseInt(startAt) - parseInt(resultsSize)));
              }}
            >
              {[t("Previous"), resultsSize].join(" ")}
            </Button>
          )}
          <Button
            color="primary"
            onClick={() => {
              setResults(null);
              // removing results from state also
              dispatch(setSearchResults(null));
            }}
          >
            {t("Return to Search")}
          </Button>
        </p>

        <SimpleTableView
          headers={headers}
          data={results.hits.hits.map((r) => ({
            // sometimes there won't be a max score (e.g. when we sort by recieved_on), so check for that.
            score: results?.hits?.max_score
              ? Math.ceil((r._score * 10000) / results.hits.max_score) / 100 +
                "%"
              : Math.round(r._score * 100) / 100,
            url: r._source.url,
            highlight: r?.highlight?.url.length ? r.highlight.url[0] : "",
            received_on: r._source.received_on,
            md5: r._id,
            error_code: r._source.error_code,
            cluster_name: r._source.cluster_name,
            subcluster_name: r._source.subcluster_name,
            cluster_dst: r._source.cluster_dst,
            last_status_code: r._source.last_status_code,
          }))}
          select={{
            icon: "OpenInNew",
            tooltip: t("Open Selected URL Reports in New Tabs"),
            onClick: (event, rowData) => {
              rowData
                .map((r) =>
                  [
                    window.location.protocol + "/",
                    window.location.host,
                    "ng",
                    "url",
                    r.md5,
                    "report",
                  ].join("/")
                )
                .forEach((u) => window.open(u, "_blank"));
            },
          }}
        />
      </CardContent>
    </Card>
  );
}
