import React from "react";
import { BorealisModules } from "components/beaver/models/borealis/borealisModel";

import {
  Box,
  Icon,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from "@material-ui/core";
import _ from "lodash";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";

export function BorealisHelp() {
  return (
    <>
      <img src="/ng/images/help/borealis.png" alt="Timeline View" />
      <Typography variant="body1">
        BOREALIS is a modular mitigation system used for threat vetting. Its
        different modules cover IP, domain, and URL mitigation, from various
        internal and open-source tools, from BeAVER to VirusTotal.
      </Typography>
      <Box mt={2}>
        <Typography variant="h5">Modules</Typography>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Module</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Source</TableCell>
                <TableCell>Default</TableCell>
                <TableCell>URL</TableCell>
                <TableCell>Domain</TableCell>
                <TableCell>IP</TableCell>
                <TableCell>Offline</TableCell>
                <TableCell>Query Parameter Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {["DNS", ...BorealisModules]
                .filter((m) => "BEAVER" !== m)
                .map((m) => BorealisModulesHelp[m])
                .map((m, i) => (
                  <TableRow key={i}>
                    {_.range(9).map((k, ii) => (
                      <TableCell key={i + "__" + ii}>
                        {0 === m[k] ? (
                          ""
                        ) : 1 === m[k] ? (
                          <Icon color="primary">
                            <CheckCircleOutlinedIcon />
                          </Icon>
                        ) : (
                          m[k]
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
    // show the table here but check the borealisModel for the keys to show only and show in that order (also show DNS too)
  );
}

// TODO - add french language support
// module, description, source, default, url, domain, ip, offline, query parameter value
export const BorealisModulesHelp = {
  DNS: [
    "DNS Resolution",
    "Resolve domains using multiple DNS services",
    "External",
    1,
    1,
    1,
    0,
    0,
    "N/A",
  ],
  MAXMIND: ["Maxmind", "IP resolution", "External", 1, 0, 0, 1, 1, "MAXMIND"],
  BEAVER: [
    "BeAVER",
    "Link to the BeAVER report",
    "INO",
    0,
    1,
    1,
    1,
    1,
    "BEAVER",
  ],
  NCTNS: [
    "Canadian CI IP space ownership (NCTNS)",
    "Query NCTNS registry to return the customer information",
    "IMOC/ADS1",
    0,
    0,
    0,
    1,
    1,
    "NCTNS",
  ],
  STONEWALL: [
    "Stonewall",
    "Legitimacy of the domain or IP ('Rejected' means the domain is NOT to be considered an IoC (for example, a legit domain) whilst 'Approved' means the domain can be)",
    "ADS1",
    0,
    0,
    1,
    1,
    1,
    "STONEWALL",
  ],
  ARIN: [
    "ARIN",
    "Returns which organization owns the IP and their POC",
    "External",
    0,
    0,
    0,
    1,
    1,
    "ARIN",
  ],
  SPUR: [
    "Spur",
    "IP resolution service (module needs to be explicitly requested in the query)",
    "External",
    0,
    0,
    0,
    1,
    1,
    "SPUR",
  ],
  //   IP2LOCATION: [
  //     "IP2Location",
  //     "IP resolution service",
  //     "External",
  //     1,
  //     0,
  //     0,
  //     1,
  //     0,
  //     "IP2LOCATION",
  //   ],
  NEUSTAR: [
    "Neustar",
    "IP resolution service",
    "External",
    0,
    0,
    0,
    1,
    1,
    "NEUSTAR",
  ],
  AUWL: [
    "AUWL",
    "Was the domain seen hosting phishing or other malicious content?",
    "INO",
    0,
    1,
    1,
    0,
    1,
    "AUWL",
  ],
  MOOSE: [
    "MOOSE",
    "Cyberthreat infrastructure analysis",
    "INO",
    0,
    0,
    1,
    0,
    1,
    "MOOSE",
  ],
  ALPHABETSOUP: [
    "AlphabetSoup",
    "DGA detection",
    "DASI1",
    0,
    0,
    1,
    0,
    1,
    "ALPHABETSOUP",
  ],
  URLSCAN: [
    "URLScan",
    "Service to scan and analyse websites including the domains and IPs contacted, resource, screenshots, DOM, Javascript variables, cookies and other information",
    "External",
    0,
    1,
    1,
    0,
    0,
    "URLSCAN",
  ],
  TOP1MILLION: [
    "Top1Million",
    "Was the domain in a list of the top 1 million most popular domains? (from Cisco, majestic, and Tranco)",
    "External",
    0,
    1,
    1,
    0,
    1,
    "TOP1MILLION",
  ],
  //   DNSDB: ["DNSDB", "Analysis summary", "External", 1, 0, 1, 0, 1, "DNSDB"],
  VIRUSTOTAL: [
    "VirusTotal",
    "Service that analyzes files and URLs for viruses, worms, trojans and other kinds of malicious content",
    "External",
    0,
    1,
    1,
    1,
    0,
    "VIRUSTOTAL",
  ],
  SAFEBROWSING: [
    "SafeBrowsing*",
    "Service that lets client applications check URLs against Google's constantly updated lists of unsafe web resources",
    "External",
    0,
    1,
    0,
    0,
    0,
    "SAFEBROWSING",
  ],
  DEVICE: [
    "Device",
    "Devices identified in a daily Internet scan",
    "External",
    0,
    0,
    0,
    1,
    1,
    "DEVICE",
  ],
};
