import React from "react";
import { cloneDeep } from "lodash";
import { Box } from "@material-ui/core";
import { SimpleTableCardView } from "../../common/SimpleTableView";
import {
  originalURL,
  transformedURL,
} from "../../../../models/report/url/transformations";
import { useTranslation } from "react-i18next";
import md5 from "md5";
import { Link } from "react-router-dom";
import { LinkWrap } from "../../common/LinkWrap";

export type TransformationsViewProps = {
  origURL: originalURL[];
  transURL: transformedURL[];
  testing?: boolean;
};

export function TransformationsView({
  origURL,
  transURL,
  testing,
}: TransformationsViewProps) {
  const { t } = useTranslation();
  return (
    <>
      <SimpleTableCardView
        testing={testing}
        title={t("Original URLs")}
        data={cloneDeep(origURL)}
        headers={[
          {
            title: t("Original URL"),
            field: "originalURL",
            render: (r) =>
              r.originalURL ? (
                <Link to={["/url", md5(r.originalURL), "report"].join("/")}>
                  <LinkWrap>{r.originalURL}</LinkWrap>
                </Link>
              ) : (
                ""
              ),
          },
          { title: t("Porcupine Rule"), field: "ruleName" },
        ]}
      />
      <Box mt={3}>
        <SimpleTableCardView
          testing={testing}
          title={t("Transformed URLs (only those with an HTTP response)")}
          data={cloneDeep(transURL)}
          headers={[
            {
              title: t("Transformed URL"),
              field: "transformedURL",
              render: (r) =>
                r.transformedURL ? (
                  <Link
                    to={["/url", md5(r.transformedURL), "report"].join("/")}
                  >
                    <LinkWrap>{r.transformedURL}</LinkWrap>
                  </Link>
                ) : (
                  ""
                ),
            },
            { title: t("Porcupine Rule"), field: "ruleName" },
          ]}
        />
      </Box>
    </>
  );
}
