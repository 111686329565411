import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { cloneDeep } from "lodash";
import { Chip } from "@material-ui/core";

import { SimpleTableCardView } from "../../common/SimpleTableView";
import { PhishingClusterFrequency } from "../../../../models/report/phishing/phishingListModel";
import { LinkWrap } from "../../common/LinkWrap";

export type PhishingClusterTableProps = {
  testing: boolean;
  clusters: PhishingClusterFrequency[];
};

export function PhishingClusterTable({
  testing,
  clusters,
}: PhishingClusterTableProps) {
  const { t } = useTranslation();
  return (
    <SimpleTableCardView
      testing={testing}
      title={t("Phishing Kit Clusters List")}
      data={cloneDeep(
        clusters.map((c) => {
          return {
            cluster_name: c.cluster_name,
            total: c.total,
          };
        })
      )}
      headers={[
        {
          title: t("Cluster Name"),
          field: "cluster_name",
          render: (row) => (
            <Link to={["/phishing_kit", row.cluster_name, "report"].join("/")}>
              <LinkWrap>
                <Chip label={row.cluster_name} clickable color="primary" />
              </LinkWrap>
            </Link>
          ),
        },
        {
          title: t("Total"),
          field: "total",
        },
      ]}
    />
  );
}
