import React, { useState } from "react";
import {
  Avatar,
  Box,
  ClickAwayListener,
  Divider,
  Fade,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  makeStyles,
  Paper,
  Popper,
  Typography,
  useTheme,
} from "@material-ui/core";

import useAppLayout from "../../hooks/useAppLayout";
// import useGravatar from "../../hooks/useGravatar";
import { Link } from "react-router-dom";
import ThemeSelection from "./ThemeSelection";

const useStyles = makeStyles((theme) => ({
  popper: {
    zIndex: theme.zIndex.drawer + 2,
    minWidth: "280px",
  },
  avatarButton: {
    padding: 0,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  iconButton: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    [theme.breakpoints.down("xs")]: {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
  },
}));

export type UserProfileProps = {
  username: string;
  email: string;
  name: string;
  avatar: string;
  server: string;
  onetime: string;
  login: number;
  canSeeBorealis: boolean;
};

export type UserMenuElement = {
  name: string;
  route: string;
  icon?: React.ReactElement<any>;
};

const UserProfile = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { currentUser, layoutProps } = useAppLayout();
  // const gravatarUrl = useGravatar(currentUser.email);
  const gravatarUrl = null;
  const [popperAnchorEl, setPopperAnchorEl] = useState(null);

  const onProfileClick = (event: React.MouseEvent) => {
    setPopperAnchorEl(popperAnchorEl ? null : event.currentTarget);
  };

  const onClickAway = () => setPopperAnchorEl(null);
  const isPopperOpen = !!popperAnchorEl;

  const renderThemeSelection = (enabled) => {
    if (enabled) {
      return (
        <Box>
          <Divider />
          <ThemeSelection props={layoutProps} />
        </Box>
      );
    }
  };

  const renderMenu = (type, menuItems, title) => {
    if (
      menuItems !== undefined &&
      menuItems !== null &&
      menuItems.length !== 0
    ) {
      return (
        <Box>
          <Divider />
          <List
            dense
            subheader={<ListSubheader disableSticky>{title}</ListSubheader>}
          >
            {menuItems.map((a, i) => (
              <ListItem
                button
                component={Link}
                to={a.route}
                key={`${type}-${i}`}
              >
                {a.icon ? <ListItemIcon>{a.icon}</ListItemIcon> : null}
                <ListItemText>{a.name}</ListItemText>
              </ListItem>
            ))}
          </List>
        </Box>
      );
    }
    return null;
  };

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <IconButton
        edge={"end"}
        className={classes.avatarButton}
        onClick={onProfileClick}
      >
        <Avatar
          className={classes.iconButton}
          alt={currentUser.name}
          src={currentUser.avatar ? currentUser.avatar : gravatarUrl}
        />
        <Popper
          open={isPopperOpen}
          anchorEl={popperAnchorEl}
          className={classes.popper}
          placement="bottom-end"
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={250}>
              <Paper style={{ padding: theme.spacing(1) }} elevation={4}>
                <List disablePadding>
                  <ListItem disableGutters dense>
                    <Box display="flex" py={2} px={3} alignItems="center">
                      <Avatar
                        style={{
                          width: theme.spacing(8),
                          height: theme.spacing(8),
                        }}
                        alt={currentUser.name}
                        src={
                          currentUser.avatar ? currentUser.avatar : gravatarUrl
                        }
                      />
                      <Box pl={2}>
                        <Typography variant={"body1"} noWrap>
                          <b>{currentUser.name}</b>
                        </Typography>
                        <Typography variant={"caption"} noWrap>
                          {currentUser.email}
                        </Typography>
                      </Box>
                    </Box>
                  </ListItem>
                </List>
                {renderMenu("usermenu", layoutProps.topnav.userMenu, "")}
                {renderMenu(
                  "adminmenu",
                  layoutProps.topnav.adminMenu,
                  layoutProps.topnav.adminMenuTitle
                )}
                {renderThemeSelection(
                  layoutProps.topnav.themeSelectionUnder === "profile"
                )}
              </Paper>
            </Fade>
          )}
        </Popper>
      </IconButton>
    </ClickAwayListener>
  );
};

export default UserProfile;
