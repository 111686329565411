import React from "react";
import { Link } from "react-router-dom";
import { LinkWrap } from "components/beaver/components/report/common/LinkWrap";
import {
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import { useStyles } from "../../common/sharedStyles";
import { useTranslation } from "react-i18next";
import moment from "moment";

import {
  ClipboardValue,
  ClipboardButton,
} from "components/beaver/components/report/common/ClipboardButton";

import { alReportModel } from "components/beaver/models/report/md5/alModel";

// TODO investigate the counts, e.g. 000001cccf225d09a2bd07e08017976d has 327, but not showing (even in json)
//   and 000001bf19e8a2647f600cf9ff9ca726 has 7 but nothing in json
// TODO - write some tests
// TODO - Find other AL reports with hits
export function ALView({ alReports }: { alReports: alReportModel[] }) {
  const classes = useStyles();
  const { t } = useTranslation();

  // state for pagination (seems to be newest first)
  const [reportToShow, setReportToShow] = React.useState(0);
  const [alReport, setAlReport] = React.useState(
    alReports[alReports.length - 1]
  );
  // controls report to be shown based on pagination
  React.useEffect(
    () => setAlReport(alReports[reportToShow]),
    [alReports, reportToShow]
  );

  return (
    <Box>
      <Card>
        <CardHeader className={classes.ch} title={t("AL Report")} />
        <CardContent>
          {/* Pagination for different creation dates */}
          {alReports.length > 1 && (
            <Box style={{ float: "right" }}>
              {alReports.map((a, i) => (
                <Button
                  key={i}
                  variant={i === reportToShow ? "contained" : "text"}
                  color="primary"
                  onClick={() => setReportToShow(i)}
                >
                  {moment(a.creationDate).format("YYYYMMDD")}
                </Button>
              ))}
            </Box>
          )}
          <TableContainer>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell>{t("Scanner Version")}</TableCell>
                  <TableCell>{alReport.api_server_version}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("Submitted")}</TableCell>
                  <TableCell>{alReport.api_response.times.submitted}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("Completed")}</TableCell>
                  <TableCell>{alReport.api_response.times.completed}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("File Count")}</TableCell>
                  <TableCell>{alReport.api_response.file_count}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("Result")}</TableCell>
                  <TableCell>{alReport.result}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
      {Object.keys(alReport.api_response?.file_infos).map((f, i) => (
        <Box mt={2} key={i}>
          <Card>
            <CardHeader
              className={classes.ch}
              title={
                t("File") +
                " " +
                (i + 1) +
                ": " +
                alReport.api_response.file_infos[f].md5
              }
            />
            <CardContent>
              <TableContainer>
                <Table size="small">
                  <TableBody>
                    {/* TODO maybe you don't need all of these, put button to show / hide them all (filtered state) */}
                    {Object.keys(alReport.api_response.file_infos[f]).map(
                      (g, j) => (
                        <TableRow key={i + "_" + j}>
                          <TableCell>{g}</TableCell>
                          <TableCell className={classes.breakword}>
                            {[
                              "ascii",
                              "hex",
                              "ssdeep",
                              "sha1",
                              "sha256",
                            ].includes(g) ? (
                              <ClipboardValue
                                value={alReport.api_response.file_infos[f][g]}
                              />
                            ) : ["md5"].includes(g) ? (
                              <>
                                <Link
                                  to={[
                                    "/md5",
                                    alReport.api_response.file_infos[f][g],
                                    "report",
                                  ].join("/")}
                                >
                                  <LinkWrap>
                                    {alReport.api_response.file_infos[f][g]}
                                  </LinkWrap>
                                </Link>
                                <ClipboardButton
                                  value={alReport.api_response.file_infos[f][g]}
                                />
                              </>
                            ) : (
                              alReport.api_response.file_infos[f][g]
                            )}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                    {Object.keys(alReport.api_response.results)
                      .filter(
                        (r) =>
                          r.split(".")[0] === f &&
                          alReport.api_response.results[r].response.extracted
                            .length
                      )
                      .map((r, ii) => (
                        <TableRow key={ii}>
                          <TableCell>
                            {/* show a count here */}
                            <Badge
                              badgeContent={
                                alReport.api_response.results[
                                  r
                                ].response.extracted.flatMap((a) => a?.metas)
                                  .length
                              }
                              max={99}
                              color="secondary"
                            >
                              {r.split(".")[1] + " " + t("Matches")}
                            </Badge>
                          </TableCell>
                          <TableCell>
                            {/* TODO - The meta's seem to have this patern:
                    {
                      "metas": [
                        "readlnk.bat",
                        "eb05248e86c14d86d787ab1c3f7f528a64203102aaf3188e7e4825e39995739e",
                        "executable/windows/pe32",
                        "UNCLASSIFIED"
                      ]
                    },
                    {
                      "metas": [
                        "EJPack.txt",
                        "41a69b2e7999bed5c66b1d56d678d6a4db9cd7f992dc4ee18079810323e0d37b",
                        "executable/windows/pe32",
                        "UNCLASSIFIED"
                      ]
                    }

    it seems like they have 4 columns or something, need to reconsider display method
*/}

                            {alReport.api_response.results[r].response.extracted
                              .flatMap((a) => a?.metas)
                              .map((a, iii) => (
                                <Box
                                  mr={0.5}
                                  key={a + "_" + iii}
                                  style={{ display: "inline" }}
                                >
                                  <Chip
                                    label={a}
                                    size="small"
                                    color="primary"
                                  />
                                </Box>
                              ))}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Box>
      ))}
    </Box>
  );
}
