import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { eventSelector, fetchReport } from "slices/misplogan";

import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  Table,
  Tooltip,
  Grid,
  LinearProgress,
  Link,
  ClickAwayListener,
  useTheme,
  withStyles,
} from "@material-ui/core";
import {
  OpenInNewOutlined,
  ArrowDropUp,
  ArrowDropDown,
  Remove,
  FiberManualRecord,
  Info,
  SubdirectoryArrowRight,
} from "@material-ui/icons";
import { LinkWrap } from "../common/LinkWrap";
import { SimpleTableCardView } from "../common/SimpleTableView";

import { useStyles } from "../common/sharedStyles";
import { ParsedError, parseErrorTitle } from "../common/ParsedError";
import { useTranslation } from "react-i18next";
import { isSimilar } from "components/beaver/helpers/colorSimilarity";
import { cloneDeep } from "lodash";
import { loganMispURL } from "../../../../../components/beaver/helpers/ServerProperties";

const ImageTooltip = withStyles({
  tooltip: {
    maxWidth: "none",
  },
})(Tooltip);

export function MISPLoganReport({ eventid }: { eventid: string }) {
  const classes = useStyles();
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const { event, error } = useSelector(eventSelector);

  useEffect(() => {
    dispatch(fetchReport(eventid));
  }, [dispatch, eventid]);

  const [showScreenshot, setShowScreenshot] = useState(null); // For PUPPETMASTER events

  if (!error && !event) {
    return (
      <>
        {t("Loading")} ...
        <LinearProgress />
      </>
    );
  } else if (error) {
    return (
      <Card>
        <CardHeader
          className={classes.ch}
          avatar={<Avatar className={classes.errorIcon}>!</Avatar>}
          title={parseErrorTitle(error)}
        />
        <CardContent>
          <ParsedError error={error} />
        </CardContent>
        <CardActions>
          <Button
            size="small"
            onClick={() => dispatch(fetchReport(eventid, true))}
          >
            {t("Retry")}
          </Button>
        </CardActions>
      </Card>
    );
  } else {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Card>
              <CardHeader className={classes.ch} title={t("Event Details")} />
              <CardContent>
                <TableContainer>
                  <Table size="small">
                    <TableBody>
                      <TableRow className={classes.row}>
                        <TableCell>{t("Event ID")}</TableCell>
                        <TableCell>
                          <LinkWrap noBeaverLink={false}>
                            <Link
                              href={loganMispURL() + "/events/view/" + event.id}
                              target="_blank"
                              rel="noopener"
                            >
                              <Tooltip
                                title={t("Open event in MISP")}
                                arrow
                                placement="top"
                              >
                                <div className={classes.centerCell}>
                                  {event.id}
                                  <OpenInNewOutlined fontSize="inherit" />
                                </div>
                              </Tooltip>
                            </Link>
                          </LinkWrap>
                        </TableCell>
                      </TableRow>

                      <TableRow className={classes.row}>
                        <TableCell>{t("Creator org")}</TableCell>
                        <TableCell>{event.creator_org}</TableCell>
                      </TableRow>

                      <TableRow className={classes.row}>
                        <TableCell>{t("Date")}</TableCell>
                        <TableCell>{event.date}</TableCell>
                      </TableRow>

                      <TableRow className={classes.row}>
                        <TableCell>{t("Threat level")}</TableCell>
                        <TableCell>
                          <div className={classes.centerCell}>
                            {event.threat_level_id === "3" && (
                              <>
                                {t("Low")}{" "}
                                <ArrowDropDown style={{ color: "green" }} />
                              </>
                            )}
                            {event.threat_level_id === "2" && (
                              <>
                                {t("Medium")} <Remove />
                              </>
                            )}
                            {event.threat_level_id === "1" && (
                              <>
                                {t("High")}{" "}
                                <ArrowDropUp style={{ color: "red" }} />
                              </>
                            )}
                          </div>
                        </TableCell>
                      </TableRow>

                      <TableRow className={classes.row}>
                        <TableCell>{t("Info")}</TableCell>
                        <TableCell>{event.info}</TableCell>
                      </TableRow>

                      <TableRow className={classes.row}>
                        <TableCell>{t("Last change")}</TableCell>
                        <TableCell>
                          {new Date(event.timestamp * 1000).toLocaleString(
                            i18n.language + "-CA"
                          )}
                        </TableCell>
                      </TableRow>

                      <TableRow className={classes.row}>
                        <TableCell>{t("Number of attributes")}</TableCell>
                        <TableCell>
                          {event.attribute_count +
                            " (" +
                            event.object_count +
                            " " +
                            (event.object_count === 1
                              ? t("object")
                              : t("objects")) +
                            ")"}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={4}>
            <Card>
              <CardHeader className={classes.ch} title={t("Tags")} />
              <CardContent
                style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}
              >
                {event.tags.map((tag, i) => (
                  <Chip
                    key={"attribute_tag_" + i}
                    label={tag?.name}
                    size="medium"
                    icon={
                      <FiberManualRecord
                        style={{
                          color: tag.colour,
                          stroke: theme.palette.text.primary,
                          strokeWidth: isSimilar(
                            tag.colour,
                            theme.palette.type === "light"
                              ? theme.palette.grey[300]
                              : theme.palette.grey[700],
                            10
                          )
                            ? "1px"
                            : "0px",
                        }}
                      />
                    }
                    deleteIcon={
                      <Link
                        href={
                          loganMispURL() + "/events/index/searchtag:" + tag.id
                        }
                        target="_blank"
                        rel="noopener"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Tooltip
                          title={t("See all events with this tag")}
                          arrow
                          placement="top"
                        >
                          <OpenInNewOutlined fontSize="inherit" />
                        </Tooltip>
                      </Link>
                    }
                    onDelete={() => {}}
                  />
                ))}
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={12} style={{ width: "0" }}>
            {event.type === "BE-IRONCLAW" ? (
              <SimpleTableCardView
                title={t("Attributes")}
                data={cloneDeep(event.attributes)}
                headers={[
                  {
                    title: t("SID"),
                    field: "sid",
                    optional: true,
                    render: (data) =>
                      data?.sid?.map((sid, i) => (
                        <Chip
                          key={i}
                          label={
                            <Link
                              style={{
                                color: theme.palette.text.secondary,
                                textDecoration: "underline",
                              }}
                              href={"/ng/sid/" + sid + "/report/"}
                            >
                              {sid ? sid : ""}
                            </Link>
                          }
                          size="small"
                          style={{ marginRight: "5px" }}
                        />
                      )),
                  },
                  {
                    title: t("MD5"),
                    field: "md5",
                    render: (data) => (
                      <>
                        {
                          <Link
                            style={{ textDecoration: "underline" }}
                            href={"/ng/md5/" + data.md5 + "/report/"}
                          >
                            {data.md5 ? data.md5 : ""}
                          </Link>
                        }
                      </>
                    ),
                  },
                  {
                    title: t("URL"),
                    field: "url",
                    render: (data) => (
                      <>
                        {
                          <Link
                            style={{
                              textDecoration: "underline",
                              width: "30vw",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              display: "block",
                            }}
                            href={"/ng/url/" + data.url_md5 + "/report/"}
                          >
                            {data.url ? data.url : ""}
                          </Link>
                        }
                      </>
                    ),
                  },
                  {
                    title: t("Domain"),
                    field: "hostname",
                    render: (data) => (
                      <>
                        {
                          <Link
                            style={{ textDecoration: "underline" }}
                            href={"/ng/domain/" + data.hostname + "/report/"}
                          >
                            {data.hostname ? data.hostname : ""}
                          </Link>
                        }
                      </>
                    ),
                  },
                  {
                    title: t("IP"),
                    field: "ip",
                    render: (data) => (
                      <>
                        {
                          <Link
                            style={{ textDecoration: "underline" }}
                            href={"/ng/ip/" + data.ip + "/report/"}
                          >
                            {data.ip ? data.ip : ""}
                          </Link>
                        }
                      </>
                    ),
                  },
                  {
                    title: t("Port"),
                    field: "port",
                  },
                  {
                    title: t("Tags"),
                    field: "tags",
                    optional: true,
                    render: (data) =>
                      data?.tags?.map((tag, i) => (
                        <Chip
                          key={i}
                          label={tag?.name}
                          size="small"
                          style={{ marginRight: "5px" }}
                          icon={
                            <FiberManualRecord
                              style={{
                                color: tag.colour,
                                stroke: theme.palette.text.primary,
                                strokeWidth: isSimilar(
                                  tag.colour,
                                  theme.palette.type === "light"
                                    ? theme.palette.grey[300]
                                    : theme.palette.grey[700],
                                  10
                                )
                                  ? "1px"
                                  : "0px",
                              }}
                            />
                          }
                          deleteIcon={
                            <Link
                              href={
                                loganMispURL() +
                                "/attributes/search/tags:" +
                                tag.id
                              }
                              target="_blank"
                              rel="noopener"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Tooltip
                                title={t("See all attributes with this tag")}
                                arrow
                                placement="top"
                              >
                                <OpenInNewOutlined fontSize="inherit" />
                              </Tooltip>
                            </Link>
                          }
                          onDelete={() => {}}
                        />
                      )),
                  },
                  {
                    title: t("Comments"),
                    field: "comment",
                    optional: true,
                  },
                ]}
              />
            ) : event.type === "BE-GRIDPATROL" ? (
              <SimpleTableCardView
                title={t("Attributes")}
                data={cloneDeep(event.attributes)}
                headers={[
                  {
                    title: t("IP"),
                    field: "ip",
                    render: (data) => (
                      <>
                        {
                          <Link
                            style={{ textDecoration: "underline" }}
                            href={"/ng/ip/" + data.ip + "/report/"}
                          >
                            {data.ip ? data.ip : ""}
                          </Link>
                        }
                      </>
                    ),
                  },
                  {
                    title: t("Port"),
                    field: "port",
                  },
                  {
                    title: t("Timestamp"),
                    field: "datetime",
                    render: (data) => (
                      <>
                        {data.datetime &&
                          new Date(data.datetime).toLocaleString(
                            i18n.language + "-CA"
                          )}
                      </>
                    ),
                  },
                  {
                    title: t("Tags"),
                    field: "tags",
                    optional: true,
                    enabled: true,
                    render: (data) =>
                      data?.tags?.map((tag, i) => (
                        <Chip
                          key={i}
                          label={tag?.name}
                          size="small"
                          style={{ marginRight: "5px" }}
                          icon={
                            <FiberManualRecord
                              style={{
                                color: tag.colour,
                                stroke: theme.palette.text.primary,
                                strokeWidth: isSimilar(
                                  tag.colour,
                                  theme.palette.type === "light"
                                    ? theme.palette.grey[300]
                                    : theme.palette.grey[700],
                                  10
                                )
                                  ? "1px"
                                  : "0px",
                              }}
                            />
                          }
                          deleteIcon={
                            <Link
                              href={
                                loganMispURL() +
                                "/attributes/search/tags:" +
                                tag.id
                              }
                              target="_blank"
                              rel="noopener"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Tooltip
                                title={t("See all attributes with this tag")}
                                arrow
                                placement="top"
                              >
                                <OpenInNewOutlined fontSize="inherit" />
                              </Tooltip>
                            </Link>
                          }
                          onDelete={() => {}}
                        />
                      )),
                  },
                  {
                    title: t("Comments"),
                    field: "comment",
                    optional: true,
                  },
                ]}
              />
            ) : event.type === "BE-PUPPETMASTER" ? (
              <>
                <SimpleTableCardView
                  title={t("Attributes")}
                  data={cloneDeep(event.attributes)}
                  headers={[
                    {
                      title: t("Sample"),
                      field: "beaconing_sample",
                      optional: false,
                      enabled:
                        event.attributes.filter(
                          (attr) => !!attr.beaconing_sample
                        ).length > 0,
                      render: (data) => (
                        <>
                          {
                            <Link
                              style={{ textDecoration: "underline" }}
                              href={
                                "/ng/md5/" + data.beaconing_sample + "/report/"
                              }
                            >
                              {data.beaconing_sample
                                ? data.beaconing_sample
                                : ""}
                            </Link>
                          }
                        </>
                      ),
                    },
                    {
                      title: t("Domain"),
                      field: "hostname",
                      render: (data) => (
                        <>
                          {
                            <Link
                              style={{ textDecoration: "underline" }}
                              href={"/ng/domain/" + data.hostname + "/report/"}
                            >
                              {data.hostname ? data.hostname : ""}
                            </Link>
                          }
                        </>
                      ),
                    },
                    {
                      title: t("IP"),
                      field: "ip",
                      render: (data) => (
                        <>
                          {
                            <Link
                              style={{ textDecoration: "underline" }}
                              href={"/ng/ip/" + data.ip + "/report/"}
                            >
                              {data.ip ? data.ip : ""}
                            </Link>
                          }
                        </>
                      ),
                    },
                    {
                      title: t("Port"),
                      field: "panel_port",
                    },
                    {
                      title: t("Gate URL"),
                      field: "gate_url",
                      optional: false,
                      enabled:
                        event.attributes.filter((attr) => !!attr.gate_url)
                          .length > 0,
                      render: (data) => (
                        <>
                          {
                            <Link
                              style={{
                                textDecoration: "underline",
                                width: "20vw",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                display: "block",
                              }}
                              href={"/ng/url/" + data.gate_url_md5 + "/report/"}
                            >
                              {data.gate_url ? data.gate_url : ""}
                            </Link>
                          }
                        </>
                      ),
                    },
                    {
                      title: t("cPanel URL"),
                      field: "url",
                      render: (data) => (
                        <>
                          {
                            <Link
                              style={{
                                textDecoration: "underline",
                                width: "20vw",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                display: "block",
                              }}
                              href={
                                "/ng/url/" + data.cpanel_url_md5 + "/report/"
                              }
                            >
                              {data.cpanel_url ? data.cpanel_url : ""}
                            </Link>
                          }
                        </>
                      ),
                    },
                    {
                      title: t("Screenshot"),
                      field: "attachment",
                      render: (data) => (
                        <>
                          {data.panel_screenshot.name && (
                            <ClickAwayListener
                              onClickAway={() => setShowScreenshot(null)}
                            >
                              <ImageTooltip
                                title={
                                  <img
                                    src={data.panel_screenshot.image}
                                    alt={data.panel_screenshot.name}
                                    style={{
                                      width: "min(50vw, 700px)",
                                      margin: "7px 5px",
                                    }}
                                  />
                                }
                                placement="left"
                                open={showScreenshot === data.tableData.id}
                                onClose={() => setShowScreenshot(null)}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                arrow
                              >
                                <img
                                  src={data.panel_screenshot.image}
                                  alt={data.panel_screenshot.name}
                                  style={{
                                    height: "50px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    setShowScreenshot(
                                      showScreenshot === data.tableData.id
                                        ? false
                                        : data.tableData.id
                                    )
                                  }
                                />
                              </ImageTooltip>
                            </ClickAwayListener>
                          )}
                        </>
                      ),
                    },
                    {
                      title: t("Tags"),
                      field: "tags",
                      optional: true,
                      render: (data) =>
                        data?.tags?.map((tag, i) => (
                          <Chip
                            key={i}
                            label={tag?.name}
                            size="small"
                            style={{ marginRight: "5px" }}
                            icon={
                              <FiberManualRecord
                                style={{
                                  color: tag.colour,
                                  stroke: theme.palette.text.primary,
                                  strokeWidth: isSimilar(
                                    tag.colour,
                                    theme.palette.type === "light"
                                      ? theme.palette.grey[300]
                                      : theme.palette.grey[700],
                                    10
                                  )
                                    ? "1px"
                                    : "0px",
                                }}
                              />
                            }
                            deleteIcon={
                              <Link
                                href={
                                  loganMispURL() +
                                  "/attributes/search/tags:" +
                                  tag.id
                                }
                                target="_blank"
                                rel="noopener"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Tooltip
                                  title={t("See all attributes with this tag")}
                                  arrow
                                  placement="top"
                                >
                                  <OpenInNewOutlined fontSize="inherit" />
                                </Tooltip>
                              </Link>
                            }
                            onDelete={() => {}}
                          />
                        )),
                    },
                    {
                      title: t("Comments"),
                      field: "comment",
                      optional: true,
                    },
                  ]}
                />
              </>
            ) : (
              <SimpleTableCardView
                title={t("Attributes")}
                data={cloneDeep(event.attributes)}
                headers={[
                  ...(event.object_count > 0
                    ? [
                        {
                          icon: "object_info",
                          width: "20px",
                          customFilterAndSearch: (term, rowData) =>
                            rowData.object_id === term,
                          render: (data) => (
                            <div
                              style={{
                                display: "flex",
                                alignContent: "center",
                              }}
                            >
                              {data.object_id && (
                                <Tooltip
                                  title={
                                    <div
                                      style={{
                                        whiteSpace: "pre-line",
                                      }}
                                    >
                                      <b>{t("ID") + ": "}</b>
                                      {data.object_id + "\n"}
                                      <b>{t("Name") + ": "}</b>
                                      {data.object_name + "\n"}
                                      <b>{t("Description") + ": "}</b>
                                      {data.object_description}
                                      {data.references?.length > 0 ? (
                                        <>
                                          {"\n"}
                                          <b>{t("References") + ": "}</b>
                                          {data.references.join(", ")}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      {data.referenced_by?.length > 0 ? (
                                        <>
                                          {"\n"}
                                          <b>{t("Referenced by") + ": "}</b>
                                          {data.referenced_by.join(", ")}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  }
                                  arrow
                                  interactive
                                  placement="right"
                                >
                                  <Info fontSize="small" />
                                </Tooltip>
                              )}
                              {data.parent_id && (
                                <SubdirectoryArrowRight
                                  fontSize="small"
                                  style={{
                                    color: theme.palette.action.disabled,
                                  }}
                                />
                              )}
                            </div>
                          ),
                        },
                      ]
                    : []),
                  {
                    title: t("ID"),
                    field: "id",
                  },
                  {
                    title: t("Date"),
                    field: "timestamp",
                    render: (data) => (
                      <>
                        {data.timestamp &&
                          new Date(data.timestamp * 1000).toLocaleDateString(
                            i18n.language + "-CA"
                          )}
                      </>
                    ),
                  },
                  {
                    title: t("Category"),
                    field: "category",
                  },
                  {
                    title: t("Type"),
                    field: "type",
                  },
                  {
                    title: t("Value"),
                    field: "value",
                  },
                  {
                    title: t("Tags"),
                    field: "tags",
                    optional: true,
                    render: (data) =>
                      data?.tags?.map((tag, i) => (
                        <Chip
                          key={i}
                          label={tag?.name}
                          size="small"
                          style={{ marginRight: "5px" }}
                          icon={
                            <FiberManualRecord
                              style={{
                                color: tag.colour,
                                stroke: theme.palette.text.primary,
                                strokeWidth: isSimilar(
                                  tag.colour,
                                  theme.palette.type === "light"
                                    ? theme.palette.grey[300]
                                    : theme.palette.grey[700],
                                  10
                                )
                                  ? "1px"
                                  : "0px",
                              }}
                            />
                          }
                          deleteIcon={
                            <Link
                              href={
                                loganMispURL() +
                                "/attributes/search/tags:" +
                                tag.id
                              }
                              target="_blank"
                              rel="noopener"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Tooltip
                                title={t("See all attributes with this tag")}
                                arrow
                                placement="top"
                              >
                                <OpenInNewOutlined fontSize="inherit" />
                              </Tooltip>
                            </Link>
                          }
                          onDelete={() => {}}
                        />
                      )),
                  },
                  {
                    title: t("Comments"),
                    field: "comment",
                    optional: true,
                  },
                ]}
                treeData={(row, rows) =>
                  rows.find((a) => a.object_id && a.object_id === row.parent_id)
                }
              />
            )}
          </Grid>
        </Grid>
      </>
    );
  }
}
