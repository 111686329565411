import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchTags, tagsSelector } from "slices/tags";

import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
} from "@material-ui/core";

import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import PageFullWidth from "../../../../../commons/components/layout/pages/PageFullWidth";
import PageHeader from "../../../../../commons/components/layout/pages/PageHeader";
import { useStyles } from "../common/sharedStyles";
import { UserCreatedTags } from "./views/AllTags";
import { ParsedError, parseErrorTitle } from "../common/ParsedError";

export function TagList() {
  const { t } = useTranslation();
  const title = t("Malware Tags");

  return (
    <>
      <Helmet>
        <title>{["BEAVER", title].join(" ")}</title>
        <meta
          name="description"
          content={["BEAVER", title, "-", t("page.home.description")].join(" ")}
        />
      </Helmet>
      <PageHeader title={title} />
      <PageFullWidth>
        <TagListReport />
      </PageFullWidth>
    </>
  );
}

export type TagListReportProps = { testing?: boolean };

export function TagListReport({ testing }: TagListReportProps) {
  const [retry, setRetry] = React.useState(0);
  const classes = useStyles();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { tags, error } = useSelector(tagsSelector);

  // will run on first mount
  useEffect(() => {
    dispatch(fetchTags(tags));
  }, [dispatch, tags, retry]);

  // start by just showing the summary once loaded
  if (!error && !tags) {
    // show a loading bar until it loads, and show the error if there is one
    return (
      <>
        {t("Loading")} ...
        <LinearProgress />
      </>
    );
  } else if (error) {
    return (
      <Card>
        <CardHeader
          className={classes.ch}
          avatar={<Avatar className={classes.errorIcon}>!</Avatar>}
          title={parseErrorTitle(error)}
        />
        <CardContent>
          <ParsedError error={error} />
        </CardContent>
        <CardActions>
          <Button size="small" onClick={() => setRetry(retry + 1)}>
            {t("Retry")}
          </Button>
        </CardActions>
      </Card>
    );
  } else {
    return (
      <Grid container>
        {Object.values(tags)?.length ? (
          <Grid item xs={12}>
            <UserCreatedTags
              customTags={Object.values(tags)}
              testing={testing}
            />
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Card>
              <CardHeader className={classes.ch} title={t("Tags")} />
              <CardContent>
                {t("Tags are still loading, please refresh the page.")}
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    );
  }
}
