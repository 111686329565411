import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";

import { useDispatch, useSelector } from "react-redux";
import { uploadSampleSelector, fetchReport } from "slices/uploadsamples";
import { fetchSources, sourceSelector } from "slices/sources";

import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  LinearProgress,
} from "@material-ui/core";

import { LinkWrap } from "components/beaver/components/report/common/LinkWrap";

import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import PageFullWidth from "../../../../commons/components/layout/pages/PageFullWidth";
import PageHeader from "../../../../commons/components/layout/pages/PageHeader";

import { useStyles } from "../report/common/sharedStyles";
import { ParsedError, parseErrorTitle } from "../report/common/ParsedError";
import { ReportType } from "../../models/report/reportType";
import { SimpleTableView } from "../report/common/SimpleTableView";

// TODO - test file ?

export function UploadedSamples() {
  const { t } = useTranslation();

  const title = t("Uploaded Samples");
  return (
    <>
      <Helmet>
        <title>{["BEAVER", title].join(" ")}</title>
        <meta
          name="description"
          content={["BEAVER", title, "-", t("page.home.description")].join(" ")}
        />
      </Helmet>
      <PageHeader title={title} />
      <PageFullWidth>
        <UploadedSamplesList />
      </PageFullWidth>
    </>
  );
}

export function UploadedSamplesList() {
  const [retry, setRetry] = useState(0);
  const classes = useStyles();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { responseData, reportType, error } = useSelector(uploadSampleSelector);

  useEffect(() => {
    dispatch(fetchReport(ReportType.UPLOAD_SAMPLE));
  }, [dispatch, retry]);
  // start by just showing the summary once loaded
  if (
    !error &&
    (!responseData || !reportType || reportType !== ReportType.UPLOAD_SAMPLE)
  ) {
    // show a loading bar until it loads, and show the error if there is one
    // TODO - translate the loading text
    return (
      <>
        {t("Loading")} ...
        <LinearProgress />
      </>
    );
  } else if (error) {
    // debugger;
    return (
      <Card>
        <CardHeader
          className={classes.ch}
          avatar={<Avatar className={classes.errorIcon}>!</Avatar>}
          title={parseErrorTitle(error)}
        />
        <CardContent>
          <ParsedError error={error} />
        </CardContent>
        <CardActions>
          {/* By incrementing the state value which is monitoired by useEffect, the useEffect is triggered*/}
          <Button size="small" onClick={() => setRetry(retry + 1)}>
            {t("Retry")}
          </Button>
        </CardActions>
      </Card>
    );
  } else {
    //TODO add upload button that links to /upload
    return (
      <UploadedSamplesTable
        data={responseData[ReportType.UPLOAD_SAMPLE.toString()]}
      />
    );
  }
}

export interface UploadedSampleData {
  fileName: string;
  md5: string;
  sourceID: string;
  uploadedOn: string;
  userName: string;
}

export type UploadedSamplesTableProps = {
  data: UploadedSampleData[];
  testing?: boolean;
};

export function UploadedSamplesTable({
  data,
  testing,
}: UploadedSamplesTableProps) {
  const { t } = useTranslation();
  const [onlyMe, setOnlyMe] = React.useState(false);

  const dispatch = useDispatch();
  const { sources } = useSelector(sourceSelector);

  React.useEffect(() => {
    dispatch(fetchSources(sources));
  }, [dispatch, sources]);

  const getHeaders = () => {
    let headers = [
      {
        title: "MD5",
        field: "md5",
        render: (r) => (
          <Link to={["/md5", r.md5, "report"].join("/")}>
            <LinkWrap>{r.md5}</LinkWrap>
          </Link>
        ),
      },
      { title: t("File Name"), field: "fileName" },
      {
        title: t("Source ID"),
        field: "sourceID",
        render: (r) =>
          sources && sources[r.sourceID]
            ? sources[r.sourceID].displayString
            : r.sourceID,
      },
      { title: t("Added On"), field: "uploadedOn" },
    ];

    return headers;
  };

  return (
    <>
      <Link to="/upload" style={{ textDecoration: "none" }}>
        <Button variant="contained" color="primary">
          {t("Upload a Sample")}
        </Button>
      </Link>
      <Button
        style={{ marginLeft: "0.5em" }}
        variant="contained"
        color="primary"
        onClick={() => setOnlyMe(!onlyMe)}
      >
        {onlyMe ? t("All uploaded") : t("Uploaded by me")}
      </Button>
      <p>{t("Showing the last 1000 samples uploaded only")}</p>
      <SimpleTableView
        data={_.cloneDeep(data.filter((r) => !onlyMe || r.userName))}
        headers={getHeaders()}
        testing={testing}
      />
    </>
  );
}
