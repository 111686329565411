import { createSlice } from "@reduxjs/toolkit";

export type SearchState = {
  searchText: string;
  searchTerms: any;
  searchResults: any;
  error: any;
  searchTextHistory: string[];
  searchTermsHistory: any[];
};

export const slice = createSlice({
  name: "search",
  initialState: {
    searchText: null,
    searchResults: null,
    error: null,
    searchTextHistory: [],
    searchTermsHistory: [],
  } as SearchState,
  reducers: {
    setSearchText: (state, { payload }) => {
      state.searchText = payload;
      state.searchTextHistory = Array.from(
        new Set([...state.searchTextHistory, payload])
      );
    },
    setSearchTerms: (state, { payload }) => {
      state.searchTerms = payload;
      state.searchTermsHistory = Array.from(
        new Set([...state.searchTermsHistory, JSON.stringify(payload)])
      );
    },
    setSearchResults: (state, { payload }) => {
      state.searchResults = payload;
    },
    setSearchError: (state, { payload }) => {
      state.error = payload;
    },
  },
});

export const {
  setSearchText,
  setSearchTerms,
  setSearchResults,
  setSearchError,
} = slice.actions;

export default slice.reducer;

// use the Redux chrome plugin to inspect the state
export const searchSelector = (state: any) => state.search;
