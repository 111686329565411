import { isGeekweek } from "components/beaver/helpers/ServerProperties";

export const BorealisModules: string[] = isGeekweek()
  ? [
      "STONEWALL",
      "ARIN",
      "AUWL",
      "MOOSE",
      "ALPHABETSOUP",
      "TOP1MILLION",
      "DEVICE",
    ]
  : [
      "MAXMIND",
      "NCTNS",
      "STONEWALL",
      "ARIN",
      "SPUR",
      // "IP2LOCATION",
      "NEUSTAR",
      "AUWL",
      "MOOSE",
      "ALPHABETSOUP",
      "URLSCAN",
      "TOP1MILLION",
      // "DNSDB",
      "VIRUSTOTAL",
      "SAFEBROWSING",
      "DEVICE",
      "DNS",
      "BEAVER",
    ];

/**
 * This function is uesed to determine if there is any actual data in the module response
 *
 * a zero indicates that there is none.  a negative 1 indicates that no determination could be made
 */
export function getModuleCount(moduleData: any): number {
  if (Array.isArray(moduleData)) return moduleData.length;
  else if ("object" === typeof moduleData)
    return Object.keys(moduleData).length;
  // this shouldn't happen, but it might
  else return -1;
}

// the above is too generic, there should be a module by module approach, e.g. APLHABITSOUP only 1 if isDGA is true
// HOWEVER - the above should still be used to determine if you should show the module in the report or not.
export function getModuleCountOrStringForChip(
  moduleData: any,
  moduleName: string
): number | string {
  // based on the module Name, try to figure out what the count really should be
  switch (moduleName) {
    case "ALPHABETSOUP":
      return moduleData["isDGA"] ? "DGA" : 0;
    case "SAFEBROWSING":
      return moduleData.length ? moduleData[0]["decision"] : null;
    case "STONEWALL":
      return moduleData["decision"];
    case "TOP1MILLION":
      return moduleData.filter((m) => m["inList"]).length;
    case "VIRUSTOTAL":
      return moduleData["avResults"]
        ? moduleData["avResults"]["malicious"] +
            moduleData["avResults"]["suspicious"]
        : null;
    case "AUWL":
      return Array.from(new Set(moduleData.map((f) => f["clusterName"]))).join(
        ", "
      );
    case "MOOSE":
      return Array.from(new Set(moduleData.map((f) => f["family"]))).join(", ");
    default:
      return getModuleCount(moduleData);
  }
}
