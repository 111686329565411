import { createSlice } from "@reduxjs/toolkit";
import Axios from "axios";
import { MISPLoganReport } from "../components/beaver/helpers/ServerProperties";
import md5 from "md5";

export type EventsState = {
  event: any;
  error: any;
};

export const slice = createSlice({
  name: "misplogan",
  initialState: { event: null, error: null } as EventsState,
  reducers: {
    setEvent: (state, { payload }) => {
      state.event = payload;
    },
    clearEvent: (state) => {
      state = { event: null, error: null };
    },
    setError: (state, { payload }) => {
      state.error = payload;
    },
  },
});

export default slice.reducer;

export const eventSelector = (state) => state.misplogan;

export const fetchReport =
  (eventid: string, forceRefetch: boolean = false) =>
  async (dispatch, getState) => {
    const user = localStorage.getItem("currentUser")
      ? JSON.parse(localStorage.getItem("currentUser"))
      : {};

    if (getState().misplogan.event?.id !== eventid || forceRefetch) {
      Axios.get(MISPLoganReport(eventid), {
        auth:
          user.server && !user.server.includes("localhost")
            ? {
                username: user ? user.username : null,
                password: user
                  ? user.onetime
                    ? window.atob(eval(window.atob(user.onetime))).substr(13)
                    : null
                  : null,
              }
            : null,
      }).then(
        (response) => {
          let res = response.data?.response[0]?.Event;

          if (!res) {
            return dispatch(
              slice.actions.setError(
                JSON.stringify({
                  status: 404,
                  message: "Event ID not found",
                })
              )
            );
          }

          let data = {
            id: res.id,
            uuid: res.uuid,
            creator_org: res.Org?.name,
            protected: res.protected,
            date: res.date,
            threat_level_id: res.threat_level_id,
            analysis: res.analysis,
            info: res.info,
            type: res.info?.slice(0, 2),
            published: res.published,
            publish_timestamp: res.publish_timestamp,
            timestamp: res.timestamp,
            attribute_count: parseInt(res.attribute_count),
            object_count: res.Object?.length,
            tags: res.Tag,
            attributes: [],
          };

          if (data.info.startsWith("TA")) {
            data.type = "TA";
          } else if (data.info.startsWith("BE")) {
            data.tags?.some((tag) => {
              const match = tag.name.match(/cccs:analytics="([^"]+)"/);

              if (match) {
                data.type = "BE-" + match[1].toUpperCase();
              }

              return match;
            });
          }

          if (data.type === "BE-IRONCLAW") {
            let objectsData = {};

            res.Object?.forEach((object) => {
              objectsData[object.uuid] = object.Attribute;
            });

            data.attributes.push(
              ...res.Object?.filter((object) => object.name === "file").flatMap(
                (object) => {
                  let entries = [];

                  object.ObjectReference.forEach((ref) => {
                    let referenceAttributes = {};

                    objectsData[ref.referenced_uuid].forEach((attribute) => {
                      referenceAttributes[attribute.object_relation] =
                        attribute.value;
                    });

                    let objectAttribute = object.Attribute?.filter(
                      (attr) => attr.type === "md5"
                    )[0];

                    entries.push({
                      sid: referenceAttributes["sid"].split(","),
                      md5: objectAttribute.value,
                      ip: referenceAttributes["ip-dst"],
                      port: referenceAttributes["dst-port"],
                      url: referenceAttributes["url"],
                      url_md5: referenceAttributes["url"]
                        ? md5(referenceAttributes["url"])
                        : undefined,
                      hostname: referenceAttributes["hostname"],
                      hostname_md5: referenceAttributes["hostname-md5"],
                      tags: objectAttribute.Tag,
                      comment: objectAttribute.comment,
                    });
                  });

                  return entries;
                }
              )
            );
          } else if (data.type === "BE-GRIDPATROL") {
            data.attributes.push(
              ...res.Object?.filter((object) => object.name === "ip-port").map(
                (object) => {
                  let objectData = {};
                  let tagsData = {};

                  object.Attribute?.forEach((attr) => {
                    objectData[attr.type === "ip-dst" ? "ip" : attr.type] =
                      attr.value;
                    attr.Tag?.forEach((tag) => {
                      tagsData[tag.id] = {
                        id: tag.id,
                        name: tag.name,
                        colour: tag.colour,
                      };
                    });
                  });

                  objectData["tags"] = Object.values(tagsData);
                  objectData["comment"] = object.comment;

                  return objectData;
                }
              )
            );
          } else if (data.type === "BE-PUPPETMASTER") {
            data.attributes.push(
              ...res.Object?.filter(
                (object) => object.name === "cpanel-url"
              ).map((object) => {
                let objectData = {};
                let tagsData = {};

                object.Attribute?.forEach((attr) => {
                  if (attr.object_relation === "panel_screenshot") {
                    objectData["panel_screenshot"] = {
                      name: attr.value,
                      image: "data:image/png;base64, " + attr.data,
                    };
                  } else if (attr.object_relation === "panel_url") {
                    objectData["cpanel_url"] = attr.value;
                    objectData["cpanel_url_md5"] = attr.value
                      ? md5(attr.value)
                      : null;
                  } else if (attr.object_relation === "gate_url") {
                    objectData["gate_url"] = attr.value;
                    objectData["gate_url_md5"] = attr.value
                      ? md5(attr.value)
                      : null;
                  } else {
                    objectData[attr.object_relation] = attr.value;
                  }

                  attr.Tag?.forEach((tag) => {
                    tagsData[tag.id] = {
                      id: tag.id,
                      name: tag.name,
                      colour: tag.colour,
                    };
                  });
                });

                objectData["tags"] = Object.values(tagsData);
                objectData["comment"] = object.comment;

                return objectData;
              })
            );
          } else {
            let referencedByMap = {};

            data.attributes.push(
              ...res.Attribute?.flatMap((attribute) => {
                return attribute.comment !== "beaver-report"
                  ? [
                      {
                        id: attribute.id,
                        timestamp: attribute.timestamp,
                        category: attribute.category,
                        type: attribute.type,
                        value: attribute.value,
                        comment: attribute.comment,
                        tags: attribute.Tag,
                      },
                    ]
                  : [];
              }),
              ...res.Object?.flatMap((object) => [
                {
                  object_id: object.id,
                  object_name: object.name,
                  object_description: object.description,
                  object_timestamp: object.timestamp,
                  references: object.ObjectReference.map((ref) => {
                    referencedByMap[ref.referenced_id] = referencedByMap[
                      ref.referenced_id
                    ]
                      ? referencedByMap[ref.referenced_id].push(object.id)
                      : [object.id];
                    return ref.referenced_id;
                  }),
                },
                ...object.Attribute?.map((attribute) => {
                  return {
                    id: attribute.id,
                    timestamp: attribute.timestamp,
                    category: attribute.category,
                    type: attribute.type,
                    value: attribute.value,
                    comment: attribute.comment,
                    tags: attribute.Tag,
                    parent_id: object.id,
                  };
                }),
              ])
            );

            data.attributes?.forEach((attribute) => {
              if (attribute.object_id && attribute.object_id in referencedByMap)
                attribute.referenced_by = referencedByMap[attribute.object_id];
            });
          }

          dispatch(slice.actions.setEvent(data));
        },
        (error) => {
          dispatch(
            slice.actions.setError(
              error.response
                ? JSON.stringify(error.response)
                : JSON.stringify({
                    status: error.message.match(/\d+/g)
                      ? parseInt(error.message.match(/\d+/g)[0])
                      : 500,
                    message: error.message,
                    data: JSON.stringify(error),
                  })
            )
          );
        }
      );
    } else {
      dispatch(slice.actions.clearEvent());
    }
  };

