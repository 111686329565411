import { createSlice } from "@reduxjs/toolkit";
import Axios from "axios";
import { PreviewSample } from "../components/beaver/helpers/ServerProperties";
import { samplePreviewData } from "../components/beaver/models/report/md5/md5ReportModel";

export type PreviewState = {
  md5: string;
  previewData: samplePreviewData;
  error: string;
};

export const slice = createSlice({
  name: "preview",
  initialState: { md5: null, previewData: null, error: null } as PreviewState,
  reducers: {
    setPreview: (state, { payload }) => {
      state.previewData = payload;
    },
    clearPreview: (state) => {
      state = { md5: null, previewData: null, error: null };
    },
    setError: (state, { payload }) => {
      state.error = payload;
    },
  },
});

export default slice.reducer;

export const previewSelector = (state) => state.preview;

export const fetchPreview =
  (md5: string, forceRefetch: boolean = false) =>
  async (dispatch, getState) => {
    const user = localStorage.getItem("currentUser")
      ? JSON.parse(localStorage.getItem("currentUser"))
      : {};

    if (getState().preview.md5 !== md5 || forceRefetch) {
      Axios.get(PreviewSample(md5), {
        auth:
          user.server && !user.server.includes("localhost")
            ? {
                username: user ? user.username : null,
                password: user
                  ? user.onetime
                    ? window.atob(eval(window.atob(user.onetime))).substr(13)
                    : null
                  : null,
              }
            : null,
      }).then(
        (response) => {
          dispatch(slice.actions.setPreview(response.data));
        },
        (error) => {
          dispatch(slice.actions.setError(error?.response?.data));
        }
      );
    } else {
      dispatch(slice.actions.clearPreview());
    }
  };

