import React from "react";
import { useTranslation } from "react-i18next";
import Axios, { Method } from "axios";
import { Box, Button, TextField } from "@material-ui/core";
import { SaveSearchJSON } from "../../helpers/ServerProperties";
import { RecentSearch } from "../../models/search/SearchResult";

export interface SearchSaveButtonsProps {
  terms: any;
  recentSearches: RecentSearch[];
  setRecentSearches: React.Dispatch<RecentSearch[]>;
  setRecentSearchValue: React.Dispatch<number>;
}

export function SearchSaveButtons({
  terms,
  recentSearches,
  setRecentSearches,
  setRecentSearchValue,
}: SearchSaveButtonsProps) {
  const [saveClicked, setSaveClicked] = React.useState(false);
  const [saveComment, setSaveComment] = React.useState("");
  const [savedId, setSavedId] = React.useState("");
  const [saveError, setSaveError] = React.useState("");
  const { t } = useTranslation();

  const user = localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser"))
    : {};

  return (
    <>
      <Box mt={1} style={{ display: "flex", flexDirection: "row-reverse" }}>
        {!saveClicked ? (
          <Button
            color="primary"
            variant="outlined"
            onClick={() => setSaveClicked(true)}
          >
            {t("Save this Search")}
          </Button>
        ) : (
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "0.5em",
            }}
          >
            <TextField
              label={t("Enter a comment (optional)")}
              value={saveComment}
              onChange={(event) => {
                setSaveComment("" + event.target.value);
              }}
            />
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                let payload = {
                  msg: {
                    comment: saveComment,
                    terms: terms,
                  },
                };

                console.log(payload);

                Axios({
                  method: SaveSearchJSON().method as Method,
                  url: SaveSearchJSON().url,
                  data: payload,
                  headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                  },
                  auth:
                    user.server && !user.server.includes("localhost")
                      ? {
                          username: user ? user.username : null,
                          password: user
                            ? user.onetime
                              ? // eslint-disable-next-line no-eval
                                window
                                  // eslint-disable-next-line no-eval
                                  .atob(eval(window.atob(user.onetime)))
                                  .substr(13)
                              : null
                            : null,
                        }
                      : null,
                }).then(
                  (res) => {
                    // show that it was saved
                    setSavedId(res.data);
                    setTimeout(() => setSavedId(""), 3000);
                    setSaveClicked(false);
                    // add the search to the list, then select it
                    setRecentSearches([
                      {
                        searchId: res.data,
                        comment: saveComment,
                      },
                      ...recentSearches,
                    ]);
                    setRecentSearchValue(res.data);
                  },
                  (err) => {
                    // show there was an error
                    console.log(err);
                    setSaveError(err);
                    setTimeout(() => setSaveError(""), 3000);
                  }
                );
              }}
            >
              {t("Save")}
            </Button>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => setSaveClicked(false)}
            >
              {t("Cancel")}
            </Button>
          </Box>
        )}
      </Box>
      {!!savedId && (
        <Box mt={2}>
          <span>
            {t("Saved")}: {savedId}
          </span>
        </Box>
      )}
      {!!saveError && (
        <Box mt={1}>
          <span style={{ color: "darkRed" }}>{t("Could not Save")}</span>
        </Box>
      )}
    </>
  );
}
