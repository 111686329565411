import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  LinearProgress,
} from "@material-ui/core";

import { beaverNGSelector, fetchReport } from "../../../../../slices/beaverng";
import PageFullWidth from "../../../../../commons/components/layout/pages/PageFullWidth";
import PageHeader from "../../../../../commons/components/layout/pages/PageHeader";
import { useStyles } from "../common/sharedStyles";
import { ParsedError, parseErrorTitle } from "../common/ParsedError";
import { PhishingClusterTable } from "./views/PhishingClusterTable";

import { ReportType } from "../../../models/report/reportType";

export function PhishingClustersList() {
  const { t } = useTranslation();

  const title = t("Phishing Kit Clusters");

  return (
    <>
      <Helmet>
        <title>{["BEAVER", title].join(" ")}</title>
        <meta
          name="description"
          content={["BEAVER", title, "-", t("page.home.description")].join(" ")}
        />
      </Helmet>
      <PageHeader title={title} />
      <PageFullWidth>
        <PhishingClustersListReport />
      </PageFullWidth>
    </>
  );
}

export type PhishingClustersListReportProps = {
  testing?: boolean;
};

export function PhishingClustersListReport({
  testing,
}: PhishingClustersListReportProps) {
  const [retry, setRetry] = useState(0);
  const classes = useStyles();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { responseData, reportType, error } = useSelector(beaverNGSelector);

  useEffect(() => {
    dispatch(fetchReport(ReportType.PHISHING_LIST));
  }, [dispatch, retry]);

  console.log(responseData);

  // start by just showing the summary once loaded
  if (
    !error &&
    (!responseData || !reportType || reportType !== ReportType.PHISHING_LIST)
  ) {
    // show a loading bar until it loads, and show the error if there is one
    return (
      <>
        {t("Loading")} ...
        <LinearProgress />
      </>
    );
  } else if (error) {
    return (
      <Card>
        <CardHeader
          className={classes.ch}
          avatar={<Avatar className={classes.errorIcon}>!</Avatar>}
          title={parseErrorTitle(error)}
        />
        <CardContent>
          <ParsedError error={error} />
        </CardContent>
        <CardActions>
          <Button size="small" onClick={() => setRetry(retry + 1)}>
            {t("Retry")}
          </Button>
        </CardActions>
      </Card>
    );
  } else {
    return (
      <>
        <Box mt={4}>
          <PhishingClusterTable
            testing={testing}
            clusters={responseData.clusterFrequency}
          />
        </Box>
      </>
    );
  }
}
