import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MD5 from "crypto-js/md5";

import { useDispatch, useSelector } from "react-redux";
import { fetchReport, urlReportSelector } from "slices/urlreport";
import { Flag } from "components/beaver/helpers/Flag";

import {
  useTheme,
  useMediaQuery,
  Box,
  LinearProgress,
  Button,
  Card,
  CardActions,
  CardContent,
  Avatar,
  CardHeader,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  List,
  ListItem,
  Paper,
  Grid,
  Badge,
  Tooltip,
} from "@material-ui/core";
import { LinkWrap } from "components/beaver/components/report/common/LinkWrap";

import MenuIcon from "@material-ui/icons/Menu";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import { URLSummary } from "./views/URLSummary";
import { DOMView } from "./views/DOMView";
import { SimpleTableCardView } from "../common/SimpleTableView";
import { CommunicationsView } from "./views/CommunicationsView";
import PivotTitleView from "./views/PivotTitleView";
import { PivotFaviconView } from "./views/PivotFaviconView";
import { PivotActualURLView } from "./views/PivotActualURLView";
import { TransformationsView } from "./views/TransformationsView";

import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import ForumOutlinedIcon from "@material-ui/icons/ForumOutlined";
import DnsOutlinedIcon from "@material-ui/icons/DnsOutlined";
import FullscreenOutlinedIcon from "@material-ui/icons/FullscreenOutlined";
import CodeOutlinedIcon from "@material-ui/icons/CodeOutlined";
import EmojiEmotionsOutlinedIcon from "@material-ui/icons/EmojiEmotionsOutlined";
import SubtitlesOutlinedIcon from "@material-ui/icons/SubtitlesOutlined";
import TitleOutlinedIcon from "@material-ui/icons/TitleOutlined";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import TransformOutlinedIcon from "@material-ui/icons/TransformOutlined";
import LinkOutlinedIcon from "@material-ui/icons/LinkOutlined";

import { useStyles } from "../common/sharedStyles";

import {
  urlReportData,
  buttonGroupPivots,
  buttonGroups,
  URLReportSections,
} from "../../../models/report/url/urlReport";
import { ReportType } from "../../../models/report/reportType";
import { ScreenshotView } from "./views/ScreenshotView";
import { ParsedError, parseErrorTitle } from "../common/ParsedError";
import { PivotPayload } from "./views/PivotPayloadView";
import { ClipboardValue, ClipboardButton } from "../common/ClipboardButton";
import { DecodeButton } from "../common/DecodeButton";

export type URLReportProps = { md5: string };

export function URLReport({ md5 }: URLReportProps) {
  const [retry, setRetry] = useState(0);
  const classes = useStyles();
  const theme = useTheme();
  const initialHiddenMenu = useMediaQuery(theme.breakpoints.down("xl"));

  // initialize the redux hook
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { responseData, reportType, error } = useSelector(urlReportSelector);

  // will run on first mount
  useEffect(() => {
    dispatch(fetchReport(ReportType.URL, md5));
  }, [dispatch, md5, retry]);

  // show a loading bar until it loads, and show the error if there is one
  if (
    !error &&
    (!responseData || !reportType || reportType !== ReportType.URL)
  ) {
    return (
      <>
        {t("Loading")} ...
        <LinearProgress />
      </>
    );
  } else if (error) {
    return (
      <Card>
        <CardHeader
          className={classes.ch}
          avatar={<Avatar className={classes.errorIcon}>!</Avatar>}
          title={parseErrorTitle(error)}
        />
        <CardContent>
          <ParsedError error={error} />
        </CardContent>
        <CardActions>
          <Button size="small" onClick={() => setRetry(retry + 1)}>
            {t("Retry")}
          </Button>
        </CardActions>
      </Card>
    );
  }

  return (
    <Box>
      <URLViewSelect
        responseData={responseData}
        hideMenu={initialHiddenMenu}
        md5={md5}
      />
    </Box>
  );
}

export type URLViewSelectProps = {
  responseData: urlReportData;
  md5: string;
  hideMenu: boolean;
};

export function ShortButtons({ buttonGroups, buttonGroupVals, setView, show }) {
  const classes = useStyles();

  return (
    <React.Fragment>
      {buttonGroups.map((val, i) => {
        return buttonGroupVals[val] != null ? (
          <Badge
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            badgeContent={buttonGroupVals[val]}
            color="secondary"
            key={i}
          >
            <Tooltip title={val} placement="top" arrow>
              <Button
                className={classes.shortFormMenu}
                color="primary"
                onClick={() => setView(val)}
                style={val === show ? selectedTabStyle : null}
              >
                <ShortButtonVal val={val} />
              </Button>
            </Tooltip>
          </Badge>
        ) : null;
      })}
    </React.Fragment>
  );
}

// this defines the style of the selected tab.
const selectedTabStyle = {
  backgroundColor: "white",
  border: "1px solid",
  borderBottom: "none",
  borderRadius: "0",
};

function ShortButtonVal({ val }) {
  switch (val) {
    case "SUMMARY":
      return <ListAltOutlinedIcon />;
    case "COMMUNICATIONS":
      return <ForumOutlinedIcon />;
    case "DNS_RESOLUTIONS":
      return <DnsOutlinedIcon />;
    case "SCREENSHOT":
      return <FullscreenOutlinedIcon />;
    case "DOM":
      return <CodeOutlinedIcon />;
    case "SOURCE_TEXT":
      return <SubtitlesOutlinedIcon />;
    case "TRANSFORMATIONS":
      return <TransformOutlinedIcon />;
    case "PAYLOAD":
      return <GetAppOutlinedIcon />;
    case "ACTUAL_URL":
      return <LinkOutlinedIcon />;
    case "FAVICON":
      return <EmojiEmotionsOutlinedIcon />;
    case "TITLE":
      return <TitleOutlinedIcon />;
    default:
      return <>{val.substr(0, 2)}</>;
  }
}

export function URLViewSelect({
  responseData,
  md5,
  hideMenu,
}: URLViewSelectProps) {
  const { t } = useTranslation();
  const classes = useStyles();

  //set the initial menuHidden based on the breakpoint (use width, but pass it from the MD5Report to the SystemsView)
  // if small or xs, we don't want to see the menu initially
  const [menuHidden, setMenuHidden] = useState(hideMenu);
  const [faviconLoadError, setFaviconLoadError] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    if (
      "" === location.hash.substr(1) ||
      !Object.values(URLReportSections)
        .map((s) => "" + s)
        .includes(location.hash.substr(1))
    )
      window.location.replace("#" + URLReportSections.Summary);

    // set view when back button pressed

    if (location.hash) {
      setTheShow(
        URLReportSections[
          Object.keys(URLReportSections).find(
            (key) => URLReportSections[key] === location.hash.substr(1)
          )
        ]
      );
    }
  }, [location, responseData, md5, hideMenu, navigate, location.hash]);

  // this sucks, but it's becasue we are using a enum for URLReportSections
  const [show, setTheShow] = useState(
    URLReportSections[
      Object.keys(URLReportSections).find(
        (key) => URLReportSections[key] === location.hash.substr(1)
      )
    ]
  );

  const setShow = (s) => {
    setTheShow(s);
    // store in browser history,
    navigate(location.pathname + "#" + s);
    // window.location.hash = s;
  };

  const toggleMenu = () => {
    setMenuHidden(!menuHidden);
  };
  const myRef = useRef(null);
  const setView = (view) => {
    setShow(view);
    // window.scrollTo({ top: myRef.current.offsetTop - 50, behavior: "smooth" });
  };

  // TODO  - this area needs to be refactored to useState rather than whatever it's doing now
  // if the value is null, then it is not shown - if 0, no badge on top
  let buttonGroupVals = {};
  buttonGroups.flatMap((val, i) => {
    switch (val) {
      case URLReportSections.Summary:
        buttonGroupVals[val] = 0;
        break;
      case URLReportSections.Communications:
        buttonGroupVals[val] = responseData.communications
          ? responseData.communications.length +
            Object.values(responseData.communications).reduce(
              (accumulator, communicationEntry) =>
                accumulator + communicationEntry.children.length,
              0
            )
          : null;
        break;
      case URLReportSections.DNSResolutions:
        buttonGroupVals[val] = responseData.report.domainResolutions
          ? responseData.report.domainResolutions.length
          : null;
        break;
      case URLReportSections.Screenshot:
        // TODO - determine how to get 0 or 1 here
        buttonGroupVals[val] = responseData.screenshotLinks
          ? responseData.screenshotLinks.length
            ? responseData.screenshotLinks.length
            : 0
          : 0;
        break;
      case URLReportSections.Dom:
        buttonGroupVals[val] = responseData.domamnt;
        break;
      case URLReportSections.SourceText:
        // have to verify that there really is source text, sometimes you can have dom but no source text
        if (responseData.domamnt)
          buttonGroupVals[val] = responseData.sourceText
            ? +(responseData.sourceText !== "")
            : null;
        else buttonGroupVals[val] = responseData.domamnt;
        break;
      default:
        buttonGroupVals[val] = 0;
    }
    return null;
  });

  // now figure out what number to show next to the pivot stuff
  let buttonGroupPivotsVals = {};
  buttonGroupPivots.flatMap((val, i) => {
    switch (val) {
      case URLReportSections.Transformations:
        buttonGroupPivotsVals[val] =
          responseData.report.originalURLsFromTransformedURL?.length +
          responseData.report.transformedURLsFromOriginalURL?.length;
        break;
      case URLReportSections.Payload:
        buttonGroupPivotsVals[val] =
          responseData.report.samePayloadURLs?.length;
        break;
      case URLReportSections.ActualURL:
        buttonGroupPivotsVals[val] =
          responseData.numActualURL +
          (responseData?.report?.requestedURLs
            ? responseData?.report?.requestedURLs.length
            : 0);
        break;
      case URLReportSections.FavIcon:
        buttonGroupPivotsVals[val] = faviconLoadError
          ? 0
          : responseData.numSameFavicon;
        break;
      case URLReportSections.Title:
        buttonGroupPivotsVals[val] = responseData.numSameTitle;
        break;
      default:
        buttonGroupPivotsVals[val] = 0;
    }
    return null;
  });

  return (
    <Box>
      <Box hidden={!menuHidden}>
        <Button onClick={toggleMenu}>
          <MenuIcon />
          &nbsp;Menu
        </Button>
        <ShortButtons
          buttonGroups={buttonGroups}
          buttonGroupVals={buttonGroupVals}
          setView={setView}
          show={show}
        />
        <ShortButtons
          buttonGroups={buttonGroupPivots}
          buttonGroupVals={buttonGroupPivotsVals}
          setView={setView}
          show={show}
        />
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={8} md={3} lg={2} hidden={menuHidden}>
          <Paper>
            <Button onClick={toggleMenu}>
              <MenuOpenIcon />
              &nbsp;Menu
            </Button>
            <List>
              {buttonGroups.map((val, i) => {
                return buttonGroupVals[val] != null ? (
                  <ListItem key={"urlreportbutton" + i}>
                    <Badge
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                      badgeContent={buttonGroupVals[val]}
                      color="secondary"
                    >
                      <Button
                        className={classes.nm}
                        color="primary"
                        onClick={() => setView(val)}
                      >
                        {t("url.report." + val)}
                      </Button>
                    </Badge>
                  </ListItem>
                ) : null;
              })}
              <ListItem>PIVOTS:</ListItem>
              {buttonGroupPivots.map((val, i) => {
                return buttonGroupPivotsVals[val] != null ? (
                  <ListItem key={"urlreportbutton" + i}>
                    <Badge
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                      badgeContent={buttonGroupPivotsVals[val]}
                      color="secondary"
                    >
                      <Button
                        className={classes.nm}
                        color="primary"
                        onClick={() => setView(val)}
                      >
                        {t("url.report." + val)}
                      </Button>
                    </Badge>
                  </ListItem>
                ) : null;
              })}
            </List>
          </Paper>
        </Grid>
        <Grid
          ref={myRef}
          item
          xs={12}
          md={menuHidden ? 12 : 9}
          lg={menuHidden ? 12 : 10}
        >
          <Box mb={2}>
            <Card>
              <CardHeader className={classes.ch} title={t("URL Details")} />
              <CardContent>
                <TableContainer>
                  <Table size="small">
                    <TableBody>
                      <TableRow>
                        <TableCell>URL</TableCell>
                        <TableCell className={classes.breakword}>
                          <LinkWrap noBeaverLink>
                            {responseData.report?.artifact?.url}
                          </LinkWrap>
                          <DecodeButton>
                            <ClipboardButton
                              value={responseData.report?.artifact?.url}
                            />
                          </DecodeButton>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>URL MD5</TableCell>
                        <TableCell>
                          <ClipboardValue value={responseData.md5} />
                        </TableCell>
                      </TableRow>
                      {responseData.reportErrors &&
                      responseData.reportErrors.length ? (
                        <TableRow>
                          <TableCell>{t("Errors")}</TableCell>
                          <TableCell>
                            <ul style={{ paddingLeft: "1em" }}>
                              {responseData.reportErrors.map((r, i) => (
                                <li>
                                  <span style={{ fontWeight: "bold" }}>
                                    {r.bt} - {r.errorMessage}
                                  </span>
                                </li>
                              ))}
                            </ul>
                          </TableCell>
                        </TableRow>
                      ) : null}
                      {!!responseData.report.spiderReport?.communication
                        ?.actualURL && (
                        <>
                          <TableRow>
                            <TableCell>{t("Actual URL")}</TableCell>
                            <TableCell className={classes.breakword}>
                              <LinkWrap noBeaverLink>
                                {
                                  responseData.report.spiderReport.communication
                                    .actualURL
                                }
                              </LinkWrap>
                              <DecodeButton>
                                <ClipboardButton
                                  value={
                                    responseData.report.spiderReport
                                      .communication.actualURL
                                  }
                                />
                              </DecodeButton>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{t("Actual URL MD5")}</TableCell>
                            <TableCell>
                              <ClipboardValue
                                value={MD5(
                                  responseData.report.spiderReport.communication
                                    .actualURL
                                ).toString()}
                              />
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                      {URLReportSections.Title === show &&
                      responseData.report.urlTitle ? (
                        <TableRow>
                          <TableCell>{t("Title")}</TableCell>
                          <TableCell className={classes.breakword}>
                            <Box
                              onClick={() => setShow(URLReportSections.Title)}
                              style={{ cursor: "pointer" }}
                            >
                              {responseData.report.urlTitle}
                            </Box>
                          </TableCell>
                        </TableRow>
                      ) : null}
                      {URLReportSections.Title === show &&
                      responseData.dom &&
                      responseData.dom.match(
                        /<[tT][iI][tT][lL][eE]>.*<\/[tT][iI][tT][lL][eE]>/
                      ) ? (
                        <TableRow>
                          <TableCell>
                            {t("Title") + " (" + t("from DOM") + ")"}
                          </TableCell>
                          <TableCell>
                            <Box
                              onClick={() => setShow(URLReportSections.Dom)}
                              style={{ cursor: "pointer" }}
                            >
                              {responseData.dom
                                .match(
                                  /<[tT][iI][tT][lL][eE]>.*<\/[tT][iI][tT][lL][eE]>/
                                )[0]
                                .replace(/<\/?[tT][iI][tT][lL][eE]>/g, "")}
                            </Box>
                          </TableCell>
                        </TableRow>
                      ) : null}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Box>
          <ShowURLSection
            show={show}
            setShow={setShow}
            data={responseData}
            md5={md5}
            setFaviconLoadError={setFaviconLoadError}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export type ShowURLSectionProps = {
  show: URLReportSections;
  setShow: any;
  //TODO - this data shouldn't be any
  data: any;
  md5: string;
  setFaviconLoadError: Function;
};

export function ShowURLSection({
  show,
  setShow,
  data,
  md5,
  setFaviconLoadError,
}: ShowURLSectionProps) {
  const { t } = useTranslation();
  switch (show) {
    case URLReportSections.Summary:
      return (
        <URLSummary
          report={data.report}
          screenshotLinks={data.screenshotLinks}
          iconResources={data.iconResources}
          setShow={setShow}
          dom={data.dom}
          setFaviconLoadError={setFaviconLoadError}
        />
      );
    case URLReportSections.Dom:
      return <DOMView dom={data.dom} title="Document Object Model (DOM)" />;
    case URLReportSections.SourceText:
      return <DOMView dom={data.sourceText} title="Source Text" />;
    case URLReportSections.Communications:
      return <CommunicationsView comms={data.communications} />;
    case URLReportSections.Title:
      return <PivotTitleView md5={md5} />;
    case URLReportSections.FavIcon:
      return (
        <PivotFaviconView
          md5={md5}
          iconResources={data.iconResources}
          setFaviconLoadError={setFaviconLoadError}
        />
      );
    case URLReportSections.Screenshot:
      return (
        <ScreenshotView
          screenshotLinks={data.screenshotLinks}
          md5={md5}
          cluster={data.report.status.clusterName}
        />
      );
    case URLReportSections.Transformations:
      return (
        <TransformationsView
          origURL={data.report.originalURLsFromTransformedURL}
          transURL={data.report.transformedURLsFromOriginalURL}
        />
      );
    case URLReportSections.DNSResolutions:
      return (
        <SimpleTableCardView
          title={t("DNS Resolutions")}
          data={data.report.domainResolutions.map((r) =>
            r.ipLocation
              ? {
                  ip: r.ipLocation.ip,
                  countryCode: r.ipLocation.countryCode,
                  city: r.ipLocation.city,
                  asName: r.ipLocation.asName,
                  asNumber: r.ipLocation.asNumber,
                  addedOn: r.addedOn,
                  domain: r.domain,
                }
              : { ...r }
          )}
          headers={[
            {
              title: t("Domain"),
              field: "domain",
              render: (r) => (
                <Link to={["/domain", r.domain, "report"].join("/")}>
                  <LinkWrap>{r.domain}</LinkWrap>
                </Link>
              ),
            },
            {
              title: t("IP"),
              field: "ip",
              render: (r) => (
                <Link to={["/ip", r.ip, "report"].join("/")}>
                  <LinkWrap>{r.ip}</LinkWrap>
                </Link>
              ),
            },
            {
              title: t("Country Code"),
              field: "countryCode",
              render: (r) => (
                <Flag
                  style={{ maxHeight: "1rem" }}
                  countryCode={r.countryCode}
                />
              ),
            },
            { title: t("City"), field: "city" },
            { title: t("ASN"), field: "asNumber" },
            { title: t("AS Name"), field: "asName" },
            { title: t("Added On"), field: "addedOn" },
          ]}
        />
      );
    case URLReportSections.Payload:
      return (
        <PivotPayload
          samePayloadURLs={data.report.samePayloadURLs}
          payloadDetails={data.payloadDetails}
        />
      );
    case URLReportSections.ActualURL:
      return (
        <PivotActualURLView
          md5={md5}
          requestedURLs={
            data?.report?.requestedURLs ? data?.report?.requestedURLs : []
          }
        />
      );
    default:
      return <URLTBD show={show} setShow={setShow} />;
  }
}

export type URLTBDProps = {
  show: URLReportSections;
  setShow: any;
};

export function URLTBD({ show, setShow }: URLTBDProps) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Card>
      <CardHeader
        className={classes.ch}
        avatar={<Avatar className={classes.errorIcon}>:(</Avatar>}
        title={
          t("The") +
          " '" +
          (buttonGroupPivots.includes(show) ? "Pivot: " : "") +
          t("url.report." + show) +
          "' " +
          t("View Not Done Yet")
        }
      />
      <CardContent>
        <p>
          {t("Sorry, the content type view")} '{show}'{" "}
          {t("is not complete (yet)")}.
        </p>
        <p>
          {t(
            "If you believe this is an error or need this view more urgently, please email the BeAVER / INO team!"
          )}
        </p>
      </CardContent>
    </Card>
  );
}
