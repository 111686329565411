import React from "react";
import Axios from "axios";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  LinearProgress,
  Modal,
  Paper,
  Tooltip,
} from "@material-ui/core";
import _ from "lodash";
import { useStyles } from "../../common/sharedStyles";
import { useTranslation } from "react-i18next";

import { yaraAssemblyLineRule } from "components/beaver/models/report/md5/md5ReportModel";
import {
  SimpleTableCardView,
  SimpleTableView,
} from "../../common/SimpleTableView";

// icon for pivot
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
// icon for details
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import useAppLayout from "commons/components/hooks/useAppLayout";
import {
  YaraPivotJSON,
  YaraRuleFullJSON,
} from "components/beaver/helpers/ServerProperties";
import { Link } from "react-router-dom";
import { LinkWrap } from "../../common/LinkWrap";
import { ClipboardButton } from "../../common/ClipboardButton";

export function YaraAssemblyLineView({
  yaraAssemblyLineRules,
  testing,
  title,
}: {
  yaraAssemblyLineRules: yaraAssemblyLineRule[];
  testing?: boolean;
  // alt title e.g. for when there are both Yara and AssemblyLine Yara Rules
  title?: string;
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [showRuleId, setShowRuleId] = React.useState(null);
  const [showRuleName, setShowRuleName] = React.useState(null);
  const [typeToShow, setTypeToShow] = React.useState(null);

  return (
    <Box>
      <Modal
        open={showRuleId && "pivot" === typeToShow ? true : false}
        onClose={() => {
          setTypeToShow(null);
          setShowRuleId(null);
        }}
      >
        <Paper
          style={{
            overflow: "auto",
            maxHeight: "80vh",
            width: "80vw",
            display: "block",
            margin: "auto",
            marginTop: "7vh",
          }}
        >
          <YaraRulePivot
            showRuleId={showRuleId}
            showRuleName={showRuleName}
            setTypeToShow={setTypeToShow}
            testing={testing}
          />
        </Paper>
      </Modal>

      <Modal
        open={showRuleId && "json" === typeToShow ? true : false}
        onClose={() => {
          setTypeToShow(null);
          setShowRuleId(null);
        }}
      >
        <Paper
          style={{
            overflow: "auto",
            maxHeight: "80vh",
            width: "80vw",
            display: "block",
            margin: "auto",
            marginTop: "7vh",
          }}
        >
          <YaraRuleFullJsonView
            showRuleId={showRuleId}
            showRuleName={showRuleName}
            setTypeToShow={setTypeToShow}
          />
        </Paper>
      </Modal>
      <SimpleTableCardView
        testing={testing}
        title={title ? title : "Yara Rules"}
        headers={[
          {
            title: "",
            field: "yaraRuleId",
            render: (r) => (
              <span>
                <Tooltip title={t("MD5s with this Yara Rule")}>
                  <Button
                    color="primary"
                    onClick={() => {
                      if (r.yaraRuleId === showRuleId && "pivot" === typeToShow)
                        setShowRuleId(null);
                      else {
                        setShowRuleId(r.yaraRuleId);
                        setShowRuleName(r.ruleName);
                        setTypeToShow("pivot");
                      }
                    }}
                  >
                    <SearchOutlinedIcon />
                  </Button>
                </Tooltip>
                <Tooltip title={t("JSON for this Yara Rule")}>
                  <Button
                    color="primary"
                    onClick={() => {
                      if (r.yaraRuleId === showRuleId && "json" === typeToShow)
                        setShowRuleId(null);
                      else {
                        setShowRuleId(r.yaraRuleId);
                        setShowRuleName(r.ruleName);
                        setTypeToShow("json");
                      }
                    }}
                  >
                    <MoreHorizOutlinedIcon />
                  </Button>
                </Tooltip>
              </span>
            ),
          },
          {
            title: t("Added On"),
            field: "addedOn",
          },

          {
            title: t("Rule ID"),
            field: "ruleId",
            render: (r) => (
              <span className={classes.breakword}>{r.ruleId}</span>
            ),
          },
          {
            title: t("Rule Version"),
            field: "ruleVersion",
          },
          {
            title: t("Rule Description"),
            field: "ruleDescription",
            render: (r) => (
              <span className={classes.breakword}>{r.ruleDescription}</span>
            ),
          },
          {
            title: t("Rule Name"),
            field: "ruleName",
            render: (r) => (
              <span className={classes.breakword}>{r.ruleName}</span>
            ),
          },
          {
            title: t("Sharing"),
            field: "sharing",
          },
          {
            title: t("Category"),
            field: "category",
            render: (r) =>
              r.category +
              (r.malwareType ? " / " + r.malwareType : "") +
              (r.malware ? " - " + r.malware : ""),
          },

          {
            title: t("Actor Type"),
            field: "actorType",
          },
        ]}
        data={_.cloneDeep(yaraAssemblyLineRules)}
      />
    </Box>
  );
}

export function YaraRulePivot({
  showRuleId,
  showRuleName,
  setTypeToShow,
  testing,
}: {
  showRuleId: number;
  showRuleName: string;
  setTypeToShow: Function;
  testing?: boolean;
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { currentUser } = useAppLayout();
  const title = t("MD5s with Yara Rule") + " '" + showRuleName + "'";
  const [results, setResults] = React.useState<any[]>(null);
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    setResults(null);
    setError(null);

    Axios.get(YaraPivotJSON(showRuleId), {
      auth:
        currentUser.server && !currentUser.server.includes("localhost")
          ? {
              username: currentUser ? currentUser.username : null,
              password: currentUser
                ? currentUser.onetime // eslint-disable-next-line no-eval
                  ? window
                      // eslint-disable-next-line no-eval
                      .atob(eval(window.atob(currentUser.onetime)))
                      .substr(13)
                  : null
                : null,
            }
          : null,
    }).then(
      (res) => setResults(res.data),
      (err) => {
        console.log(err);
        setError(true);
        // at this point, see if the error is 401, if so, logout the user.
        if (err.response && err.response.status === 401) {
          setTimeout(() => {
            if (localStorage.ssoPrefix) localStorage.removeItem("ssoPrefix");
            if (localStorage.currentUser)
              localStorage.removeItem("currentUser");
            window.location.reload();
          }, 1000);
        }
      }
    );
  }, [currentUser, showRuleId]);

  return (
    <Card>
      <CardHeader
        className={classes.ch}
        title={
          <Box>
            <Box display="inline" style={{ float: "right" }}>
              <Button onClick={() => setTypeToShow(null)}>
                <CancelOutlinedIcon />
              </Button>
            </Box>
            {title}
          </Box>
        }
      />
      <CardContent>
        {!error && !results && (
          <Box>
            {t("Loading")} ...
            <LinearProgress />
          </Box>
        )}
        {error && <p>{t("An error occured")}</p>}
        {results && (
          <SimpleTableView
            headers={[
              {
                title: t("Sample MD5"),
                field: "sampleMd5",
                render: (r) => (
                  <Link to={["/md5", r.sampleMd5, "report"].join("/")}>
                    <LinkWrap>{r.sampleMd5}</LinkWrap>
                  </Link>
                ),
              },
              { title: t("Added On"), field: "addedOn" },
            ]}
            data={results}
            testing={testing}
          />
        )}
      </CardContent>
    </Card>
  );
}

export function YaraRuleFullJsonView({
  showRuleId,
  showRuleName,
  setTypeToShow,
}: {
  showRuleId: number;
  showRuleName: string;
  setTypeToShow: Function;
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { currentUser } = useAppLayout();
  const title = t("Full JSON for Yara Rule") + " '" + showRuleName + "'";
  const [results, setResults] = React.useState(null);
  const [error, setError] = React.useState(false);
  const [resultIndex, setResultIndex] = React.useState(0);

  React.useEffect(() => {
    setResults(null);
    setError(null);
    setResultIndex(0);

    Axios.get(YaraRuleFullJSON(showRuleId), {
      auth:
        currentUser.server && !currentUser.server.includes("localhost")
          ? {
              username: currentUser ? currentUser.username : null,
              password: currentUser
                ? currentUser.onetime // eslint-disable-next-line no-eval
                  ? window
                      // eslint-disable-next-line no-eval
                      .atob(eval(window.atob(currentUser.onetime)))
                      .substr(13)
                  : null
                : null,
            }
          : null,
    }).then(
      (res) => setResults(res.data),
      (err) => {
        console.log(err);
        setError(true);
        // at this point, see if the error is 401, if so, logout the user.
        if (err.response && err.response.status === 401) {
          setTimeout(() => {
            if (localStorage.ssoPrefix) localStorage.removeItem("ssoPrefix");
            if (localStorage.currentUser)
              localStorage.removeItem("currentUser");
            window.location.reload();
          }, 1000);
        }
      }
    );
  }, [currentUser, showRuleId]);

  return (
    <Box mb={2}>
      <Card>
        <CardHeader
          className={classes.ch}
          title={
            <Box>
              <Box display="inline" style={{ float: "right" }}>
                <Button onClick={() => setTypeToShow(null)}>
                  <CancelOutlinedIcon />
                </Button>
              </Box>
              {title}{" "}
              {results && (
                <Tooltip
                  arrow
                  placement="bottom"
                  title={t("Copy Yara Rule to Clipboard")}
                >
                  <Box display="inline">
                    <ClipboardButton
                      value={JSON.stringify(results[0], null, 2)}
                    />
                  </Box>
                </Tooltip>
              )}
            </Box>
          }
        />
        <CardContent>
          {!error && !results && (
            <Box>
              {t("Loading")} ...
              <LinearProgress />
            </Box>
          )}
          {error && <p>{t("An error occured")}</p>}
          {results && results.length === 1 && (
            <Box>
              <pre className={classes.pre}>
                {JSON.stringify(results[0], null, 2)}
              </pre>
            </Box>
          )}
          {/* There can sometimes be only one or many versions of a rule, so show them all if there is more than one*/}
          {results && results.length > 1 && (
            <Box>
              {resultIndex > 0 && (
                <Button
                  color="primary"
                  onClick={() => setResultIndex(resultIndex - 1)}
                >
                  {t("Previous")}
                </Button>
              )}
              {resultIndex < results.length - 1 && (
                <Button
                  color="primary"
                  onClick={() => setResultIndex(resultIndex + 1)}
                >
                  {t("Next")}
                </Button>
              )}
              <pre className={classes.pre}>
                {JSON.stringify(results[resultIndex], null, 2)}
              </pre>
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
}
